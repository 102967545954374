import React from 'react';
import { makeStyles, TableCell, TableHead, TableRow } from '@material-ui/core';

export interface HeaderType {
  id: string;
  title: string;
  width: number | string;
}

interface HeaderColumns {
  columns: Array<HeaderType>;
}

/* ************ Style ************ */
const useStyles = makeStyles(() => ({
  head: {
    backgroundColor: '#CFCFCF',
  },
}));

const MachineStationTableHead: React.FC<HeaderColumns> = React.memo(({ columns }) => {
  const classes = useStyles();

  return (
    <TableHead>
      <TableRow>
        {columns.map((column) => (
          <TableCell
            key={`machine_list_table_head_key_${column.id}`}
            style={{ width: column.width, whiteSpace: 'pre-line' }}
            className={classes.head}>
            {column.title}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
});

export default MachineStationTableHead;
