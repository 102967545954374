import COMMON from 'constants/common';
import React, { useEffect, useState } from 'react';
import Layout from 'components/system/layouts/Layout';
import ScreenContext from 'Contexts/Common/ScreenContext';
import MachineConfContent from 'components/machineConf/organisms/contents/MachineConfContent';
import { Box, makeStyles } from '@material-ui/core';
import { ReturnButton } from 'components/system/atoms';
import redirectLinks from 'constants/redirectLinks';
import { useHistory, useLocation } from 'react-router-dom';
import UrlHelper from 'utils/url.helper';
import CLSFCN from 'constants/classification';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import { handleCallSystemError } from 'redux/slices/commonSlice';
import ERROR_CODE from 'constants/errorCode';
import { getClassificationName } from 'utils/common.helper';
import { SaveDetailInfoType } from 'types/machineConf/stationSelectType';

/* ************ Context ************ */
const ScreenContextValue = {
  title: 'その他設定',
  screenId: COMMON.SCREEN_ID.OTHER_CONF_GRAND,
  currentMenu: COMMON.MENU.MACHINE_CONF,
  disableContents: true,
};

/* ************ Style ************ */
const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    minWidth: '1080px',
    minHeight: '563px',
    margin: '18px 24px',
  },
  topContent: {
    display: 'flex',
    alignItems: 'center',
    '& .description': {
      fontSize: '23px',
      marginLeft: '36px',
    },
  },
  middleContent: {
    marginTop: '48px',
    marginLeft: 20,
    '& .row1, .row2': {
      display: 'flex',
      justifyContent: 'start',
      marginBottom: '28px',
    },
  },
  groupContainer: {
    display: 'flex',
    flexDirection: 'column',
    width: 330,
    height: 138,
    borderRadius: 8,
    border: `1px solid ${theme.base.tableBorder}`,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: '#FFFBDF',
  },
  settingBtnMargin: {
    marginRight: '24px',
  },
  textTitle: {
    fontSize: 21,
    lineHeight: '21px',
    marginBottom: 20,
  },
  textContainer: {
    display: 'flex',
    justifyContent: 'center',
    width: 176,
    height: 65,
    border: `2px solid ${theme.grandStation.itemBorder}`,
    alignItems: 'center',
    borderRadius: 4,
    fontSize: 19,
    backgroundColor: '#fff',
  },
}));

/* ******** Sub Component ******** */
type BoxPartType = {
  title: string;
  value: string | undefined;
  categoryCd: string;
};

const BoxPartComponent: React.FC<BoxPartType> = (props) => {
  const { title, value, categoryCd } = props;
  const classes = useStyles();

  return (
    <Box className={`${classes.groupContainer} ${classes.settingBtnMargin}`}>
      <Box className={classes.textTitle}>{title}</Box>
      <Box className={classes.textContainer}>{value && getClassificationName(categoryCd, value)}</Box>
    </Box>
  );
};

/* ******** Main Component ******** */
/**
 * @returns {React.FC} - その他設定
 * @constructor
 */
const OtherConfGrand: React.FC = () => {
  const classes = useStyles();
  const history = useHistory();
  const location = useLocation();
  const [savedConfProps] = useState(location.state !== undefined ? (location.state as SaveDetailInfoType) : null);
  const dispatch = useAppDispatch();

  /* ************ State ************ */
  // Global
  const ledServiceOtherConf = useAppSelector((state) => state.machineConf.machineGrandConf.ledServiceOtherConf);

  /* ************ Event ************ */
  useEffect(() => {
    if (!ledServiceOtherConf) {
      dispatch(handleCallSystemError({ errorNo: ERROR_CODE.NOT_FOUND }));
    }
  }, []);

  // 戻るボタン
  const handleClickReturn = () => {
    history.push(
      UrlHelper.convertQueryUrlFrontEnd(redirectLinks.MCONF_GRAND_HOME_DETAIL, {
        selectConfId: 'none',
      }),
    );
  };

  return (
    <ScreenContext.Provider value={ScreenContextValue}>
      <Layout>
        <MachineConfContent savedConfProps={savedConfProps}>
          <Box className={classes.root}>
            <Box className={classes.topContent}>
              <ReturnButton onClick={handleClickReturn}>
                機器設定
                <br />
                詳細
              </ReturnButton>
              <Box className="description">「運営停止」「各種センサ」など運営に関わる設定内容です。</Box>
            </Box>
            <Box className={classes.middleContent}>
              <Box className="row1">
                <BoxPartComponent
                  title="ステーション停止"
                  value={ledServiceOtherConf?.stationStop}
                  categoryCd={CLSFCN.STATION_STOP.CATEGORY_CD}
                />
                <BoxPartComponent
                  title="ペイアウトセンサ"
                  value={ledServiceOtherConf?.payoutSensor}
                  categoryCd={CLSFCN.ON_OFF.CATEGORY_CD}
                />
              </Box>
              <Box className="row2">
                <BoxPartComponent
                  title="アームサイズセンサ"
                  value={ledServiceOtherConf?.armSizeSensor}
                  categoryCd={CLSFCN.ON_OFF.CATEGORY_CD}
                />
                <BoxPartComponent
                  title="キャッチャー回転センサ"
                  value={ledServiceOtherConf?.catcherRotationSensor}
                  categoryCd={CLSFCN.ON_OFF.CATEGORY_CD}
                />
              </Box>
            </Box>
          </Box>
        </MachineConfContent>
      </Layout>
    </ScreenContext.Provider>
  );
};

export default OtherConfGrand;
