import React, { useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import { useHistory, useLocation } from 'react-router-dom';
import { Box, makeStyles } from '@material-ui/core';

import COMMON from 'constants/common';
import MachineConfContext from 'Contexts/MachineConf/MachineConfContext';
import ScreenContext from 'Contexts/Common/ScreenContext';
import MachineConfContent from 'components/machineConf/organisms/contents/MachineConfContent';
import Layout from 'components/system/layouts/Layout';
import { ReturnButton } from 'components/system/atoms';

import PitPinpointPanelGrand from 'components/machineConf/organisms/Pit/PitPinpointPanelGrand';
import PitPinpointInfoGrand from 'components/machineConf/organisms/Pit/PitPinpointInfoGrand';
import { handleCallSystemError } from 'redux/slices/commonSlice';

import ERROR_CODE from 'constants/errorCode';
import AreaDetailGrand from 'components/machineConf/organisms/Area/AreaDetailGrand';
import BoundAreaDetailGrand from 'components/machineConf/organisms/Area/BoundAreaDetailGrand';
import UrlHelper from 'utils/url.helper';
import redirectLinks from 'constants/redirectLinks';
import { SaveDetailInfoType } from 'types/machineConf/stationSelectType';

const SIZE = {
  POSITION_CONTROLLER: '52px',
  PIT_ICON: '24px',
  PANEL: '390px',
};

const useStyles = makeStyles((theme) => ({
  content: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    margin: '18px 24px',
    minHeight: '563px',
    minWidth: '1080px',
  },
  topArea: {
    display: 'flex',
    height: '100px',
    '& .description': {
      position: 'absolute',
      top: '12px',
      left: '220px',
      lineHeight: '28px',
      fontSize: '23px',
      '& .doneImage': {
        width: '56px',
        height: '28px',
        padding: '2px 0px',
        borderRadius: '4px',
        fontSize: '16px',
        textAlign: 'center',
        verticalAlign: 'middle',
        display: 'inline-block',
        background: theme.palette.common.black,
        color: theme.palette.common.white,
      },
    },
  },
  mainArea: {
    display: 'flex',
    '& .settingPattern': {
      position: 'absolute',
      top: '100px',
      left: '0px',
      width: '386px',
      height: '80px',
      fontSize: '30px',
      borderRadius: '12px',
    },
    '& .pinpointInfo': {
      position: 'absolute',
      top: '188px',
      left: '0px',
      width: '386px',
      '& .description': {
        height: '64px',
        lineHeight: '28px',
        fontSize: '23px',
      },
    },
    '& .pitConfArea': {
      position: 'absolute',
      top: '100px',
      left: '438px',
    },
    '& .doneArea': {
      position: 'absolute',
      top: '464px',
      left: '900px',
    },
  },
  mainLeftArea: {
    width: '386px',
  },
  pinpointArea: {
    position: 'relative',
    '& .pit1': {
      position: 'absolute',
      top: '0px',
      left: '32px',
    },
    '& .pit2': {
      position: 'absolute',
      top: '0px',
      left: '200px',
    },
  },
  pinpointInfoLine: {
    display: 'flex',
    justifyContent: 'space-evenly',
    alignItems: 'center',
    marginBottom: '8px',
  },
  mainPitArea: {
    position: 'relative',
    marginTop: '-40px',
    marginLeft: '-40px',
    fontSize: '18px',
    height: '390px',
    width: '390px',
    '& .areaDetail': {
      position: 'absolute',
      top: '28px',
      left: '28px',
    },
    '& .pitPanel': {
      position: 'absolute',
      top: '0px',
      left: '0px',
      width: `calc(${SIZE.PANEL} + 80px)`,
      height: `calc(${SIZE.PANEL} + 80px)`,
      padding: '40px',
    },
    '& .minPositionXY': {
      position: 'absolute',
      top: '438px',
      left: (props: { leftRight: string | undefined }) =>
        props.leftRight === COMMON.LEFT_RIGHT.LEFT ? '8px' : '438px',
      width: '32px',
      textAlign: (props: { leftRight: string | undefined }) =>
        props.leftRight === COMMON.LEFT_RIGHT.LEFT ? 'end' : 'start',
    },
    '& .maxPositionX': {
      position: 'absolute',
      top: '438px',
      left: (props: { leftRight: string | undefined }) =>
        props.leftRight === COMMON.LEFT_RIGHT.LEFT ? '438px' : '8px',
      width: '32px',
      textAlign: (props: { leftRight: string | undefined }) =>
        props.leftRight === COMMON.LEFT_RIGHT.LEFT ? 'start' : 'end',
    },
    '& .maxPositionY': {
      position: 'absolute',
      top: '8px',
      left: (props: { leftRight: string | undefined }) =>
        props.leftRight === COMMON.LEFT_RIGHT.LEFT ? '8px' : '438px',
      width: '32px',
      textAlign: (props: { leftRight: string | undefined }) =>
        props.leftRight === COMMON.LEFT_RIGHT.LEFT ? 'end' : 'start',
    },
    '& .pinpointPanel': {
      position: 'absolute',
      top: '28px',
      left: '28px',
      width: `calc(${SIZE.PANEL} + ${SIZE.PIT_ICON})`,
      height: `calc(${SIZE.PANEL} + ${SIZE.PIT_ICON})`,
    },
    '& .xController': {
      position: 'absolute',
      top: '438px',
      left: '144px',
    },
    '& .yController': {
      position: 'absolute',
      top: '144px',
      left: (props: { leftRight: string | undefined }) =>
        props.leftRight === COMMON.LEFT_RIGHT.LEFT
          ? '-20px'
          : `calc(${SIZE.POSITION_CONTROLLER} + ${SIZE.PANEL} + ${SIZE.PIT_ICON})`,
    },
  },
  buttonLabel: {
    color: '#000000',
  },
}));

const ScreenContextValue = {
  title: '落とし穴設定画面',
  screenId: COMMON.SCREEN_ID.PIT_CONF_GRAND,
  currentMenu: COMMON.MENU.MACHINE_CONF,
  disableContents: true,
};

/* ********** Constants ********** */
const INPUT_NAMES = {
  SETTING_PATTERN: 'settingPattern',
  PIT_1_STATUS: 'pit1Status',
  PIT_2_STATUS: 'pit2Status',
};

const PitConfGrand: React.FC = () => {
  const history = useHistory();
  const location = useLocation();
  const [savedConfProps] = useState(location.state !== undefined ? (location.state as SaveDetailInfoType) : null);
  const dispatch = useAppDispatch();
  const pitConf = useAppSelector((state) => state.machineConf.machineGrandConf.pitConf);

  if (!pitConf) {
    dispatch(handleCallSystemError({ errorNo: ERROR_CODE.NOT_FOUND }));
    return <></>;
  }
  const areaConf = useAppSelector((state) => state.machineConf.machineGrandConf.areaConf);
  const leftRight = useAppSelector((state) => state.machineConf.selectedStation.leftRight);
  const classes = useStyles({ leftRight });
  const [pit1, setPit1] = useState({ x: pitConf.pit1X, y: pitConf.pit1Y });
  const [pit2, setPit2] = useState({ x: pitConf.pit2X, y: pitConf.pit2Y });

  const MachineConfContextValue = { leftRight };

  /* ************ Event ************ */
  useEffect(() => {
    setPit1({ x: pitConf.pit1X, y: pitConf.pit1Y });
    setPit2({ x: pitConf.pit2X, y: pitConf.pit2Y });
  }, [pitConf]);

  // 戻るボタン
  const handleClickReturn = () => {
    history.push(
      UrlHelper.convertQueryUrlFrontEnd(redirectLinks.MCONF_GRAND_HOME_DETAIL, {
        selectConfId: 'none',
      }),
    );
  };

  const pinpointPitList = [
    {
      title: '落とし穴１',
      status: pitConf.pit1Status,
      pitX: pit1.x,
      pitY: pit1.y,
      inputName: INPUT_NAMES.PIT_1_STATUS,
    },
    {
      title: '落とし穴２',
      status: pitConf.pit2Status,
      pitX: pit2.x,
      pitY: pit2.y,
      inputName: INPUT_NAMES.PIT_2_STATUS,
    },
  ];

  return (
    <ScreenContext.Provider value={ScreenContextValue}>
      <Layout>
        <MachineConfContext.Provider value={MachineConfContextValue}>
          <MachineConfContent savedConfProps={savedConfProps}>
            <Box className={classes.content}>
              <Box className={classes.topArea}>
                {/* 戻るボタン */}
                <Box>
                  <ReturnButton onClick={handleClickReturn}>
                    機器設定
                    <br />
                    詳細
                  </ReturnButton>
                </Box>
                {/* 操作説明 */}
                <Box className="description">「落とし穴」の設定内容です。</Box>
              </Box>
              <Box className={classes.mainArea}>
                <Box className={classes.mainLeftArea}>
                  <Box className="pinpointInfo">
                    {/* ピンポイント設定 */}
                    <Box className={classes.pinpointArea}>
                      {pinpointPitList.map((pinpointInfo, index) => {
                        const pinpointIndex = index + 1;
                        const key = `pit${String(pinpointIndex)}`;
                        return (
                          <Box key={key} className={key}>
                            <PitPinpointInfoGrand
                              index={index}
                              title={pinpointInfo.title}
                              status={pinpointInfo.status}
                              pitX={pinpointInfo.pitX}
                              pitY={pinpointInfo.pitY}
                            />
                          </Box>
                        );
                      })}
                    </Box>
                  </Box>
                </Box>
                {/* パネル 落とし穴設定 */}
                <Box className="pitConfArea">
                  <Box className={classes.mainPitArea}>
                    <AreaDetailGrand
                      className="areaDetail"
                      leftRight={leftRight}
                      size="L"
                      areaConf={areaConf}
                      defaultBorderColor
                      isDefault={areaConf && areaConf.areaConf === COMMON.SWITCH.OFF}
                      borderColor="#707070"
                    />

                    <>
                      <BoundAreaDetailGrand
                        className="areaDetail"
                        isLeft={leftRight === COMMON.LEFT_RIGHT.LEFT}
                        size="CM"
                      />
                      <Box className="minPositionXY">0</Box>
                      <Box className="maxPositionX">100</Box>
                      <Box className="maxPositionY">100</Box>
                      <PitPinpointPanelGrand
                        className="pinpointPanel"
                        pitConf={pitConf}
                        size="CM"
                        strokeColor="#707070"
                        strokeWidth={2}
                      />
                    </>
                  </Box>
                </Box>
              </Box>
            </Box>
          </MachineConfContent>
        </MachineConfContext.Provider>
      </Layout>
    </ScreenContext.Provider>
  );
};

export default PitConfGrand;
