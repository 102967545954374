import { makeStyles, Table, TableBody, TableCell, TableContainer, TableRow, Theme } from '@material-ui/core';
import { useHistory, useLocation } from 'react-router-dom';
import redirectLinks from 'constants/redirectLinks';
import React, { useCallback, useState } from 'react';
import NormalButton from 'components/system/atoms/buttons/NormalButton';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import {
  cancelMachineConfThunk,
  getStationListThunk,
  setAutoSelectedStationList,
  setMachineStatusBaseConf,
  setMachineStatusSelectStation,
  setSelectedStationInfo,
  setSelectedStationList,
} from 'redux/slices/machineConfSlice';
import { StationDetailType, StationTableRowType, StationTableType } from 'types/machineConf/stationSelectType';
import UrlHelper from 'utils/url.helper';
import ListCheckBox from 'components/system/atoms/checkBoxs/ListCheckBox';
import ListRadio from 'components/system/atoms/radioButtons/ListRadio';
import { ConfirmDialog } from 'components/system/organisms';
import MESSAGES from 'constants/messages';
import COMMON from 'constants/common';
import TooltipComponent from 'components/system/organisms/dialogs/TooltipComponent';
import { getMachineVersion, getStationName, renderMachineType } from 'utils/common.helper';
import MachineStationTableHead from 'components/data/organisms/tables/common/MachineStationTableHead';
import { MachineSelectStationNameType } from 'types/group/GroupType';

/**
 * StationTable Component
 * ステーション一覧
 * @author atsushi.teruya
 *
 */

type StyleProps = {
  isLeftClena3?: boolean;
  isRightClena3?: boolean;
};

/* ************ Style ************ */
const useStyles = makeStyles<Theme, StyleProps>((theme) => ({
  container: {
    height: '434px',
    // 1080pxにしても実際のサイズがあわないので+20pxする。
    width: '1100px',
  },
  table: {
    tableLayout: 'fixed',
    borderCollapse: 'separate',
    '& th,td': {
      border: '1px solid',
      borderRightColor: theme.base.tableBorder,
      borderLeftColor: theme.base.tableBorder,
      borderTopColor: ({ isRightClena3 }) => `${isRightClena3 ? '#FFFFFF !important' : theme.base.tableBorder}`,
      borderBottomColor: ({ isLeftClena3 }) => `${isLeftClena3 ? '#FFFFFF !important' : theme.base.tableBorder}`,
    },
    '& td.noneBorderTop': {
      borderTopColor: '#FFFFFF !important',
    },
    '& td.noneBorderBottom': {
      borderBottomColor: '#FFFFFF !important',
    },
    '& th': {
      textAlign: 'center',
      fontSize: '23px',
      lineHeight: '28px',
      padding: 0,
    },
    '& th:last-child': {
      textAlign: 'left',
      paddingLeft: '176px',
      // レイアウトに合わせるため、1行目を字下げして中央揃えの見た目にする
      textIndent: '-2em',
    },
    '& tbody tr': {
      height: '58px',
    },
    '& tbody tr:nth-child(odd)': {
      backgroundColor: theme.base.tableRowPrimary,
    },
    '& tbody tr:nth-child(even)': {},
    '& td': {
      fontSize: '15px',
      lineHeight: '23px',
      '& .cellStyle': {
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'fade',
      },
    },
  },
  tdSelectStation: {
    textAlign: 'center',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    height: '58px',
  },
  tdStation: {
    textAlign: 'left',
    padding: '0 8px 0 0',
  },
  tdBoardSerial: {
    textAlign: 'center',
    borderTopColor: theme.base.tableBorder,
    borderBottomColor: theme.base.tableBorder,
    padding: '0 8px',
  },
  tdBoardSerialNoneBorderTop: {
    borderTopColor: '#FFFFFF !important',
  },
  tdBoardSerialNoneBorderBottom: {
    borderBottomColor: '#FFFFFF !important',
  },
  tdMachineType: {
    textAlign: 'left',
    borderTopColor: theme.base.tableBorder,
    borderBottomColor: theme.base.tableBorder,
    padding: '0 8px',
  },
  tdMachineTypeNoneBorderTop: {
    borderTopColor: '#FFFFFF !important',
  },
  tdMachineTypeNoneBorderBottom: {
    borderBottomColor: '#FFFFFF !important',
  },
  tdReceiveWaiting: {
    textAlign: 'center',
  },
  tdBaseConfSelect: {
    textAlign: 'center',
  },
  tdConfDetail: {
    textAlign: 'center',
  },
  tdStatus: { textAlign: 'center' },
  tdCorrespondenceStatus: { textAlign: 'center', padding: '0px 6px 0px 6px !important' },
  tdCorrespondenceDate: {
    textAlign: 'center',
  },
  selectedBaseConf: { backgroundColor: '#FFFF84' },
  confDetail: {
    backgroundColor: '#B8FFC9',
    borderColor: '#B8FFC9',
    color: theme.palette.common.black,
    height: '40px',
    width: '120px',
    boxShadow: '5px 5px 3px 0px rgba(0, 0, 0, 0.5)',
    '&:hover': {
      backgroundColor: '#B8FFC9',
      boxShadow: '5px 5px 3px 0px rgba(0, 0, 0, 0.5)',
    },
  },
  receiveWaiting: {
    backgroundColor: '#B8FFC9',
    borderColor: '#B8FFC9',
    color: theme.palette.common.black,
    height: '40px',
    width: '120px',
    boxShadow: '5px 5px 3px 0px rgba(0, 0, 0, 0.5)',
    '&:hover': {
      backgroundColor: '#B8FFC9',
      boxShadow: '5px 5px 3px 0px rgba(0, 0, 0, 0.5)',
    },
  },
  '&:hover': {
    backgroundColor: '#B8FFC9',
    boxShadow: '5px 5px 3px 0px rgba(0, 0, 0, 0.5)',
  },
  stationSelect: {
    width: '56px',
  },
  station: {
    width: '280px',
    textAlign: 'left',
  },
  receiveWaitingWrap: {
    width: '120px',
  },
  baseConfSelect: {
    width: '200px',
    marginLeft: '8px',
  },
  machineVersion: {
    width: '76px',
  },
  confDetailWrap: {
    width: '120px',
    marginLeft: '8px',
  },
  giftNameWrap: {
    paddingTop: '5px',
    flex: 1,
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    display: '-webkit-box',
    '-webkit-line-clamp': 1,
    '-webkit-box-orient': 'vertical',
    'word-break': 'break-all',
  },
}));

/* ************ Component ************ */
/* ********    Table Item     ******** */
/**
 * 受取待機列
 * 設定送信後プライズ機から設定通知が来るまでの間、受取待機中を表示する。
 *
 * @author atsushi.teruya
 * @param {number} - receiveWaiting 仮置き
 * @returns {React.FC} - ステーション一覧の明細行
 *
 */
const ReceiveWaitingColumn: React.FC<{ receiveWaiting: number; confId: number; stationId: number }> = (props) => {
  const classes = useStyles({ isRightClena3: false });
  const { receiveWaiting } = props;
  const { stationId } = props;
  const loginUserInfo = useAppSelector((state) => state.common.userInfo);
  const { machineTypeFilter, stationListSort } = useAppSelector((state) => state.machineConf);
  const dispatch = useAppDispatch();
  const handleReceiveWaitingClick = () => setOpenConfirmDialog(true);
  const [openConfirmDialog, setOpenConfirmDialog] = useState(false);
  const handleCloseInfoDialog = (): void => {
    setOpenConfirmDialog(false);
  };

  // 受取待機中 OKボタン クリック
  const handleClickOk = async (): Promise<void> => {
    setOpenConfirmDialog(false);
    await dispatch(
      cancelMachineConfThunk({
        stationId,
        headquartersId: loginUserInfo?.currentHeadQId,
        storeId: loginUserInfo?.currentStoreId,
      }),
    );
    dispatch(setAutoSelectedStationList()); // 選択中ステーションを更新
    // Re-load station list when Cancel Machine Config
    dispatch(
      getStationListThunk({
        headquartersId: loginUserInfo?.currentHeadQId,
        storeId: loginUserInfo?.currentStoreId,
        machineType: machineTypeFilter,
        sort: stationListSort,
      }),
    );
  };
  return receiveWaiting === 1 ? (
    <div>
      <NormalButton className={classes.receiveWaiting} onClick={handleReceiveWaitingClick}>
        受取待機中
      </NormalButton>
      <ConfirmDialog
        open={openConfirmDialog}
        msg={MESSAGES.INF205}
        onOk={handleClickOk}
        onCancel={handleCloseInfoDialog}
      />
    </div>
  ) : null;
};

/* ******** Control Component ******** */
/**
 * ステーション一覧 明細行
 * ステーション一覧の行を出力する。
 * memo：基準設定が変更された行のみ再描画する。
 *
 * @author atsushi.teruya
 * @param {StationTableType} -  {row:ステーション一覧, index：対象の行, selectedBaseConfRowIdx：選択中の基準設定がある行のindex}
 * @returns {React.FC} - ステーション一覧の明細行
 *
 */
const StationTableRow: React.FC<StationTableRowType> = React.memo(({ row, index, selectedList, showCheckbox }) => {
  const isClena3 = row.machineType === COMMON.MACHINE_TYPE.CLENA3;
  const isJack = row.machineType === COMMON.MACHINE_TYPE.JACK;
  const isLeftClena3 = isClena3 && row.leftRight === COMMON.LEFT_RIGHT.LEFT;
  const isRightClena3 = isClena3 && row.leftRight === COMMON.LEFT_RIGHT.RIGHT;
  const isShowCheckBoxAndRadio = showCheckbox && (isClena3 || isJack);

  const classes = useStyles({ isRightClena3, isLeftClena3 });

  const dispatch = useAppDispatch();
  const history = useHistory();
  const location = useLocation();

  const tdBoardSerial = `${classes.tdBoardSerial} ${isRightClena3 ? classes.tdBoardSerialNoneBorderTop : ''} ${
    isLeftClena3 ? classes.tdBoardSerialNoneBorderBottom : ''
  }`;

  const tdBoardSerialSecond = `${isRightClena3 ? classes.tdBoardSerialNoneBorderTop : ''}`;

  const tdMachineType = `${classes.tdMachineType} ${isRightClena3 ? classes.tdMachineTypeNoneBorderTop : ''} ${
    isLeftClena3 ? classes.tdMachineTypeNoneBorderBottom : ''
  }`;

  /* ************ Event ************ */
  // ステーション選択(チェックボックス)
  const handleChangeSelectStation = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      dispatch(
        setMachineStatusSelectStation({
          index,
          value: Number(event.target.value),
        }),
      );
    },
    [index],
  );

  // 基準設定ラジオボタン選択
  const handleChangeBaseConf = useCallback(
    // 選択結果をステートに反映
    (event: React.ChangeEvent<HTMLInputElement>) => {
      dispatch(
        setMachineStatusBaseConf({
          index,
          value: Number(event.target.value),
        }),
      );
    },
    [index],
  );

  // 設定詳細ボタンクリック
  const onClickConfDetail = useCallback(() => {
    // 基準設定のステーションを抽出
    let baseConf: StationDetailType | undefined;
    if (row.machineType === COMMON.MACHINE_TYPE.GRAND) {
      baseConf = row;
    } else {
      baseConf = selectedList.find((s) => s.baseConfSelect === s.stationId);
    }

    if (baseConf) {
      // 基準設定のステーションを登録
      dispatch(
        setSelectedStationInfo({
          stationId: baseConf.stationId ?? '',
          stationName: baseConf.stationName ?? '',
          giftName: baseConf.giftName ?? '',
          leftRight: baseConf.leftRight,
          boardStation: baseConf.boardStation,
          machineType: baseConf.machineType,
          machineVersion: baseConf.machineVersion,
        }),
      );
    }

    // 選択中のステーションを登録
    if (row.machineType === COMMON.MACHINE_TYPE.GRAND) {
      dispatch(setSelectedStationList([row]));
    } else {
      dispatch(setSelectedStationList(selectedList));
    }

    // 画面用のurl変換メソッドを利用し、遷移する
    let redirect = redirectLinks.MCONF_STATION_HOME_DETAIL;
    switch (row.machineType) {
      case COMMON.MACHINE_TYPE.JACK:
        redirect = redirectLinks.MCONF_JACK_HOME_DETAIL;
        break;
      case COMMON.MACHINE_TYPE.GRAND:
        redirect = redirectLinks.MCONF_GRAND_HOME_DETAIL;
        break;
      default:
        break;
    }

    history.push(
      UrlHelper.convertQueryUrlFrontEnd(redirect, {
        selectConfId: String(row.confId),
      }),
      { from: location.pathname },
    );
  }, [row.stationId, selectedList]);

  const getStationNameFunc = React.useCallback(
    ({ machineType, stationName, leftRight }: MachineSelectStationNameType): string =>
      `${getStationName(machineType, stationName, leftRight)}`,
    [],
  );

  return (
    <>
      <TableRow>
        <TableCell className={tdBoardSerial}>
          {isRightClena3 || (
            <>
              <div className="cellStyle">{row.boardSerial}</div>
              <div className="cellStyle">{!isJack ? `(Ver.${getMachineVersion(row.machineVersion)})` : null}</div>
            </>
          )}
        </TableCell>
        <TableCell className={tdMachineType}>
          {isRightClena3 || <div className="cellStyle">{renderMachineType(row.machineType)}</div>}
        </TableCell>
        <TableCell className={`${classes.tdStation} ${tdBoardSerialSecond}`}>
          <div className={classes.tdSelectStation}>
            {/** 選択チェックボックス（受取待機中の場合は選択できない） */}
            <div className={classes.stationSelect}>
              {isShowCheckBoxAndRadio && (
                <ListCheckBox
                  checked={row.confReceive === COMMON.CONF_RECEIVE.WAITING ? false : !!row.selectStation}
                  name="selectStation"
                  onChange={handleChangeSelectStation}
                  disabled={row.confReceive === COMMON.CONF_RECEIVE.WAITING}
                />
              )}
            </div>
            <div className={classes.station}>
              <TooltipComponent
                tooltipText={row.giftName}
                displayWord={`${getStationNameFunc(row as MachineSelectStationNameType)}`}
                isBlank
              />
              <div className={classes.giftNameWrap}>{row.giftName ?? COMMON.BLANK}</div>
            </div>
            <div className={classes.receiveWaitingWrap}>
              {row.confReceive === COMMON.CONF_RECEIVE.WAITING && (
                <ReceiveWaitingColumn confId={row.confId} stationId={row.stationId} receiveWaiting={1} />
              )}
            </div>
            <div className={classes.baseConfSelect}>
              {/* 基準の設定にする。 ラジオボタンの表示制御 ステーション選択時のみ表示 */}
              {row.selectStation && isShowCheckBoxAndRadio ? (
                <ListRadio
                  checked={row.stationId === row.baseConfSelect}
                  className={row.stationId === row.baseConfSelect ? classes.selectedBaseConf : ''}
                  name="baseConf"
                  value={row.stationId}
                  onChange={handleChangeBaseConf}
                />
              ) : null}
            </div>
            <div className={classes.confDetailWrap}>
              <NormalButton className={classes.confDetail} onClick={onClickConfDetail}>
                設定詳細
              </NormalButton>
            </div>
          </div>
        </TableCell>
      </TableRow>
    </>
  );
});

/* ******** Main Component ******** */
/**
 * ステーション一覧
 * ステーションの一覧を表示する
 *
 * @author atsushi.teruya
 * @param {StationTableType} -  {stationList:ステーション一覧, selectedBaseConfRowIdx：選択中の基準設定がある行のindex}
 * @returns {React.FC} - ステーション一覧
 *
 */
const StationTable: React.FC<StationTableType> = ({
  stationList,
  selectedBaseConfRowIdx,
  tableId,
  showCheckbox = true,
}) => {
  const classes = useStyles({ isRightClena3: false });
  /* ************ state/redux ************ */

  /* ************ Event ************ */

  // テーブル列定義 idは利用しないが定義する。
  const headerColumns = [
    { id: 'machineNo', title: '登録番号', width: 143 },
    { id: 'machineType', title: '機種名', width: 141 },
    { id: 'station', title: `ステーション名\n景品名`, width: '100%' },
  ];

  return (
    <>
      <TableContainer id={tableId} className={classes.container}>
        <Table className={classes.table} stickyHeader aria-label="sticky table">
          <MachineStationTableHead columns={headerColumns} />
          <TableBody>
            {stationList.map((row, index) => (
              <StationTableRow
                key={String(index)}
                row={row}
                index={index}
                selectedBaseConfRowIdx={selectedBaseConfRowIdx}
                selectedList={stationList.filter((value: StationDetailType) => value.selectStation)}
                showCheckbox={showCheckbox}
              />
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};

export default StationTable;
