import { makeStyles, Table, TableBody, TableCell, TableContainer, TableRow } from '@material-ui/core';
import React from 'react';
import NormalButton from 'components/system/atoms/buttons/NormalButton';
import ListCheckBox from 'components/system/atoms/checkBoxs/ListCheckBox';
import { ConfListType } from 'types/machineConf/machineConfType';
import COMMON from 'constants/common';
import { getMachineVersion } from 'utils/common.helper';
import { themeColor } from 'constants/theme';

/* ************ Style ************ */
const useStyles = makeStyles((theme) => ({
  container: {
    height: '350px',
    width: '1100px',
  },
  table: {
    tableLayout: 'fixed',
    border: '1px solid',
    borderColor: theme.base.tableBorder,
    '& th,td': {
      borderBottom: '1px solid',
      borderBottomColor: theme.base.tableBorder,
      fontSize: '23px',
    },
    '& tbody tr': {
      height: '58px',
      backgroundColor: theme.base.tableRowPrimary,
    },
    '& td': {
      padding: '0px 8px 0px 8px',
      '& .cellStyle': {
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
      },
    },
  },
  tdConfValue: {
    textAlign: 'center',
  },
  tdConfName: {
    textAlign: 'left',
  },
  tdReflect: {
    textAlign: 'center',
  },
  tdDetailSetting: {
    textAlign: 'center',
  },
  rowButton: {
    height: '40px',
    width: '120px',
    fontSize: '22px',
    boxShadow: '5px 5px 3px 0px rgba(0, 0, 0, 0.5)',
    '&.green': {
      backgroundColor: themeColor.button.green,
      borderColor: themeColor.button.green,
      color: theme.palette.common.black,
      '&:hover': {
        backgroundColor: themeColor.button.green,
        boxShadow: '5px 5px 3px 0px rgba(0, 0, 0, 0.5)',
      },
    },
    '&.black': {
      backgroundColor: themeColor.button.black,
      borderColor: themeColor.button.black,
      color: theme.palette.common.white,
      whiteSpace: 'nowrap',
      '&:hover': {
        backgroundColor: themeColor.button.black,
        boxShadow: '5px 5px 3px 0px rgba(0, 0, 0, 0.5)',
      },
    },
  },
}));

/* ************ Component ************ */
type SavedConfTableType = {
  savedConfList: Array<ConfListType>;
  tableId?: string;
  machineType: string | null;
  confReceive?: string;
  handleSelectedItemChange: (row: ConfListType) => void;
  handleClickReflect: (row: ConfListType) => void;
  handleClickSettingDetail: (row: ConfListType) => void;
};

const SavedConfTable: React.FC<SavedConfTableType> = ({
  savedConfList,
  tableId,
  machineType,
  confReceive,
  handleSelectedItemChange,
  handleClickReflect,
  handleClickSettingDetail,
}) => {
  const classes = useStyles();
  const cols = [
    { id: 'confValue', width: 56 },
    { id: 'confName', width: 648 },
    { id: 'machineVersion', width: 88 },
    { id: 'reflect', width: 136 },
    { id: 'detailSetting', width: 152 },
  ];

  const renderButtonReflect = (row: ConfListType) => {
    if (confReceive !== COMMON.CONF_RECEIVE.NORMAL) return <></>;
    if (machineType === COMMON.MACHINE_TYPE.GRAND) {
      return (
        <>
          <NormalButton className={`${classes.rowButton} black`} onClick={() => handleClickReflect(row)}>
            設定を送信
          </NormalButton>
        </>
      );
    }
    return (
      <>
        <NormalButton className={`${classes.rowButton} green`} onClick={() => handleClickReflect(row)}>
          反映
        </NormalButton>
      </>
    );
  };

  return (
    <>
      <TableContainer id={tableId} className={classes.container}>
        <Table className={classes.table} stickyHeader aria-label="sticky table">
          {cols.map((column) => (
            <col key={column.id} style={{ width: column.width }} />
          ))}
          <TableBody>
            {savedConfList.map((row) => {
              const key = `saved_conf_table_key_${row.value}`;
              return (
                <React.Fragment key={key}>
                  <TableRow key={key}>
                    <TableCell className={classes.tdConfValue}>
                      <ListCheckBox name="confIdList" onChange={() => handleSelectedItemChange(row)} />
                    </TableCell>
                    <TableCell className={classes.tdConfName}>
                      <div className="cellStyle">{row.name}</div>
                    </TableCell>
                    <TableCell>
                      {/* クレナ3ジャックのステーションはmachineVersion非表示 */}
                      {/* バージョン情報が無い場合は、「Ver.D」を表示する */}
                      {row.machineType === COMMON.MACHINE_TYPE.CLENA3 || row.machineType === COMMON.MACHINE_TYPE.GRAND
                        ? `(Ver.${getMachineVersion(row.machineVersion, true)})`
                        : null}
                    </TableCell>
                    <TableCell className={classes.tdReflect}>{renderButtonReflect(row)}</TableCell>
                    <TableCell className={classes.tdDetailSetting}>
                      <NormalButton
                        className={`${classes.rowButton} green`}
                        onClick={() => handleClickSettingDetail(row)}>
                        設定詳細
                      </NormalButton>
                    </TableCell>
                  </TableRow>
                </React.Fragment>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};

export default SavedConfTable;
