import { Box, makeStyles } from '@material-ui/core';
import React from 'react';
import COMMON from 'constants/common';
import { themeColor } from 'constants/theme';

// 機器名ラベルに適用するCSSを定義
const useStyles = makeStyles(() => ({
  label: {
    borderRadius: '4px',
    height: '20px',
    minWidth: '72px',
    textAlign: 'center',
    verticalAlign: 'middle',
    fontSize: '12px',
    paddingTop: '2px',
    fontWeight: 'bold',
  },
}));

const getMachineLabel = (
  machineType: string | null,
): {
  machineName: string;
  color: string;
} => {
  switch (machineType) {
    case COMMON.MACHINE_TYPE.CLENA3:
      return { machineName: 'クレナ3', color: themeColor.leftDarkColor };
    case COMMON.MACHINE_TYPE.JACK:
      return { machineName: 'ジャック', color: themeColor.rightDarkColor };
    case COMMON.MACHINE_TYPE.GRAND:
      return { machineName: 'グラン', color: themeColor.grandDarkColor };
    default:
      return { machineName: '', color: '' };
  }
};

// ラベル定義
const MachineNameLabel: React.FC<{ machineType: string | null }> = ({ machineType }) => {
  const labelColor = getMachineLabel(machineType);
  const classes = useStyles();

  return (
    <Box className={classes.label} style={{ backgroundColor: labelColor.color }}>
      {labelColor.machineName}
    </Box>
  );
};

export default MachineNameLabel;
