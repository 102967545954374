import React, { useState } from 'react';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import { useHistory, useLocation } from 'react-router-dom';
import { Box, makeStyles } from '@material-ui/core';

import COMMON from 'constants/common';
import MachineConfContext from 'Contexts/MachineConf/MachineConfContext';
import ScreenContext from 'Contexts/Common/ScreenContext';
import MachineConfContent from 'components/machineConf/organisms/contents/MachineConfContent';
import Layout from 'components/system/layouts/Layout';
import { ReturnButton } from 'components/system/atoms';

import PitPinpointPanelGrand from 'components/machineConf/organisms/Pit/PitPinpointPanelGrand';
import { handleCallSystemError } from 'redux/slices/commonSlice';

import ERROR_CODE from 'constants/errorCode';
import AreaConfPanelGrand from 'components/machineConf/organisms/Area/AreaConfPanelGrand';
import BoundAreaDetailGrand from 'components/machineConf/organisms/Area/BoundAreaDetailGrand';
import UrlHelper from 'utils/url.helper';
import redirectLinks from 'constants/redirectLinks';
import { SaveDetailInfoType } from 'types/machineConf/stationSelectType';

const SIZE = {
  POSITION_CONTROLLER: '52px',
  PIT_ICON: '24px',
  PANEL: '330px',
};

const useStyles = makeStyles((theme) => ({
  content: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    margin: '18px 24px',
    minHeight: '563px',
    minWidth: '1080px',
  },
  topArea: {
    display: 'flex',
    height: '100px',
    '& .description': {
      position: 'absolute',
      top: '12px',
      left: '220px',
      lineHeight: '28px',
      fontSize: '23px',
      '& .doneImage': {
        width: '56px',
        height: '28px',
        padding: '2px 0px',
        borderRadius: '4px',
        fontSize: '16px',
        textAlign: 'center',
        verticalAlign: 'middle',
        display: 'inline-block',
        background: theme.palette.common.black,
        color: theme.palette.common.white,
      },
    },
  },
  mainArea: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: '20px',
  },
  mainLeftArea: {
    width: '386px',
  },
  mainPitArea: {
    position: 'relative',
    fontSize: '18px',
    height: '372px',
    width: '372px',
    '& .areaDetail': {
      position: 'absolute',
      top: '28px',
      left: '28px',
    },
    '& .minPositionXY': {
      position: 'absolute',
      bottom: '-80px',
      left: (props: { leftRight: string | undefined }) =>
        props.leftRight === COMMON.LEFT_RIGHT.LEFT ? '-8px' : '378px',
      width: '32px',
      textAlign: (props: { leftRight: string | undefined }) =>
        props.leftRight === COMMON.LEFT_RIGHT.LEFT ? 'end' : 'start',
      color: '#111111',
      fontSize: '20px',
      lineHeight: '22px',
    },
    '& .maxPositionX': {
      position: 'absolute',
      bottom: '-80px',
      left: (props: { leftRight: string | undefined }) =>
        props.leftRight === COMMON.LEFT_RIGHT.LEFT ? '378px' : '8px',
      width: '32px',
      textAlign: (props: { leftRight: string | undefined }) =>
        props.leftRight === COMMON.LEFT_RIGHT.LEFT ? 'start' : 'end',
      color: '#111111',
      fontSize: '20px',
      lineHeight: '22px',
    },
    '& .maxPositionY': {
      position: 'absolute',
      top: '8px',
      left: (props: { leftRight: string | undefined }) =>
        props.leftRight === COMMON.LEFT_RIGHT.LEFT ? '-8px' : '378px',
      width: '32px',
      textAlign: (props: { leftRight: string | undefined }) =>
        props.leftRight === COMMON.LEFT_RIGHT.LEFT ? 'end' : 'start',
      color: '#111111',
      fontSize: '20px',
      lineHeight: '22px',
    },
    '& .pinpointPanel': {
      position: 'absolute',
      top: '28px',
      left: '28px',
      width: `calc(${SIZE.PANEL} + ${SIZE.PIT_ICON})`,
      height: `calc(${SIZE.PANEL} + ${SIZE.PIT_ICON})`,
    },
    '& .xController': {
      position: 'absolute',
      top: '378px',
      left: '144px',
    },
    '& .yController': {
      position: 'absolute',
      top: '144px',
      left: (props: { leftRight: string | undefined }) =>
        props.leftRight === COMMON.LEFT_RIGHT.LEFT
          ? '-20px'
          : `calc(${SIZE.POSITION_CONTROLLER} + ${SIZE.PANEL} + ${SIZE.PIT_ICON})`,
    },
  },
  buttonLabel: {
    color: '#000000',
  },
}));

const ScreenContextValue = {
  title: 'エリア設定画面',
  screenId: COMMON.SCREEN_ID.AREA_CONF_GRAND,
  currentMenu: COMMON.MENU.MACHINE_CONF,
  disableContents: true,
};

/* ********** Constants ********** */
const AreaConfGrand: React.FC = () => {
  const history = useHistory();
  const location = useLocation();
  const [savedConfProps] = useState(location.state !== undefined ? (location.state as SaveDetailInfoType) : null);
  const dispatch = useAppDispatch();
  const pitConf = useAppSelector((state) => state.machineConf.machineGrandConf.pitConf);

  const areaConf = useAppSelector((state) => state.machineConf.machineGrandConf.areaConf);
  const leftRight = COMMON.LEFT_RIGHT.LEFT;
  const classes = useStyles({ leftRight });

  const MachineConfContextValue = { leftRight };

  /* ************ Event ************ */

  // 戻るボタン
  const handleClickReturn = () => {
    history.push(
      UrlHelper.convertQueryUrlFrontEnd(redirectLinks.MCONF_GRAND_HOME_DETAIL, {
        selectConfId: 'none',
      }),
    );
  };

  React.useEffect(() => {
    if (!pitConf || !areaConf) {
      dispatch(handleCallSystemError({ errorNo: ERROR_CODE.NOT_FOUND }));
    }
  }, []);

  if (!pitConf) {
    return <></>;
  }

  return (
    <ScreenContext.Provider value={ScreenContextValue}>
      <Layout>
        <MachineConfContext.Provider value={MachineConfContextValue}>
          <MachineConfContent savedConfProps={savedConfProps}>
            <Box className={classes.content}>
              <Box className={classes.topArea}>
                {/* 戻るボタン */}
                <Box>
                  <ReturnButton onClick={handleClickReturn}>
                    機器設定
                    <br />
                    詳細
                  </ReturnButton>
                </Box>
                {/* 操作説明 */}
                <Box className="description">エリアの分割に関する設定内容です。</Box>
              </Box>
              <Box className={classes.mainArea}>
                {/* パネル 落とし穴設定 */}
                <Box>
                  <Box className={classes.mainPitArea}>
                    <AreaConfPanelGrand
                      className="areaDetail"
                      leftRight={leftRight}
                      size="L"
                      areaConf={areaConf}
                      defaultBorderColor
                      isDefault={areaConf && areaConf.areaConf === COMMON.SWITCH.OFF}
                      borderColor="none"
                    />

                    <>
                      <BoundAreaDetailGrand
                        className="areaDetail"
                        isLeft={leftRight === COMMON.LEFT_RIGHT.LEFT}
                        size="L"
                      />
                      <Box className="minPositionXY">0</Box>
                      <Box className="maxPositionX">100</Box>
                      <Box className="maxPositionY">100</Box>
                      <PitPinpointPanelGrand className="pinpointPanel" pitConf={pitConf} />
                    </>
                  </Box>
                </Box>
              </Box>
            </Box>
          </MachineConfContent>
        </MachineConfContext.Provider>
      </Layout>
    </ScreenContext.Provider>
  );
};

export default AreaConfGrand;
