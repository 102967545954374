import { Box, makeStyles } from '@material-ui/core';
import COMMON from 'constants/common';
import React from 'react';
import grandMachine from 'assets/images/grandMachine.svg';

import { ledSelectAreaGrandType } from 'types/machineConf/ledSelectAreaType';

import { getClassificationName } from 'utils/common.helper';
import CLSFCN from 'constants/classification';
import { ledSelectButtonType } from 'types/machineConf/ledSelectButtontype';
import ColorEffectSelectAreaGrand from './ColorEffectSelectAreaGrand';

/* ************ Style ************ */
const useStyles = makeStyles(() => ({
  content: {
    display: 'flex',
    padding: '1px',
  },
  settingArea: {
    '& > *': {
      marginBottom: '8px',
    },
    '& > *:last-child': {
      marginBottom: '0px',
    },
    '&.grayOut': {
      position: 'relative',
    },
  },
  imgArea: {
    position: 'relative',
    textAlign: 'center',
    width: '211px',
    height: '356px',
    top: '50px',
    '& .prizeMachine': {},
  },
  resetButton: {
    width: '116px',
    height: '48px',
    fontSize: '21px',
    marginBottom: '88px',
  },
  disableInputArea: {
    height: '110px',
  },
}));

/* ******** Main Component ******** */
/**
 *
 * @Params leftRight, ledSelectAreaConf, onChange
 * @returns {React.FC} - LED 設定エリア
 *
 */
const LedSelectAreaGrand: React.FC<ledSelectAreaGrandType> = React.memo((props) => {
  const { ledSelectAreaConf } = props;
  const classes = useStyles();

  /* ************ Params ************ */
  const getColorParam = (value: string): ledSelectButtonType => ({
    selectButton: {
      isSelected: false,
    },
    value: getClassificationName(CLSFCN.LED_GRAND_COLOR.CATEGORY_CD, value),
  });

  const getEffectParam = (value: string): ledSelectButtonType => ({
    selectButton: {
      isSelected: false,
    },
    value: getClassificationName(CLSFCN.LED_EFFECT_GRAND.CATEGORY_CD, value),
  });

  return (
    <Box className={classes.content}>
      <Box className={classes.settingArea}>
        <ColorEffectSelectAreaGrand
          selectButtonLR={COMMON.LEFT_RIGHT.LEFT}
          colorSelect={getColorParam(ledSelectAreaConf.lLedColor4)}
          effectSelect={getEffectParam(ledSelectAreaConf.lLedEffect4)}
        />
        <ColorEffectSelectAreaGrand
          selectButtonLR={COMMON.LEFT_RIGHT.LEFT}
          colorSelect={getColorParam(ledSelectAreaConf.lLedColor3)}
          effectSelect={getEffectParam(ledSelectAreaConf.lLedEffect3)}
        />
        <ColorEffectSelectAreaGrand
          selectButtonLR={COMMON.LEFT_RIGHT.LEFT}
          colorSelect={getColorParam(ledSelectAreaConf.lLedColor2)}
          effectSelect={getEffectParam(ledSelectAreaConf.lLedEffect2)}
        />
        <ColorEffectSelectAreaGrand
          selectButtonLR={COMMON.LEFT_RIGHT.LEFT}
          colorSelect={getColorParam(ledSelectAreaConf.lLedColor1)}
          effectSelect={getEffectParam(ledSelectAreaConf.lLedEffect1)}
        />
      </Box>
      <Box className={classes.imgArea}>
        <img src={grandMachine} alt="grandMachine" className="prizeMachine" />
      </Box>
      <Box className={`${classes.settingArea}`}>
        {/* 右ステーション下から4段目は何も表示しない */}
        <Box className={classes.disableInputArea} />
        <ColorEffectSelectAreaGrand
          selectButtonLR={COMMON.LEFT_RIGHT.RIGHT}
          colorSelect={getColorParam(ledSelectAreaConf.rLedColor3)}
          effectSelect={getEffectParam(ledSelectAreaConf.rLedEffect3)}
        />
        <ColorEffectSelectAreaGrand
          selectButtonLR={COMMON.LEFT_RIGHT.RIGHT}
          colorSelect={getColorParam(ledSelectAreaConf.rLedColor2)}
          effectSelect={getEffectParam(ledSelectAreaConf.rLedEffect2)}
        />
        <ColorEffectSelectAreaGrand
          selectButtonLR={COMMON.LEFT_RIGHT.RIGHT}
          colorSelect={getColorParam(ledSelectAreaConf.rLedColor1)}
          effectSelect={getEffectParam(ledSelectAreaConf.rLedEffect1)}
        />
      </Box>
    </Box>
  );
});

export default LedSelectAreaGrand;
