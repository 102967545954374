import React, { useCallback, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { makeStyles, Grid } from '@material-ui/core';
import redirectLinks from 'constants/redirectLinks';
import ReturnButton from 'components/system/atoms/buttons/ReturnButton';
import MsgHelper from 'utils/message.helper';
import MSG from 'constants/messages';
import DecisionButton from 'components/machineConf/atoms/DecisionButton';
import OutlineTextBox from 'components/system/atoms/textBoxs/OutlineTextBox';
import ConfirmDialog from 'components/system/organisms/dialogs/ConfirmDialog';
import InfoDialog from 'components/system/organisms/dialogs/InfoDialog';
import HttpConnection from 'utils/httpConnection';
import { REST_API } from 'constants/apiUrls';
import { useAppDispatch } from 'redux/hooks';
import DialogLoading from 'components/system/organisms/dialogs/DialogLoading';
import CLSFCN from 'constants/classification';

/* ************ Style ************ */
const useStyles = makeStyles(() => ({
  content: {
    height: '598px',
    width: '1140px',
    padding: '0 16px',
  },
  topArea: {
    display: 'flex',
    justifyContent: 'flex-start',
    '& .returnButton': {
      flex: 1,
      padding: '10px 0px 0px 0px',
      width: '224px',
    },
    '& .addNewButton': {
      height: '64px',
      marginLeft: '28px',
    },
  },
  mainArea: {
    height: '430px',
    flexDirection: 'row',
    marginTop: 24,
  },
  decision: {
    textAlign: 'right',
  },
  addNewButton: {
    height: '64px',
  },
  inputRow: {
    display: 'flex',
    alignItems: 'center',
    marginTop: 24,
  },
  labelStyle: {
    fontSize: 28,
    color: '#000000',
    marginRight: 24,
    width: 217,
  },
  labelContent: {
    textAlign: 'right',
  },
  textInputMachineNo: {
    '& .outlinedInput': {
      height: '48px',
      width: 222,
      borderColor: '#000000',
    },
  },
  contentText: { fontSize: '24px' },
}));

type NewMachineValueType = {
  machineNo: string;
};

type SubmitResponseType = {
  publishDatetime: string | undefined;
};

type UpdateMachineResponseType = {
  result: boolean | undefined;
};

type MachineStatusResponseType = {
  status: string | null;
};

// messages
const confirmMsgSave: string = MsgHelper.messageFormat(MSG.INF229);
const messageSaveSuccess: string = MsgHelper.messageFormat(MSG.INF308, '機器', '機器一覧');

const NewMachine: React.FC = () => {
  const history = useHistory();
  const dispatch = useAppDispatch();
  const classes = useStyles();
  const [openSaveConfirmDlg, setOpenSaveConfirmDlg] = React.useState(false);
  const [openDialogLoading, setOpenDialogLoading] = React.useState(false);
  const [openInfoDialog, setOpenInfoDialog] = React.useState(false);
  const [openInfoDialogError, setOpenInfoDialogError] = React.useState(false);
  const [messageInfoDialog, setMessageInfoDialog] = React.useState('');
  const [inputValue, setInputValue] = useState<NewMachineValueType>({
    machineNo: '',
  });
  const handleClickGroupConfTop = () => {
    history.push(redirectLinks.MAINT_MACHINE_LIST);
  };

  const handleSaveButton = () => {
    setOpenSaveConfirmDlg(true);
  };
  const handleChangeInput = (ev: React.ChangeEvent<HTMLInputElement>): void => {
    setInputValue({
      ...inputValue,
      [ev.target.name]: ev.target.value,
    });
  };

  const apiCheckMachineStatus = async (boardSerial: string, publishDateTime: string) => {
    const http = new HttpConnection({ dispatch }, false);
    const data = await http.get<MachineStatusResponseType>(REST_API.MAINTENANCE.MACHINE_STATUS, {
      boardSerial,
      publishDateTime,
    });
    return data.status;
  };

  const handleMachineStatus = (machineStatus: string) => {
    setOpenDialogLoading(false);
    if (machineStatus === CLSFCN.MACHINE_ENTRY_STATUS.TIME_OUT_ERROR) {
      setMessageInfoDialog(MSG.INF237);
      setOpenInfoDialogError(true);
    }
    if (machineStatus === CLSFCN.MACHINE_ENTRY_STATUS.CANCELED) {
      setMessageInfoDialog(MSG.INF236);
      setOpenInfoDialogError(true);
    }
    if (machineStatus === CLSFCN.MACHINE_ENTRY_STATUS.COMPLETED) {
      setMessageInfoDialog(messageSaveSuccess);
      setOpenInfoDialog(true);
    }
  };

  const apiUpdateMachineStatus = async (boardSerial: string) => {
    const http = new HttpConnection({ dispatch });
    const data = await http.post<UpdateMachineResponseType>(REST_API.MAINTENANCE.MACHINE_UPDATE_STATUS, {
      boardSerial,
    });
    if (data.result) {
      setMessageInfoDialog(MSG.INF239);
      setOpenInfoDialogError(true);
    }
  };

  const apiSubmitEntryMachine = async (boardSerial: string) => {
    const http = new HttpConnection({ dispatch });
    const data = await http.post<SubmitResponseType>(REST_API.MAINTENANCE.MACHINE_ENTRY_STATUS, { boardSerial });
    const publishDateTime = data.publishDatetime;
    if (publishDateTime) {
      setOpenDialogLoading(true);
      const machineStatus = await apiCheckMachineStatus(boardSerial, publishDateTime);
      let intervalCheckStatus: NodeJS.Timeout | null = null;
      let count = 1;
      if (machineStatus && machineStatus === CLSFCN.MACHINE_ENTRY_STATUS.SENDING) {
        intervalCheckStatus = setInterval(async () => {
          count += 1;
          let machineStatusNext: string | null = '';
          try {
            machineStatusNext = (await apiCheckMachineStatus(boardSerial, publishDateTime)) ?? '';
          } catch (error) {
            if (intervalCheckStatus) clearInterval(intervalCheckStatus);
          }
          if (machineStatusNext !== null && machineStatusNext !== CLSFCN.MACHINE_ENTRY_STATUS.SENDING) {
            handleMachineStatus(machineStatusNext);
            if (intervalCheckStatus) {
              clearInterval(intervalCheckStatus);
              return;
            }
          }
          if (intervalCheckStatus && count > 5) {
            // Call api update machine status
            setOpenDialogLoading(false);
            clearInterval(intervalCheckStatus);
            apiUpdateMachineStatus(boardSerial);
          }
        }, 8000);
      } else if (machineStatus) {
        handleMachineStatus(machineStatus);
      }
    }
  };

  const handleSubmitData = useCallback(() => {
    setOpenSaveConfirmDlg(false);
    apiSubmitEntryMachine(inputValue.machineNo);
  }, [inputValue]);

  const handleCloseInfoDialog = useCallback(() => {
    setOpenDialogLoading(false);
    handleClickGroupConfTop();
  }, []);

  return (
    <>
      <div className={classes.content}>
        <div className={classes.topArea}>
          <div className="returnButton">
            <ReturnButton onClick={handleClickGroupConfTop}>機器一覧</ReturnButton>
          </div>
        </div>
        <div className={classes.mainArea}>
          <div className={classes.inputRow}>
            <div className={classes.labelStyle}>
              <div className={classes.labelContent}>登録番号</div>
            </div>
            <div>
              <OutlineTextBox
                id="machineNo"
                name="machineNo"
                value={inputValue.machineNo}
                onChange={handleChangeInput}
                className={classes.textInputMachineNo}
              />
            </div>
          </div>
        </div>
        <Grid className={classes.decision}>
          <DecisionButton onClick={handleSaveButton}>登録</DecisionButton>
        </Grid>
      </div>
      <ConfirmDialog
        open={openSaveConfirmDlg}
        msg={confirmMsgSave}
        onOk={handleSubmitData}
        onCancel={() => setOpenSaveConfirmDlg(false)}
        addClass={{
          contentText: classes.contentText,
        }}
      />
      <DialogLoading open={openDialogLoading} titleLoading="機器登録情報送信中・・・" msg={MSG.INF235} />
      <InfoDialog open={openInfoDialog} closeFunc={handleCloseInfoDialog} msg={messageInfoDialog} />
      <InfoDialog open={openInfoDialogError} closeFunc={() => setOpenInfoDialogError(false)} msg={messageInfoDialog} />
    </>
  );
};

export default NewMachine;
