import React, { useCallback, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import Layout from 'components/system/layouts/Layout';
import { Grid, makeStyles } from '@material-ui/core';
import ScreenContext from 'Contexts/Common/ScreenContext';
import COMMON from 'constants/common';
import redirectLinks from 'constants/redirectLinks';
import ReturnButton from 'components/system/atoms/buttons/ReturnButton';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import { NormalSelectBox } from 'components/system/organisms';
import NormalButton from 'components/system/atoms/buttons/NormalButton';
import CLSFCN from 'constants/classification';
import { getClsfcnByCategory, getSortedClsfcnByCategory } from 'utils/common.helper';
import { setMachineListDataSource, setSortMachineList, setSortMachineTypeList } from 'redux/slices/maintenanceSlice';
import { GroupApiResponseType, MachineListApiResponseType } from 'types/group/GroupDataStateType';
import { EquipmentSelectDataTableRow, GroupDataTableRow } from 'types/group/EquipmentSelectType';
import MachineListTable from 'components/maintenance/machine/MachineListTable';
import HttpConnection from 'utils/httpConnection';
import { REST_API } from 'constants/apiUrls';
import ConfirmDialog from 'components/system/organisms/dialogs/ConfirmDialog';
import MsgHelper from 'utils/message.helper';
import MSG from 'constants/messages';
import InfoDialog from 'components/system/organisms/dialogs/InfoDialog';
import MachineInfoDialog, { MachineInfoDialogContent } from 'components/system/organisms/dialogs/MachineInfoDialog';
import MachineInfoDialogJack, {
  MachineInfoDialogContentJack,
} from 'components/system/organisms/dialogs/MachineInfoDialogJack';
import MachineInfoDialogGrand, {
  MachineInfoDialogContentGrand,
} from 'components/system/organisms/dialogs/MachineInfoDialogGrand';
import SettingLinkagePosDialog from 'components/system/organisms/dialogs/SettingLinkagePosDialog';
import { getFileNameFromContentDisposition } from '../../utils/string.helper';
import { DesignatedAuthorityType } from '../../types/maintenance/DesignatedAuthorityType';

/* ************ Context ************ */
const ScreenContextValue = {
  title: '機器一覧',
  screenId: 'SCR521',
  currentMenu: COMMON.MENU.MAINTENANCE,
};

type DeleteObject = {
  stationIds: Array<number>;
  boardIds: Array<string>;
};

type DeleteMachineResponseType = {
  result: number | boolean;
  message?: string;
};

/* ************ Style ************ */
/**
 * 2024/11/21のリリース時はPOS利用はしない方針となりました。
 * その為、POSライセンスCSV出力ボタンとPOS連携筐体設定ボタンは非表示とする。
 * 対応としては、以下のスタイルCSSで設定している「btnPosDownloadCSV」に「visibility: 'hidden',」を追記
 * 表示する場合は、「btnPosDownloadCSV」の「visibility: 'hidden',」を削除する。
 */
const useStyles = makeStyles(() => ({
  content: {
    height: '598px',
    width: '1140px',
    padding: '0 16px',
  },
  topArea: {
    display: 'flex',
    justifyContent: 'flex-start',
    '& .returnButton': {
      flex: 1,
      padding: '10px 0px 0px 0px',
      width: '224px',
    },
    '& .addNewButton': {
      width: '180px',
      height: '64px',
      marginLeft: '28px',
      boxShadow: '5px 5px 3px 0px rgba(0, 0, 0, 0.2)',
    },
    '& .settingLinkageButton': {
      width: '180px',
      height: '64px',
      marginRight: '28px',
      boxShadow: '5px 5px 3px 0px rgba(0, 0, 0, 0.2)',
    },
  },
  mainArea: {
    height: '430px',
    flexDirection: 'row',
  },
  decision: {
    marginTop: '20px',
    display: 'flex',
    justifyContent: 'space-between',
    '& .btnDeleteRow': {
      height: '68px',
      width: '140px',
      backgroundColor: '#E8FF4A',
      boxShadow: '5px 5px 3px 0px rgba(0, 0, 0, 0.2)',
    },
  },
  rightDecision: {
    display: 'flex',
    flexDirection: 'row',
    gap: 20,
    '& .btnPosDownloadCSV': {
      width: '190px',
      visibility: 'hidden',
    },
  },
  buttonSize: {
    fontSize: '22px',
    lineHeight: '24px',
  },
  buttonSizeAddNew: {
    fontSize: '24px',
    lineHeight: '24px',
  },
  plusButton: {
    marginRight: 15,
  },
  boxTextRight: {
    margin: '10px 0px 0px 15px',
  },
  boxTextRightContent: {
    display: 'inline-flex',
  },
  sortWrap: {
    display: 'flex',
    flexDirection: 'column',
  },
  sortSpacing: {
    height: '5px',
  },
  addNewButton: {
    height: '64px',
  },
  contentText: { fontSize: '24px' },
}));

// messages
const confirmMsgSave: string = MsgHelper.messageFormat(MSG.INF226);
const messageInfoDeleteEquipment: string = MsgHelper.messageFormat(MSG.INF112, '機器');
const messageDeleteEquipmentSuccess: string = MsgHelper.messageFormat(MSG.INF304, 'チェックした機器');

const MachineList: React.FC = () => {
  const history = useHistory();
  const dispatch = useAppDispatch();
  const classes = useStyles();
  const machineDatasource = useAppSelector((state) => state.maintenance.machineDatasource);
  const { sortMachineList, sortMachineTypeList } = useAppSelector((state) => state.maintenance);
  const [designatedAuthority, setDesignatedAuthority] = React.useState<number>(0);
  const sortDataSource = getClsfcnByCategory(CLSFCN.MACHINE_SORT.CATEGORY_CD).filter(
    (item) =>
      ![CLSFCN.MACHINE_SORT.UNREGISTERED_PRIORITY, CLSFCN.MACHINE_SORT.REGISTERED_PRIORITY].includes(item.value),
  );
  const sortMachineDataSource = getSortedClsfcnByCategory(CLSFCN.MACHINE_TYPE_FILTER.CATEGORY_CD);
  const [openDeleteConfirmDlg, setOpenDeleteConfirmDlg] = React.useState<boolean>(false);
  const [openDeleteInfoDlg, setOpenDeleteInfoDlg] = React.useState<boolean>(false);
  const [openDeleteDlg, setOpenDeleteDlg] = React.useState<boolean>(false);
  const [messageDeleteDlg, setMessageDeleteDlg] = React.useState<string>(messageDeleteEquipmentSuccess);
  const [machineInfoDialog, setMachineInfoDialog] = React.useState<MachineInfoDialogContent>({
    openDialog: false,
    machineInfo: undefined,
  });
  const [machineInfoDialogJack, setMachineInfoDialogJack] = React.useState<MachineInfoDialogContentJack>({
    openDialog: false,
    machineInfo: undefined,
  });
  const [machineInfoDialogGrand, setMachineInfoDialogGrand] = React.useState<MachineInfoDialogContentGrand>({
    openDialog: false,
    machineInfo: undefined,
  });
  const [messageConfirmDeleteEquipment, setMessageConfirmDeleteEquipment] = React.useState<string>('');
  const [openSettingDlg, setOpenSettingDlg] = React.useState<boolean>(false);

  /* ************ API ************ */
  const getEquipmentData = async (sortType: string, filterType: string) => {
    const http = new HttpConnection({ dispatch });
    const data = await http.get<Array<MachineListApiResponseType>>(REST_API.MAINTENANCE.GET_MACHINE_LIST, {
      sort: sortType,
      machineType: filterType,
    });
    let machineDataListFormat: Array<EquipmentSelectDataTableRow> = [];
    if (data.length > 0) {
      machineDataListFormat = data.map((machineItem) => ({
        key: machineItem.boardSerial,
        checked: false,
        machineNo: machineItem.boardSerial,
        machineType: machineItem.machineType,
        machineName: machineItem.machineName,
        leftStationKey: machineItem.leftStationId,
        leftStationName: machineItem.leftStationName,
        leftStationGiftName: machineItem.leftGiftName,
        rightStationKey: machineItem.rightStationId,
        rightStationName: machineItem.rightStationName,
        rightStationGiftName: machineItem.rightGiftName,
      }));
    }
    dispatch(setMachineListDataSource(machineDataListFormat));
  };

  const callApiGetDesignatedAuthority = async () => {
    const http = new HttpConnection({ dispatch });
    const data = await http.get<DesignatedAuthorityType>(REST_API.MAINTENANCE.GET_DESIGNATED_AUTHORITY);
    setDesignatedAuthority(data.authority1);
  };

  useEffect(() => {
    getEquipmentData(sortMachineList, sortMachineTypeList).then();
    callApiGetDesignatedAuthority().then();
  }, []);

  const handleClickGroupConfTop = () => {
    dispatch(setSortMachineList(CLSFCN.MACHINE_SORT.BOARD_SERIAL_ASK));
    history.push(redirectLinks.MAINT_TOP);
  };

  /* ************ Event ************ */
  // sort change
  const handleChangeSort = (ev: React.ChangeEvent<HTMLSelectElement>) => {
    const sortType = ev.target.value;
    dispatch(setSortMachineList(sortType));
    getEquipmentData(sortType, sortMachineTypeList);
  };

  const handleChangeMachineTypeSort = (ev: React.ChangeEvent<HTMLSelectElement>) => {
    const filterType = ev.target.value;
    dispatch(setSortMachineTypeList(filterType));
    getEquipmentData(sortMachineList, filterType);
  };

  const handleSelectedItemChange = (itemChecked: EquipmentSelectDataTableRow) => {
    const newRows = machineDatasource.map((item) => {
      if (itemChecked !== null && item.key === itemChecked.key) {
        return { ...item, checked: !item.checked };
      }
      return item;
    });
    dispatch(setMachineListDataSource([...newRows]));
  };

  const handleShowDeleteDialog = () => {
    const equipmentDeleteData = machineDatasource.filter((equipment) => equipment.checked);
    if (equipmentDeleteData.length > 0) {
      setOpenDeleteConfirmDlg(true);
      let listEquipmentString = '';
      equipmentDeleteData.forEach((item) => {
        listEquipmentString += `${item.machineNo}\n`;
      });
      setMessageConfirmDeleteEquipment(`${confirmMsgSave}\n${listEquipmentString}`);
    } else {
      setOpenDeleteInfoDlg(true);
    }
  };

  const downloadCSV = useCallback(async () => {
    const http = new HttpConnection({ dispatch });
    const response = await http.getFileWithHeader(REST_API.MAINTENANCE.DOWNLOAD_POS_LICENSE_CSV);
    if (response.data.size !== 0) {
      const fileName = getFileNameFromContentDisposition(response.headers.contentDisposition);
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', fileName);
      document.body.appendChild(link);
      link.click();
    }
  }, []);

  const deleteMachineList = async (deleteObject: DeleteObject) => {
    const http = new HttpConnection({ dispatch });
    const data = await http.delete<DeleteMachineResponseType>(REST_API.MAINTENANCE.DELETE_MACHINE, { ...deleteObject });
    if (!data.result) {
      setMessageDeleteDlg(data.message ?? '');
    } else {
      setMessageDeleteDlg(messageDeleteEquipmentSuccess);
      getEquipmentData(sortMachineList, sortMachineTypeList);
    }
    setOpenDeleteDlg(true);
  };

  const handleOkDeleteConfirmDlg = () => {
    setOpenDeleteConfirmDlg(false);
    const deleteObjectFormat: DeleteObject = machineDatasource.reduce(
      (deleteObject: DeleteObject, item) => {
        if (item.checked) {
          deleteObject.stationIds.push(item.leftStationKey);
          if (item.machineType === COMMON.MACHINE_TYPE.CLENA3) {
            deleteObject.stationIds.push(item.rightStationKey);
          }
          deleteObject.boardIds.push(item.machineNo);
        }
        return deleteObject;
      },
      { stationIds: [], boardIds: [] },
    );

    deleteMachineList(deleteObjectFormat);
  };

  const handleGotoScreenNewMachine = () => {
    history.push(redirectLinks.MAINT_MACHINE_DETAIL);
  };

  const handleOpenSettingLinkagePosDialog = () => {
    setOpenSettingDlg(true);
  };

  const getMachineDetail = async (boardSerial: string): Promise<EquipmentSelectDataTableRow | null> => {
    const http = new HttpConnection({ dispatch });
    const data = await http.get<MachineListApiResponseType>(REST_API.MAINTENANCE.GET_MACHINE_DETAIL_BY_BOARD_SERIAL, {
      boardSerial,
    });
    let machineDetail: EquipmentSelectDataTableRow | null = null;
    if (data) {
      machineDetail = {
        key: data.boardSerial,
        checked: false,
        machineNo: data.boardSerial,
        machineType: data.machineType,
        machineName: data.machineName,
        leftStationKey: data.leftStationId,
        leftStationName: data.leftStationName,
        leftStationGiftName: data.leftGiftName,
        leftStationGiftKey: data.leftGiftId,
        rightStationKey: data.rightStationId,
        rightStationName: data.rightStationName,
        rightStationGiftName: data.rightGiftName,
        rightStationGiftKey: data.rightGiftId,
      };
    }
    return Promise.resolve<EquipmentSelectDataTableRow | null>(machineDetail);
  };

  const getGroup = async (boardSerial: string): Promise<GroupDataTableRow | null> => {
    const http = new HttpConnection({ dispatch });
    const data = await http.get<GroupApiResponseType>(REST_API.MAINTENANCE.GET_MACHINE_GET_GROUP, { boardSerial });
    let group: GroupDataTableRow | null = null;
    if (data) {
      group = {
        getGroupId: data.getGroupId,
        getGroupName: data.getGroupName,
        ledGroupId: data.ledGroupId,
        ledGroupName: data.ledGroupName,
      };
    }
    return Promise.resolve<GroupDataTableRow | null>(group);
  };

  const handleOpenMachineInfoDialog = (machineInfoDialogData: MachineInfoDialogContent) => {
    const { machineInfo } = machineInfoDialogData;
    if (machineInfo !== undefined) {
      getMachineDetail(machineInfo.machineNo).then((machineDetail: EquipmentSelectDataTableRow | null) => {
        if (machineDetail !== null) {
          getGroup(machineDetail.machineNo).then((group) => {
            const newMachineDetail: EquipmentSelectDataTableRow = {
              ...machineDetail,
              earningProductionGroupId: group?.getGroupId,
              earningProductionGroupName: group?.getGroupName,
              ledProductionGroupId: group?.ledGroupId,
              ledProductionGroupName: group?.ledGroupName,
            };
            if (machineInfo.machineType === COMMON.MACHINE_TYPE.JACK) {
              setMachineInfoDialogJack({ openDialog: true, machineInfo: { ...newMachineDetail } });
            } else if (machineInfo.machineType === COMMON.MACHINE_TYPE.GRAND) {
              setMachineInfoDialogGrand({ openDialog: true, machineInfo: { ...newMachineDetail } });
            } else {
              setMachineInfoDialog({ openDialog: true, machineInfo: { ...newMachineDetail } });
            }
          });
        }
      });
    }
  };

  return (
    <ScreenContext.Provider value={ScreenContextValue}>
      <Layout>
        <div className={classes.content}>
          <div className={classes.topArea}>
            <div className="returnButton">
              <ReturnButton onClick={handleClickGroupConfTop}>
                管理登録
                <br />
                TOP
              </ReturnButton>
            </div>
            <div className={classes.boxTextRight}>
              <div className={classes.boxTextRightContent}>
                <div className={classes.sortWrap}>
                  <NormalSelectBox
                    name="machineTypeSort"
                    dataSource={sortMachineDataSource}
                    value={sortMachineTypeList}
                    onChange={handleChangeMachineTypeSort}
                    label="機種"
                  />
                  <div className={classes.sortSpacing} />
                  <NormalSelectBox
                    name="sort"
                    dataSource={sortDataSource}
                    value={sortMachineList}
                    onChange={handleChangeSort}
                    label="並べ替え"
                  />
                </div>
                <NormalButton className="addNewButton" onClick={handleGotoScreenNewMachine}>
                  <div className={classes.plusButton}>
                    <span className={classes.buttonSizeAddNew}>+ </span>
                  </div>
                  <span className={classes.buttonSizeAddNew}> 新規登録</span>
                </NormalButton>
              </div>
            </div>
          </div>
          <div className={classes.mainArea}>
            <MachineListTable
              rows={machineDatasource}
              handleSelectedItemChange={handleSelectedItemChange}
              handleOpenMachineInfo={(row) => handleOpenMachineInfoDialog({ openDialog: true, machineInfo: row })}
            />
          </div>
          <Grid className={classes.decision}>
            <NormalButton className="btnDeleteRow" onClick={handleShowDeleteDialog}>
              <span className={classes.buttonSize}>チェックして削除</span>
            </NormalButton>
            <div className={classes.rightDecision}>
              {designatedAuthority >= 1 ? (
                <NormalButton className="btnPosDownloadCSV" onClick={downloadCSV}>
                  <span className={classes.buttonSize}>
                    POSライセンス
                    <br />
                    CSV出力
                  </span>
                </NormalButton>
              ) : (
                <></>
              )}
              <NormalButton className="btnPosDownloadCSV" onClick={handleOpenSettingLinkagePosDialog}>
                <span className={classes.buttonSizeAddNew}>
                  POS連携
                  <br />
                  筐体設定
                </span>
              </NormalButton>
            </div>
          </Grid>
        </div>
        <ConfirmDialog
          open={openDeleteConfirmDlg}
          msg={messageConfirmDeleteEquipment}
          onOk={handleOkDeleteConfirmDlg}
          onCancel={() => setOpenDeleteConfirmDlg(false)}
          addClass={{
            contentText: classes.contentText,
          }}
        />
        <InfoDialog
          open={openDeleteInfoDlg}
          msg={messageInfoDeleteEquipment}
          closeFunc={() => setOpenDeleteInfoDlg(false)}
        />
        <InfoDialog open={openDeleteDlg} msg={messageDeleteDlg} closeFunc={() => setOpenDeleteDlg(false)} />
        <MachineInfoDialog
          machineInfo={machineInfoDialog}
          onCancel={() => setMachineInfoDialog({ openDialog: false, machineInfo: undefined })}
        />
        <MachineInfoDialogJack
          machineInfo={machineInfoDialogJack}
          onCancel={() => setMachineInfoDialogJack({ openDialog: false, machineInfo: undefined })}
        />
        <MachineInfoDialogGrand
          machineInfo={machineInfoDialogGrand}
          onCancel={() => setMachineInfoDialogGrand({ openDialog: false, machineInfo: undefined })}
        />
        <SettingLinkagePosDialog open={openSettingDlg} onCancel={() => setOpenSettingDlg(false)} />
      </Layout>
    </ScreenContext.Provider>
  );
};

export default MachineList;
