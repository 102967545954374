import React, { memo } from 'react';
import { makeStyles } from '@material-ui/core/styles';

import { Box, Table, TableRow } from '@material-ui/core';
import { useAppSelector } from 'redux/hooks';
import CLSFCN from 'constants/classification';
import COMMON from 'constants/common';
import { getClassificationName } from './TableStationHomeDetail';

const useStyles = makeStyles((theme) => ({
  container: {
    border: `2px solid ${theme.grandStation.contentsBorder}`,
    borderRadius: 4,
    padding: 9,
    height: '188px',
    width: '203px',
  },
  priceContainer: {
    border: `2px solid ${theme.grandStation.contentsBorder}`,
    borderRadius: 4,
    padding: '4px 4px',
    height: '293px',
    width: '238px',
    marginLeft: '5px',
  },
  title: {
    position: 'relative',
    fontSize: 24,
    lineHeight: '22px',
  },
  frame: {
    flexDirection: 'column',
    alignItems: 'center',
  },
  child: {
    margin: '4px 0px 0px 0',
  },
  detailBlockSpeed: {
    borderCollapse: 'collapse',
    fontSize: '14px',
    display: 'block',
  },
  boxImg: {
    position: 'relative',
    height: '116px',
  },
  img: {
    position: 'relative',
    height: '125px',
    margin: '0 auto',
    top: '-10px',
  },
  speedTop: {
    backgroundColor: '#EEEEEE',
    width: '80px',
    height: '38px',
    border: '1px solid #707070',
    textAlign: 'center',
  },
  price: {
    backgroundColor: '#EEEEEE',
    width: '94px',
    height: '23px',
    border: '1px solid #707070',
    textAlign: 'center',
    lineHeight: '16px',
  },
  priceHeightLarge: { height: '35px' },
  priceHeightSmall: { height: '23px' },
  speedTopZ: {
    color: '#00B050',
  },
  speedTopValue: {
    backgroundColor: '#ffffff',
    border: '1px solid #707070',
    width: '104px',
    height: '38px',
    textAlign: 'center',
  },
  priceValue: {
    backgroundColor: '#ffffff',
    border: '1px solid #707070',
    width: '150px',
    height: '23px',
    textAlign: 'left',
    lineHeight: '16px',
  },
  textAlignCenter: {
    textAlign: 'center',
  },
  priceValueGiftCost: {
    paddingLeft: '10px',
  },
  X: {
    display: 'inline',
    color: '#FF3E3E',
    marginRight: '10px',
  },
  Y: {
    display: 'inline',
    color: '#3086FF',
  },
  speedTopText: {
    textAlign: 'center',
    color: '#FFC000',
  },
}));

const PriceSettingGrand = () => {
  const ledServiceOtherConf = useAppSelector((state) => state.machineConf.machineGrandConf.ledServiceOtherConf);
  const classes = useStyles();

  return (
    <>
      <Box className={classes.priceContainer}>
        <Box className={classes.frame}>
          <Box className={`${classes.title}`}>
            <b>料金サービス設定</b>
          </Box>
          <Box className={classes.child}>
            <Table className={classes.detailBlockSpeed}>
              <TableRow>
                <td className={`${classes.price} ${classes.priceHeightLarge}`}>料金設定</td>
                <td className={`${classes.priceValue} ${classes.textAlignCenter}`}>
                  {ledServiceOtherConf?.pricing &&
                    getClassificationName(CLSFCN.PRICING_NEW.CATEGORY_CD, ledServiceOtherConf.pricing)}
                </td>
              </TableRow>
              <TableRow>
                <td className={`${classes.price} ${classes.priceHeightSmall}`}>電子ﾏﾈｰ利用</td>
                <td className={`${classes.priceValueGiftCost} ${classes.priceValue} ${classes.textAlignCenter}`}>
                  {getClassificationName(
                    CLSFCN.ON_OFF.CATEGORY_CD,
                    ledServiceOtherConf?.electronicMoney ?? COMMON.FLAG.OFF,
                  )}
                </td>
              </TableRow>
              <TableRow>
                <td className={`${classes.price} ${classes.priceHeightSmall}`}>1コイン</td>
                <td className={`${classes.priceValueGiftCost} ${classes.priceValue} ${classes.textAlignCenter}`}>
                  {ledServiceOtherConf?.coinCost ? `${ledServiceOtherConf?.coinCost}円` : COMMON.BLANK1}
                </td>
              </TableRow>
              <TableRow>
                <td className={classes.price}>
                  サービス
                  <br /> ボタン設定
                </td>
                <td className={`${classes.priceValueGiftCost} ${classes.priceValue} ${classes.textAlignCenter}`}>
                  {ledServiceOtherConf?.serviceButton
                    ? getClassificationName(
                        CLSFCN.SERVICE_BUTTON_SETTING.CATEGORY_CD,
                        ledServiceOtherConf?.serviceButton,
                      )
                    : COMMON.BLANK1}
                </td>
              </TableRow>
              <TableRow>
                <td className={`${classes.price} ${classes.priceHeightSmall}`}>景品原価</td>
                <td className={`${classes.priceValueGiftCost} ${classes.priceValue} ${classes.textAlignCenter}`}>
                  {ledServiceOtherConf?.giftCost && `\xA5 ${String(ledServiceOtherConf?.giftCost)}`}
                </td>
              </TableRow>
              <TableRow>
                <td className={classes.price}>
                  目標景品
                  <br /> 原価率
                </td>
                <td className={`${classes.priceValue} ${classes.textAlignCenter}`}>
                  {ledServiceOtherConf?.targetCostRate &&
                    getClassificationName(CLSFCN.TARGET_COST_RATE.CATEGORY_CD, ledServiceOtherConf.targetCostRate)}
                </td>
              </TableRow>
              <TableRow>
                <td className={`${classes.price} ${classes.priceHeightSmall}`}>とり放題</td>
                <td className={`${classes.priceValue} ${classes.textAlignCenter}`}>
                  {ledServiceOtherConf?.freeGet &&
                    getClassificationName(CLSFCN.FREE_GET.CATEGORY_CD, ledServiceOtherConf.freeGet)}
                </td>
              </TableRow>
              <TableRow>
                <td className={`${classes.price} ${classes.priceHeightSmall}`}>両替時間</td>
                <td className={`${classes.priceValue} ${classes.textAlignCenter}`}>
                  {ledServiceOtherConf?.keepTime &&
                    getClassificationName(CLSFCN.KEEP_TIME_GRAND.CATEGORY_CD, ledServiceOtherConf.keepTime)}
                </td>
              </TableRow>
              <TableRow>
                <td className={classes.price}>
                  連続プレイ
                  <br /> みなし時間
                </td>
                <td className={`${classes.priceValue} ${classes.textAlignCenter}`}>
                  {ledServiceOtherConf?.playingDeemedTime &&
                    getClassificationName(
                      CLSFCN.PLAYING_DEEMED_TIME_GRAND.CATEGORY_CD,
                      ledServiceOtherConf.playingDeemedTime,
                    )}
                </td>
              </TableRow>
            </Table>
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default memo(PriceSettingGrand);
