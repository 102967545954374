import { makeStyles, Table, TableBody, TableCell, TableContainer, TableRow } from '@material-ui/core';
import React, { useEffect } from 'react';
import { MachineStationTableType } from 'types/data/dataType';
import { renderMachineType } from 'utils/common.helper';
import MachineStationTableHead, { HeaderType } from './common/MachineStationTableHead';
import StationGiftTableCell from './common/StationGiftTableCell'; /* ************ Style ************ */

interface TableProps {
  height?: number;
}

/* ************ Style ************ */
const useStyles = makeStyles(() => ({
  container: (props: TableProps) => ({
    height: props.height || '407px',
    width: '1100px',
  }),
  table: {
    tableLayout: 'fixed',
    '& th,td': {
      border: '1px solid #707070',
      fontSize: '23px',
      lineHeight: '28px',
      minHeight: '74px',
    },
    '& th': {
      textAlign: 'center',
      height: '58px',
      padding: 0,
    },
    '& tbody tr': {
      minHeight: '74px',
    },
    '& td': {
      padding: '0 8px',
      '& .cellStyle': {
        fontSize: '16px',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
      },
    },
  },
  tdStation: {
    textAlign: 'left',
  },
}));

const MachineStationTable: React.FC<MachineStationTableType> = ({
  tableId,
  stationList,
  handleSelectedItemChange,
  height,
}) => {
  const classes = useStyles({ height });
  const headerColumns: Array<HeaderType> = [
    { id: 'machineNo', title: '登録番号', width: 143 },
    { id: 'machineType', title: '機種名', width: 141 },
    { id: 'station', title: 'ステーション名\n景品名', width: '100%' },
  ];

  const [dataStationList, setDataStationList] = React.useState(stationList);

  useEffect(() => {
    setDataStationList([...stationList]);
  }, [stationList]);

  return (
    <TableContainer id={tableId} className={classes.container}>
      <Table className={classes.table} stickyHeader aria-label="sticky table">
        <MachineStationTableHead columns={headerColumns} />
        <TableBody>
          {dataStationList &&
            dataStationList.map((row) => (
              <TableRow key={`table-row-key-${row[0].boardSerial}`}>
                <TableCell className={classes.tdStation}>
                  <div className="cellStyle">{row[0].boardSerial}</div>
                </TableCell>
                <TableCell className={classes.tdStation}>
                  <div className="cellStyle">{renderMachineType(row[0].machineType)}</div>
                </TableCell>
                <StationGiftTableCell row={row} handleClickCheckBox={handleSelectedItemChange} />
              </TableRow>
            ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default MachineStationTable;
