import { Box, makeStyles } from '@material-ui/core';
import React, { ReactNode, useContext, useEffect } from 'react';
import homeIcon from 'assets/images/homeIcon.svg';
import dataIcon from 'assets/images/dataIcon.svg';
import confIcon from 'assets/images/confIcon.svg';
import groupConfIcon from 'assets/images/groupConfIcon.svg';
import manageConfIcon from 'assets/images/manageConfIcon.svg';
import bear from 'assets/images/bear.svg';
import ScreenContext from 'Contexts/Common/ScreenContext';
import COMMON from 'constants/common';
import CLSFCN from 'constants/classification';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import { useHistory } from 'react-router-dom';
import redirectLinks from 'constants/redirectLinks';
import UrlHelper from 'utils/url.helper';
import { USE_REDUX_VALUES } from 'pages/data/DataStationList';
import { initializeDataSlice } from 'redux/slices/dataSlice';
import { initializeMachineConfSlice } from 'redux/slices/machineConfSlice';
import { initializeMaintenanceSlice } from 'redux/slices/maintenanceSlice';
import { initializeUserSlice } from 'redux/slices/userSlice';
import { initializeGroupSlice } from 'redux/slices/groupSlice';
import { initializeManualInformationSlice } from 'redux/slices/manualInformationSlice';
import { IS_FILTERED } from 'pages/maintenance/UserList';

/**
 * Menu Component
 * メニュー
 * @author atsushi.teruya
 *
 */

/* ************ Style ************ */
const useStyles = makeStyles((theme) => ({
  menuArea: {
    height: '100%',
  },
  menuItem: {
    backgroundColor: '#BEBABA',
    borderColor: theme.palette.common.white,
    cursor: 'pointer',
    height: '120px',
    textAlign: 'center',
    position: 'relative',
    '& img': {
      position: 'absolute',
      top: '20%',
      left: '50%',
      transform: 'translateX(-50%)',
      WebkitTransform: 'translateX(-50%)',
    },
    '& p': {
      position: 'absolute',
      top: '65%',
      left: '50%',
      transform: 'translateX(-50%)',
      WebkitTransform: 'translateX(-50%)',
      width: '100%',
    },
  },
  selectedMenu: {
    backgroundColor: theme.palette.common.white,
    cursor: 'auto',
  },
  information: {
    textAlign: 'center',
    height: '120px',
    paddingTop: '5px',
    '& .notification': {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
  },
  updateInfo: {
    display: 'flex',
    alignItems: 'center',
    marginTop: '8px',
    justifyContent: 'center',
    '& .update': {
      fontSize: '19px',
      margin: '0px 6px 0px 3px',
    },
  },
  menuImageManual: {
    top: '10px !important',
  },
  textStyleManual: {
    top: '53% !important',
    fontSize: 18,
    whiteSpace: 'break-spaces',
  },
  iconNotifyStyle: {
    height: 23,
    width: 23,
    position: 'absolute',
    left: '88% !important',
    top: '0px !important',
  },
}));

/* ************ Component ************ */
/* ******** Control Component ******** */
/**
 * メニュー項目表示制御
 * 権限とparam.menuにより自身の配下に定義されたコンポーネントを出力する。
 *
 * @author atsushi.teruya
 * @param {string} menu - メニュー項目の識別コード constants/common COMMON.MENUに定義
 * @param src
 * @param menuText
 * @param imageStyle
 * @param textStyle
 * @param children
 * @returns {React.FC} - メニュー項目またはnull
 *
 */
const MenuItem: React.FC<{
  menu: string;
  src: string;
  menuText: string;
  imageStyle?: string;
  textStyle?: string;
  children?: ReactNode;
}> = ({ menu, src, menuText, imageStyle, textStyle, children }) => {
  const classes = useStyles();
  const history = useHistory();

  const { MENU } = COMMON;
  const { ROLE } = CLSFCN;
  const { currentMenu } = useContext(ScreenContext);
  const userInfo = useAppSelector((state) => state.common.userInfo);

  // 変数
  let checkDisplay = false; // 表示判定用項目
  let transitionScreen = ''; // 遷移先URL
  const isCurrent = currentMenu === menu;
  let transitionParam: { [key: string]: string } | null = null;

  // 権限判定
  switch (userInfo?.role) {
    // システム管理者  管理登録メニューを表示
    case ROLE.SYSTEM_ADMIN:
      if (menu === MENU.HOME || menu === MENU.MAINTENANCE || menu === MENU.OPERATION_EXAMPLE_LIST) {
        checkDisplay = true;
      }
      break;
    // 閲覧者(本部、店舗)  HOMEまたはDATAメニューを表示
    case ROLE.HEAD_VIEWER:
    case ROLE.STORE_VIEWER:
      if (
        menu === MENU.HOME ||
        menu === MENU.DATA ||
        menu === MENU.OPERATION_EXAMPLE_LIST ||
        menu === MENU.MAINTENANCE
      ) {
        checkDisplay = true;
      }
      break;
    // 本部管理者、店舗管理者 全メニューを表示
    default:
      checkDisplay = true;
  }

  switch (menu) {
    // システム管理者  管理登録メニューを表示
    case MENU.HOME:
      transitionScreen = redirectLinks.HOME_TOP;
      break;
    case MENU.DATA:
      transitionScreen = UrlHelper.convertQueryUrlFrontEnd(redirectLinks.DATA_STATION_LIST, {
        prevScreen: USE_REDUX_VALUES.FALSE,
      });
      break;
    case MENU.MACHINE_CONF:
      transitionScreen = redirectLinks.MCONF_STATION_SELECT;
      break;
    case MENU.GROUP:
      transitionScreen = redirectLinks.GROUP_CONF_TOP;
      break;
    case MENU.MAINTENANCE:
      if (userInfo?.role === ROLE.SYSTEM_ADMIN) {
        transitionScreen = redirectLinks.MAINT_USER_LIST;
        transitionParam = { isFiltered: IS_FILTERED.FALSE };
      } else {
        transitionScreen = redirectLinks.MAINT_TOP;
      }
      break;
    case MENU.OPERATION_EXAMPLE_LIST:
      transitionScreen = redirectLinks.OPERATION_EXAMPLE_LIST;
      break;
    default:
      transitionScreen = '/';
  }

  /* ************ Event ************ */
  // 画面遷移
  const onClick = () => {
    if (isCurrent) return;
    if (transitionParam) {
      history.push(UrlHelper.convertQueryUrlFrontEnd(transitionScreen, transitionParam));
    } else {
      history.push(transitionScreen);
    }
  };

  return (
    <>
      {checkDisplay ? (
        <Box border={1} className={`${classes.menuItem} ${isCurrent ? classes.selectedMenu : ''}`} onClick={onClick}>
          <img className={imageStyle ?? ''} src={src} alt={menuText} />
          <p className={textStyle ?? ''}>{menuText}</p>
          {children}
        </Box>
      ) : (
        <Box border={1} className={`${classes.menuItem} ${classes.selectedMenu}`} />
      )}
    </>
  );
};

/* ******** Main Component ******** */
/**
 * メニュー
 * メニューを表示する。
 *
 * @author atsushi.teruya
 * @returns {React.FC} - 権限に該当するメニュー
 *
 */
const Menu: React.FC = () => {
  const classes = useStyles();
  const dispatch = useAppDispatch();
  const { MENU } = COMMON;
  const { currentMenu, initializeState } = useContext(ScreenContext);

  useEffect(() => {
    if (!initializeState) return;
    // state初期化
    if (currentMenu !== MENU.DATA) {
      dispatch(initializeDataSlice());
    }
    if (currentMenu !== MENU.MACHINE_CONF) {
      dispatch(initializeMachineConfSlice());
    }
    if (currentMenu !== MENU.GROUP) {
      dispatch(initializeGroupSlice());
    }
    if (currentMenu !== MENU.MAINTENANCE) {
      dispatch(initializeMaintenanceSlice());
      dispatch(initializeUserSlice());
    }
    if (currentMenu !== MENU.OPERATION_EXAMPLE_LIST) {
      dispatch(initializeManualInformationSlice());
    }
  }, []);

  return (
    <>
      <Box className={classes.menuArea}>
        <MenuItem menu={MENU.HOME} src={homeIcon} menuText="HOME" />
        <MenuItem menu={MENU.DATA} src={dataIcon} menuText="データ" />
        <MenuItem menu={MENU.MACHINE_CONF} src={confIcon} menuText="機器設定" />
        <MenuItem menu={MENU.GROUP} src={groupConfIcon} menuText="グループ" />
        <MenuItem menu={MENU.OPERATION_EXAMPLE_LIST} src={bear} menuText="運営事例" />
        <MenuItem menu={MENU.MAINTENANCE} src={manageConfIcon} menuText="管理登録" />
      </Box>
    </>
  );
};

export default Menu;
