import { Box, makeStyles } from '@material-ui/core';
import CLSFCN from 'constants/classification';
import React from 'react';
import { SelectButton } from 'components/system/atoms';
import { getClassificationName } from 'utils/common.helper';

type Props = {
  index: number;
  title: string;
  status: string;
  pitX: number | null;
  pitY: number | null;
};

type ColorsType = {
  headerBorder: string;
  headerBg: string;
  headerText: string;
  mainBorder: string;
  buttonBg: string;
  buttonText: string;
};

const useStyles = makeStyles(() => ({
  root: {
    display: 'inline-block',
    width: '127px',
    height: '149px',
    background: '#FFFFFF',
  },
  header: {
    height: '37px',
    border: '1px solid',
    borderColor: (props: Props) => getColors(props).headerBorder,
    borderBottom: 'none',
    background: (props: Props) => getColors(props).headerBg,
    color: (props: Props) => getColors(props).headerText,
    fontSize: '18px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  main: {
    width: '100%',
    height: '112px',
    border: '1px solid',
    borderColor: (props: Props) => getColors(props).mainBorder,
    fontSize: '18px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-evenly',
    alignItems: 'center',
  },
  selectButton: {
    width: '105px',
    height: '30px',
    fontSize: '19px',
    padding: '0px',
    '&:disabled': {
      background: (props: Props) => getColors(props).buttonBg,
      color: (props: Props) => getColors(props).buttonText,
      borderStyle: 'none',
      '&:hover': {
        background: (props: Props) => getColors(props).buttonBg,
      },
    },
  },
  Axes: {
    width: '56px',
    '& span': {
      display: 'inline-block',
      width: '32px',
      textAlign: 'end',
    },
  },
}));

/* ********** Constants ********** */
const COLORS = {
  RED: '#FF3E3E',
  DARK_GRAY: '#707070',
  LIGHT_GRAY: '#E9E8E8',
  BLACK: '#000000',
  WHITE: '#FFFFFF',
};

const PIT_INDEX = {
  PIT1: 0,
  PIT2: 1,
};

/* ********** Function ********** */
const getColors = (props: Props) => {
  const colors: ColorsType = {
    headerBorder: '',
    headerBg: '',
    headerText: '',
    mainBorder: '',
    buttonBg: '',
    buttonText: '',
  };

  if (props.index === PIT_INDEX.PIT1 && props.status === CLSFCN.PIT_CONFIG.STATUS.ENABLED) {
    colors.headerBorder = COLORS.RED;
    colors.headerBg = COLORS.RED;
    colors.headerText = COLORS.WHITE;
    colors.mainBorder = COLORS.RED;
    colors.buttonBg = COLORS.RED;
    colors.buttonText = COLORS.WHITE;
  } else if (props.index === PIT_INDEX.PIT2 && props.status === CLSFCN.PIT_CONFIG.STATUS.ENABLED) {
    colors.headerBorder = COLORS.BLACK;
    colors.headerBg = COLORS.BLACK;
    colors.headerText = COLORS.WHITE;
    colors.mainBorder = COLORS.BLACK;
    colors.buttonBg = COLORS.RED;
    colors.buttonText = COLORS.WHITE;
  } else {
    colors.headerBorder = COLORS.DARK_GRAY;
    colors.headerBg = COLORS.LIGHT_GRAY;
    colors.headerText = COLORS.BLACK;
    colors.mainBorder = COLORS.DARK_GRAY;
    colors.buttonBg = COLORS.LIGHT_GRAY;
    colors.buttonText = COLORS.BLACK;
  }

  return colors;
};

/* ********** Component ********** */
// ヘッダ定義
const PitPinpointInfoGrand: React.FC<Props> = (props) => {
  const { title, status, pitX, pitY } = props;
  const classes = useStyles(props);

  return (
    <Box className={classes.root}>
      <Box className={classes.header}>{title}</Box>
      <Box className={classes.main}>
        {(pitX === null || pitY === null) && <p>なし</p>}
        {pitX !== null && pitY !== null && (
          <>
            <p className={classes.Axes}>
              縦 <span>{pitY}</span>
            </p>
            <p className={classes.Axes}>
              横 <span>{pitX}</span>
            </p>
            <SelectButton
              className={classes.selectButton}
              variant="contained"
              isSelected={false}
              arrowSize="0"
              disabled>
              {getClassificationName(CLSFCN.PIT_CONFIG.STATUS_CATEGORY_CD, status)}
            </SelectButton>
          </>
        )}
      </Box>
    </Box>
  );
};

export default PitPinpointInfoGrand;
