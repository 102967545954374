import React, { useEffect, useState } from 'react';
import ScreenContext from 'Contexts/Common/ScreenContext';
import COMMON from 'constants/common';
import { getSortedClsfcnByCategory } from 'utils/common.helper';
import CLSFCN from 'constants/classification';
import { Box, IconButton, makeStyles } from '@material-ui/core';
import OutlineTextBox from 'components/system/atoms/textBoxs/OutlineTextBox';
import { NormalSelectBox } from 'components/system/organisms';
import { getUserAuthThunk } from '../../redux/slices/commonSlice';
import { useAppDispatch } from '../../redux/hooks';
import Layout from '../../components/system/layouts/Layout';
import HttpConnection from '../../utils/httpConnection';
import { REST_API } from '../../constants/apiUrls';
import { OperationExampleType } from '../../types/manualInformation/manualInformationType';
import OperationExampleGrid from './components/OperationExampleGrid';

/* ************ Context ************ */
const ScreenContextValue = {
  title: '運営事例一覧',
  screenId: COMMON.SCREEN_ID.OPERATION_EXAMPLE_LIST,
  currentMenu: COMMON.MENU.OPERATION_EXAMPLE_LIST,
};

// CSS定義
const useStyles = makeStyles((theme) => ({
  contents: {
    marginLeft: '104px',
    backgroundColor: theme.palette.common.black,
    padding: '0px 16px 12px 16px',
    '& .detail': {
      height: 668,
      width: '1144px',
      padding: '4px 5px',
      borderRadius: '0 0 4px 4px',
      backgroundColor: theme.palette.common.white,
    },
  },
  topArea: {
    display: 'flex',
    padding: '16px 20px 12px',
  },
  contentsDetail: {
    height: '668px',
    width: '1144px',
  },
  container: {
    height: 650,
    borderRadius: 4,
    margin: 4,
  },
  header: {
    padding: '5px 10px',
    width: '100%',
    height: 33,
    background: '#535B80',
    fontSize: 19,
    color: '#fff',
    justifyContent: 'left',
    alignItems: 'center',
    display: 'flex',
  },
  informationText: {
    fontSize: 20,
    marginBottom: 5,
    color: '#007bff',
    textDecoration: 'underline',
    cursor: 'pointer',
    marginLeft: 5,
  },
  informationContainer: {
    margin: '16px 25px',
    height: 504,
    overflowY: 'auto',
  },
  textContain: {
    display: 'table',
    lineHeight: 2,
  },
  textItem: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  boxKeyword: {
    display: 'inline-flex',
    width: '286px',
  },
  keyword: {
    '& .outlinedInput': {
      width: '276px',
      '& input': {
        fontSize: '20px',
      },
    },
  },
  searchBtn: {
    margin: '1px 0px 0px -50px',
  },
  explanation: {
    display: 'inline-flex',
    whiteSpace: 'pre-wrap',
    fontSize: '20px',
    width: '500px',
    fontWeight: 'bold',
  },
  machineSelectArea: { marginTop: '10px', marginLeft: '36px' },
}));

/* ************ Component ************ */
const OperationExampleList: React.FC = () => {
  const dispatch = useAppDispatch();
  const classes = useStyles();
  const [operationExampleList, setOperationExampleList] = React.useState<Array<OperationExampleType>>([]);
  const [inputWord, setInputWord] = React.useState<string>('');
  const explanation = '運営事例の動画、マニュアルを参照できます。\n「設定を保存」で機器設定の保存一覧に保存できます。';
  const dataMachineDataSource = getSortedClsfcnByCategory(CLSFCN.MACHINE_TYPE_FILTER.CATEGORY_CD);
  const [machineTypeFilter, setMachineTypeFilter] = useState<string>(CLSFCN.MACHINE_TYPE_FILTER.ALL);

  // get OperationExample api
  const getOperationExampleApi = async () => {
    const http = new HttpConnection({ dispatch });
    const data = await http.get<OperationExampleType[]>(REST_API.OPERATION_EXAMPLE.GET_OPERATION_EXAMPLE, {
      title: inputWord,
      machineType: machineTypeFilter,
    });
    setOperationExampleList(data);
  };

  useEffect(() => {
    dispatch(getUserAuthThunk());
  }, []);

  useEffect(() => {
    getOperationExampleApi().then();
  }, [machineTypeFilter]);

  const handleChangeKeyword = (ev: React.ChangeEvent<HTMLInputElement>): void => {
    setInputWord(ev.target.value);
  };

  const handleClickSearch = () => {
    getOperationExampleApi().then();
  };

  // Handle change machine type
  const handleChangeMachineTypeSort = (ev: React.ChangeEvent<HTMLSelectElement>) => {
    setMachineTypeFilter(ev.target.value);
  };

  return (
    <ScreenContext.Provider value={ScreenContextValue}>
      <Layout>
        <Box className={classes.topArea}>
          <Box className={classes.boxKeyword}>
            <OutlineTextBox
              id="keyWord"
              name="keyWord"
              placeholder="キーワード検索"
              labelPlacement="top"
              value={inputWord}
              onChange={handleChangeKeyword}
              className={classes.keyword}
              fullWidth
            />
            <IconButton onClick={handleClickSearch} className={classes.searchBtn}>
              🔍
            </IconButton>
          </Box>
          <Box className={classes.explanation}>{explanation}</Box>
          <div className={classes.machineSelectArea}>
            <NormalSelectBox
              name="sort"
              dataSource={dataMachineDataSource}
              value={machineTypeFilter}
              onChange={handleChangeMachineTypeSort}
              label="機種"
            />
          </div>
        </Box>
        <OperationExampleGrid operationExampleList={operationExampleList} />
      </Layout>
    </ScreenContext.Provider>
  );
};

export default OperationExampleList;
