import React, { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import Layout from 'components/system/layouts/Layout';
import { Box, Grid, makeStyles } from '@material-ui/core';
import ScreenContext from 'Contexts/Common/ScreenContext';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import COMMON from 'constants/common';
import ReturnButton from 'components/system/atoms/buttons/ReturnButton';
import MachineConfContent from 'components/machineConf/organisms/contents/MachineConfContent';

import { handleCallSystemError } from 'redux/slices/commonSlice';
import ERROR_CODE from 'constants/errorCode';

import arrowBottomOn from 'assets/images/arrowBottomOn.svg';
import arrowBottomOff from 'assets/images/arrowBottomOff.svg';
import arrowIconLeft from 'assets/images/arrowLeft.svg';
import arrowIconUp from 'assets/images/arrowUp.svg';

import sideToggleSwapGray from 'assets/images/side.toggle.swap-gra.svg';
import sideToggleSwapScreen from 'assets/images/timing25.toggle.swap.svg';
import AreaConfPanelGrand from 'components/machineConf/organisms/Area/AreaConfPanelGrand';
import BoundAreaDetailGrand from 'components/machineConf/organisms/Area/BoundAreaDetailGrand';
import PitPinpointPanelGrand from 'components/machineConf/organisms/Pit/PitPinpointPanelGrand';
import { getClassificationName, getSupportConf } from 'utils/common.helper';
import CLSFCN from 'constants/classification';
import HttpConnection from 'utils/httpConnection';
import { REST_API } from 'constants/apiUrls';
import {
  AreaConfGrandType,
  GetSupportInfoType,
  OperationConfGrandType,
  ShowAreaColorType,
} from 'types/machineConf/machineConfType';
import UrlHelper from 'utils/url.helper';
import redirectLinks from 'constants/redirectLinks';
import { SaveDetailInfoType } from 'types/machineConf/stationSelectType';
/* ************ Context ************ */
const ScreenContextValue = {
  title: 'プレイヤーサポート設定',
  screenId: COMMON.SCREEN_ID.NEW_PAYOUT_SUPPORT_GRAND,
  currentMenu: COMMON.MENU.MACHINE_CONF,
  disableContents: true,
};

const SIZE = {
  POSITION_CONTROLLER: '52px',
  PIT_ICON: '24px',
  PANEL: '200px',
};

/* ********** Constants ********** */
const SELECTED_ID_DEFAULT = 4;

/* ********** create data ********** */

/* ************ Style ************ */
const useStyles = makeStyles((theme) => ({
  content: {
    position: 'relative',
    margin: '18px 24px',
    minHeight: '563px',
    minWidth: '1080px',
  },
  topArea: {
    display: 'flex',
    height: '80px',
    '& .modeArea': {
      position: 'absolute',
      top: '0px',
      left: '232px',
      height: '72px',
      width: '272px',
      display: 'flex',
      alignItems: 'center',
      padding: '6px 0px 0px 18px',
      fontSize: '20px',
      zIndex: 200,
    },
    '& .description': {
      position: 'absolute',
      top: '0px',
      left: '210px',
      fontSize: '23px',
      padding: '8px 0 0',
      width: '289px',
      height: '55px',
      lineHeight: '28px',
    },
  },
  mainArea: {
    display: 'flex',
    '& .basicArmArea': {
      height: '180px',
      width: '352px',
      '& .itemArea': {
        height: '100%',
        justifyContent: 'space-between',
        padding: '8px 12px 16px 12px',
      },
    },
    '& .glabToBasicArrow': {
      position: 'absolute',
      height: '140px',
      width: '72px',
      top: '232px',
      left: '140px',
      zIndex: 1,
    },
    '& .grabArmArea': {
      position: 'absolute',
      top: '344px',
      left: '0px',
      height: '220px',
      width: '352px',
      '& .itemArea': {
        height: '100%',
        justifyContent: 'space-between',
        padding: '8px 12px 4px 12px',
      },
      '& .main': {
        margin: '26px 0px 0px 0px',
        '& .armOpen': {
          margin: '48px 0px 0px 0px',
        },
      },
    },
    '& .areaConfArea': {
      position: 'absolute',
      top: '88px',
      left: '592px',
      height: '474px',
      width: '235px',
      '& .top': {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        width: '100%',
        padding: '4px 12px 0px',
      },
      '& .description': {
        margin: '2px auto 0px 10px',
        fontSize: '16px',
        lineHeight: '24px',
        height: '48px',
      },
      '& .main': {
        position: 'relative',
        display: 'block',
        flexDirection: 'row',
        justifyContent: 'flex-start',
        alignItems: 'center',
        width: '100%',
        padding: '0px 10px 0px 10px',
        '& .areaConf': {
          display: 'flex',
        },
        '& .areaConfTable': {
          marginLeft: '15px',
          width: '180px',
          '& .title': {
            borderWidth: '1px 1px 0px 1px',
            borderColor: theme.base.tableBorder,
            borderStyle: 'solid',
            fontSize: '21px',
            textAlign: 'center',
            backgroundColor: '#FFFF77',
            '& span': {
              color: '#FF0000',
            },
          },
          '& .area': {
            position: 'relative',
          },
        },
        '& .addButton': {
          margin: '-12px 6px 6px 6px',
          height: '60px',
          width: '200px',
          color: theme.palette.common.white,
          boxShadow: '5px 5px 3px 0px rgba(0, 0, 0, 0.5)',
          justifyContent: 'flex-start',
          padding: 0,
          '& img': {
            marginLeft: '16px',
          },
          '& .caption': {
            marginLeft: '16px',
            fontSize: '19px',
          },
        },
      },
    },
  },
  disableInputArea: {
    position: 'absolute',
    height: '100%',
    width: '100%',
    background: 'rgb(128 128 128 / 50%)',
    borderRadius: '8px',
    zIndex: 100,
    top: '0px',
    left: '0px',
  },
  disableAreaConfArea: {
    position: 'absolute',
    height: '100%',
    width: '100%',
    background: 'rgb(128 128 128 / 50%)',
    borderRadius: '0 0 8px 8px',
    zIndex: 100,
    top: '0px',
    left: '0px',
  },
  disableGetSupportArea: {
    position: 'absolute',
    height: '87%',
    width: '100%',
    background: 'rgb(128 128 128 / 50%)',
    borderRadius: '0 0 8px 8px',
    zIndex: 100,
    top: '48px',
    left: '0px',
  },
  baseArea: {
    borderWidth: '2px',
    borderStyle: 'solid',
    borderRadius: '8px',
  },
  titleBaseArea: {
    minWidth: 140,
  },
  baseAreaFlex: {
    display: 'flex',
    position: 'relative',
    flexDirection: 'column',
  },
  baseAreaArm: {
    '& .main': {
      display: 'flex',
      justifyContent: 'space-between',
      width: '100%',
    },
    '& .bottom': {
      margin: 'auto 0px 4px 0px',
    },
    '& .icon': {
      position: 'absolute',
      top: '24px',
      left: '126px',
    },
  },
  returnButton: {
    fontSize: '20px',
    lineHeight: '28px',
    zIndex: 200,
  },
  payoutSupport: {
    margin: '0px 0px 0px 12px',
    width: '80px',
  },
  decisionImage: {
    borderRadius: '4px',
    fontSize: '19px',
    height: '32px',
    padding: '2px 0px',
    width: '60px',
    textAlign: 'center',
    verticalAlign: 'middle',
    display: 'inline-block',
    background: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  caption: {
    fontSize: '20px',
    lineHeight: '24px',
  },
  selectButton: {
    width: '100px',
    height: '56px',
    fontSize: '24px',
  },
  giftCostArea: {
    margin: '16px 0px 0px 0px',
  },
  targetCostArea: {
    margin: '8px 0px 0px 0px',
  },
  leftStyle: {
    backgroundColor: '#fff',
    borderColor: theme.grandStation.itemBorder,
    '&:before': {
      borderRightColor: theme.grandStation.itemBorder,
    },
    '&:after': {
      borderRightColor: '#fff',
    },
  },
  rightStyle: {
    backgroundColor: '#fff',
    borderColor: theme.rightStation.itemBorder,
    '&:before': {
      borderRightColor: theme.rightStation.itemBorder,
    },
    '&:after': {
      borderRightColor: '#fff',
    },
  },
  leftStyleButton: {
    backgroundColor: theme.grandStation.contentsBorder,
    '&:hover': {
      backgroundColor: theme.grandStation.contentsBorder,
    },
    '&:disabled': {
      background: theme.grandStation.contentsBorder,
    },
  },
  rightStyleButton: {
    backgroundColor: theme.rightStation.contentsBorder,
    '&:hover': {
      backgroundColor: theme.rightStation.contentsBorder,
    },
    '&:disabled': {
      background: theme.rightStation.contentsBorder,
    },
  },
  areaSimplePit: {
    position: 'absolute',
  },
  baseAreaSelect: {
    height: '94px',
    '& .iconButton': {
      padding: 0,
    },
    '& .area': {
      height: '40px',
      width: '40px',
      border: '2px solid #707070',
      margin: '0 15px 7px',
    },
    '& .selectedArea': {
      border: '2px solid #FF0000',
    },
  },
  cost: {
    position: 'absolute',
    width: '495px',
    height: '354px',
    border: `2px solid ${theme.grandStation.itemBorder}`,
    borderRadius: '8px',
    padding: '8px',
    backgroundColor: '#ffffff',
  },
  getSupportContainer: {
    width: '236px',
    height: '184px',
    borderRadius: '4px',
    position: 'relative',
  },
  getSupportContainerDisable: {
    position: 'absolute',
    background: 'rgb(128 128 128 / 50%)',
    width: 236,
    height: 196,
    top: 0,
    borderRadius: 4,
  },
  getSupport: {
    width: '236px',
    height: '166px',
    background: 'rgba(159,226,99,0.5 )',
    marginTop: '4px',
    padding: '3px 10px',
    borderRadius: '4px',
  },
  getSupportLabel: {
    fontSize: '22px',
    lineHeight: '28px',
    height: '54px',
    textAlign: 'left',
    padding: 0,
  },
  getSupportText: {
    fontSize: '22px',
    lineHeight: '28px',
    paddingLeft: '4px',
  },
  getSupportValue: {
    width: '103px',
    height: '64px',
    fontSize: '36px',
    lineHeight: '24px',
    color: '#000000',
    border: `2px solid #39b54a`,
    borderRadius: '4px',
    background: '#ffffff',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  getSupportValueShort: {
    width: '103px',
    height: '56px',
    fontSize: '36px',
    lineHeight: '24px',
    color: '#000000',
    border: `2px solid #39b54a`,
    borderRadius: '4px',
    background: '#ffffff',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  limitDropValue: {
    width: '87px',
    height: '32px',
    fontSize: '19px',
    lineHeight: '14px',
    textAlign: 'center',
    color: '#000000',
    border: `2px solid #32d7a5`,
    borderRadius: '4px',
    background: '#ffffff',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    position: 'absolute',
    top: '338px',
    left: '4px',
  },
  getSupportWrap: {
    marginTop: '8px',
  },
  getSupportEstimated: {
    fontSize: '14px',
    lineHeight: '28px',
    marginTop: '10px',
    '& span': {
      fontSize: '18px',
      lineHeight: '20px',
    },
  },
  getSupportAcquisition: {
    fontSize: '19px',
    lineHeight: '22px',
  },
  getSupportAcquisitionLabel: {
    fontSize: '19px',
    lineHeight: '28px',
    paddingRight: '50px',
  },
  priceDisplayLabel: {
    fontSize: '19px',
    lineHeight: '28px',
    paddingRight: '17px',
  },
  electronicMoneyContainer: { margin: '10px 0' },
  getSupportAcquisitionWrap: {
    width: 234,
    paddingLeft: '10px',
  },
  getSupportCost: {
    width: 234,
    height: 198,
    borderRadius: '4px',
    padding: '4px 8px',
    background: 'rgba(159,226,99,0.5 )',
    position: 'relative',
  },
  getSupportCostDisable: {
    width: 234,
    height: 107,
    background: 'rgb(128 128 128 / 50%)',
    top: '45%',
    left: '0px',
    position: 'absolute',
    borderRadius: 4,
  },
  getSupportPricing: {
    fontSize: '19px',
    lineHeight: '28px',
    textAlign: 'left',
  },
  textAlignCenter: {
    textAlign: 'center',
  },
  alignCenter: { display: 'flex', alignItems: 'center', height: '100%' },
  getSupportPricingWrap: {
    width: 217,
    height: 59,
    background: '#ffffff',
    border: `2px solid #39b54a`,
    borderRadius: '4px',
    marginTop: '-4px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    textAlign: 'center',
  },
  getSupportCostSelect: {
    fontSize: '19px',
    lineHeight: '21px',
    padding: '7px 2px',
  },
  infoWrap: {
    width: 363,
    height: 138,
    position: 'relative',
  },
  infoWrapDisable: {
    position: 'absolute',
    width: 363,
    height: 147,
    background: 'rgb(128 128 128 / 50%)',
    top: 0,
    borderRadius: 4,
  },
  hightLightColor: { color: '#39B54A' },
  infoLabel: {
    fontSize: '19px',
    lineHeight: '20px',
  },
  infoValue: {
    fontSize: '19px',
    lineHeight: '20px',
    textAlign: 'right',
  },
  costWrap: {
    height: '100%',
  },
  powerChangeWrap: {
    width: 232,
    height: 437,
    background: '#c9f2e2',
    border: `2px solid #32d7a5`,
    borderRadius: '4px',
    position: 'absolute',
    top: '-3px',
    left: '500px',
    padding: '5px',
  },
  powerChangeTitle: {
    fontSize: '18px',
    lineHeight: '22px',
    marginBottom: 3,
  },
  powerChangeSubTitle: {
    fontSize: '15px',
    lineHeight: '17px',
    marginTop: '-5px',
  },
  timing: {
    position: 'relative',
    '& .iconLateralMoving': {
      position: 'absolute',
      top: '6px',
      left: '22px',
      width: '46px',
      height: '46px',
    },
    '& .icon0': {
      position: 'absolute',
      top: '6px',
      right: '40px',
      width: '46px',
      height: '46px',
    },
    '& .icon25': {
      position: 'absolute',
      top: '82px',
      right: '40px',
      width: '46px',
      height: '46px',
    },
    '& .icon50': {
      position: 'absolute',
      top: '158px',
      right: '40px',
      width: '46px',
      height: '46px',
    },
    '& .icon75': {
      position: 'absolute',
      top: '238px',
      right: '40px',
      width: '46px',
      height: '46px',
    },
    '& .icon100': {
      position: 'absolute',
      top: '317px',
      right: '40px',
      width: '46px',
      height: '46px',
    },
    '& .labelLateralMoving': {
      position: 'absolute',
      top: '53px',
      left: '17px',
      fontSize: '19px',
      lineHeight: '22px',
      color: '#000000',
    },
    '& .labelLimitDrop': {
      position: 'absolute',
      top: '316px',
      left: '9px',
      fontSize: '19px',
      lineHeight: '22px',
      color: '#000000',
    },
    '& .label0': {
      position: 'absolute',
      top: '16px',
      left: '185px',
      fontSize: '19px',
      textAlign: 'right',
      width: 33,
      height: 22,
    },
    '& .label25': {
      position: 'absolute',
      top: '97px',
      left: '185px',
      fontSize: '19px',
      textAlign: 'right',
      width: 33,
      height: 22,
    },
    '& .label50': {
      position: 'absolute',
      top: '169px',
      left: '185px',
      fontSize: '19px',
      textAlign: 'right',
      width: 33,
      height: 22,
    },
    '& .label75': {
      position: 'absolute',
      top: '250px',
      left: '185px',
      fontSize: '19px',
      textAlign: 'right',
      width: 33,
      height: 22,
    },
    '& .label100': {
      position: 'absolute',
      top: '327px',
      left: '185px',
      fontSize: '19px',
      textAlign: 'right',
      width: 33,
      height: 22,
    },
    '& .arrowIconLeft': {
      position: 'absolute',
      top: '18px',
      left: '88px',
      width: '28px',
      height: '27px',
    },
    '& .arrowIconUpTo0': {
      position: 'absolute',
      top: '53px',
      right: '55px',
      height: '27px',
    },
    '& .arrowIconUpTo25': {
      position: 'absolute',
      top: '130px',
      right: '55px',
      height: '27px',
    },
    '& .arrowIconUpTo50': {
      position: 'absolute',
      top: '208px',
      right: '55px',
      height: '27px',
    },
    '& .arrowIconUpTo75': {
      position: 'absolute',
      top: '288px',
      right: '55px',
      height: '27px',
    },
  },
  direction: {
    position: 'absolute',
    width: '87px',
    height: '242px',
    border: '2px solid #32d7a5',
    borderRadius: '5px',
    backgroundColor: '#ffffff',
    padding: '4px 23px 0 23px',
    top: '74px',
    left: '3px',
  },
  directionText: {
    fontSize: '19px',
    lineHeight: '22px',
    width: '39px',
    height: '66px',
    color: '#000000',
  },
  directionBottom: {
    width: '38px',
    height: '45px',
  },
  directionBottomRight: {
    width: '59px',
    height: '59px',
    marginTop: '10px',
    transform: 'rotate(-45deg)',
  },
  directionBottomLeft: {
    width: '59px',
    height: '59px',
    transform: 'rotate(45deg)',
  },
  playAssistWrap: {
    width: '265px',
    height: '119px',
    position: 'absolute',
    padding: '11px 12px',
    border: '2px solid #32d7a5',
    borderRadius: '8px',
    top: '444px',
    backgroundColor: '#ffffff',
  },
  playAssistWrapDisable: {
    width: '265px',
    height: '119px',
    position: 'absolute',
    padding: '11px 12px',
    borderRadius: '8px',
    top: '444px',
    background: 'rgb(128 128 128 / 50%)',
  },
  playAssistTitle: {
    fontSize: '22px',
    lineHeight: '26px',
    marginBottom: '15px',
  },
  playAssistLabel: {
    fontSize: '22px',
    lineHeight: '22px',
    paddingLeft: '35px !important',
  },
  playAssistValueWrap: {
    width: '100px',
    height: '52px',
    border: '2px solid #32d7a5',
    borderRadius: '4px',
    fontSize: '36px',
    lineHeight: '24px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: '#ffffff',
  },
  playAssistValue: {
    fontSize: '24px',
    lineHeight: '28px',
  },
  basicPowerWrap: {
    width: '337px',
    height: '120px',
    border: '2px solid #32d7a5',
    borderRadius: '8px',
    padding: '10px 12px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    position: 'absolute',
    right: 0,
    top: '-3px',
    backgroundColor: '#ffffff',
  },
  basicPowerTitle: {
    fontSize: '22px',
    lineHeight: '23px',
    marginBottom: '2px',
  },
  basicPowerLabel: {
    fontSize: '15px',
    lineHeight: '17px',
    textAlign: 'center',
  },
  basicPowerValueWrap: {
    width: '93px',
    height: '56px',
    border: '2px solid #32d7a5',
    borderRadius: '4px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: '#ffffff',
  },
  basicPowerValue: {
    fontSize: '36px',
    lineHeight: '21px',
  },
  grabbingPowerWrap: {
    width: '337px',
    height: '120px',
    border: '2px solid #32d7a5',
    borderRadius: '8px',
    padding: '10px 12px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    position: 'absolute',
    right: 0,
    top: '126px',
    backgroundColor: '#ffffff',
  },
  areaPowerConfigWrap: {
    position: 'absolute',
    right: 0,
    bottom: 0,
    width: '337px',
    height: '289px',
    border: '2px solid #32d7a5',
    borderRadius: '8px',
    borderBottomLeftRadius: 0,
    backgroundColor: '#ffffff',
  },
  mainPitArea: {
    position: 'relative',
    fontSize: '18px',
    height: '210px',
    width: '210px',
    top: '40px',
    left: '70px',
    '& .areaDetail': {
      position: 'absolute',
    },
    '& .boundArea': {
      position: 'absolute',
      top: '-12px',
      left: '-12px',
    },
    '& .minPositionXY': {
      position: 'absolute',
      bottom: '-30px',
      left: '-42px',
      width: '32px',
      textAlign: 'end',
    },
    '& .maxPositionX': {
      position: 'absolute',
      bottom: '-30px',
      right: '-42px',
      width: '32px',
      textAlign: 'start',
    },
    '& .maxPositionY': {
      position: 'absolute',
      top: '-30px',
      left: '-42px',
      width: '32px',
      textAlign: 'end',
    },
    '& .pinpointPanel': {
      position: 'absolute',
      top: '-7px',
      left: '-7px',
      width: `calc(${SIZE.PANEL} + ${SIZE.PIT_ICON})`,
      height: `calc(${SIZE.PANEL} + ${SIZE.PIT_ICON})`,
      zIndex: 2,
    },
    '& .xController': {
      position: 'absolute',
      top: '438px',
      left: '144px',
    },
    '& .yController': {
      position: 'absolute',
      top: '144px',
      left: '-20px',
    },
  },
  areaConfWrap: {
    left: '270px',
    height: '119px',
    position: 'absolute',
    padding: '11px 12px',
    border: '2px solid #32d7a5',
    borderRadius: '8px',
    top: '444px',
    borderTopRightRadius: 0,
    borderBottomRightRadius: 0,
    borderRightColor: '#f5fff7',
    width: '475px',
    display: 'flex',
    alignItems: 'center',
    backgroundColor: '#ffffff',
    borderRight: 'none',
  },
  areaConfLabel: {
    height: '80px',
    width: '111px',
  },
  selectColor: {
    fontSize: '19px',
    lineHeight: '23px',
  },
  bgWhite: {
    background: '#ffffff',
    width: '40px',
    height: '40px',
    border: '1px solid #b3b3b3',
    margin: '0 auto',
    cursor: 'pointer',
  },
  bgGreen: {
    background: '#C9F2E2',
    width: '40px',
    height: '40px',
    border: '1px solid #b3b3b3',
    margin: '0 auto',
    cursor: 'pointer',
  },
  bgPurple: {
    background: '#EBCFEB',
    width: '40px',
    height: '40px',
    border: '1px solid #b3b3b3',
    margin: '0 auto',
    cursor: 'pointer',
  },
  bgYellow: {
    background: '#FFFDB5',
    width: '40px',
    height: '40px',
    border: '1px solid #b3b3b3',
    margin: '0 auto',
    cursor: 'pointer',
  },
  bgRed: {
    background: '#FFC8C8',
    width: '40px',
    height: '40px',
    border: '1px solid #b3b3b3',
    margin: '0 auto',
    cursor: 'pointer',
  },
  bgBlue: {
    background: '#B4DFFF',
    width: '40px',
    height: '40px',
    border: '1px solid #b3b3b3',
    margin: '0 auto',
    cursor: 'pointer',
  },
  selectColorWrap: {
    width: '160px',
  },
  isActive: {
    border: '2px solid #000000',
  },
  isDisable: {
    background: '#e3e3e3',
    border: '1px solid #e3e3e3',
    cursor: 'default',
  },
}));

type ColorType = { id: number; className: string; key: string; background: string; value: number };
type AreaConfProps = {
  selected: ColorType;
  handleSelected: (_value: ColorType) => void;
  colors: ColorType[];
};
type PowerChangeProps = {
  selected: ColorType;
};

type GetSupportProps = {
  isDisable: boolean;
};

type BasicPowerProps = {
  selected: ColorType;
};

type GrabbingPowerProps = {
  selected: ColorType;
};

const floorPercentage = (value: number, digits: number): string => {
  const factor = 100;
  const flooredValue = Math.floor(value * factor) / factor;
  return flooredValue.toFixed(digits);
};

/* ******** Main Component ******** */

const CostGrand: React.FC<{ isStationConf: boolean; isDisable: boolean }> = ({ isStationConf, isDisable }) => {
  const classes = useStyles();
  const dispatch = useAppDispatch();
  const [supportInfo, setSupportInfo] = React.useState<GetSupportInfoType>();
  const { ledServiceOtherConf, operationConf } = useAppSelector((state) => state.machineConf.machineGrandConf);
  const { selectedStation } = useAppSelector((state) => state.machineConf);

  const targetCostRate =
    (ledServiceOtherConf?.targetCostRate &&
      getClassificationName(CLSFCN.TARGET_COST_RATE.CATEGORY_CD, ledServiceOtherConf.targetCostRate)) ||
    '）0';
  const targetCost = +targetCostRate.split('）')[1].split('%')[0];
  const supportEstimated =
    ledServiceOtherConf?.giftCost && targetCost ? ledServiceOtherConf?.giftCost / (targetCost / 100) : 0;

  const getSupportInfo = async () => {
    if (!selectedStation.stationId) {
      return;
    }
    const http = new HttpConnection({ dispatch });
    const data = await http.get<GetSupportInfoType>(REST_API.MACHINE_CONF.GET_SUPPORT_INFO_GRAND, {
      stationId: selectedStation.stationId,
    });
    setSupportInfo(data);
  };

  React.useEffect(() => {
    getSupportInfo();
  }, []);

  const costRate =
    supportInfo?.payoutCost && supportInfo?.salesTotal ? (supportInfo?.payoutCost / supportInfo?.salesTotal) * 100 : 0;

  return (
    <Box className={classes.cost}>
      <Grid container justify="space-between" direction="column" className={classes.costWrap}>
        <Grid item>
          <Grid container justify="space-between">
            <Grid item>
              <Box className={classes.getSupportContainer}>
                <Box className={classes.getSupportAcquisitionWrap}>
                  <Grid container alignItems="center">
                    <Grid item>
                      <Box className={classes.getSupportAcquisitionLabel}>獲得サポート</Box>
                    </Grid>
                    <Grid item className={classes.getSupportAcquisition}>
                      {operationConf?.getSupport
                        ? getClassificationName(
                            CLSFCN.GET_SUPPORT_CONF.CATEGORY_CD,
                            getSupportConf(operationConf?.getSupport, operationConf?.supportConf),
                          )
                        : COMMON.BLANK1}
                    </Grid>
                  </Grid>
                </Box>
                <Box className={classes.getSupport}>
                  <Grid container justify="space-between">
                    <Grid item>
                      <Box className={classes.alignCenter}>
                        <Box className={classes.getSupportLabel}>
                          景品
                          <br /> 原価
                        </Box>
                      </Box>
                    </Grid>
                    <Grid item>
                      <Grid container alignItems="flex-end">
                        <Grid item className={classes.getSupportValue}>
                          {ledServiceOtherConf?.giftCost ?? COMMON.BLANK1}
                        </Grid>
                        <Grid item className={classes.getSupportText}>
                          円
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid container justify="space-between" className={classes.getSupportWrap}>
                    <Grid item>
                      <Box className={classes.alignCenter}>
                        <Box className={classes.getSupportLabel}>
                          目標
                          <br /> 原価率
                        </Box>
                      </Box>
                    </Grid>
                    <Grid item>
                      <Grid container alignItems="flex-end">
                        <Grid item className={classes.getSupportValueShort}>
                          {targetCost}
                        </Grid>
                        <Grid item className={classes.getSupportText}>
                          %
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Box className={classes.getSupportEstimated}>
                    ペイアウト目安 <span>{Math.floor(supportEstimated)} 円/個</span>
                  </Box>
                </Box>
                <div className={isDisable ? classes.getSupportContainerDisable : ''} />
              </Box>
            </Grid>
            <Grid item>
              <Grid container direction="column" justify="flex-end">
                <Grid item className={classes.getSupportCost}>
                  <Box className={`${classes.getSupportPricing} ${classes.textAlignCenter}`}>料金設定</Box>
                  <Box className={classes.getSupportPricingWrap}>
                    <Box>
                      <Grid container className={classes.getSupportCostSelect}>
                        {ledServiceOtherConf?.pricing &&
                          getClassificationName(CLSFCN.PRICING_NEW.CATEGORY_CD, ledServiceOtherConf.pricing)}
                      </Grid>
                    </Box>
                  </Box>
                  <Box className={classes.electronicMoneyContainer}>
                    <Grid container alignItems="center">
                      <Grid item>
                        <Box className={classes.priceDisplayLabel}>電子マネー専用</Box>
                      </Grid>
                      <Grid item className={classes.getSupportAcquisition}>
                        {ledServiceOtherConf?.electronicMoney
                          ? getClassificationName(CLSFCN.ON_OFF.CATEGORY_CD, ledServiceOtherConf?.electronicMoney)
                          : COMMON.BLANK1}
                      </Grid>
                    </Grid>
                  </Box>
                  <Grid container justify="space-between">
                    <Grid item>
                      <Box className={classes.alignCenter}>
                        <Box className={classes.getSupportPricing}>1コイン =</Box>
                      </Box>
                    </Grid>
                    <Grid item>
                      <Grid container alignItems="flex-end">
                        <Grid item className={classes.getSupportValueShort}>
                          {ledServiceOtherConf?.coinCost ?? COMMON.BLANK1}
                        </Grid>
                        <Grid item className={classes.getSupportText}>
                          円
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                  <div className={isDisable ? classes.getSupportCostDisable : ''} />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>

        <Grid item>
          <Box className={classes.infoWrap}>
            <Grid container justify="space-between" spacing={1}>
              <Grid item xs={8}>
                <Box className={classes.infoLabel}>原価率</Box>
              </Grid>
              <Grid item xs={4}>
                <Box className={classes.infoValue}>
                  {isStationConf && !isDisable ? floorPercentage(costRate, 2) : COMMON.BLANK1} ％
                </Box>
              </Grid>
            </Grid>
            <Grid container justify="space-between" spacing={1}>
              <Grid item xs={8}>
                <Box className={classes.infoLabel}>売上</Box>
              </Grid>
              <Grid item xs={4}>
                <Box className={classes.infoValue}>
                  {isStationConf && !isDisable ? supportInfo?.salesTotal ?? COMMON.BLANK1 : COMMON.BLANK1} 円
                </Box>
              </Grid>
            </Grid>
            <Grid container justify="space-between" spacing={1}>
              <Grid item xs={8}>
                <Box className={classes.infoLabel}>払出原価</Box>
              </Grid>
              <Grid item xs={4}>
                <Box className={classes.infoValue}>
                  {isStationConf && !isDisable ? supportInfo?.payoutCost ?? COMMON.BLANK1 : COMMON.BLANK1} 円
                </Box>
              </Grid>
            </Grid>
            <Grid container justify="space-between" spacing={1}>
              <Grid item xs={8}>
                <Box className={classes.infoLabel}>獲得サポート払出</Box>
              </Grid>
              <Grid item xs={4}>
                <Box className={classes.infoValue}>
                  {isStationConf && !isDisable ? supportInfo?.getSupport ?? COMMON.BLANK1 : COMMON.BLANK1} 回
                </Box>
              </Grid>
            </Grid>
            <Grid container justify="space-between" spacing={1}>
              <Grid item xs={8}>
                <Box className={classes.infoLabel}>連続プレイアシスト払出</Box>
              </Grid>
              <Grid item xs={4}>
                <Box className={classes.infoValue}>
                  {isStationConf && !isDisable ? supportInfo?.playAssist ?? COMMON.BLANK1 : COMMON.BLANK1} 回
                </Box>
              </Grid>
            </Grid>
            <Grid container justify="space-between" spacing={1}>
              <Grid item xs={8}>
                <Box className={classes.infoLabel}>前回払出後売上</Box>
              </Grid>
              <Grid item xs={4}>
                <Box className={classes.infoValue}>
                  {isStationConf && !isDisable ? supportInfo?.previousSales ?? COMMON.BLANK1 : COMMON.BLANK1} 円
                </Box>
              </Grid>
            </Grid>
            <div className={isDisable ? classes.infoWrapDisable : ''} />
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

const PowerChange: React.FC<PowerChangeProps> = ({ selected }) => {
  const classes = useStyles();

  const {
    areaConf,
    operationConf = {
      grabLPower1: 0,
      grabCPower1: 0,
      grabRPower1: 0,
      timing1: '000000',
      giftFallingDirection1: '',
      grabLPower2: 0,
      grabCPower2: 0,
      grabRPower2: 0,
      timing2: '000000',
      giftFallingDirection2: '',
      grabLPower3: 0,
      grabCPower3: 0,
      grabRPower3: 0,
      timing3: '000000',
      giftFallingDirection3: '',
      grabLPower4: 0,
      grabCPower4: 0,
      grabRPower4: 0,
      timing4: '000000',
      giftFallingDirection4: '',
      grabLPower5: 0,
      grabCPower5: 0,
      grabRPower5: 0,
      timing5: '000000',
      giftFallingDirection5: '',
      grabLPower6: 0,
      grabCPower6: 0,
      grabRPower6: 0,
      timing6: '000000',
      giftFallingDirection6: '',
      getSupport: '',
      assist: '',
      assistCost: 0,
      playCount: '',
      limitLPower: 0,
      limitRPower: 0,
      limitCPower: 0,
      limitPlayCount: '',
      resetTiming: '000000',
      shiftCm: '',
      shiftDirection: '',
      movingX: '',
      movingY: '',
      openingArm: '',
      descending: '',
      closingArm: '',
      movingPit: '',
      finishingArm: '',
      movingHome: '',
    },
  } = useAppSelector((state) => state.machineConf.machineGrandConf);

  const payoutInfoSupport = operationConf as OperationConfGrandType;
  const giftFallingDirection = payoutInfoSupport[
    `giftFallingDirection${selected.value}` as keyof OperationConfGrandType
  ] as string;
  const timing = payoutInfoSupport[`timing${selected.value}` as keyof OperationConfGrandType] as string;
  const descentLimit = `descentLimit${selected.value}` as keyof AreaConfGrandType;

  return (
    <Box className={classes.powerChangeWrap} style={{ background: selected.background }}>
      <Box className={classes.powerChangeTitle}>パワー変化タイミング</Box>
      <Box className={classes.powerChangeSubTitle}>
        <Grid container>
          <Grid item xs={1}>
            ・
          </Grid>
          <Grid item xs={11}>
            設定したいアイコンを
            <br />
            タッチで選択
          </Grid>
        </Grid>
      </Box>
      <Box className={classes.timing}>
        <Box className={classes.direction}>
          <Box className={classes.directionText}>
            景品
            <br /> 落下
            <br /> 方向
          </Box>
          <img
            src={giftFallingDirection?.[0] === COMMON.FLAG.ON ? arrowBottomOn : arrowBottomOff}
            alt="bottom"
            className={classes.directionBottom}
            width={38}
            height={45}
          />
          <img
            src={giftFallingDirection?.[1] === COMMON.FLAG.ON ? arrowBottomOn : arrowBottomOff}
            alt="bottomRight"
            className={classes.directionBottomRight}
            width={59}
            height={59}
          />
          <img
            src={giftFallingDirection?.[2] === COMMON.FLAG.ON ? arrowBottomOn : arrowBottomOff}
            alt="bottomLeft"
            className={classes.directionBottomLeft}
            width={59}
            height={59}
          />
        </Box>
        <Box className="labelLimitDrop">
          <Box>下降限界</Box>
        </Box>
        <Box className={classes.limitDropValue}>{areaConf?.[descentLimit] ?? COMMON.BLANK1}</Box>
        <img
          src={timing?.[0] === COMMON.FLAG.ON ? sideToggleSwapScreen : sideToggleSwapGray}
          alt="catcherIconLateralMoving"
          className="iconLateralMoving"
        />
        <img
          src={timing?.[1] === COMMON.FLAG.ON ? sideToggleSwapScreen : sideToggleSwapGray}
          alt="catcherIcon0"
          className="icon0"
        />
        <img
          src={timing?.[2] === COMMON.FLAG.ON ? sideToggleSwapScreen : sideToggleSwapGray}
          alt="catcherIcon25"
          className="icon25"
        />
        <img
          src={timing?.[3] === COMMON.FLAG.ON ? sideToggleSwapScreen : sideToggleSwapGray}
          alt="catcherIcon50"
          className="icon50"
        />
        <img
          src={timing?.[4] === COMMON.FLAG.ON ? sideToggleSwapScreen : sideToggleSwapGray}
          alt="catcherIcon75"
          className="icon75"
        />
        <img
          src={timing?.[5] === COMMON.FLAG.ON ? sideToggleSwapScreen : sideToggleSwapGray}
          alt="catcherIcon100"
          className="icon100"
        />
        <img src={arrowIconLeft} alt="arrowIconLeft" className="arrowIconLeft" />
        <img src={arrowIconUp} alt="arrowIconUpTo0" className="arrowIconUpTo0" />
        <img src={arrowIconUp} alt="arrowIconUpTo25" className="arrowIconUpTo25" />
        <img src={arrowIconUp} alt="arrowIconUpTo50" className="arrowIconUpTo50" />
        <img src={arrowIconUp} alt="arrowIconUpTo75" className="arrowIconUpTo75" />
        <p className="labelLateralMoving">横移動</p>
        <p className="label0">0</p>
        <p className="label25">25</p>
        <p className="label50">50</p>
        <p className="label75">75</p>
        <p className="label100">100</p>
      </Box>
    </Box>
  );
};

const GetSupport: React.FC<GetSupportProps> = ({ isDisable }) => {
  const classes = useStyles();
  const { operationConf } = useAppSelector((state) => state.machineConf.machineGrandConf);
  return (
    <>
      <Box className={classes.playAssistWrap}>
        <Box className={classes.playAssistTitle}>連続プレイアシスト</Box>
        <Grid container spacing={1} justify="space-between" alignItems="center">
          <Grid item className={classes.playAssistLabel}>
            {operationConf?.assist && getClassificationName(CLSFCN.ON_OFF.CATEGORY_CD, operationConf?.assist)}
          </Grid>
          <Grid item>
            <Grid container alignItems="flex-end">
              <Grid item className={classes.playAssistValueWrap}>
                {operationConf?.assistCost ?? COMMON.BLANK1}
              </Grid>
              <Grid item className={classes.playAssistValue}>
                円
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Box>
      <div className={isDisable ? classes.playAssistWrapDisable : ''} />
    </>
  );
};

const BasicPower: React.FC<BasicPowerProps> = ({ selected }) => {
  const classes = useStyles();
  const { areaConf } = useAppSelector((state) => state.machineConf.machineGrandConf);
  const lPower = `lPower${selected.value}` as keyof AreaConfGrandType;
  const cPower = `cPower${selected.value}` as keyof AreaConfGrandType;
  const rPower = `rPower${selected.value}` as keyof AreaConfGrandType;
  return (
    <Box className={classes.basicPowerWrap} style={{ background: selected.background }}>
      <Grid container direction="column">
        <Grid item>
          <Box className={classes.basicPowerTitle}>通常時のアームパワー</Box>
        </Grid>
        <Grid item>
          <Grid container justify="center">
            <Grid item xs={4}>
              <Grid container direction="column" alignItems="center" justify="center">
                <Grid item>
                  <Box className={classes.basicPowerLabel}>左アーム</Box>
                </Grid>
                <Grid item>
                  <Box className={classes.basicPowerValueWrap}>
                    <Box className={classes.basicPowerValue}>{areaConf?.[lPower] ?? COMMON.BLANK1}</Box>
                  </Box>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={4}>
              <Grid container direction="column" alignItems="center" justify="center">
                <Grid item>
                  <Box className={classes.basicPowerLabel}>中アーム</Box>
                </Grid>
                <Grid item>
                  <Box className={classes.basicPowerValueWrap}>
                    <Box className={classes.basicPowerValue}>{areaConf?.[cPower] ?? COMMON.BLANK1}</Box>
                  </Box>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={4}>
              <Grid container direction="column" alignItems="center" justify="center">
                <Grid item>
                  <Box className={classes.basicPowerLabel}>右アーム</Box>
                </Grid>
                <Grid item>
                  <Box className={classes.basicPowerValueWrap}>
                    <Box className={classes.basicPowerValue}>{areaConf?.[rPower] ?? COMMON.BLANK1}</Box>
                  </Box>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
};
const GrabbingPower: React.FC<GrabbingPowerProps> = ({ selected }) => {
  const classes = useStyles();
  const { operationConf } = useAppSelector((state) => state.machineConf.machineGrandConf);
  const grabLPower = `grabLPower${selected.value}` as keyof OperationConfGrandType;
  const grabCPower = `grabCPower${selected.value}` as keyof OperationConfGrandType;
  const grabRPower = `grabRPower${selected.value}` as keyof OperationConfGrandType;
  return (
    <Box className={classes.grabbingPowerWrap} style={{ background: selected.background }}>
      <Grid container direction="column">
        <Grid item>
          <Box className={classes.basicPowerTitle}>つかみ時のアームパワー</Box>
        </Grid>
        <Grid item>
          <Grid container justify="center">
            <Grid item xs={4}>
              <Grid container direction="column" alignItems="center" justify="center">
                <Grid item>
                  <Box className={classes.basicPowerLabel}>左アーム</Box>
                </Grid>
                <Grid item>
                  <Box className={classes.basicPowerValueWrap}>
                    <Box className={classes.basicPowerValue}>{operationConf?.[grabLPower] ?? COMMON.BLANK1}</Box>
                  </Box>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={4}>
              <Grid container direction="column" alignItems="center" justify="center">
                <Grid item>
                  <Box className={classes.basicPowerLabel}>中アーム</Box>
                </Grid>
                <Grid item>
                  <Box className={classes.basicPowerValueWrap}>
                    <Box className={classes.basicPowerValue}>{operationConf?.[grabCPower] ?? COMMON.BLANK1}</Box>
                  </Box>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={4}>
              <Grid container direction="column" alignItems="center" justify="center">
                <Grid item>
                  <Box className={classes.basicPowerLabel}>右アーム</Box>
                </Grid>
                <Grid item>
                  <Box className={classes.basicPowerValueWrap}>
                    <Box className={classes.basicPowerValue}>{operationConf?.[grabRPower] ?? COMMON.BLANK1}</Box>
                  </Box>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
};

const AreaConf: React.FC<AreaConfProps> = ({ handleSelected, selected, colors }) => {
  const classes = useStyles();

  const {
    machineGrandConf: { areaConf },
    showAreaColor,
  } = useAppSelector((state) => state.machineConf);

  return (
    <Box className={classes.areaConfWrap}>
      <Grid container spacing={6}>
        <Grid item>
          <Grid container direction="column" className={classes.areaConfLabel} justify="space-between">
            <Grid item>
              <Box className={classes.playAssistTitle}>エリア設定</Box>
            </Grid>
            <Grid item className={classes.playAssistLabel}>
              {areaConf?.areaConf && getClassificationName(CLSFCN.ON_OFF.CATEGORY_CD, areaConf?.areaConf)}
            </Grid>
          </Grid>
        </Grid>
        <Grid item>
          <Grid container alignItems="center" spacing={2}>
            <Grid item className={classes.selectColor}>
              設定したい
              <br /> エリアの色を
              <br /> タッチで選択
            </Grid>
            <Grid item>
              <Grid container spacing={1} className={classes.selectColorWrap}>
                {colors.map((item) => {
                  const { id, className, key } = item;
                  const areaKey = key as keyof ShowAreaColorType;
                  const isActive = id === selected.id;
                  const isShowArea = showAreaColor[areaKey];
                  const itemStyle = `${className} ${isActive ? classes.isActive : ''} ${
                    isShowArea ? '' : classes.isDisable
                  }`;
                  return (
                    <Grid key={id} item xs={4}>
                      <Box className={itemStyle} onClick={() => isShowArea && handleSelected(item)} />
                    </Grid>
                  );
                })}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
};

const AreaPowerConfig: React.FC = () => {
  const classes = useStyles();
  const dispatch = useAppDispatch();
  const pitConf = useAppSelector((state) => state.machineConf.machineGrandConf.pitConf);
  if (!pitConf) {
    dispatch(handleCallSystemError({ errorNo: ERROR_CODE.NOT_FOUND }));
    return <></>;
  }
  const areaConf = useAppSelector((state) => state.machineConf.machineGrandConf.areaConf);
  const leftRight = useAppSelector((state) => state.machineConf.selectedStation.leftRight);

  return (
    <Box className={classes.areaPowerConfigWrap}>
      <Box className={classes.mainPitArea}>
        <AreaConfPanelGrand
          className="areaDetail"
          leftRight={leftRight}
          size="S"
          areaConf={areaConf}
          defaultBorderColor
          isDefault={areaConf && areaConf.areaConf === COMMON.SWITCH.OFF}
          borderColor="none"
        />

        <Box>
          <BoundAreaDetailGrand className="boundArea" isLeft size="S" />
          <Box className="minPositionXY">0</Box>
          <Box className="maxPositionX">100</Box>
          <Box className="maxPositionY">100</Box>
          <PitPinpointPanelGrand className="pinpointPanel" pitConf={pitConf} size="S" />
        </Box>
      </Box>
    </Box>
  );
};

/**
 * NewPayoutSupportConfGrand Screen プレイヤーサポート画面
 *
 * @author atsushi.teruya
 * @returns {React.FC} - プレイヤーサポート画面
 *
 */
const NewPayoutSupportConfGrand: React.FC = () => {
  /* ************ state/redux ************ */
  const dispatch = useAppDispatch();

  const classes = useStyles();
  const history = useHistory();
  const location = useLocation();
  const [savedConfProps] = useState(location.state !== undefined ? (location.state as SaveDetailInfoType) : null);
  const { machineGrandConf } = useAppSelector((state) => state.machineConf);
  const { conf } = machineGrandConf;
  const isStationConf = conf?.confDiv === COMMON.CONF_DIV.STATION_CONF;

  const selectedItemDefault = {
    id: SELECTED_ID_DEFAULT,
    className: classes.bgWhite,
    key: 'area0',
    background: '#ffffff',
    value: 1,
  };

  const [selectedItem, setSelectedItem] = React.useState<ColorType>(selectedItemDefault);

  /* ************ state ************ */
  // global (Redux)
  const {
    machineGrandConf: { operationConf, areaConf, pitConf },
    showAreaColor,
  } = useAppSelector((state) => state.machineConf);

  const colors: ColorType[] = [
    { id: 1, className: classes.bgYellow, key: 'area3', background: '#FFFDB5', value: 4 },
    { id: 2, className: classes.bgRed, key: 'area4', background: '#FFC8C8', value: 5 },
    { id: 3, className: classes.bgBlue, key: 'area5', background: '#B4DFFF', value: 6 },
    { id: SELECTED_ID_DEFAULT, className: classes.bgWhite, key: 'area0', background: '#ffffff', value: 1 },
    { id: 5, className: classes.bgGreen, key: 'area1', background: '#C9F2E2', value: 2 },
    { id: 6, className: classes.bgPurple, key: 'area2', background: '#EBCFEB', value: 3 },
  ];

  /* ************ Event ************ */
  // 初期表示処理
  useEffect(() => {
    if (!operationConf || !areaConf || !pitConf) {
      dispatch(handleCallSystemError({ errorNo: ERROR_CODE.NOT_FOUND }));
    } else {
      const isAreaConfOn = areaConf.areaConf === COMMON.SWITCH.ON;
      let selectedDefault = colors.find(({ key }) => key === 'area0');
      if (isAreaConfOn) {
        const colorsSort = colors.sort((a, b) => {
          if (a.key < b.key) {
            return -1;
          }
          if (a.key > b.key) {
            return 1;
          }
          return 0;
        });
        selectedDefault = colorsSort.find(({ key }) => {
          const colorKey = key as keyof ShowAreaColorType;
          return showAreaColor[colorKey];
        });
      }
      setSelectedItem(selectedDefault || selectedItemDefault);
    }
  }, []);

  // return button change
  const clickReturn = () => {
    history.push(
      UrlHelper.convertQueryUrlFrontEnd(redirectLinks.MCONF_GRAND_HOME_DETAIL, {
        selectConfId: 'none',
      }),
    );
  };

  const handleSelected = (item: ColorType) => setSelectedItem(item);
  const isGetSupportDisable = [CLSFCN.GET_SUPPORT_CONF.OFF_1, CLSFCN.GET_SUPPORT_CONF.OFF_2].includes(
    getSupportConf(operationConf?.getSupport ?? null, operationConf?.supportConf ?? null),
  );
  const isPlayAssistDisable = CLSFCN.ON_OFF.OFF === operationConf?.assist;
  return (
    <ScreenContext.Provider value={ScreenContextValue}>
      <Layout>
        <MachineConfContent savedConfProps={savedConfProps}>
          <Box className={classes.content}>
            <Box className={classes.topArea}>
              <ReturnButton className={classes.returnButton} onClick={clickReturn}>
                機器設定
                <br />
                詳細
              </ReturnButton>
              <p className="description">プレイヤーサポート設定の 詳細です。</p>
            </Box>
            <CostGrand isStationConf={isStationConf} isDisable={isGetSupportDisable} />
            <PowerChange selected={selectedItem} />
            <GetSupport isDisable={isPlayAssistDisable} />
            <BasicPower selected={selectedItem} />
            <GrabbingPower selected={selectedItem} />
            <AreaPowerConfig />
            <AreaConf handleSelected={handleSelected} selected={selectedItem} colors={colors} />
          </Box>
        </MachineConfContent>
      </Layout>
    </ScreenContext.Provider>
  );
};

export default NewPayoutSupportConfGrand;
