import React, { useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import Layout from 'components/system/layouts/Layout';
import ScreenContext from 'Contexts/Common/ScreenContext';
import { Box, Grid, makeStyles } from '@material-ui/core';
import redirectLinks from 'constants/redirectLinks';
import COMMON from 'constants/common';
import MachineConfContentSettingDetail from 'components/machineConf/organisms/contents/MachineConfContentSettingDetail';
import SpeedConfInfoGrand from 'components/machineConf/organisms/setting/SpeedConfInfoGrand';
import XyConfInfoGrand from 'components/machineConf/organisms/setting/XyConfInfoGrand';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import OperationConfGrand from 'components/machineConf/organisms/setting/OperationConfGrand';
import { ConfListType, MachineConfGrandType } from 'types/machineConf/machineConfType';
import NormalButton from 'components/system/atoms/buttons/NormalButton';
import backAllowIcon from 'assets/images/backAllowIcon.svg';
import { InfoDialog } from 'components/system/organisms';
import MsgHelper from 'utils/message.helper';
import { setMachineGrandConf, setSelectedStationInfo } from 'redux/slices/machineConfSlice';
import ConfSaveDialog from 'components/machineConf/atoms/dialog/ConfSaveDialog';
import MESSAGES from 'constants/messages';
import { InfoDialogProps } from 'components/system/organisms/dialogs/InfoDialog';
import { SaveDetailInfoType } from 'types/machineConf/stationSelectType';
import HttpConnection from 'utils/httpConnection';
import { REST_API } from 'constants/apiUrls';
import TableStationHomeDetailGrand from './TableStationHomeDetailGrand';
import PriceSettingGrand from './PriceSettingGrand';

const CONF_NAME_MAX = 50;
const MSG301: string = MsgHelper.messageFormat(MESSAGES.INF301, '設定');

/* ************ Context ************ */
const ScreenContextValue = {
  title: 'ステーション設定詳細画面',
  screenId: COMMON.SCREEN_ID.STATION_HOME_DETAIL_GRAND,
  currentMenu: COMMON.MENU.MACHINE_CONF,
  disableContents: true,
};

type StationHomeProps = {
  stationName: string;
  giftName: string;
  machineType: string | null;
  machineVersion: string | null;
};

/* ************ Style ************ */
const useStyles = makeStyles(() => ({
  container: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: '5px 12px',
    '& .leftArea': {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
      width: '328px',
    },
    '& .centerArea': {
      display: 'flex',
      flexDirection: 'column',
      '& .bottom': {
        display: 'flex',
        marginTop: '5px',
      },
    },
  },
  operationNewPayoutButton: {
    width: '326px',
    '& button': {
      width: '100%',
      marginTop: '0',
      marginLeft: '0',
    },
  },
  xyConfButton: {
    width: '447px',
    '& button': {
      width: '100%',
    },
  },
  SpeedConfButton: {
    width: '203px',
    '& button': {
      width: '100%',
    },
    '& table': {
      marginLeft: '0',
    },
  },
  PitConfButton: {
    width: '235px',
    '& button': {
      width: '100%',
    },
  },
  ArmPowerButton: {
    width: '320px',
    '& button': {
      width: '100%',
      margin: '0',
    },
  },
  breakText: {
    whiteSpace: 'break-spaces',
    marginLeft: '5px',
  },
  button: {
    width: '157px',
    height: '70px',
    background: '#EBEBEB',
    margin: '0 0 1px auto',
    fontSize: '20px',
    boxShadow: '5px 5px 3px rgba(0, 0, 0, 0.2)',
    textAlign: 'left',
    lineHeight: '24px',
    justifyContent: 'flex-start',
    padding: '6px 8px',
  },
  emptyButton: {
    width: '157px',
    height: '70px',
  },
  supportPartition: {
    height: '70px',
    border: '1px solid #575757',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    background: '#EBEBEB',
    borderRadius: '2px',
  },
  supportPartitionLeft: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '107px',
    borderRight: '1px solid #575757',
    fontSize: '14px',
    height: '100%',
  },
  supportPartitionRight: {
    width: '50px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    fontSize: '14px',
    height: '100%',
    background: '#FFFFFF',
    padding: '8px',
  },
  returnButton: {
    width: '157px',
    height: '70px',
    background: '#EBEBEB',
    margin: '0 0 1px auto',
    fontSize: '20px',
    boxShadow: '5px 5px 3px rgba(0, 0, 0, 0.2)',
    padding: 0,
    textAlign: 'left',
    lineHeight: '24px',
    justifyContent: 'flex-start',
  },
  backIconWrap: { marginLeft: '3px' },
  contentText: { fontSize: '24px' },
}));

const StationHomeDetailGrand: React.FC = () => {
  const history = useHistory();
  const { selectConfId } = useParams<{ selectConfId: string }>();
  const classes = useStyles();
  const dispatch = useAppDispatch();
  const { machineGrandConf, selectedStation } = useAppSelector((state) => state.machineConf);
  const { stationList } = useAppSelector((state) => state.machineConf.stationSelect);
  const [isStationListScreen, setIsStationListScreen] = React.useState(false);
  const [textButtonBack, setTextButtonBack] = React.useState('ステーション\n一覧');
  const [openConfSaveDialog, setOpenConfSaveDialog] = React.useState(false);
  const [openSaveConfInfoDialog, setOpenSaveConfInfoDialog] = React.useState(false);
  const savedConfList: Array<ConfListType> = useAppSelector((state) => state.machineConf.savedConfList);
  const {
    conf,
    pitConf,
    areaConf,
    partRefillMsgConf,
    xySpeedLeverConf = {
      homeX: 0,
      homeY: 0,
      endX: 0,
      endY: 0,
      customConf: '0',
      customX: 0,
      customY: 0,
      speedXy: '',
      speedZ: '',
      leverStatus: COMMON.LEVER_STATUS.FALSE,
      leverTime: '',
      enterButton: '',
    },
    operationConf: payoutInfoSupport = {
      grabLPower1: 0,
      grabCPower1: 0,
      grabRPower1: 0,
      timing1: '000000',
      giftFallingDirection1: '',
      grabLPower2: 0,
      grabCPower2: 0,
      grabRPower2: 0,
      timing2: '000000',
      giftFallingDirection2: '',
      grabLPower3: 0,
      grabCPower3: 0,
      grabRPower3: 0,
      timing3: '000000',
      giftFallingDirection3: '',
      grabLPower4: 0,
      grabCPower4: 0,
      grabRPower4: 0,
      timing4: '000000',
      giftFallingDirection4: '',
      grabLPower5: 0,
      grabCPower5: 0,
      grabRPower5: 0,
      timing5: '000000',
      giftFallingDirection5: '',
      grabLPower6: 0,
      grabCPower6: 0,
      grabRPower6: 0,
      timing6: '000000',
      giftFallingDirection6: '',
      getSupport: '',
      supportConf: '',
      assist: '',
      assistCost: 0,
      playCount: '',
      limitLPower: 0,
      limitRPower: 0,
      limitCPower: 0,
      limitPlayCount: '',
      resetTiming: '000000',
      shiftCm: '',
      shiftDirection: '',
      movingX: '',
      movingY: '',
      openingArm: '',
      descending: '',
      closingArm: '',
      movingPit: '',
      finishingArm: '',
      movingHome: '',
    },
  } = machineGrandConf;
  const isLeft = true;
  const isStationConf = conf?.confDiv === COMMON.CONF_DIV.STATION_CONF;
  const newServiceGraph = {
    lPower1: machineGrandConf?.areaConf?.lPower1 ?? 0,
    rPower1: machineGrandConf?.areaConf?.rPower1 ?? 0,
    limitLPower: machineGrandConf?.operationConf?.limitLPower ?? 0,
    limitRPower: machineGrandConf?.operationConf?.limitRPower ?? 0,
    limitCPower: machineGrandConf?.operationConf?.limitCPower ?? 0,
    resetTiming: machineGrandConf?.operationConf?.resetTiming ?? '',
  };
  const shiftOperation = {
    shiftDirection: machineGrandConf?.operationConf?.shiftDirection ?? '0',
    shiftCm: machineGrandConf?.operationConf?.shiftCm ?? '',
  };
  const levelManagement = {
    levelStatus: machineGrandConf?.xySpeedLeverConf?.leverStatus ?? COMMON.LEVER_STATUS.FALSE,
    levelTime: machineGrandConf?.xySpeedLeverConf?.leverTime ?? '',
  };
  const operationConf = machineGrandConf?.operationConf?.operationConf ?? COMMON.OPERATION_CONF.NO_CONF;
  const [saveConfName, setSaveConfName] = React.useState('');
  const [stationHomeProps, setStationHomeProps] = React.useState<StationHomeProps>({
    stationName: '',
    giftName: '',
    machineType: null,
    machineVersion: null,
  });
  const [savedConfProps, setSavedConfProps] = React.useState<SaveDetailInfoType>({
    saveName: '',
    saveMachineVersion: null,
    saveMachineType: null,
  });
  const processing = React.useRef(false);
  const [infoDialog, setInfoDialog] = React.useState<InfoDialogProps>({
    open: false,
    msg: '',
  });
  const [infoDialogSaveConf, setInfoDialogSaveConf] = React.useState({
    open: false,
    msg: '',
  });

  // 選択したステーションの設定情報を取得
  const getSettingDetail = async (confId: number) => {
    if (confId) {
      const http = new HttpConnection({ dispatch });
      const data = await http.post<MachineConfGrandType>(REST_API.MACHINE_CONF.GET_SETTING_DETAIL_GRAND, { confId });
      dispatch(setMachineGrandConf(data));
    }
  };

  // 選択中のステーション情報取得
  const getStationSelected = (configId: number) => {
    const station = stationList.find((item) => item.confId === configId);
    if (station) {
      setStationHomeProps({
        giftName: station.giftName ?? COMMON.BLANK,
        stationName: station.stationName ?? COMMON.EMPTY,
        machineType: station.machineType,
        machineVersion: station.machineVersion,
      });
      if (isStationConf && selectConfId && selectConfId !== 'none') {
        dispatch(
          setSelectedStationInfo({
            stationId: station.stationId ?? COMMON.EMPTY,
            stationName: station.stationName ?? COMMON.EMPTY,
            giftName: station.giftName ?? COMMON.EMPTY,
            leftRight: station.leftRight,
            boardStation: station.boardStation,
            machineType: station.machineType,
            machineVersion: station.machineVersion,
            confId: Number(selectConfId),
          }),
        );
      }
    }
  };

  // 選択中の保存設定情報取得
  const getSavedConfInfo = (configId: number) => {
    const savedConfInfo = savedConfList.find((item) => Number(item.value) === configId);
    if (savedConfInfo) {
      setSavedConfProps({
        saveName: savedConfInfo.name,
        saveMachineVersion: savedConfInfo.machineVersion,
        saveMachineType: savedConfInfo.machineType,
      });
    }
  };

  useEffect(() => {
    setIsStationListScreen(isStationConf);
    if (isStationConf) {
      setTextButtonBack('ステーション\n一覧');
    } else {
      setTextButtonBack('保存設定\n一覧');
    }
  }, [isStationConf, selectConfId]);

  useEffect(() => {
    if (selectConfId !== 'none') {
      const confId = Number(selectConfId);
      getStationSelected(confId);
      getSettingDetail(confId);
      getSavedConfInfo(confId);
    } else {
      setStationHomeProps({
        giftName: selectedStation.giftName ?? COMMON.BLANK,
        stationName: selectedStation.stationName ?? COMMON.EMPTY,
        machineType: selectedStation.machineType,
        machineVersion: selectedStation.machineVersion,
      });
      setSavedConfProps({
        saveName: conf?.saveConfName ?? '',
        saveMachineVersion: conf?.machineVersion ?? null,
        saveMachineType: conf?.machineType ?? null,
      });
    }
  }, [selectConfId, stationList, isStationConf]);

  const handleClickReturn = () => {
    history.push(isStationConf ? redirectLinks.MCONF_STATION_SELECT : redirectLinks.MCONF_STATION_CONF_SAVED_CONF);
  };

  const handleClickSavedConf = () => {
    history.push(redirectLinks.MCONF_STATION_CONF_SAVED_CONF);
  };

  const handleTransitConfigOtherScreen = () => {
    history.push({ pathname: redirectLinks.MCONF_GRAND_CONF_OTHER, state: isSaveSetting ? savedConfProps : undefined });
  };

  const handleTransitGrandMessageScreen = () => {
    history.push({ pathname: redirectLinks.MCONF_GRAND_MESSAGE, state: isSaveSetting ? savedConfProps : undefined });
  };

  const handleTransitLedGrandConfScreen = () => {
    history.push({ pathname: redirectLinks.MCONF_GRAND_CONF_LED, state: isSaveSetting ? savedConfProps : undefined });
  };

  const handleTransitConfigSequenceScreen = () => {
    history.push({
      pathname: redirectLinks.MCONF_GRAND_CONF_SEQUENCE,
      state: isSaveSetting ? savedConfProps : undefined,
    });
  };

  const handleTransitSupportPartitionScreen = () => {
    history.push({
      pathname: redirectLinks.MCONF_GRAND_SUPPORT_PARTITION,
      state: isSaveSetting ? savedConfProps : undefined,
    });
  };

  const handleOpenSaveConfirmDialog = () => setOpenConfSaveDialog(true);

  const handleCloseConfSaveDialog = (): void => {
    setOpenConfSaveDialog(false);
  };

  // convert to half using normalize
  function convertToHalf(value: string) {
    return value.normalize('NFKC');
  }

  // 設定保存タイトルの変更
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = convertToHalf(e.target.value);
    const checkSpace = value.trim();
    const spaceLength = value.length;

    if (checkSpace === '' && spaceLength !== 0) {
      setSaveConfName('invalid');
    } else {
      setSaveConfName(e.target.value);
    }
  };

  // 設定を保存押下
  const handleOnClickOk = async (e: React.FormEvent) => {
    e.preventDefault();

    // 二重登録防止
    if (processing.current) return;
    processing.current = true;

    if (!saveConfName) {
      setInfoDialog({
        open: true,
        msg: MsgHelper.messageFormat(MESSAGES.INF103, 'タイトル'),
        closeFunc: () => setInfoDialog({ open: false, msg: '' }),
      });
    } else if (saveConfName === 'invalid') {
      setInfoDialog({
        open: true,
        msg: MsgHelper.messageFormat(MESSAGES.INF105),
        closeFunc: () => setInfoDialog({ open: false, msg: '' }),
      });
    } else if (CONF_NAME_MAX < saveConfName.length) {
      // 文字数チェック
      setInfoDialog({
        open: true,
        msg: MsgHelper.messageFormat(MESSAGES.INF111, CONF_NAME_MAX.toString()),
        closeFunc: () => setInfoDialog({ open: false, msg: '' }),
      });
    } else {
      const confId = selectConfId && selectConfId !== 'none' ? Number(selectConfId) : selectedStation.confId;
      const station = stationList.find((item) => item.confId === confId);

      const resultSaveConf = await saveConfGrand({
        saveConfName,
        machineConf: machineGrandConf,
        leftRight: COMMON.LEFT_RIGHT.LEFT,
        boardStation: station?.boardStation ?? '',
        machineType: station?.machineType ?? '',
        machineVersion: station?.machineVersion ?? '',
      });
      if (resultSaveConf && typeof resultSaveConf !== 'number' && !resultSaveConf.success) {
        setInfoDialogSaveConf({
          open: true,
          msg: resultSaveConf.errors[0][0],
        });
      } else {
        setOpenConfSaveDialog(false);
        setOpenSaveConfInfoDialog(true);
        setSaveConfName('');
      }
    }
    // 二重登録防止
    setTimeout(() => {
      // 処理中フラグを下げる
      processing.current = false;
    }, 2000);
  };

  const saveConfGrand = async (dataRequest: {
    saveConfName: string;
    machineConf: MachineConfGrandType;
    leftRight: string;
    boardStation: string;
    machineType: string | null;
    machineVersion: string | null;
  }) => {
    const http = new HttpConnection({ dispatch });
    const result = await http.postSecond(REST_API.MACHINE_CONF.SAVE_CONF_GRAND, dataRequest);
    return result;
  };

  // ダイアログを閉じる
  const handleCloseInfoDialog = (): void => {
    setOpenSaveConfInfoDialog(false);
    setInfoDialogSaveConf({ open: false, msg: '' });
  };

  const supportPartitionButton =
    partRefillMsgConf?.supportPartition === COMMON.FLAG.OFF ? (
      <Box className={classes.supportPartition}>
        <Box className={classes.supportPartitionLeft}>
          サポート
          <br />
          パーテーション
        </Box>
        <Box className={classes.supportPartitionRight}>OFF</Box>
      </Box>
    ) : (
      <>
        <NormalButton className={classes.button} onClick={handleTransitSupportPartitionScreen}>
          サポート
          <br />
          パーテーション
        </NormalButton>
      </>
    );

  const isSaveSetting = machineGrandConf?.conf?.confDiv === COMMON.CONF_DIV.SAVE_CONF;
  return (
    <ScreenContext.Provider value={ScreenContextValue}>
      <Layout>
        <MachineConfContentSettingDetail
          isStationListScreen={isStationListScreen}
          isSaveSetting={isSaveSetting}
          isLeft={isLeft}
          stationName={stationHomeProps.stationName}
          giftName={stationHomeProps.giftName}
          machineType={isStationConf ? stationHomeProps.machineType : savedConfProps.saveMachineType}
          machineVersion={stationHomeProps.machineVersion}
          savedConfProps={savedConfProps}>
          <Box className={classes.container}>
            <Box className="leftArea">
              <Grid container spacing={1}>
                <Grid item>
                  <NormalButton className={classes.returnButton} onClick={handleClickReturn}>
                    <img src={backAllowIcon} alt="戻る" width={28} height={28} className={classes.backIconWrap} />
                    <p className={classes.breakText}>{`${textButtonBack}`}</p>
                  </NormalButton>
                </Grid>
                <Grid item>
                  <NormalButton className={classes.button} onClick={handleTransitConfigOtherScreen}>
                    その他設定
                  </NormalButton>
                </Grid>
                <Grid item>
                  {isSaveSetting ? (
                    <Box className={classes.emptyButton} />
                  ) : (
                    <NormalButton className={classes.button} onClick={handleOpenSaveConfirmDialog}>
                      設定を保存
                    </NormalButton>
                  )}
                </Grid>
                <Grid item>
                  <NormalButton className={classes.button} onClick={handleTransitLedGrandConfScreen}>
                    照明演出
                  </NormalButton>
                </Grid>
                <Grid item>
                  {isSaveSetting ? (
                    <Box className={classes.emptyButton} />
                  ) : (
                    <NormalButton className={classes.button} onClick={handleClickSavedConf}>
                      保存設定一覧
                    </NormalButton>
                  )}
                </Grid>
                <Grid item>
                  <NormalButton className={classes.button} onClick={handleTransitGrandMessageScreen}>
                    メッセージ表示
                  </NormalButton>
                </Grid>
                <Grid item>
                  <NormalButton className={classes.button} onClick={handleTransitConfigSequenceScreen}>
                    シーケンス設定
                  </NormalButton>
                </Grid>
                <Grid item>{supportPartitionButton}</Grid>
              </Grid>
              <Box className={classes.operationNewPayoutButton}>
                <OperationConfGrand
                  title="運営設定"
                  payoutInfoSupport={payoutInfoSupport}
                  newServiceGraph={newServiceGraph}
                  shiftOperation={shiftOperation}
                  operationConf={operationConf}
                  areaConf={areaConf}
                  isLeft={isLeft}
                  savedConfProps={isSaveSetting ? savedConfProps : undefined}
                />
              </Box>
            </Box>
            <Box className="centerArea">
              <Box className={classes.xyConfButton}>
                <XyConfInfoGrand
                  pitConf={pitConf}
                  areaConf={areaConf}
                  isLeft={isLeft}
                  title="移動範囲"
                  xySpeedLeverConf={xySpeedLeverConf}
                  savedConfProps={isSaveSetting ? savedConfProps : undefined}
                />
              </Box>
              <Box className="bottom">
                <Box className={classes.SpeedConfButton}>
                  <SpeedConfInfoGrand
                    isLeft={isLeft}
                    title="ｷｬｯﾁｬｰ速度"
                    levelStatus={levelManagement.levelStatus}
                    speedXy={xySpeedLeverConf?.speedXy}
                    speedZ={xySpeedLeverConf?.speedZ}
                    enterButton={xySpeedLeverConf?.enterButton}
                    leverTime={xySpeedLeverConf?.leverTime}
                  />
                </Box>
                <Box className={classes.PitConfButton}>
                  <PriceSettingGrand />
                </Box>
              </Box>
            </Box>
            <Box className="rightArea">
              <Box className={classes.ArmPowerButton}>
                <TableStationHomeDetailGrand isLeft={isLeft} isStationConf={isStationConf} />
              </Box>
            </Box>
          </Box>
        </MachineConfContentSettingDetail>
      </Layout>

      <ConfSaveDialog
        open={openConfSaveDialog}
        onOk={handleOnClickOk}
        handleChange={handleChange}
        onCancel={handleCloseConfSaveDialog}
      />
      <InfoDialog open={infoDialog.open} msg={infoDialog.msg} closeFunc={infoDialog.closeFunc} />
      <InfoDialog open={infoDialogSaveConf.open} msg={infoDialogSaveConf.msg} closeFunc={handleCloseInfoDialog} />
      <InfoDialog open={openSaveConfInfoDialog} msg={MSG301} closeFunc={handleCloseInfoDialog} />
    </ScreenContext.Provider>
  );
};

export default StationHomeDetailGrand;
