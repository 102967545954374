import { Box, makeStyles } from '@material-ui/core';
import BorderBox from 'components/system/atoms/boxs/BorderBox';
import COMMON from 'constants/common';
import { themeColor } from 'constants/theme';
import React from 'react';
import { ColorEffectSelectAreaGrandType } from 'types/machineConf/colorEffectSelectAreaType';

/* ************ Style ************ */
const useStyles = makeStyles(() => ({
  selectArea: {
    width: '296px',
    fontSize: '19px',
    borderWidth: '1px',
    borderStyle: 'solid',
    borderRadius: '8px',
    padding: '4px 8px',
    '&.grayOut': {
      position: 'relative',
    },
  },
  boxStyle: {
    width: '100%',
    height: '48px',
    fontSize: '19px',
    borderColor: themeColor.grandDarkColor,
  },
  buttonBox: {
    width: '85%',
    '&.effect': {
      position: 'relative',
    },
  },
  buttonLabel: {
    width: '15%',
  },
  colorBox: {
    display: 'flex',
    alignItems: 'center',
    textAlign: 'center',
    '&.reverse': {
      flexDirection: 'row-reverse',
    },
  },
  effectBox: {
    display: 'flex',
    alignItems: 'center',
    textAlign: 'center',
    marginTop: '4px',
    '&.reverse': {
      flexDirection: 'row-reverse',
    },
  },
  disableInputArea: {
    position: 'absolute',
    height: '100%',
    width: '100%',
    background: 'rgb(128 128 128 / 50%)',
    borderRadius: '8px',
    zIndex: 100,
    top: '0px',
    left: '0px',
  },
  grandStyle: {
    backgroundColor: themeColor.grandLightColor,
    borderColor: themeColor.grandDarkColor,
  },
}));

/**
 *
 * @Params
 * @returns {React.FC} - 色・効果 設定エリア
 *
 */
const ColorEffectSelectAreaGrand: React.FC<ColorEffectSelectAreaGrandType> = React.memo((props) => {
  const { selectButtonLR, colorSelect, effectSelect } = props;
  const classes = useStyles();
  const reverse = selectButtonLR === COMMON.LEFT_RIGHT.LEFT ? '' : 'reverse';

  return (
    <Box className={`${classes.selectArea} ${classes.grandStyle}`}>
      <Box className={`${classes.colorBox} ${reverse}`}>
        <Box className={`${classes.buttonBox}`}>
          <BorderBox className={classes.boxStyle}>{colorSelect.value}</BorderBox>
        </Box>
        <Box className={classes.buttonLabel}>色</Box>
      </Box>
      <Box className={`${classes.effectBox} ${reverse}`}>
        <Box className={`${classes.buttonBox} effect`}>
          <BorderBox className={classes.boxStyle}>{effectSelect.value}</BorderBox>
        </Box>
        <Box className={classes.buttonLabel}>効果</Box>
      </Box>
    </Box>
  );
});

export default ColorEffectSelectAreaGrand;
