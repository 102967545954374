import React, { memo, useCallback, useState } from 'react';
import { Box, GridList, makeStyles } from '@material-ui/core';
import { OperationExampleGridType, OperationExampleType } from 'types/manualInformation/manualInformationType';
import playIcon from 'assets/images/playButtonIcon.svg';
import MachineNameLabel from 'components/system/organisms/labels/MachineNameLabel';
import CommonButton from 'components/machineConf/atoms/buttons/CommonButton';
import COMMON from 'constants/common';
import redirectLinks from 'constants/redirectLinks';
import HttpConnection from '../../../utils/httpConnection';
import { REST_API } from '../../../constants/apiUrls';
import { useAppDispatch } from '../../../redux/hooks';
import InfoDialog from '../../../components/system/organisms/dialogs/InfoDialog';
import PlayVideo from '../../../components/operation/PlayVideo';
import MsgHelper from '../../../utils/message.helper';
import MESSAGES from '../../../constants/messages';

/* ************ Style ************ */
const useStyles = makeStyles(() => ({
  container: {
    display: 'flex',
    height: '530px',
    margin: '12px 0 0 40px',
  },
  gridList: {
    width: '100%',
  },
  exampleItem: {
    display: 'flex',
    width: '100%',
    flexWrap: 'wrap',
    paddingRight: '40px',
    marginBottom: '40px',
  },
  thumbnail: {
    position: 'relative',
    width: '316px',
    height: '180px',
    cursor: 'pointer',
  },
  playIcon: {
    marginBottom: '10px',
    position: 'absolute',
    top: '138px',
    left: '5px',
  },
  title: {
    width: '100%',
    height: '40px',
    marginLeft: '4px',
    fontSize: '16px',
    overflow: 'hidden',
  },
  labelTitle: {
    marginTop: '4px',
    display: 'flex',
  },
  linkButton: {
    marginTop: '10px',
    display: 'flex',
    width: '100%',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  informationText: {
    fontSize: 16,
    color: '#007bff',
    textDecoration: 'underline',
    cursor: 'pointer',
  },
  saveConfButton: {
    '& button': {
      width: '132px',
      height: '40px',
      fontSize: '16px',
      padding: '8px',
    },
    '& img': {
      width: '24px',
    },
    '& p': {
      margin: '0',
      textAlign: 'right',
    },
  },
}));

/* ************ Constants ************ */
const MSG301: string = MsgHelper.messageFormat(MESSAGES.INF301, '設定');

/* ************ Component ************ */
const OperationExampleItem: React.FC<{ example: OperationExampleType }> = ({ example }) => {
  const classes = useStyles();
  const dispatch = useAppDispatch();
  const [infoDialogSaveConf, setInfoDialogSaveConf] = useState({
    open: false,
    msg: '',
  });
  const [playVideo, setPlayVideo] = React.useState(false);
  const [openSaveConfInfoDialog, setOpenSaveConfInfoDialog] = React.useState(false);

  const callLogApi = async (id: number) => {
    const http = new HttpConnection({ dispatch });
    http.postSecond(REST_API.OPERATION_EXAMPLE.LOG_OPERATION_EXAMPLE, {
      id,
    });
  };

  const handleClickThumbnail = useCallback(async (operationExample: OperationExampleType) => {
    await callLogApi(operationExample.id);
    // 動画をモーダル表示
    setPlayVideo(true);
  }, []);

  const handleOpenPdf = useCallback(async (operationExample: OperationExampleType) => {
    if (operationExample.manualPath === undefined) return;
    await callLogApi(operationExample.id);
    // PDF画面を別タブで開く
    window.open(`${redirectLinks.OPERATION_EXAMPLE_PDF}/${operationExample.manualPath}`);
  }, []);

  /**
   * Save conf click
   */
  const handleClickSaveConf = useCallback(async (operationExample: OperationExampleType) => {
    const http = new HttpConnection({ dispatch });
    const saveConf = await http.postSecond(REST_API.OPERATION_EXAMPLE.SAVE_CONF_OPERATION_EXAMPLE, {
      conf_id: operationExample?.confId,
      title: operationExample?.title,
      machine_type: operationExample?.machineType,
    });

    if (saveConf && typeof saveConf !== 'number' && !saveConf.success) {
      setInfoDialogSaveConf({
        open: true,
        msg: saveConf.errors[0][0],
      });
    } else {
      setOpenSaveConfInfoDialog(true);
    }
  }, []);

  return (
    <>
      <Box className={classes.exampleItem}>
        <Box className={classes.thumbnail} onClick={() => handleClickThumbnail(example)}>
          <img src={`${COMMON.CONTENT_URL}${example.thumbnailPath}`} alt="" />
          <img className={classes.playIcon} src={playIcon} alt="" />
        </Box>
        <div className={classes.labelTitle}>
          <MachineNameLabel machineType={example.machineType} />
          <div className={classes.title}>{example.title}</div>
        </div>
        <div className={classes.linkButton}>
          <div className={classes.informationText} onClick={() => handleOpenPdf(example)}>
            運営マニュアル(PDF)
          </div>
          <div className={classes.saveConfButton} style={example.confId === null ? { visibility: 'hidden' } : {}}>
            <CommonButton onClick={() => handleClickSaveConf(example)} iconName="confSaveIcon">
              設定を保存
            </CommonButton>
          </div>
        </div>
      </Box>
      <InfoDialog
        open={infoDialogSaveConf.open}
        msg={infoDialogSaveConf.msg}
        closeFunc={() => setInfoDialogSaveConf({ open: false, msg: '' })}
      />
      <PlayVideo
        source={`${COMMON.CONTENT_URL}${example.moviePath}`}
        open={playVideo}
        onClose={() => setPlayVideo(false)}
      />
      <InfoDialog open={openSaveConfInfoDialog} msg={MSG301} closeFunc={() => setOpenSaveConfInfoDialog(false)} />
    </>
  );
};

const OperationExampleGrid: React.FC<OperationExampleGridType> = ({ operationExampleList }) => {
  const classes = useStyles();
  return (
    <div className={classes.container}>
      <GridList className={classes.gridList} cols={3} spacing={0} cellHeight="auto">
        {operationExampleList.map((e) => (
          <div>
            <OperationExampleItem example={e} />
          </div>
        ))}
      </GridList>
    </div>
  );
};

export default memo(OperationExampleGrid);
