import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { EquipmentSelectDataTableRow } from 'types/group/EquipmentSelectType';
import { MachineGiftNameResponseType } from 'types/maintenance/MachineType';
import CLSFCN from 'constants/classification';
import COMMON from 'constants/common';

/* ************ MAINTENABCE用Redux定義 ************ */

/* ************ Type ************ */
export type MachineDataStateType = {
  machineDatasource: Array<EquipmentSelectDataTableRow>;
  // Gift
  giftNameDatasource: Array<MachineGiftNameResponseType>;
  sortMachineList: string;
  sortMachineTypeList: string;
  sortGiftList: string;
  giftSearchKey: string;
  giftNoStation: string;
};

/* ************ State ************ */
const initialState: MachineDataStateType = {
  machineDatasource: [],
  // Gift
  giftNameDatasource: [],
  sortMachineList: CLSFCN.MACHINE_SORT.BOARD_SERIAL_ASK,
  sortMachineTypeList: CLSFCN.MACHINE_TYPE_FILTER.ALL,
  sortGiftList: CLSFCN.DATA_GIFT_LIST_SORT.GIFT_LIST_SORT_DEFAULT,
  giftSearchKey: '',
  giftNoStation: COMMON.FLAG.OFF,
};

/* ******* Event ****** */

const slice = createSlice({
  name: 'maintenance',
  initialState,
  reducers: {
    initializeMaintenanceSlice: () => initialState,
    setMachineListDataSource(state, action: PayloadAction<Array<EquipmentSelectDataTableRow>>) {
      state.machineDatasource = action.payload;
    },
    setGiftNameListDataSource(state, action: PayloadAction<Array<MachineGiftNameResponseType>>) {
      state.giftNameDatasource = action.payload;
    },
    setSortMachineList(state, action: PayloadAction<string>) {
      state.sortMachineList = action.payload;
    },
    setSortMachineTypeList(state, action: PayloadAction<string>) {
      state.sortMachineTypeList = action.payload;
    },
    setSortGiftList(state, action: PayloadAction<string>) {
      state.sortGiftList = action.payload;
    },
    setGiftSearchKey(state, action: PayloadAction<string>) {
      state.giftSearchKey = action.payload;
    },
    setGiftNoStation(state, action: PayloadAction<string>) {
      state.giftNoStation = action.payload;
    },
  },
});

export const {
  initializeMaintenanceSlice,
  setMachineListDataSource,
  setGiftNameListDataSource,
  setSortMachineList,
  setSortMachineTypeList,
  setSortGiftList,
  setGiftSearchKey,
  setGiftNoStation,
} = slice.actions;
export default slice.reducer;
