import React, { memo } from 'react';
import COMMON from 'constants/common';
import { Box, makeStyles } from '@material-ui/core';
import { getClassificationName } from 'utils/common.helper';
import { SelectListType } from 'types/support/supportType';
import CLSFCN from 'constants/classification';
import SelectButtonWithLabel from 'components/system/atoms/buttons/SelectButtonWithLabel';
import { GROUP_LED_INPUT_NAMES } from 'pages/group/LedGroupCreate';

/* ********** Constants ********** */

/* ************ Style ************ */
const useStyles = makeStyles(() => ({
  rightArea: {
    display: 'flex',
    flexDirection: 'column',
  },
  ledSettings: {
    display: 'flex',
    flexDirection: 'column',
    gap: 18,
    padding: '20px 4px 0 6px',
  },
  basicSettings: {
    display: 'flex',
    flexDirection: 'column',
    gap: 18,
    backgroundColor: '#F6F6F6',
    border: '1px solid #C8C8C8',
    padding: '8px 4px 12px 6px',
    marginTop: 12,
  },
  pullDownEmpty: {
    height: 108,
  },
  pullDownEmptyGrand: {
    height: 27,
  },
  pullDown: {
    width: 296,
    justifyContent: 'space-between',
    '& button': {
      width: 200,
      height: 36,
      fontSize: 19,
      border: '2px solid #000',
      borderRadius: 8,
      padding: '0 8px',
      '& div': {
        textAlign: 'left',
      },
    },
  },
  baseFontSize: {
    fontSize: 19,
  },
  label: {
    fontSize: '19px',
    color: '#0D0D0D',
    margin: 0,
  },
}));

/* ************ Type ************ */
type Props = {
  selectList: SelectListType;
  inputValue: {
    setting: string;
    directing: string;
    ecoMode: string;
    keepEcoType: string;
    communicationConf: string;
    bgm: string;
    se: string;
    voice: string;
  };
  handleClickSelectList: (ev: React.MouseEvent<HTMLButtonElement>, id: string, settingTab?: string) => void;
  settingTab: string;
};

/* ************ Component ************ */
const LedGroupBasicSettings: React.FC<Props> = ({ selectList, inputValue, handleClickSelectList, settingTab }) => {
  const classes = useStyles();
  const INPUT_NAMES = GROUP_LED_INPUT_NAMES;

  /* ************ state/redux ************ */

  /* ************ Event ************ */

  return (
    <Box className={classes.rightArea}>
      <Box className={classes.ledSettings}>
        <SelectButtonWithLabel
          className={classes.pullDown}
          label="設定内容"
          onClick={(ev) => handleClickSelectList(ev, INPUT_NAMES.SETTING)}
          arrowSize="28px"
          isSelected={selectList.name === INPUT_NAMES.SETTING}>
          {getClassificationName(CLSFCN.LED_SETTING.CATEGORY_CD, inputValue.setting)}
        </SelectButtonWithLabel>
        <SelectButtonWithLabel
          className={classes.pullDown}
          label="演出内容"
          onClick={(ev) => handleClickSelectList(ev, INPUT_NAMES.DIRECTING)}
          arrowSize="28px"
          isSelected={selectList.name === INPUT_NAMES.DIRECTING}>
          {getClassificationName(CLSFCN.LED_DIRECTING.CATEGORY_CD, inputValue.directing)}
        </SelectButtonWithLabel>
        {settingTab === COMMON.SETTING_TAB.CLENA3 ? (
          <SelectButtonWithLabel
            className={classes.pullDown}
            label="エコモード"
            onClick={(ev) => handleClickSelectList(ev, INPUT_NAMES.ECO_MODE, settingTab)}
            arrowSize="28px"
            isSelected={selectList.name === INPUT_NAMES.ECO_MODE}>
            {getClassificationName(CLSFCN.LED_ECO_MODE.CATEGORY_CD, inputValue.ecoMode)}
          </SelectButtonWithLabel>
        ) : (
          <SelectButtonWithLabel
            className={classes.pullDown}
            label="エコモード"
            onClick={(ev) => handleClickSelectList(ev, INPUT_NAMES.ECO_MODE, settingTab)}
            arrowSize="28px"
            isSelected={selectList.name === INPUT_NAMES.ECO_MODE}>
            {getClassificationName(CLSFCN.LED_ECO_TYPE.CATEGORY_CD, inputValue.ecoMode)}
          </SelectButtonWithLabel>
        )}

        {settingTab === COMMON.SETTING_TAB.GRAND ? (
          <Box>
            <Box className={classes.label}>プレイ時もエコモード継続</Box>
            <SelectButtonWithLabel
              className={classes.pullDown}
              label=" "
              onClick={(ev) => handleClickSelectList(ev, INPUT_NAMES.KEEP_ECO_TYPE, settingTab)}
              arrowSize="28px"
              isSelected={selectList.name === INPUT_NAMES.KEEP_ECO_TYPE}>
              {getClassificationName(CLSFCN.LED_ECO_MODE.CATEGORY_CD, inputValue.keepEcoType)}
            </SelectButtonWithLabel>
          </Box>
        ) : (
          <></>
        )}
      </Box>
      <Box className={classes.basicSettings}>
        <SelectButtonWithLabel
          className={classes.pullDown}
          label="通信設定"
          onClick={(ev) => handleClickSelectList(ev, INPUT_NAMES.COMMUNICATION_CONF)}
          arrowSize="28px"
          isSelected={selectList.name === INPUT_NAMES.COMMUNICATION_CONF}>
          {getClassificationName(CLSFCN.GROUP_COMMUNICATION_CONF.CATEGORY_CD, inputValue.communicationConf)}
        </SelectButtonWithLabel>
        <SelectButtonWithLabel
          className={classes.pullDown}
          label="BGM"
          onClick={(ev) => handleClickSelectList(ev, INPUT_NAMES.BGM)}
          arrowSize="28px"
          isSelected={selectList.name === INPUT_NAMES.BGM}>
          {getClassificationName(CLSFCN.GROUP_BGM.CATEGORY_CD, inputValue.bgm)}
        </SelectButtonWithLabel>
      </Box>

      <Box className={settingTab === COMMON.SETTING_TAB.GRAND ? classes.pullDownEmptyGrand : classes.pullDownEmpty} />
    </Box>
  );
};

export default memo(LedGroupBasicSettings);
