import { createSlice } from '@reduxjs/toolkit';
import { DataStateType } from 'types/data/dataType';

/* ************ 機器設定用Redux定義 ************ */
/* ************ State ************ */
// Reduxで管理するグローバルステートの設定
const initialState: DataStateType = {
  // 2024年度アップデート対応でデータ機能のsliceは不要になったため、定義を削除済み
  // 今後必要になったときのためredux自体は残しておく
};

const slice = createSlice({
  name: 'data',
  initialState,
  reducers: {
    initializeDataSlice: () => initialState,
  },
});

export const { initializeDataSlice } = slice.actions;
export default slice.reducer;
