import { makeStyles, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@material-ui/core';
import React, { memo, useCallback } from 'react';
import { RegisteredStationResponseType } from 'types/maintenance/GiftType';
import { getStationName, renderMachineType } from 'utils/common.helper';
import COMMON from 'constants/common';

/* ************ Style ************ */
const useStyles = makeStyles(() => ({
  default: {
    height: '211px',
    overflowX: 'hidden',
    width: '1100px',
  },
  table: {
    tableLayout: 'fixed',
    '& th,td': {
      border: '1px solid #707070',
      fontSize: '21px',
      lineHeight: '40px',
      minHeight: '42px',
    },
    '& th': {
      textAlign: 'left',
      paddingLeft: 17,
      height: '42px',
      padding: 0,
    },
    '& tbody tr': {
      minHeight: '74px',
    },
    '& td': {
      padding: '0 17px',
      '& .cellStyle': {
        whiteSpace: 'nowrap',
        overflow: 'hidden',
      },
    },
    '& .machineType': {
      padding: '0 0 0 17px',
    },
    '& .tdNoPadding': {
      padding: '0',
    },
  },
  head: {
    backgroundColor: '#CFCFCF',
  },
  tdPadding: {
    padding: '0 17px',
  },
  tdStationName: {
    textAlign: 'left',
    alignItems: 'center',
  },
  tdStationContentFirst: {
    borderBottom: '1px solid #000',
  },
}));

/* ************ Type ************ */
type RegisteredStationTableProps = {
  rows: Array<Array<RegisteredStationResponseType>>;
};

/* ************ Component ************ */
const RegisteredStationTable: React.FC<RegisteredStationTableProps> = ({ rows }) => {
  const classes = useStyles();
  const column1 = 192;
  const column2 = 192;

  const headerColumns = [
    { id: 'machineNoColumn', label: '登録番号', width: column1 },
    { id: 'machineTypeColumn', label: '機種名', width: column2 },
    { id: 'stationNameColumn', label: 'ステーション名', width: '100%' },
  ];

  const getStationNameFunc = useCallback(
    (row: RegisteredStationResponseType) => getStationName(row.machineType, row.stationName, row.leftRightStationFlag),
    [],
  );

  return (
    <>
      <TableContainer id="scrollContainer" className={classes.default}>
        <Table className={classes.table} stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              {headerColumns.map((column) => (
                <TableCell
                  key={`machine_list_table_head_key_${column.id}`}
                  style={{ width: column.width }}
                  className={classes.head}>
                  {column.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {Object.values(rows).map((row, index) => {
              const key = `gift_list_table_key_${index}`;
              return (
                <React.Fragment key={key}>
                  <TableRow key={key}>
                    <TableCell className={classes.tdStationName}>
                      <div>
                        <div className="cellStyle">{row[0].machineNo}</div>
                      </div>
                    </TableCell>
                    <TableCell className={`${classes.tdStationName} machineType`}>
                      <div>
                        <div className="cellStyle">{renderMachineType(row[0].machineType)}</div>
                      </div>
                    </TableCell>
                    {row[0].machineType === COMMON.MACHINE_TYPE.CLENA3 ? (
                      <>
                        <TableCell className={`${classes.tdStationName} tdNoPadding`}>
                          <div
                            className={`${classes.tdPadding} ${row.length >= 2 ? classes.tdStationContentFirst : ''}`}>
                            <div className="cellStyle">{getStationNameFunc(row[0])}</div>
                          </div>
                          {row.length >= 2 ? (
                            <div className={`${classes.tdPadding}`}>
                              <div className="cellStyle">{getStationNameFunc(row[1])}</div>
                            </div>
                          ) : (
                            <></>
                          )}
                        </TableCell>
                      </>
                    ) : (
                      <TableCell className={`${classes.tdStationName} tdNoPadding`}>
                        <div className={`${classes.tdPadding}`}>
                          <div className="cellStyle">{getStationNameFunc(row[0])}</div>
                        </div>
                      </TableCell>
                    )}
                  </TableRow>
                </React.Fragment>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};

export default memo(RegisteredStationTable);
