import React, { useState } from 'react';
import { Box, Dialog, DialogContent, makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  content: {
    display: 'flex',
    minHeight: '276px',
    width: '800px',
    padding: '0px',
    // DialogContentのデフォルトcssを上書き
    '&:first-child': {
      padding: '0px',
    },
  },
  paper: {
    backgroundColor: theme.palette.common.black,
    minHeight: '276px',
    width: '800px',
  },
}));

interface PlayVideoInterface {
  source: string;
  open: boolean;
  onClose: () => void;
}

const PlayVideo: React.FC<PlayVideoInterface> = (props) => {
  const classes = useStyles();
  const { source, open, onClose } = props;
  const [touchTimeout, setTouchTimeout] = useState<NodeJS.Timeout | null>(null);

  const handleClose = () => {
    onClose();
  };

  /**
   * コンテキストメニュー（右クリックメニュー）の表示を防止するハンドラ
   */
  const handleContextMenu = (event: React.MouseEvent<HTMLVideoElement>) => {
    event.preventDefault();
  };

  /**
   * タッチ開始時のイベントハンドラ
   * 一定時間（ここでは500ms）後にデフォルトのコンテキストメニューを防止
   */
  const handleTouchStart = (event: React.TouchEvent<HTMLVideoElement>) => {
    // イベントがキャンセル可能かチェック
    if (event.cancelable) {
      const timeout = setTimeout(() => {
        event.preventDefault();
      }, 500);
      setTouchTimeout(timeout);
    }
  };

  /**
   * タッチ終了時のイベントハンドラ
   * タイマーが設定されていた場合、クリアしてリセット
   */
  const handleTouchEnd = () => {
    if (touchTimeout) {
      clearTimeout(touchTimeout);
      setTouchTimeout(null);
    }
  };

  return (
    <>
      <Dialog
        classes={{
          paper: classes.paper,
        }}
        onClose={handleClose}
        open={open}
        maxWidth={false}>
        <DialogContent
          classes={{
            root: classes.content,
          }}>
          <Box className={classes.content}>
            <video
              controls
              width="800"
              style={{ maxWidth: '100%', height: 'auto' }}
              // video内のメニューから「ダウンロード」を削除
              controlsList="nodownload"
              // video上での右クリック防止
              onContextMenu={handleContextMenu}
              // video上でのロングタップ（タブレットでの右クリック相当）防止
              onTouchStart={handleTouchStart}
              onTouchEnd={handleTouchEnd}>
              <source src={source} type="video/mp4" />
              <track kind="captions" srcLang="en" label="English captions" default />
              Your browser does not support the video tag.
            </video>
          </Box>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default PlayVideo;
