import React, { memo } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { getClassificationName } from 'utils/common.helper';
import CLSFCN from 'constants/classification';
import { Box, Table, TableRow, Theme } from '@material-ui/core';
import COMMON from 'constants/common';
import { useAppSelector } from 'redux/hooks';

type SpeedConfInfoProps = {
  title: string;
  isLeft: boolean;
  levelStatus: string;
  speedXy: string | undefined;
  speedZ: string | undefined;
  enterButton?: string;
  leverTime?: string;
};

type StyleProps = {
  isLeft: boolean;
};

const useStyles = makeStyles<Theme, StyleProps>((theme) => ({
  container: {
    border: ({ isLeft }) =>
      `2px solid ${isLeft ? theme.grandStation.contentsBorder : theme.rightStation.contentsBorder}`,
    borderRadius: 4,
    padding: '4px 9px',
    height: '177px',
    width: '203px',
  },
  prizeContainer: {
    border: ({ isLeft }) =>
      `2px solid ${isLeft ? theme.grandStation.contentsBorder : theme.rightStation.contentsBorder}`,
    borderRadius: 4,
    padding: '4px 9px',
    height: '111px',
    width: '203px',
    marginTop: '5px',
  },
  title: {
    fontSize: 24,
    lineHeight: '22px',
    marginBottom: '5px',
  },
  leverOperationTitle: {
    fontSize: 24,
    marginTop: '5px',
  },
  frame: {
    flexDirection: 'column',
    alignItems: 'center',
  },
  child: {
    margin: '0 0px 0px 0',
  },
  detailBlockSpeed: {
    borderCollapse: 'collapse',
    fontSize: '14px',
    display: 'block',
    marginLeft: '20px',
  },
  boxImg: {
    position: 'relative',
    height: '116px',
  },
  img: {
    position: 'relative',
    height: '125px',
    margin: '0 auto',
    top: '-10px',
  },
  speedTop: {
    backgroundColor: '#EEEEEE',
    width: '79px',
    height: '21px',
    border: '1px solid #707070',
    textAlign: 'center',
  },
  prize: {
    backgroundColor: '#EEEEEE',
    width: '136px',
    height: '21px',
    border: '1px solid #707070',
    textAlign: 'center',
  },
  speedTopZ: {
    color: '#00B050',
  },
  speedTopValue: {
    backgroundColor: '#ffffff',
    border: '1px solid #707070',
    width: '103px',
    height: '21px',
    textAlign: 'center',
  },
  prizeValue: {
    backgroundColor: '#ffffff',
    border: '1px solid #707070',
    width: '46px',
    height: '21px',
    textAlign: 'center',
  },
  X: {
    display: 'inline',
    color: '#FF3E3E',
  },
  Y: {
    display: 'inline',
    color: '#3086FF',
  },
  speedTopText: {
    textAlign: 'center',
    color: '#FFC000',
  },
}));

const SpeedConfInfoGrand: React.FC<SpeedConfInfoProps> = (props) => {
  const { title, isLeft, levelStatus, speedXy, speedZ, enterButton, leverTime } = props;
  const { machineGrandConf } = useAppSelector((state) => state.machineConf);
  const classes = useStyles({ isLeft });
  let operationTime = COMMON.BLANK3;
  let confirmButton = COMMON.BLANK3;
  let speedTopText = (
    <>
      <p className={classes.X}>X</p>
      <p className={classes.Y}>Y</p>
    </>
  );
  if (levelStatus === COMMON.LEVER_STATUS.TRUE) {
    speedTopText = <Box className={classes.speedTopText}>レバー</Box>;
    operationTime = getClassificationName(CLSFCN.LEVER_TIME.CATEGORY_CD, String(leverTime)) || COMMON.BLANK3;
    confirmButton = getClassificationName(CLSFCN.ENTER_BUTTON.CATEGORY_CD, `${enterButton}`) || COMMON.BLANK3;
  }

  return (
    <>
      <Box className={classes.container}>
        <Box className={classes.frame}>
          <Box className={`${classes.title}`}>
            <b>{title}</b>
          </Box>
          <Box className={classes.child}>
            <Table className={classes.detailBlockSpeed}>
              <TableRow>
                <td className={classes.speedTop}>{speedTopText}</td>
                <td className={classes.speedTopValue}>
                  {getClassificationName(CLSFCN.SPEED_CONF.XYZ, String(speedXy))}
                </td>
              </TableRow>
              <TableRow>
                <td className={classes.speedTop}>
                  <div className={classes.speedTopZ}>Z</div>
                </td>
                <td className={classes.speedTopValue}>
                  {getClassificationName(CLSFCN.SPEED_CONF.XYZ, String(speedZ))}
                </td>
              </TableRow>
            </Table>
          </Box>
        </Box>
        <Box className={classes.frame}>
          <Box className={`${classes.leverOperationTitle}`}>
            <b>レバー操作</b>
          </Box>
          <Box className={classes.child}>
            <Table className={classes.detailBlockSpeed}>
              <TableRow>
                <td className={classes.speedTop}>操作時間</td>
                <td className={classes.speedTopValue}>{operationTime}</td>
              </TableRow>
              <TableRow>
                <td className={classes.speedTop}>決定ボタン</td>
                <td className={classes.speedTopValue}>{confirmButton}</td>
              </TableRow>
            </Table>
          </Box>
        </Box>
      </Box>
      <Box className={classes.prizeContainer}>
        <Box className={classes.frame}>
          <Box className={classes.title}>
            <b>景品払出後操作</b>
          </Box>
          <Box className={classes.child}>
            <Table className={classes.detailBlockSpeed}>
              <TableRow>
                <td className={classes.prize}>景品自動補充</td>
                <td className={classes.prizeValue}>
                  {getClassificationName(
                    CLSFCN.ON_OFF.CATEGORY_CD,
                    machineGrandConf?.partRefillMsgConf?.refill ?? COMMON.FLAG.OFF,
                  )}
                </td>
              </TableRow>
              <TableRow>
                <td className={classes.prize}>上部背板途中停止</td>
                <td className={classes.prizeValue}>
                  {getClassificationName(
                    CLSFCN.ON_OFF.CATEGORY_CD,
                    machineGrandConf?.partRefillMsgConf?.midwayStop ?? COMMON.FLAG.OFF,
                  )}
                </td>
              </TableRow>
              <TableRow>
                <td className={classes.prize}>景品補充待機機能</td>
                <td className={classes.prizeValue}>
                  {getClassificationName(
                    CLSFCN.ON_OFF.CATEGORY_CD,
                    machineGrandConf?.partRefillMsgConf?.waitRefill ?? COMMON.FLAG.OFF,
                  )}
                </td>
              </TableRow>
            </Table>
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default memo(SpeedConfInfoGrand);
