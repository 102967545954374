import * as React from 'react';
import { useEffect } from 'react';
import {
  Dialog,
  DialogContent,
  DialogContentText,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import NormalButton from 'components/system/atoms/buttons/NormalButton';
import ListCheckBox from 'components/system/atoms/checkBoxs/ListCheckBox';
import MSG from 'constants/messages';
import MsgHelper from 'utils/message.helper';
import { AccessMachineTypeType, PostLicenseCountType } from 'types/maintenance/AccessMachineType';
import { useDispatch } from 'react-redux';
import InfoDialog from './InfoDialog';
import { renderMachineType } from '../../../../utils/common.helper';
import ConfirmDialog from './ConfirmDialog';
import HttpConnection from '../../../../utils/httpConnection';
import { REST_API } from '../../../../constants/apiUrls';
import { useAppSelector } from '../../../../redux/hooks';

/* ************ Style ************ */
const useStyles = makeStyles(() => ({
  paper: {
    backgroundColor: 'transparent',
    height: '600px',
    width: '880px',
    boxShadow: 'none',
  },
  paperMin: {
    height: 395,
  },
  content: {
    display: 'flex',
    paddingTop: '0!important',
    paddingBottom: '0!important',
    paddingLeft: '0!important',
    paddingRight: '0!important',
    overflow: 'hidden',
  },
  buttonCloseText: {
    fontSize: 28,
    color: '#ffffff',
  },
  margin10: {
    marginRight: 10,
  },
  buttonCloseStyle: {
    width: '156px',
    height: '70px',
    borderRadius: '8px',
  },
  dialogContent: {
    background: '#ffffff',
    width: '100%',
    padding: '0 10px',
    borderTopLeftRadius: 4,
    borderBottomLeftRadius: 4,
  },
  header: {
    margin: '16px 0 6px',
  },
  title: {
    textAlign: 'center',
    fontSize: '24px',
  },
  title2: {
    marginTop: 10,
    textAlign: 'left',
    fontSize: '16px',
  },
  limitCount: {
    textAlign: 'right',
    fontSize: '20px',
  },
  footer: {
    textAlign: 'center',
    margin: '14px 0',
  },
  actionButtonStyle: {
    width: '156px',
    height: '70px',
    borderRadius: '8px',
    fontSize: '21px',
  },
  // テーブル関連スタイル
  default: {
    height: 385,
    overflowX: 'hidden',
  },
  defaultMin: {
    height: 185,
    overflowX: 'hidden',
  },
  table: {
    tableLayout: 'fixed',
    '& th,td': {
      border: '1px solid #707070',
      fontSize: '24px',
      lineHeight: '20px',
      minHeight: '74px',
    },
    '& th': {
      textAlign: 'center',
      height: '50px',
      padding: 0,
    },
    '& tbody tr': {
      minHeight: '74px',
    },
    '& td': {
      padding: '0 8px',
      '& .cellStyle': {
        fontSize: '21px',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
      },
    },
  },
  head: {
    backgroundColor: '#CFCFCF',
  },
  tdStationName: {
    textAlign: 'left',
    alignItems: 'center',
    '& .tdDetailButton': {
      height: '44px',
      width: '128px',
      backgroundColor: '#A2FFBB',
      fontSize: '24px',
      boxShadow: '5px 5px 3px 0px rgba(0, 0, 0, 0.2)',
    },
  },
  tdEquipmentNo: {
    display: 'flex',
    alignItems: 'center',
  },
  tdMachineName: {
    textAlign: 'center',
  },
  tdMachineType: {
    textAlign: 'left',
  },
  contentText: { fontSize: '24px' },
}));

type SettingLinkagePosDialogProps = {
  open: boolean;
  onCancel: () => void;
};

// messages
const infoMSG: string = MsgHelper.messageFormat(MSG.INF401);
const confirmMsgSave: string = MsgHelper.messageFormat(MSG.INF230);
const messageSaveSuccess: string = MsgHelper.messageFormat(MSG.INF309, '機器', '機器一覧');

const column1 = '200px';
const column2 = '200px';
const column3 = '233px';

const SettingLinkagePosDialog: React.FC<SettingLinkagePosDialogProps> = (props) => {
  const { open, onCancel } = props;
  const [height, setHeight] = React.useState(window.innerHeight);

  const dispatch = useDispatch();
  const classes = useStyles();
  const loginUserInfo = useAppSelector((state) => state.common.userInfo);
  const [accessMachineList, setAccessMachineList] = React.useState<Array<AccessMachineTypeType>>([]);
  const [licenseCount, setLicenseCount] = React.useState<number>(0);
  const [checkCount, setCheckCount] = React.useState(0);
  const [openInfoDlg, setOpenInfoDlg] = React.useState(false);
  const [disabledUpdate, setDisabledUpdate] = React.useState(false);
  const [currentCheck, setCurrentCheck] = React.useState(0);
  const [openSaveConfirmDlg, setOpenSaveConfirmDlg] = React.useState(false);
  const [openSaveSuccessDlg, setOpenSaveSuccessDlg] = React.useState(false);

  const headerColumns = [
    { id: 'machineNoColumnId', label: '登録番号', width: column1 },
    { id: 'stationName', label: 'POS登録番号', width: column2 },
    { id: 'machineType', label: '機種名', width: column3 },
  ];

  useEffect(() => {
    const handleResize = () => setHeight(window.innerHeight);
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  useEffect(() => {
    setAccessMachineList(accessMachineList);
    const checkCountNow = getCheckCount(accessMachineList);
    if (checkCountNow > licenseCount && checkCountNow >= currentCheck) {
      setOpenInfoDlg(true);
      setDisabledUpdate(true);
      setCurrentCheck(checkCountNow);
    }
  }, [accessMachineList, licenseCount, currentCheck]);

  const handleCallApiGetAccessMachineList = async () => {
    const http = new HttpConnection({ dispatch });
    const licenseCountType = await http.get<PostLicenseCountType>(REST_API.MAINTENANCE.GET_POS_LICENSE_COUNT, {
      headquarters_id: loginUserInfo?.currentHeadQId,
      store_id: loginUserInfo?.currentStoreId,
    });
    setLicenseCount(licenseCountType.licenseCount);
    const data = await http.get<Array<AccessMachineTypeType>>(REST_API.MAINTENANCE.GET_ACCESS_MACHINE_LIST);
    const dataMap = data.map((item) => ({ ...item, linkage: item.selectedTarget == null }));
    setAccessMachineList(dataMap);
  };

  useEffect(() => {
    if (open) {
      handleCallApiGetAccessMachineList().then();
    }
  }, [open]);

  const getCheckCount = (rowsList: Array<AccessMachineTypeType>) => {
    const result = rowsList.filter((row) => row.linkage);
    return result.length;
  };

  useEffect(() => {
    setCheckCount(getCheckCount(accessMachineList));
    if (getCheckCount(accessMachineList) <= licenseCount) {
      setDisabledUpdate(false);
    }
  }, [accessMachineList]);

  const onChangeCheck = (index: number) => {
    const tempRows = accessMachineList.slice();
    const targetRow = { ...tempRows[index] };
    if (!targetRow.linkage && getCheckCount(accessMachineList) >= licenseCount) {
      return;
    }
    targetRow.linkage = !targetRow.linkage;
    tempRows[index] = targetRow;
    setAccessMachineList(tempRows);
  };

  const handleSaveButton = React.useCallback(() => {
    setOpenSaveConfirmDlg(true);
  }, []);

  const handleSubmitData = React.useCallback(async () => {
    setOpenSaveConfirmDlg(false);
    const linkageMachineList = accessMachineList.map((row) => ({
      boardSerial: row.boardSerial,
      linkageFlg: row.linkage,
    }));
    const http = new HttpConnection({ dispatch });
    const data = await http.post(REST_API.MAINTENANCE.UPDATE_ACCESS_MACHINE_LIST, {
      linkage_machine_list: [...linkageMachineList],
    });
    if (data) {
      setOpenSaveSuccessDlg(true);
    }
  }, [accessMachineList]);

  const handleCloseSuccessDialog = React.useCallback(() => {
    setOpenSaveSuccessDlg(false);
    onCancel();
  }, [accessMachineList]);

  const onSubClose = React.useCallback(() => {
    setAccessMachineList(accessMachineList.slice());
    onCancel();
    setOpenInfoDlg(false);
    setDisabledUpdate(false);
    setCurrentCheck(0);
    setAccessMachineList([]);
  }, [accessMachineList]);

  const classTableHeight = height <= 648 ? classes.defaultMin : classes.default;
  const classPaperMin = height <= 648 ? classes.paperMin : '';

  return (
    <Dialog
      classes={{
        paper: `${classes.paper} ${classPaperMin}`,
      }}
      open={open}
      onClose={onSubClose}
      maxWidth={false}>
      <DialogContent
        classes={{
          root: classes.content,
        }}>
        <div onClick={onSubClose} className={classes.margin10}>
          <DialogContentText>
            <div>
              <NormalButton baseColor="black" className={classes.buttonCloseStyle}>
                <span className={classes.buttonCloseText}>× 閉じる</span>
              </NormalButton>
            </div>
          </DialogContentText>
        </div>
        <div className={classes.dialogContent}>
          <div className={classes.header}>
            <div className={classes.title}>POS連携筐体設定</div>
            <div className={classes.title2}>POSへ連携したい筐体にチェックをいれてください。</div>
            <div className={classes.limitCount}>
              {checkCount} / {licenseCount}
            </div>
          </div>
          <TableContainer id="scrollContainer" className={classTableHeight}>
            <Table className={classes.table} stickyHeader aria-label="sticky table">
              <TableHead>
                <TableRow>
                  {headerColumns.map((column) => (
                    <TableCell
                      key={`machine_list_table_head_key_${column.id}`}
                      style={{ width: column.width }}
                      className={classes.head}>
                      {column.label}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {accessMachineList.map((row, index) => {
                  const key = `machine_list_table_key_${index}_index_${row.boardSerial}`;
                  return (
                    <React.Fragment key={key}>
                      <TableRow key={key}>
                        <TableCell className={classes.tdStationName}>
                          <div className={classes.tdEquipmentNo}>
                            <ListCheckBox
                              checked={row.linkage}
                              name="selectStation"
                              onChange={() => onChangeCheck(index)}
                            />
                            <div className="cellStyle">{row.boardSerial}</div>
                          </div>
                        </TableCell>
                        <TableCell className={classes.tdMachineType}>
                          <div className="cellStyle">{row.leftStationName}</div>
                          <div className="cellStyle">{row.rightStationName}</div>
                        </TableCell>
                        <TableCell className={classes.tdMachineType}>
                          <div className="cellStyle">{renderMachineType(row.machineType)}</div>
                        </TableCell>
                      </TableRow>
                    </React.Fragment>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
          <div className={classes.footer}>
            <NormalButton
              baseColor="black"
              className={classes.actionButtonStyle}
              onClick={handleSaveButton}
              disabled={disabledUpdate}>
              更新
            </NormalButton>
          </div>
        </div>
      </DialogContent>
      <InfoDialog open={openInfoDlg} msg={infoMSG} closeFunc={() => setOpenInfoDlg(false)} />
      <ConfirmDialog
        open={openSaveConfirmDlg}
        msg={confirmMsgSave}
        onOk={handleSubmitData}
        onCancel={() => setOpenSaveConfirmDlg(false)}
        addClass={{
          contentText: classes.contentText,
        }}
      />
      <InfoDialog open={openSaveSuccessDlg} msg={messageSaveSuccess} closeFunc={handleCloseSuccessDialog} />
    </Dialog>
  );
};

export default SettingLinkagePosDialog;
