import React from 'react';
import { makeStyles, Table, TableBody, TableCell, TableContainer, TableRow } from '@material-ui/core';
import { AreaDetailGrandType, AreaDetailRowGrandType, CellSizeType } from 'types/machineConf/areaType';
import COMMON from 'constants/common';
import { AreaConfGrandType } from 'types/machineConf/machineConfType';
import { themeColor } from 'constants/theme';
import { useDispatch } from 'react-redux';
import { setShowAreaColor } from 'redux/slices/machineConfSlice';

/* ************* constant ************* */
const tableArrayRender = [0, 1, 2];

/* ************ Style ************ */
const useStyles = makeStyles((theme) => ({
  tableRoot: {
    borderCollapse: 'collapse',
    tableLayout: 'fixed',
    '& .area0': {
      backgroundColor: theme.base.area3,
    },
    '& .area1': {
      backgroundColor: theme.base.area4,
    },
    '& .area2': {
      backgroundColor: theme.base.area5,
    },
    '& .area3': {
      backgroundColor: theme.base.area0,
    },
    '& .area4': {
      backgroundColor: theme.base.area1,
    },
    '& .area5': {
      backgroundColor: theme.base.area2,
    },
    '& td': {
      padding: 0,
      '& img': {
        transform: 'scale(0.8)',
      },
    },
  },
  tableRootBorder: {
    border: '3px solid',
  },
  tableContainer: {
    position: 'relative',
    overflow: 'hidden',
  },
  mediumLineSeparate: {
    borderBottom: '0px solid',
  },
  mediumColumnSeparate: {
    borderRight: '2px solid',
  },
  lineSeparateNone: {
    borderBottomWidth: '0px',
  },
  lineSeparateDot: {
    borderBottom: '2px dashed',
  },
  columnSeparateNone: {
    borderRightWidth: '0px',
  },
  columnSeparateDot: {
    borderRight: '2px dashed',
  },
  pinpointPit: {
    position: 'absolute',
    borderRadius: '100%',
    border: '2px solid',
    zIndex: 1,
    backgroundColor: '#FF0035',
    borderColor: theme.palette.common.white,
  },
  smallPit: {
    width: '16px',
    height: '16px',
  },
  mediumPit: {
    width: '20px',
    height: '20px',
  },
  largePit: {
    width: '24px',
    height: '24px',
  },
  smallContainer: {
    top: '-8px',
    left: '-8px',
    width: '196px',
    height: '196px',
    padding: '8px 0px 0px 8px',
  },
  smallContainerGridDashLineSize: {},
  mediumContainer: {
    top: '-10px',
    left: '-10px',
    width: '380px',
    height: '380px',
    padding: '10px',
  },
  mediumContainerGridDashLineSize: {
    '& .hLine1': {
      height: 2,
      width: 329,
      borderTop: '2px dashed',
      marginTop: 39,
    },
    '& .hLine2': {
      height: 2,
      width: 329,
      borderTop: '2px dashed',
      marginTop: 356,
    },
    '& .vLine1': {
      width: 2,
      height: 321,
      borderRight: '2px dashed',
      marginTop: 39,
      marginLeft: 2,
    },
    '& .vLine2': {
      width: 2,
      height: 321,
      borderRight: '2px dashed',
      marginTop: 39,
      marginLeft: 87,
    },
    '& .vLine3': {
      width: 2,
      height: 321,
      borderRight: '2px dashed',
      marginTop: 39,
      marginLeft: 149,
    },
    '& .vLine4': {
      width: 2,
      height: 321,
      borderRight: '2px dashed',
      marginTop: 39,
      marginLeft: 210,
    },
    '& .vLine5': {
      width: 2,
      height: 321,
      borderRight: '2px dashed',
      marginTop: 39,
      marginLeft: 329,
    },
  },
  customMediumContainer: {
    width: '210px',
    height: '210px',
  },
  customContainerGridDashLineSize: {
    '& .hLine1': {
      height: 2,
      width: 193,
      borderTop: '2px dashed',
      marginTop: 21,
    },
    '& .hLine2': {
      height: 2,
      width: 193,
      borderTop: '2px dashed',
      marginTop: 205,
    },
    '& .vLine1': {
      width: 2,
      height: 189,
      borderRight: '2px dashed',
      marginTop: 21,
      marginLeft: 3,
    },
    '& .vLine2': {
      width: 2,
      height: 189,
      borderRight: '2px dashed',
      marginTop: 21,
      marginLeft: 53,
    },
    '& .vLine3': {
      width: 2,
      height: 189,
      borderRight: '2px dashed',
      marginTop: 21,
      marginLeft: 88,
    },
    '& .vLine4': {
      width: 2,
      height: 189,
      borderRight: '2px dashed',
      marginTop: 21,
      marginLeft: 124,
    },
    '& .vLine5': {
      width: 2,
      height: 189,
      borderRight: '2px dashed',
      marginTop: 21,
      marginLeft: 193,
    },
  },
  largeContainer: {
    top: '-12px',
    left: '-12px',
    width: '414px',
    height: '414px',
    padding: '12px',
  },
  largeContainerGridDashLineSize: {
    '& .hLine1': {
      height: 2,
      width: '357px',
      borderTop: '2px dashed',
      marginTop: 41,
    },
    '& .hLine2': {
      height: 2,
      width: '357px',
      borderTop: '2px dashed',
      marginTop: 387,
    },
    '& .vLine1': {
      width: 2,
      height: 348,
      borderRight: '2px dashed',
      marginTop: 41,
      marginLeft: 1,
    },
    '& .vLine2': {
      width: 2,
      height: 348,
      borderRight: '2px dashed',
      marginTop: 41,
      marginLeft: 96,
    },
    '& .vLine3': {
      width: 2,
      height: 348,
      borderRight: '2px dashed',
      marginTop: 41,
      marginLeft: 162,
    },
    '& .vLine4': {
      width: 2,
      height: 348,
      borderRight: '2px dashed',
      marginTop: 41,
      marginLeft: 229,
    },
    '& .vLine5': {
      width: 2,
      height: 348,
      borderRight: '2px dashed',
      marginTop: 41,
      marginLeft: 357,
    },
  },
  smallStyle: {
    height: '180px',
    width: '180px',
  },
  mediumStyle: {
    height: '360px',
    width: '360px',
  },
  customMediumStyle: {
    height: '210px',
    width: '210px',
  },
  largeStyle: {
    height: '390px',
    width: '390px',
  },
  defaultStyle: {
    borderColor: theme.base.tableBorder,
  },
  leftStyle: {
    borderColor: theme.grandStation.itemBorder,
  },
  rightStyle: {
    borderColor: theme.rightStation.itemBorder,
  },
  gridDashLine: {
    position: 'absolute',
    left: 0,
    top: 0,
    '& .dashLine': {
      borderColor: theme.base.tableBorder,
      position: 'absolute',
    },
  },
}));

const sizeDefault = {
  area0: { width: '0px', height: '0px', border: 'none' },
  area1: { width: '0px', height: '0px', border: 'none' },
  area2: { width: '0px', height: '0px', border: 'none' },
  area3: { width: '0px', height: '0px', border: 'none' },
  area4: { width: '0px', height: '0px', border: 'none' },
  area5: { width: '0px', height: '0px', border: 'none' },
};

/* ********* Local Function ********** */
const getCellSize = (areaConf: AreaConfGrandType, borderColor?: string): CellSizeType => {
  const isDefault = areaConf && areaConf.areaConf === COMMON.SWITCH.OFF;
  const SIZE_AREA = 210;
  const BORDER_AREA = 1;
  const borderColorStyle = borderColor ?? themeColor.grandDarkColor;

  if (isDefault) {
    return {
      area0: {
        width: '0px',
        height: '0px',
        border: 'none',
      },
      area1: {
        width: '0px',
        height: '0px',
        border: 'none',
      },
      area2: {
        width: '0px',
        height: '0px',
        border: 'none',
      },
      area3: {
        width: `${SIZE_AREA / 3 - BORDER_AREA}px`,
        height: `${SIZE_AREA / 2 - BORDER_AREA}px`,
        border: 'none',
      },
      area4: {
        width: '0px',
        height: '0px',
        border: 'none',
      },
      area5: {
        width: '0px',
        height: '0px',
        border: 'none',
      },
    };
  }
  const line1 = (areaConf.line1 * SIZE_AREA) / 100;
  const line2 = (areaConf.line2 * SIZE_AREA) / 100;
  const line3 = (areaConf.line3 * SIZE_AREA) / 100;
  let areaWidth0 = line1;
  areaWidth0 = areaWidth0 ? areaWidth0 - BORDER_AREA * 2 : 0;
  let areaHeight0 = SIZE_AREA - line3;
  areaHeight0 = areaHeight0 ? areaHeight0 - BORDER_AREA * 2 : 0;
  const isArea0NoneBorder = [areaWidth0, areaHeight0].includes(0);
  let areaWidth1 = SIZE_AREA - (line1 + (SIZE_AREA - line2));
  areaWidth1 = areaWidth1 ? areaWidth1 - BORDER_AREA * 2 : 0;
  let areaHeight1 = SIZE_AREA - line3;
  areaHeight1 = areaHeight1 ? areaHeight1 - BORDER_AREA * 2 : 0;
  const isArea1NoneBorder = [areaWidth1, areaHeight1].includes(0);
  let areaWidth2 = SIZE_AREA - line2;
  areaWidth2 = areaWidth2 ? areaWidth2 - BORDER_AREA * 2 : 0;
  let areaHeight2 = SIZE_AREA - line3;
  areaHeight2 = areaHeight2 ? areaHeight2 - BORDER_AREA * 2 : 0;
  const isArea2NoneBorder = [areaWidth2, areaHeight2].includes(0);
  let areaWidth3 = line1;
  areaWidth3 = areaWidth3 ? areaWidth3 - BORDER_AREA * 2 : 0;
  let areaHeight3 = line3;
  areaHeight3 = areaHeight3 ? areaHeight3 - BORDER_AREA * 2 : 0;
  const isArea3NoneBorder = [areaWidth3, areaHeight3].includes(0);
  let areaWidth4 = SIZE_AREA - (line1 + (SIZE_AREA - line2));
  areaWidth4 = areaWidth4 ? areaWidth4 - BORDER_AREA * 2 : 0;
  let areaHeight4 = line3;
  areaHeight4 = areaHeight4 ? areaHeight4 - BORDER_AREA * 2 : 0;
  const isArea4NoneBorder = [areaWidth4, areaHeight4].includes(0);
  let areaWidth5 = SIZE_AREA - line2;
  areaWidth5 = areaWidth5 ? areaWidth5 - BORDER_AREA * 2 : 0;
  let areaHeight5 = line3;
  areaHeight5 = areaHeight5 ? areaHeight5 - BORDER_AREA * 2 : 0;
  const isArea5NoneBorder = [areaWidth5, areaHeight5].includes(0);

  return {
    area0: {
      width: `${areaWidth0}px`,
      height: `${areaHeight0}px`,
      border: isArea0NoneBorder ? 'none' : `1px solid ${borderColorStyle}`,
    },
    area1: {
      width: `${areaWidth1}px`,
      height: `${areaHeight1}px`,
      border: isArea1NoneBorder ? 'none' : `1px solid ${borderColorStyle}`,
    },
    area2: {
      width: `${areaWidth2}px`,
      height: `${areaHeight2}px`,
      border: isArea2NoneBorder ? 'none' : `1px solid ${borderColorStyle}`,
    },
    area3: {
      width: `${areaWidth3}px`,
      height: `${areaHeight3}px`,
      border: isArea3NoneBorder ? 'none' : `1px solid ${borderColorStyle}`,
    },
    area4: {
      width: `${areaWidth4}px`,
      height: `${areaHeight4}px`,
      border: isArea4NoneBorder ? 'none' : `1px solid ${borderColorStyle}`,
    },
    area5: {
      width: `${areaWidth5}px`,
      height: `${areaHeight5}px`,
      border: isArea5NoneBorder ? 'none' : `1px solid ${borderColorStyle}`,
    },
  };
};

/* ************ Component ************ */
/* ********** Sub Component ********** */
/**
 * AreaDetailRow エリア明細行
 *
 * @author atsushi.teruya
 * @Params {AreaDetailRowGrandType} - {className, pitLine, areaLine}
 * @returns {React.FC} - エリア明細行の表示
 *
 */
const AreaDetailRow: React.FC<AreaDetailRowGrandType> = ({
  className,
  displayCellSeparate,
  value: rowValue = 0,
  size,
  isDefault,
}) => {
  const classes = useStyles();

  // 通常のセルのスタイル
  const cellClass = `${className} ${displayCellSeparate ? classes.columnSeparateDot : classes.columnSeparateNone}`;
  // 2列、4列目のセルのスタイル 右側に線を引く
  return (
    <TableRow>
      {tableArrayRender.map((value) => {
        const areaStyle = isDefault ? 'area3' : `area${value + (rowValue ? 3 : 0)}`;
        return <TableCell key={value} className={`${cellClass} ${areaStyle}`} style={size[areaStyle]} />;
      })}
    </TableRow>
  );
};

/* ************ Main Component ************ */
const AreaDetail: React.FC<AreaDetailGrandType> = ({
  className,
  displayCellSeparate,
  displayTableBorder,
  defaultBorderColor,
  leftRight,
  size,
  areaConf,
  borderColor,
  isDefault,
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [sizeArea, setSizeArea] = React.useState<CellSizeType>(sizeDefault);
  // テーブルサイズを定義
  let tableSizeStyle = '';
  let contanerSizeStyle = '';
  let containerGridDashLineSize = '';
  const isLeft = leftRight === COMMON.LEFT_RIGHT.LEFT;
  switch (size) {
    case COMMON.SIZE.SMALL:
      tableSizeStyle = classes.smallStyle;
      contanerSizeStyle = classes.smallContainer;
      containerGridDashLineSize = classes.smallContainerGridDashLineSize;
      break;
    case COMMON.SIZE.CUSTOM_MEDIUM:
      tableSizeStyle = classes.customMediumStyle;
      contanerSizeStyle = classes.customMediumContainer;
      containerGridDashLineSize = classes.customContainerGridDashLineSize;
      break;
    case COMMON.SIZE.MEDIUM:
      tableSizeStyle = classes.mediumStyle;
      contanerSizeStyle = classes.mediumContainer;
      containerGridDashLineSize = classes.mediumContainerGridDashLineSize;
      break;
    default:
      tableSizeStyle = classes.largeStyle;
      contanerSizeStyle = classes.largeContainer;
      containerGridDashLineSize = classes.largeContainerGridDashLineSize;
  }
  // ステーションの向きに応じた配色を定義
  let stationStyle = classes.defaultStyle;
  // デフォルトカラーじゃない場合は左右のスタイルを設定
  if (!defaultBorderColor) {
    stationStyle = isLeft ? classes.leftStyle : classes.rightStyle;
  }
  const contanerStyle = `${classes.tableContainer} ${className} ${contanerSizeStyle}`;
  const containerGridStyle = `${classes.gridDashLine} ${contanerSizeStyle} ${containerGridDashLineSize}`;
  // テーブルのスタイル定義 サイズ、左右の配色など最終的なスタイルを定義
  const tableStyle = `${classes.tableRoot} ${
    displayTableBorder && classes.tableRootBorder
  } ${tableSizeStyle} ${stationStyle}`;
  // 基本は1行ごとに破線のボーダーを表示。XY範囲など一部の画面は非表示。
  // 2行、4行の行(実際はセル)のスタイル 右側に線を引く
  const mediumRowClass = `${classes.mediumLineSeparate} ${stationStyle}`;
  // 行ごとにセルごとの表示制御するためline(実際は列)の配列を作成

  React.useEffect(() => {
    const sizeAreaFromAreaConf: CellSizeType = areaConf ? getCellSize(areaConf, borderColor) : sizeDefault;
    setSizeArea(sizeAreaFromAreaConf);
    dispatch(
      setShowAreaColor({
        area0: sizeAreaFromAreaConf.area3.border !== 'none',
        area1: sizeAreaFromAreaConf.area4.border !== 'none',
        area2: sizeAreaFromAreaConf.area5.border !== 'none',
        area3: sizeAreaFromAreaConf.area0.border !== 'none',
        area4: sizeAreaFromAreaConf.area1.border !== 'none',
        area5: sizeAreaFromAreaConf.area2.border !== 'none',
      }),
    );
  }, [areaConf]);

  return (
    <>
      <TableContainer className={contanerStyle}>
        <Table className={tableStyle}>
          <TableBody>
            {[0, 1].map((value) => (
              <AreaDetailRow
                key={value}
                className={mediumRowClass}
                displayCellSeparate={displayCellSeparate}
                value={value}
                size={sizeArea}
                isDefault={isDefault}
              />
            ))}
          </TableBody>
        </Table>
        <div className={containerGridStyle}>
          <div className="hLine1 dashLine" />
          <div className="hLine2 dashLine" />
          <div className="vLine1 dashLine" />
          <div className="vLine2 dashLine" />
          <div className="vLine3 dashLine" />
          <div className="vLine4 dashLine" />
          <div className="vLine5 dashLine" />
        </div>
      </TableContainer>
    </>
  );
};

export default AreaDetail;
