import React, { useMemo } from 'react';
import { Stage, Layer, Line } from 'react-konva';
import { XyAreaTypeGrand } from 'types/machineConf/areaType';
import { toScreenPos } from 'utils/machineConf.helper';

/* ************ constant ************ */
const ICON_SIZE = 0;
const XY_AREA_SIZE = 330;
const XY_AREA_SIZE_S = 210;
const PRIZE_AREA_MAX = 100;

/* ************ Component ************ */
const XyLineGrand: React.FC<XyAreaTypeGrand> = ({
  // H/Eの位置（画面上に表示される0~100の座標で指定(プライズ機座標系)）
  homePos,
  endPos,
  size = 'M',
}) => {
  let xyAreaSize = XY_AREA_SIZE;
  switch (size) {
    case 'S':
      xyAreaSize = XY_AREA_SIZE_S;
      break;
    default:
      break;
  }
  // アイコンとエリアのサイズからkonvaによるアイコンの操作範囲を定義
  const width = xyAreaSize + ICON_SIZE;
  const height = xyAreaSize + ICON_SIZE;
  const iconHalfSize = ICON_SIZE / 2;

  const INITIAL_POSITION_PARAMS = {
    screenAreaSize: xyAreaSize,
    panelAreaSize: PRIZE_AREA_MAX,
    isLeft: true,
  };

  // useMemoでポジション変更時のみ変数を設定する。理由不明だがpropの値は利用できない。
  const areaHomePos = useMemo(
    () =>
      toScreenPos({
        ...homePos,
        ...INITIAL_POSITION_PARAMS,
      }),
    [homePos],
  );
  const areaEndPos = useMemo(
    () =>
      toScreenPos({
        ...endPos,
        ...INITIAL_POSITION_PARAMS,
      }),
    [endPos],
  );

  return (
    <Stage width={width} height={height}>
      {/* Layer=エリアはアイコンのサイズ分配置する座標をずらす。 */}
      <Layer x={iconHalfSize} y={iconHalfSize} width={xyAreaSize} height={xyAreaSize}>
        {/* H，Eに交差する点線 */}
        <Line
          points={[areaEndPos.x, 0, areaEndPos.x, xyAreaSize]}
          dash={[8, 8]}
          dashOffset={8}
          stroke="#FFB54E"
          strokeWidth={4}
        />
        <Line
          points={[0, areaEndPos.y, xyAreaSize, areaEndPos.y]}
          dash={[8, 8]}
          dashOffset={8}
          stroke="#3086FF"
          strokeWidth={4}
        />
        <Line points={[areaHomePos.x, 0, areaHomePos.x, xyAreaSize]} dash={[8, 8]} stroke="#FF3E3E" strokeWidth={4} />
      </Layer>
    </Stage>
  );
};

export default XyLineGrand;
