import * as React from 'react';
import { Box, Dialog, DialogActions, DialogContent, DialogContentText } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import InfoIcon from 'assets/images/InfoIcon.svg';
import { TextBox } from 'components/system/atoms';
import NormalButton from 'components/system/atoms/buttons/NormalButton';

/* ************ Style ************ */
const useStyles = makeStyles((theme) => ({
  paper: {
    backgroundColor: theme.palette.common.black,
    minHeight: '276px',
    width: '640px',
    border: '10px',
    padding: '10px',
    backgroundClip: 'contentBox',
  },
  content: {
    alignItems: 'start',
    padding: '40px 8px 0px 40px ',
  },
  msgContent: {
    display: 'flex',
  },
  defaultBox: {
    margin: '8px 0 0 24px',
  },
  defaultText: {
    color: theme.palette.common.white,
    whiteSpace: 'pre-wrap',
    wordWrap: 'break-word',
    fontSize: '21px',
  },
  action: {
    padding: '0px 40px 16px 40px ',
    display: 'flex',
    justifyContent: 'space-between',
  },
  buttonIcon: {
    height: 80,
    width: 200,
    fontSize: 28,
    borderRadius: 12,
  },
  buttonOk: {
    backgroundColor: '#FF3E3E',
    color: theme.palette.common.white,
    '&:hover': {
      backgroundColor: '#FF3E3E',
    },
    '&:active': {
      backgroundColor: '#FF7070',
    },
  },
  buttonCancel: {
    backgroundColor: '#BCBCBC',
    color: theme.palette.common.black,
    '&:hover': {
      backgroundColor: '#BCBCBC',
    },
    '&:active': {
      backgroundColor: '#D3D3D3',
    },
  },
  textBox: {
    height: '36px',
    width: '150px',
    backgroundColor: theme.palette.common.white,
    '& input': {
      fontSize: '19px',
      paddingLeft: 5,
    },
    '& input::-webkit-calendar-picker-indicator': {
      display: 'none',
    },
  },
  boxDateType: {
    display: 'flex',
    color: theme.palette.common.white,
    marginTop: '20px',
  },
  boxDateInputType: {
    display: 'flex',
    alignItems: 'center',
  },
  boxToDateInputType: {
    display: 'flex',
    alignItems: 'center',
    paddingLeft: 41,
  },
  dateLabel: {
    fontSize: 19,
    marginRight: 16,
  },
}));

/* ************ Type ************ */
type FilterDate = {
  fromDate: string;
  toDate: string;
};

type DialogProps = {
  open: boolean;
  msg: string;
  detailMsg?: string;
  okBtnLabel?: string;
  cancelBtnLabel?: string;
  onOk: () => void;
  onCancel: () => void;
  children?: React.ReactNode;
  addClass?: {
    contentBox?: string;
    contentText?: string;
  };
  filterDate: FilterDate;
  onChangeDate: (ev: React.ChangeEvent<HTMLInputElement>) => void;
};

const PeriodInputDialog: React.FC<DialogProps> = ({
  open,
  msg,
  onOk,
  okBtnLabel = '発行',
  onCancel,
  cancelBtnLabel = '閉じる',
  addClass,
  filterDate,
  onChangeDate,
}) => {
  const classes = useStyles();

  return (
    <Dialog
      classes={{
        paper: classes.paper,
      }}
      open={open}
      onClose={onCancel}
      maxWidth={false}>
      <DialogContent
        classes={{
          root: classes.content,
        }}>
        <Box className={classes.msgContent}>
          <img src={InfoIcon} alt="Info" />
          <Box className={`${classes.defaultBox} ${addClass?.contentBox}`}>
            <DialogContentText className={`${classes.defaultText} ${addClass?.contentText}`}>{msg}</DialogContentText>
          </Box>
        </Box>
        <Box className={classes.boxDateType}>
          <Box className={classes.boxDateInputType}>
            <Box className={classes.dateLabel}>開始日</Box>
            <TextBox
              id="fromDate"
              name="fromDate"
              value={filterDate.fromDate}
              onChange={onChangeDate}
              className={classes.textBox}
              fullWidth
              type="date"
            />
          </Box>
          <Box className={classes.boxToDateInputType}>
            <Box className={classes.dateLabel}>終了日</Box>
            <TextBox
              id="toDate"
              name="toDate"
              value={filterDate.toDate}
              onChange={onChangeDate}
              className={classes.textBox}
              fullWidth
              type="date"
            />
          </Box>
        </Box>
      </DialogContent>
      <DialogActions
        classes={{
          root: classes.action,
        }}>
        <NormalButton onClick={onOk} className={`${classes.buttonIcon} ${classes.buttonOk}`}>
          {okBtnLabel}
        </NormalButton>
        <NormalButton onClick={onCancel} className={`${classes.buttonIcon} ${classes.buttonCancel}`}>
          {cancelBtnLabel}
        </NormalButton>
      </DialogActions>
    </Dialog>
  );
};

export default PeriodInputDialog;
