import React, { memo } from 'react';
import { Box, makeStyles, Table, TableBody, TableCell, TableContainer, TableRow } from '@material-ui/core';
import arrowIcon from 'assets/images/downArrowIcon_3.svg';
import pitMarkIcon from 'assets/images/pitMarkIcon.svg';
import COMMON from 'constants/common';
import { ShiftPanelTypeGrand } from 'types/machineConf/shiftPanelType';

/* ************ Style ************ */
const useStyles = makeStyles(() => ({
  root: {
    height: '100%',
  },
  container: {
    width: '100%',
    height: '100%',
    overflow: 'hidden',
  },
  table: {
    height: '100%',
  },
  tableCell: {
    position: 'relative',
    backgroundColor: '#FFF',
    border: '2px solid #707070',
    padding: '0px',
    '& .arrowBox': {
      width: '100%',
      height: '100%',
    },
    '& img': {
      width: 96,
      height: 152,
      top: '0',
      left: '0',
      padding: '4px',
      marginLeft: 'auto',
      marginRight: 'auto',
      // 斜めの矢印だと当たり判定が変わるので、
      // 画像をクリックしてもイベントが発火しないようにする
      pointerEvents: 'none',
      // ダブルクリックすると選択中表示（画像が青くなる）になるので、範囲選択不可にする
      userSelect: 'none',
    },
  },
  // 矢印Style
  backArrow: {
    transform: 'rotate(180deg)',
  },
  backRightArrow: {
    transform: 'rotate(225deg)',
  },
  rightArrow: {
    transform: 'rotate(270deg)',
  },
  frontRightArrow: {
    transform: 'rotate(315deg)',
  },
  frontLeftArrow: {
    transform: 'rotate(45deg)',
  },
  leftArrow: {
    transform: 'rotate(90deg)',
  },
  backLeftArrow: {
    transform: 'rotate(135deg)',
  },
  // 最奥列Style
  row1: {
    height: '80px',
  },
}));

/* ******** Component ******** */

const ShiftPanelGrand: React.FC<ShiftPanelTypeGrand> = memo((props) => {
  /* ************ State ************ */
  const classes = useStyles();
  const { className, shiftConf } = props;

  /* ************ Event ************ */
  // 矢印画像のレンダー分岐
  const renderArrowImage = (shiftArrow: string) => {
    switch (shiftArrow) {
      case COMMON.SHIFT_ARROW.BACK:
        return <img src={arrowIcon} alt="back" className={classes.backArrow} />;
      case COMMON.SHIFT_ARROW.BACK_RIGHT:
        return <img src={arrowIcon} alt="backRight" className={classes.backRightArrow} />;
      case COMMON.SHIFT_ARROW.RIGHT:
        return <img src={arrowIcon} alt="right" className={classes.rightArrow} />;
      case COMMON.SHIFT_ARROW.FRONT_RIGHT:
        return <img src={arrowIcon} alt="frontRight" className={classes.frontRightArrow} />;
      case COMMON.SHIFT_ARROW.FRONT:
        return <img src={arrowIcon} alt="front" />;
      case COMMON.SHIFT_ARROW.FRONT_LEFT:
        return <img src={arrowIcon} alt="frontLeft" className={classes.frontLeftArrow} />;
      case COMMON.SHIFT_ARROW.LEFT:
        return <img src={arrowIcon} alt="left" className={classes.leftArrow} />;
      case COMMON.SHIFT_ARROW.BACK_LEFT:
        return <img src={arrowIcon} alt="backLeft" className={classes.backLeftArrow} />;
      case COMMON.SHIFT_ARROW.PIT:
        return <img src={pitMarkIcon} alt="pit" />;
      default:
        return <></>;
    }
  };

  return (
    <Box className={classes.root}>
      <TableContainer className={`${classes.container} ${className}`}>
        <Table className={classes.table}>
          <TableBody>
            <TableRow>
              <TableCell className={`${classes.tableCell} ${classes.row1}`}>{renderArrowImage(shiftConf)}</TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
});

export default ShiftPanelGrand;
