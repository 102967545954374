import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Box, makeStyles } from '@material-ui/core';
import LoginLayout from 'components/system/layouts/LoginLayout';
import ScreenContext from 'Contexts/Common/ScreenContext';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import OutlineTextBox from 'components/system/atoms/textBoxs/OutlineTextBox';
import DoneButton from 'components/system/atoms/buttons/DoneButton';
import NomalCheckBox from 'components/system/atoms/checkBoxs/NomalCheckBox';
import HttpConnection from 'utils/httpConnection';
import { setAppInfo, setAuth, setClassificationList, setDeviceInfo, setUserInfo } from 'redux/slices/commonSlice';
import redirectLinks from 'constants/redirectLinks';
import CLSFCN from 'constants/classification';
import LoginLogo from 'components/login/layouts/loginLogo';
import { AppInfoType, ApplicationInitialType } from 'types/common/commonType';
import { REST_API } from 'constants/apiUrls';
import { LoginInputValueType } from 'types/login/loginType';
import prizeMachineImage from 'assets/images/machine.png';
import { getDeviceInfo } from 'utils/common.helper';
import { IInformationType } from '../../types/manualInformation/manualInformationType';
import { clearManualInformation } from '../../redux/slices/manualInformationSlice';

/* ************ Style ************ */
const useStyles = makeStyles(() => ({
  contens: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: '111px 48px 23px 48px',
    '& .leftArea': {
      width: '436px',
      '& .systemLogo': {
        height: '50px',
        marginTop: 23,
      },
      '& .systemImage': {
        position: 'absolute',
        width: 652,
        top: 198,
        left: -80,
        zIndex: 0,
      },
    },
    '& .rightArea': {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      width: '60%',
      height: 586,
      padding: '20px 48px 26px 48px',
      background: '#E5E5E5',
      borderRadius: '8px',
      zIndex: 1,
      '& .titleLogin': {
        fontSize: '40px',
      },
    },
  },
  inputArea: {
    width: '560px',
  },
  textBoxTop: {
    marginTop: '15px',
  },
  textBox: {
    marginTop: '24px',
    '& .outlinedInput': {
      height: '60px',
    },
  },
  checkBox: {},
  loginBtn: {
    height: '60px',
    marginTop: '30px',
    fontSize: '28px',
  },
  textInfo: {
    fontSize: 19,
    marginTop: '35px',
  },
  versionNoText: {
    width: '110%',
    textAlign: 'right',
    fontSize: 19,
    marginTop: '-10px',
  },
  informationText: {
    fontSize: 16,
    lineHeight: 1.8,
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
  },
  informationContainer: {
    position: 'absolute',
    left: 44,
    width: 1189,
    zIndex: 100,
    paddingLeft: 14,
    paddingRight: 14,
    padding: 5,
    marginTop: 22,
    borderTop: '1px solid #757575',
    borderBottom: '1px solid #757575',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  },
}));
/* ************ Context ************ */
const ScreenContextValue = {
  title: 'ログイン',
  screenId: 'SCR011',
  currentMenu: '',
};

/* ************ Component ************ */
const Login: React.FC = () => {
  const classes = useStyles();
  const history = useHistory();
  const dispatch = useAppDispatch();
  const { versionNo } = useAppSelector((state) => state.common.appInfo);
  /* ************ state/redux ************ */
  // 画面項目用
  const [inputValue, setInputValue] = useState<LoginInputValueType>({
    storeId: '',
    accountId: '',
    password: '',
  });
  const [informationLogin, setInformationLogin] = useState<IInformationType[]>([]);
  const hasCsrf = useRef<boolean>(false);
  const [isDisplayPw, setIsDisplayPw] = useState<boolean>();

  /* ************ API ************ */
  const getCsrfApi = async () => {
    const http = new HttpConnection({ dispatch });
    await http.get<ApplicationInitialType>(REST_API.LOGIN.GET_CSRF).then(() => {
      hasCsrf.current = true;
    });
  };

  const getVersionApi = async () => {
    const http = new HttpConnection({ dispatch });
    const data = await http.get<AppInfoType>(REST_API.COMMON.GET_VERSION_NO);
    if (data) dispatch(setAppInfo(data));
  };

  const getInformationApi = async () => {
    const http = new HttpConnection({ dispatch });
    const data = await http.get<IInformationType[]>(REST_API.MANUAL_NOTICE.GET_INFORMATION_PUBLIC);
    if (data) setInformationLogin(data);
  };

  /* ************ use effect ************ */
  useEffect(() => {
    // get version info
    getVersionApi().then();
    // Get information public
    getInformationApi().then();
    // Clear manual information
    dispatch(clearManualInformation());
  }, []);

  const loginApi = async () => {
    const http = new HttpConnection({ dispatch });
    await http
      .post<{
        data: ApplicationInitialType;
        pwChangeFlg: string;
      }>(REST_API.LOGIN.LOGIN, inputValue)
      .then((data) => {
        if (data.data.userInfo) {
          dispatch(setUserInfo(data.data.userInfo));
        }
        dispatch(setDeviceInfo(getDeviceInfo()));
        if (data.data.classificationList) {
          dispatch(setClassificationList(data.data.classificationList));
        }
        sessionStorage.setItem('isFresh', 'true');
        dispatch(setAuth());
        // 遷移先
        if (data.pwChangeFlg === CLSFCN.PW_CHANGE_FLG.TRUE) {
          history.push(redirectLinks.PW_CONF);
          return;
        }
        history.push(redirectLinks.HOME_TOP);
      });
  };

  /* ************ Event ************ */
  // 画面項目のチェンジイベント
  const handleChangeInput = (ev: React.ChangeEvent<HTMLInputElement>): void => {
    setInputValue({
      ...inputValue,
      [ev.target.name]: ev.target.value,
    });
  };
  const handleChangeIsDisplayPw = (event: React.ChangeEvent<HTMLInputElement>) => {
    setIsDisplayPw(event.target.checked);
  };
  const handleClickLogin = async () => {
    if (!hasCsrf.current) {
      await getCsrfApi();
    }
    loginApi();
  };

  const renderInformation = useCallback(() => {
    if (informationLogin.length === 0) return <></>;
    const informationInfo = informationLogin.map((item) => {
      if (item.url) {
        return (
          <a key={item.id} href={item.url} target="_blank" rel="noreferrer">
            <div className={classes.informationText}>{item.text}</div>
          </a>
        );
      }
      return <div className={classes.informationText}>{item.text}</div>;
    });
    return <div className={classes.informationContainer}>{informationInfo}</div>;
  }, [informationLogin]);

  return (
    <ScreenContext.Provider value={ScreenContextValue}>
      <LoginLayout>
        {renderInformation()}
        <Box className={classes.contens}>
          <Box className="leftArea">
            <Box className="systemLogo">
              <LoginLogo />
            </Box>
            <Box className="systemImage">
              <img src={prizeMachineImage} alt="プライズ機イメージ" />
            </Box>
          </Box>
          <Box className="rightArea">
            <div className="titleLogin">ログイン</div>
            <Box className={classes.inputArea}>
              <OutlineTextBox
                id="storeId"
                name="storeId"
                value={inputValue.storeId}
                placeholder="企業・店舗ID"
                onChange={handleChangeInput}
                className={classes.textBox}
                fullWidth
              />
              <OutlineTextBox
                id="accountId"
                name="accountId"
                value={inputValue.accountId}
                placeholder="アカウントID"
                onChange={handleChangeInput}
                className={classes.textBox}
                fullWidth
              />
              <OutlineTextBox
                id="password"
                name="password"
                value={inputValue.password}
                placeholder="パスワード"
                type={isDisplayPw ? 'text' : 'password'}
                onChange={handleChangeInput}
                className={classes.textBox}
                fullWidth
              />
              <NomalCheckBox
                label="パスワードを表示する"
                checked={isDisplayPw}
                onChange={handleChangeIsDisplayPw}
                exClassName={classes.checkBox}
              />
              <DoneButton className={classes.loginBtn} onClick={handleClickLogin} fullWidth>
                ログイン
              </DoneButton>
              <Box className={classes.textInfo}>
                アカウントが凍結された場合または、パスワードを忘れた場合は 管理者へ問い合わせてください。
              </Box>
            </Box>
            <Box className={classes.versionNoText}>{`Ver. ${versionNo}`}</Box>
          </Box>
        </Box>
      </LoginLayout>
    </ScreenContext.Provider>
  );
};

export default Login;
