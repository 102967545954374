import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import Layout from 'components/system/layouts/Layout';
import { makeStyles, Grid } from '@material-ui/core';
import ScreenContext from 'Contexts/Common/ScreenContext';
import COMMON from 'constants/common';
import redirectLinks from 'constants/redirectLinks';
import ReturnButton from 'components/system/atoms/buttons/ReturnButton';
import HttpConnection from 'utils/httpConnection';
import { REST_API } from 'constants/apiUrls';
import {
  LedGroupType,
  GetMachineApiType,
  GroupLocationType,
  MachineListType,
  MachineSelectListType,
  registerMachineApiType,
} from 'types/group/GroupType';
import UrlHelper from 'utils/url.helper';
import { initializeGroupSlice, setLedGroup, setLedMachineList } from 'redux/slices/groupSlice';
import BorderBottomBox from 'components/system/atoms/boxs/BorderBottomBox';
import DeleteButton from 'components/system/atoms/buttons/DeleteButton';
import { ConfirmDialog, InfoDialog } from 'components/system/organisms';
import MESSAGES from 'constants/messages';
import { ScrModetype } from 'types/common/commonType';
import NormalButton from '../../components/system/atoms/buttons/NormalButton';
import MachineSelect from '../../components/group/layouts/MachineSelect';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import MsgHelper from '../../utils/message.helper';

/* ************ Context ************ */
const ScreenContextValue = {
  title: '登録機器選択',
  screenId: COMMON.SCREEN_ID.LED_SELECT_MACHINE,
  currentMenu: COMMON.MENU.GROUP,
};

/* ************ Style ************ */
const useStyles = makeStyles(() => ({
  content: {
    height: 598,
    width: 1140,
    padding: '0 16px',
  },
  topArea: {
    display: 'flex',
    alignItems: 'flex-start',
    padding: '10px 0px 0px 0px',
  },
  groupName: {
    flex: '1',
    margin: '16px 0px 0px 42px',
    fontSize: 28,
    borderBottom: '2px solid #707070',
    '& .value': {
      marginLeft: 4,
    },
  },
  bottomArea: {
    textAlign: 'right',
    marginTop: 20,
    display: 'flex',
    justifyContent: (props: { isCrateGroup: boolean }) => (props.isCrateGroup ? 'flex-end' : 'space-between'),
  },
  nextBtnLabel: {
    fontSize: 21,
  },
}));

const LedSelectMachine: React.FC = () => {
  const dispatch = useAppDispatch();
  const history = useHistory();
  const location = useLocation<GroupLocationType>();
  const prmGroupId = useRef<number>(location.state?.groupId ?? COMMON.DUMMY_GROUP_ID);
  const returnScrName = useRef<string>('設定グループ作成');
  const returnScrUrl = useRef<string>(redirectLinks.GROUP_LED_GROUP_CREATE);
  const SCR_MODE = useRef<ScrModetype>('new');
  const getLedGroup = useAppSelector((state) => state.group.ledGroup);
  const isCrateGroup = useAppSelector((state) => state.crateGroupSlice.isCrateGroup);
  const [machineList, setmachineList] = useState<Array<MachineSelectListType>>([]);
  const [isOpenConfirmDialog, setisOpenConfirmDialog] = useState<boolean>(false);
  const [showDeleteDialog, setShowDeleteDialog] = useState<boolean>(false);
  const classes = useStyles({ isCrateGroup });

  /* ************ API ************ */
  const getLedGroupApi = async (groupId: number): Promise<LedGroupType> => {
    // 景品獲得演出設定グループ情報取得
    const http = new HttpConnection({ dispatch });
    const data = await http.get<LedGroupType>(
      UrlHelper.convertGetUrl(REST_API.GROUP.LED_GROUP, {
        group_id: groupId.toString(),
      }),
    );
    dispatch(setLedGroup(data));
    return data;
  };

  // 機器一覧情報を取得
  const getLedMachineList = async (sort: string, groupInfo: LedGroupType) => {
    const http = new HttpConnection({ dispatch });
    const data = await http.get<GetMachineApiType[]>(
      UrlHelper.convertGetUrl(REST_API.GROUP.LED_GROUP_MACHINE_LIST, {
        group_id: groupInfo.groupId.toString(),
        sort,
      }),
    );
    const mlist: MachineSelectListType[] = data.map((d) => ({
      checked: groupInfo.machineList.map((b) => b.boardSerial).includes(d.boardSerial),
      boardSerial: d.boardSerial,
      leftStationName: d.leftStationName,
      leftGiftName: d.leftGiftName,
      rightStationName: d.rightStationName,
      rightGiftName: d.rightGiftName,
      machineType: d.machineType,
    }));
    setmachineList(mlist);
  };

  // バリデートと連動順の設定
  const registerMachineList = async (selectedList: registerMachineApiType[]) => {
    const http = new HttpConnection({ dispatch });
    const data = await http.post<MachineListType[]>(REST_API.GROUP.LED_GROUP_SELECT_MACHINE, {
      group_id: getLedGroup.groupId,
      machineList: getLedGroup.machineList.map((d) => ({
        boardSerial: d.boardSerial,
        orderNo: d.orderNo,
        machineType: d.machineType,
      })),
      selectedMachineList: selectedList,
    });
    return data;
  };

  /* ************ UseEffect ************ */
  useEffect(() => {
    // 戻り先画面によって独自処理を実施
    if (getLedGroup.returnFromSelectScr === COMMON.SCREEN_ID.LED_GROUP_CREATE) {
      returnScrName.current = '設定グループ作成';
      returnScrUrl.current = redirectLinks.GROUP_LED_GROUP_CREATE;
    } else if (getLedGroup.returnFromSelectScr === COMMON.SCREEN_ID.GROUP_LIST) {
      returnScrName.current = '登録グループ一覧';
      returnScrUrl.current = redirectLinks.GROUP_LIST_DATA;
    }
    if (prmGroupId.current !== COMMON.DUMMY_GROUP_ID || getLedGroup.groupId !== COMMON.DUMMY_GROUP_ID) {
      SCR_MODE.current = 'edit';
    }
  }, []);

  useEffect(() => {
    // 初回のみ実施(グループIDがパラメータとしてわたってきた場合、グループ情報を取得)
    if (prmGroupId.current !== COMMON.DUMMY_GROUP_ID) {
      getLedGroupApi(prmGroupId.current).then((groupInfo: LedGroupType) => {
        getLedMachineList(getLedGroup.selectMachineSort, groupInfo);
      });
      prmGroupId.current = COMMON.DUMMY_GROUP_ID; // 初回のみ実施させるため、ここで初期化
      return;
    }

    getLedMachineList(getLedGroup.selectMachineSort, getLedGroup);
  }, [getLedGroup.selectMachineSort]);

  /* ************ Events ************ */

  // return button
  const handleClickReturn = useCallback(() => {
    if (getLedGroup.returnFromSelectScr === COMMON.SCREEN_ID.GROUP_LIST) {
      // 登録グループ一覧に戻る場合は、STTを削除
      dispatch(initializeGroupSlice());
      history.push(returnScrUrl.current);
      return;
    }

    // 選択中の機器情報を取得
    const selectedList: registerMachineApiType[] = machineList
      .filter((d) => d.checked === true)
      .map((d) => ({
        boardSerial: d.boardSerial,
        stationNameR: d.rightStationName,
        stationNameL: d.leftStationName,
        machineType: d.machineType,
        giftNameL: d.leftGiftName,
        giftNameR: d.rightGiftName,
      }));

    // 0件選択の場合、画面遷移
    if (!selectedList.length) {
      dispatch(setLedMachineList([]));
      history.push(returnScrUrl.current);
      return;
    }

    // 1件以上選択中の場合、バリデーションしてSTTに保存
    registerMachineList(selectedList).then((list) => {
      dispatch(setLedMachineList(list));
      history.push(returnScrUrl.current);
    });
  }, [machineList]);

  // check box of header
  const handleCheckAll = (ev: React.ChangeEvent<HTMLInputElement>, checked: boolean): void => {
    setmachineList(
      machineList.map((d) => {
        const newData = d;
        newData.checked = checked;
        return newData;
      }),
    );
  };

  // check box in row
  const handleCheck = (ev: React.ChangeEvent<HTMLInputElement>, checked: boolean, row: MachineSelectListType): void => {
    setmachineList(
      machineList.map((d) => {
        if (d.boardSerial === row.boardSerial) {
          const newData = d;
          newData.checked = checked;
          return newData;
        }
        return d;
      }),
    );
  };

  // next button
  const handleClickNext = useCallback(() => {
    // 選択中の機器情報を取得
    const selectedList: registerMachineApiType[] = machineList
      .filter((d) => d.checked === true)
      .map((d) => ({
        boardSerial: d.boardSerial,
        stationNameR: d.rightStationName,
        stationNameL: d.leftStationName,
        machineType: d.machineType,
        giftNameL: d.leftGiftName,
        giftNameR: d.rightGiftName,
      }));
    registerMachineList(selectedList).then((list) => {
      dispatch(setLedMachineList(list));
      history.push(redirectLinks.GROUP_LED_INTERLOCKING_ORDER_SETTING);
    });
  }, [machineList]);

  const handleDelete = async () => {
    if (!getLedGroup.groupId) return;

    const http = new HttpConnection({ dispatch });
    await http.delete(`${REST_API.GROUP.DELETE_GROUP_COMPOSITION}${getLedGroup.groupId}`);
    setisOpenConfirmDialog(false);
    setShowDeleteDialog(true);
    dispatch(initializeGroupSlice());
  };

  return (
    <ScreenContext.Provider value={ScreenContextValue}>
      <Layout>
        <div className={classes.content}>
          <div className={classes.topArea}>
            <ReturnButton onClick={handleClickReturn}>{returnScrName.current}</ReturnButton>
            <BorderBottomBox value={getLedGroup.groupName} className={classes.groupName} />
          </div>
          <MachineSelect
            scrMode={SCR_MODE.current}
            groupCategory={COMMON.GROUP_CATEGORY.LED}
            handleCheckAll={handleCheckAll}
            handleCheck={handleCheck}
            machineList={machineList}
          />
          <Grid className={classes.bottomArea}>
            {!isCrateGroup && (
              <DeleteButton onClick={() => setisOpenConfirmDialog(true)}>{'グループ \n 削除'}</DeleteButton>
            )}
            <NormalButton onClick={handleClickNext}>
              <span className={classes.nextBtnLabel}>連動順の設定へ</span>
            </NormalButton>
          </Grid>
        </div>
        <ConfirmDialog
          open={isOpenConfirmDialog}
          msg={MESSAGES.INF311}
          onOk={handleDelete}
          onCancel={() => setisOpenConfirmDialog(false)}
        />
        <InfoDialog
          open={showDeleteDialog}
          msg={MsgHelper.messageFormat(MESSAGES.INF304, 'グループ')}
          closeFunc={() => history.push(redirectLinks.GROUP_LIST_DATA)}
        />
      </Layout>
    </ScreenContext.Provider>
  );
};

export default LedSelectMachine;
