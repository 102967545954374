import React, { memo } from 'react';
import { Box, makeStyles } from '@material-ui/core';
import arrowIcon from 'assets/images/downArrowIcon_3.svg';
import pitMarkIcon from 'assets/images/pitMarkIcon.svg';
import COMMON from 'constants/common';

export type ShiftPanelReviewType = {
  shiftDirection: string;
};

const useStyles = makeStyles(() => ({
  root: {
    width: '180px',
    height: '180px',
    border: '2px solid #707070',
    background: '#ffffff',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',

    '& img': {
      width: '140px',
      height: '64px',
    },
  },
  backArrow: {
    transform: 'rotate(180deg)',
  },
  backRightArrow: {
    transform: 'rotate(225deg)',
  },
  rightArrow: {
    transform: 'rotate(270deg)',
  },
  frontRightArrow: {
    transform: 'rotate(315deg)',
  },
  frontLeftArrow: {
    transform: 'rotate(45deg)',
  },
  leftArrow: {
    transform: 'rotate(90deg)',
  },
  backLeftArrow: {
    transform: 'rotate(135deg)',
  },
}));

const ShiftPanelReviewGrand: React.FC<ShiftPanelReviewType> = memo((props) => {
  const classes = useStyles();
  const { shiftDirection } = props;

  const renderArrowImage = (shiftArrow: string) => {
    switch (shiftArrow) {
      case COMMON.SHIFT_ARROW.BACK:
        return <img src={arrowIcon} alt="back" className={classes.backArrow} />;
      case COMMON.SHIFT_ARROW.BACK_RIGHT:
        return <img src={arrowIcon} alt="backRight" className={classes.backRightArrow} />;
      case COMMON.SHIFT_ARROW.RIGHT:
        return <img src={arrowIcon} alt="right" className={classes.rightArrow} />;
      case COMMON.SHIFT_ARROW.FRONT_RIGHT:
        return <img src={arrowIcon} alt="frontRight" className={classes.frontRightArrow} />;
      case COMMON.SHIFT_ARROW.FRONT:
        return <img src={arrowIcon} alt="front" />;
      case COMMON.SHIFT_ARROW.FRONT_LEFT:
        return <img src={arrowIcon} alt="frontLeft" className={classes.frontLeftArrow} />;
      case COMMON.SHIFT_ARROW.LEFT:
        return <img src={arrowIcon} alt="left" className={classes.leftArrow} />;
      case COMMON.SHIFT_ARROW.BACK_LEFT:
        return <img src={arrowIcon} alt="backLeft" className={classes.backLeftArrow} />;
      case COMMON.SHIFT_ARROW.PIT:
        return <img src={pitMarkIcon} alt="pit" />;
      default:
        return <></>;
    }
  };

  return <Box className={classes.root}>{renderArrowImage(shiftDirection)}</Box>;
});

export default ShiftPanelReviewGrand;
