import React from 'react';
import { makeStyles } from '@material-ui/core';
import COMMON from 'constants/common';
import { SaveDetailInfoType } from 'types/machineConf/stationSelectType';
import { getMachineVersion } from 'utils/common.helper';

/**
 * SelectedSaveInfo Component
 * @author rikuya.yasui
 *
 * @param props
 * @returns {*}
 * @constructor
 */

// makeStylesでstyleを定義
const useStyles = makeStyles(() => ({
  Label: {
    display: 'flex',
    alignItems: 'center',
    margin: '0 0 0 auto',
    position: 'relative',
    border: '1px solid',
    borderRadius: '4px',
    height: '40px',
    width: '840px',
    fontSize: '21px',
    padding: '0px 8px',
    backgroundColor: '#FFFFFF',
    justifyContent: 'space-between',
  },
  BoxLeft: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  BoxRight: {
    paddingLeft: '8px',
  },
}));

// コンポーネント定義
const SaveDetailInfo: React.FC<SaveDetailInfoType> = (props) => {
  // makeStyleで定義したstyleを取得
  const classes = useStyles();
  // propsのプロパティ情報を取得
  const { saveName, saveMachineVersion, saveMachineType } = props;

  const versionMachine =
    saveMachineVersion && [COMMON.MACHINE_TYPE.CLENA3, COMMON.MACHINE_TYPE.GRAND].includes(saveMachineType) ? (
      <div className={classes.BoxRight}>{`(Ver.${getMachineVersion(saveMachineVersion, true)})`}</div>
    ) : null;

  return (
    <div className={classes.Label}>
      <div className={classes.BoxLeft}>{saveName}</div>
      {/* クレナ3ジャックはmachineVersion非表示 */}
      {versionMachine}
    </div>
  );
};
export default SaveDetailInfo;
