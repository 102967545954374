import React, { memo } from 'react';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';
import { ReactComponent as SvgIcon } from 'assets/images/downArrowIcon.svg';
import { SelectButtonType } from 'types/system/buttonType';
import COMMON from 'constants/common';
import { Box, Theme } from '@material-ui/core';
import { themeColor } from 'constants/theme';

type StyleProps = {
  disabledTextColor?: string;
  disabledBorderColor?: string;
  leftRight?: string;
};

// makeStylesでstyleを定義
const useStyles = makeStyles<Theme, StyleProps>((theme) => ({
  root: {
    backgroundColor: theme.palette.common.white,
    borderRadius: '4px',
    justifyContent: 'flex-end',
    paddingLeft: '0px',
    paddingRight: '8px',
    borderWidth: '2px',
    borderStyle: 'solid',
    textTransform: 'none',
    '&:hover': {
      background: theme.palette.common.white,
    },
    '&:disabled': {
      color: ({ disabledTextColor }) => disabledTextColor ?? themeColor.mainText,
      borderWidth: '2px',
      borderStyle: 'solid',
      borderColor: ({ leftRight, disabledBorderColor }) =>
        disabledBorderColor ??
        (leftRight === COMMON.LEFT_RIGHT.LEFT ? theme.leftStation.itemBorder : theme.rightStation.itemBorder),
    },
  },
  displayText: {
    textAlign: 'center',
    width: '100%',
  },
  defaultStyle: {
    width: '100px',
    height: '56px',
    fontSize: '24px',
  },
  readOnly: {
    color: '#000000 !important',
    backgroundColor: '#FFFFFF !important',
    borderColor: '#FF0000 !important',
    border: '2px solid !important',
  },
  left: {
    borderColor: theme.leftStation.itemBorder,
  },
  right: {
    borderColor: theme.rightStation.itemBorder,
  },
}));

/* ************ Component ************ */
/* ********** Main Component ********* */
/**
 * SelectButton 一覧選択ボタン
 * 一覧選択画面を表示する。
 *
 * @author atsushi.teruya
 * @Params {SelectButtonType} props - 一覧選択ボタン利用に必要なパラメータ
 * @returns {React.FC}
 *
 */
const SelectButton: React.FC<SelectButtonType> = (props) => {
  const {
    children,
    className,
    disabled,
    fullWidth,
    isSelected,
    leftRight,
    onClick,
    disabledTextColor,
    disabledBorderColor,
    arrowSize = '24px',
    arrowColor = '#050101',
    variant = 'outlined',
    style,
  } = props;
  const classes = useStyles({ leftRight, disabledTextColor, disabledBorderColor });

  const styleClass = `${classes.root}  ${className || classes.defaultStyle} ${
    style ?? (leftRight && (leftRight === COMMON.LEFT_RIGHT.LEFT ? classes.left : classes.right))
  } ${isSelected ? classes.readOnly : ''} `;

  return (
    <Button
      variant={variant}
      className={styleClass}
      fullWidth={fullWidth}
      onClick={onClick}
      disabled={disabled || isSelected}>
      <Box className={classes.displayText}>{children}</Box>
      <SvgIcon fill={arrowColor} width={arrowSize} height={arrowSize} />
    </Button>
  );
};

// propsの初期値。未指定時に使用する。
SelectButton.defaultProps = {
  fullWidth: false,
  disabled: false,
};

// レンダーが前回と変わらない場合前回のレンダーを再利用
export default memo(SelectButton);
