import React from 'react';
import { useLocation } from 'react-router-dom';
import ScreenContext from 'Contexts/Common/ScreenContext';
import Layout from 'components/system/layouts/Layout';
import COMMON from 'constants/common';
import NewMachine from 'components/maintenance/organisms/NewMachine';
import UpdateMachine from 'components/maintenance/organisms/UpdateMachine';

/* ************ Context ************ */
const ScreenContextValue = {
  title: '機器詳細',
  screenId: COMMON.SCREEN_ID.MACHINE_DETAIL,
  currentMenu: COMMON.MENU.MAINTENANCE,
};

type MachineDetailType = {
  boardSerial?: string;
};

const MachineDetail: React.FC = () => {
  const location = useLocation();
  const boardSerial = (location.state as MachineDetailType)?.boardSerial;

  let content = <NewMachine />;
  if (boardSerial !== undefined) {
    content = <UpdateMachine />;
  }
  return (
    <ScreenContext.Provider value={ScreenContextValue}>
      <Layout>{content}</Layout>
    </ScreenContext.Provider>
  );
};

export default MachineDetail;
