/**
 * maxSizeを超過する部分を省略する
 *
 * @param {string} string 対象の文字列
 * @param {number} maxLen 文字数(全角は2文字、半角は1文字として計算)
 * @returns {string}
 */
export const substrMaxSize = (string: string, maxLen: number): string => {
  let length = 0;
  let value = '';
  for (let i = 0; i < string.length; i += 1) {
    const c = string.charCodeAt(i);
    if ((c >= 0x0 && c < 0x81) || c === 0xf8f0 || (c >= 0xff61 && c < 0xffa0) || (c >= 0xf8f1 && c < 0xf8f4)) {
      length += 1;
    } else {
      length += 2;
    }
    if (maxLen < length) {
      return value;
    }
    value += string[i];
  }
  return value;
};

/**
 * digit桁ごとにseparatorで区切る
 *
 * @param {string} string 対象の文字列
 * @param {number} digit 桁数
 * @param {number} separator 区切り文字
 * @returns {string}
 */
export const formatString = (string: string, digit = 3, separator = '-'): string => {
  const regExp = new RegExp(`(.)(?=(.{${digit}})+$)`, 'g');
  return string.replace(regExp, `$1${separator}`);
};

/**
 * 全角文字を半角文字に変換
 *
 * @param {string} string 対象の文字列
 * @returns {string}
 */
export const convertFullToHalfCharacters = (str: string): string => {
  const newStr = str.replace(/[Ａ-Ｚａ-ｚ０-９！-～]/g, (s: string) => String.fromCharCode(s.charCodeAt(0) - 0xfee0));
  // 全角カタカナ→半角ｶﾀｶﾅ
  return convertFullToHalfKana(newStr);
};

/**
 * 全角カタカナ→半角ｶﾀｶﾅに変換
 *
 * @param {string} string 対象の文字列
 * @returns {string}
 */
const convertFullToHalfKana = (str: string): string => {
  const kanaMap: { [key: string]: string } = {
    ガ: 'ｶﾞ',
    ギ: 'ｷﾞ',
    グ: 'ｸﾞ',
    ゲ: 'ｹﾞ',
    ゴ: 'ｺﾞ',
    ザ: 'ｻﾞ',
    ジ: 'ｼﾞ',
    ズ: 'ｽﾞ',
    ゼ: 'ｾﾞ',
    ゾ: 'ｿﾞ',
    ダ: 'ﾀﾞ',
    ヂ: 'ﾁﾞ',
    ヅ: 'ﾂﾞ',
    デ: 'ﾃﾞ',
    ド: 'ﾄﾞ',
    バ: 'ﾊﾞ',
    ビ: 'ﾋﾞ',
    ブ: 'ﾌﾞ',
    ベ: 'ﾍﾞ',
    ボ: 'ﾎﾞ',
    パ: 'ﾊﾟ',
    ピ: 'ﾋﾟ',
    プ: 'ﾌﾟ',
    ペ: 'ﾍﾟ',
    ポ: 'ﾎﾟ',
    ヴ: 'ｳﾞ',
    ヷ: 'ﾜﾞ',
    ヺ: 'ｦﾞ',
    ア: 'ｱ',
    イ: 'ｲ',
    ウ: 'ｳ',
    エ: 'ｴ',
    オ: 'ｵ',
    カ: 'ｶ',
    キ: 'ｷ',
    ク: 'ｸ',
    ケ: 'ｹ',
    コ: 'ｺ',
    サ: 'ｻ',
    シ: 'ｼ',
    ス: 'ｽ',
    セ: 'ｾ',
    ソ: 'ｿ',
    タ: 'ﾀ',
    チ: 'ﾁ',
    ツ: 'ﾂ',
    テ: 'ﾃ',
    ト: 'ﾄ',
    ナ: 'ﾅ',
    ニ: 'ﾆ',
    ヌ: 'ﾇ',
    ネ: 'ﾈ',
    ノ: 'ﾉ',
    ハ: 'ﾊ',
    ヒ: 'ﾋ',
    フ: 'ﾌ',
    ヘ: 'ﾍ',
    ホ: 'ﾎ',
    マ: 'ﾏ',
    ミ: 'ﾐ',
    ム: 'ﾑ',
    メ: 'ﾒ',
    モ: 'ﾓ',
    ヤ: 'ﾔ',
    ユ: 'ﾕ',
    ヨ: 'ﾖ',
    ラ: 'ﾗ',
    リ: 'ﾘ',
    ル: 'ﾙ',
    レ: 'ﾚ',
    ロ: 'ﾛ',
    ワ: 'ﾜ',
    ヲ: 'ｦ',
    ン: 'ﾝ',
    ァ: 'ｧ',
    ィ: 'ｨ',
    ゥ: 'ｩ',
    ェ: 'ｪ',
    ォ: 'ｫ',
    ッ: 'ｯ',
    ャ: 'ｬ',
    ュ: 'ｭ',
    ョ: 'ｮ',
    '。': '｡',
    '、': '､',
    ー: 'ｰ',
    '「': '｢',
    '」': '｣',
    '・': '･',
  };
  const reg = new RegExp(`(${Object.keys(kanaMap).join('|')})`, 'g');
  return str
    .replace(reg, (s: string) => kanaMap[s])
    .replace(/゛/g, 'ﾞ')
    .replace(/゜/g, 'ﾟ');
};

/**
 * Get file name
 * @param contentDisposition
 */
export const getFileNameFromContentDisposition = (contentDisposition: string): string => {
  const regex = /filename\*?=['"]?(?:UTF-\d['"]*)?([^;\r\n"']*)['"]?;?/i;
  const matches = regex.exec(contentDisposition);
  if (matches && matches[1]) {
    let fileName = matches[1].trim();
    if (fileName.startsWith('"') && fileName.endsWith('"')) {
      fileName = fileName.slice(1, -1); // Remove surrounding quotes if any
    }
    return fileName;
  }
  return ''; // No filename found
};

/**
 * Function using for get UTF-8 file name
 *
 * @author: tat.pham
 * @param contentDisposition
 */
export const getDecodedFilename = (contentDisposition: string): string => {
  const regex = /filename\*=(?:UTF-8'')?(.+)/;
  const matches = regex.exec(contentDisposition);

  if (matches && matches[1]) {
    const encodedFilename = matches[1];
    const decodedFilename = decodeURIComponent(encodedFilename);
    return decodedFilename;
  }
  return '';
};
