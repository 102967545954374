import React, { useContext } from 'react';
import { makeStyles } from '@material-ui/core';
import { SelectedStationType } from 'types/machineConf/stationSelectType';
import SelectedStationInfoContext from 'Contexts/Common/SelectedStationInfoContext';
import TooltipComponent from 'components/system/organisms/dialogs/TooltipComponent';
import { getMachineVersion, renderMachineTypeWithSuffix } from 'utils/common.helper';
import COMMON from 'constants/common';
import SaveDetailInfo from './SaveDetailInfo';

/**
 * SelectedStationInfo Component
 * SCR301 選択ステーション情報
 * @author takuya.tomisato
 *
 * @param props
 * @returns {*}
 * @constructor
 */

// makeStylesでstyleを定義
const useStyles = makeStyles(() => ({
  Label: {
    display: 'flex',
    alignItems: 'center',
    margin: '0 0 0 auto',
    position: 'relative',
    border: '1px solid',
    borderRadius: '4px',
    height: '40px',
    width: '840px',
    fontSize: '21px',
    padding: '0px 8px',
    backgroundColor: '#FFFFFF',
  },
  BoxLeft: {
    width: 'auto',
    whiteSpace: 'nowrap',
  },
  BoxCenter: {
    width: 'auto',
    overflow: 'hidden',
  },
  BoxRight: {
    paddingLeft: '16px',
    marginLeft: 'auto',
  },
}));

// コンポーネント定義
const SelectedStationInfo: React.FC<SelectedStationType> = (props) => {
  // makeStyleで定義したstyleを取得
  const classes = useStyles();
  // propsのプロパティ情報を取得
  const { stationName, giftName, machineType, machineVersion, leftRight, savedConfProps } = props;
  // Context取得
  // 選択ステーション情報の表示制御
  const LabelFlg = useContext(SelectedStationInfoContext);
  // LabelFlg.value
  if (LabelFlg.SelectedStationInfoDisplay === true) {
    if (savedConfProps) {
      const { saveName, saveMachineVersion, saveMachineType } = savedConfProps;
      return (
        <SaveDetailInfo saveName={saveName} saveMachineVersion={saveMachineVersion} saveMachineType={saveMachineType} />
      );
    }
    return (
      <div className={classes.Label}>
        <div className={classes.BoxLeft}>{renderMachineTypeWithSuffix(machineType, leftRight ?? '')}</div>
        <div>：</div>
        <div className={classes.BoxCenter}>
          <TooltipComponent
            displayWord={`${stationName}（${giftName}）`}
            tooltipText={`${stationName}（${giftName}）`}
          />
        </div>
        {/* クレナ3ジャックはmachineVersion非表示 */}
        {[COMMON.MACHINE_TYPE.CLENA3, COMMON.MACHINE_TYPE.GRAND].includes(machineType) ? (
          <div className={classes.BoxRight}>{`(Ver.${getMachineVersion(machineVersion)})`}</div>
        ) : null}
      </div>
    );
  }
  return <></>;
};
export default SelectedStationInfo;
