import { createMuiTheme } from '@material-ui/core';
import { ClenaColorType, ClenaStationColorType } from 'types/system/clenaColorType';

declare module '@material-ui/core/styles/createMuiTheme' {
  interface Theme {
    leftStation: ClenaStationColorType;
    rightStation: ClenaStationColorType;
    grandStation: ClenaStationColorType;
    base: ClenaColorType;
  }
  interface ThemeOptions {
    leftStation: ClenaStationColorType;
    rightStation: ClenaStationColorType;
    grandStation: ClenaStationColorType;
    base: ClenaColorType;
  }
}

const theme = createMuiTheme({
  leftStation: {
    contents: '#EEFEFF',
    contentsBorder: '#01C3D5',
    itemBorder: '#01C3D5',
    itemMain: '#01C3D5',
    itemSub: 'rgba(1, 195, 213, 0.5)',
  },
  rightStation: {
    contents: '#FFF9FC',
    contentsBorder: '#FF4386',
    itemBorder: '#FF4386',
    itemMain: '#FF4386',
    itemSub: 'rgba(255, 67, 134, 0.5)',
  },
  grandStation: {
    contents: '#F5FFF7',
    contentsBorder: '#32D7A5',
    itemBorder: '#32D7A5',
    itemMain: '#32D7A5',
    itemSub: 'rgba(50, 215, 165, 0.5)',
  },
  base: {
    tableBorder: '#707070',
    tableRowPrimary: '#FFFFFF',
    tableRowSecondory: '#EBEBEB',
    tableItemBorder: '#707070',
    gray: '#EBEBEB',
    switchDefault: '#BFBFBF',
    switchThumbDefault: '#F1F1F1',
    switchThumbBorderDefault: '#BFBFBF',
    area0: '#FFFFFF',
    area1: '#B5EED8',
    area2: '#EBCFEB',
    area3: '#FFFDB5',
    area4: '#FFC8C8',
    area5: '#B4DFFF',
  },
  typography: {
    fontFamily: [
      '"Helvetica Neue"',
      'Arial',
      '"Hiragino Kaku Gothic ProN"',
      '"Hiragino Sans"',
      'Meiryo',
      'Roboto',
      'sans-serif',
    ].join(','),
  },
  overrides: {
    MuiCssBaseline: {
      '@global': {
        body: {
          backgroundColor: '#000000',
          '& #root': {
            height: '100%',
          },
        },
      },
    },
  },
});

export default theme;
