import React, { memo } from 'react';
import { Box, Theme, Table, TableRow } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import armPowerIcon from 'assets/images/armPowerIcon.svg';
import arrowUpIcon from 'assets/images/arrowUpIcon.svg';
import arrowRigthIcon from 'assets/images/arrowRigthIcon.svg';
import newServiceGraphImg from 'assets/images/newServiceGraphImg.svg';
import COMMON from 'constants/common';
import { getClassificationName } from 'utils/common.helper';
import CLSFCN from 'constants/classification';
import { AreaConfType, ConfType } from 'types/machineConf/machineConfType';
import grayCatcherIcon from 'assets/images/grayCatcher.svg';
import redCatcherIcon from 'assets/images/redCatcher.svg';
import grabCatcherIcon from 'assets/images/catcherIconGrab.svg';
import movingCatcherIcon from 'assets/images/catcherIconMoving.svg';
import ShiftPanelReview from './ShiftPanelReview';

type StyleProps = {
  isLeft: boolean;
  powerIconLeft?: boolean;
};

const useStyles = makeStyles<Theme, StyleProps>((theme) => ({
  container: {
    height: '251px',
    padding: '3px 10px',
  },
  title: {
    position: 'relative',
    fontSize: '24px',
    textAlign: 'left',
    fontWeight: 'bold',
    marginBottom: '4px',
    '& span': {
      display: 'inline-block',
      margin: '0 0 0 15px',
      fontSize: '17px',
    },
  },
  frame: {
    flexDirection: 'column',
    alignItems: 'center',
    '& .inner': {
      display: 'flex',
    },
    '& .areaTable': {
      columnGap: '10px',
    },
    '& .between': {
      display: 'flex',
      justifyContent: 'space-between',
    },
    '& .column': {
      display: 'flex',
      flexDirection: 'column',
    },
  },
  powerIcon: {
    position: 'relative',
    top: '-12px',
    width: '150px',
    '& .icon': {
      margin: '0 auto',
    },
    '& .square': {
      width: '28px',
      height: '28px',
      background: '#CCCCCC',
      display: 'inline-block',
      position: 'absolute',
      top: '70px',
      left: '61px',
      border: `1px solid ${theme.palette.common.black}`,
    },
    '& .value': {
      width: '66px',
      height: '46px',
      lineHeight: '44px',
      fontSize: '24px',
      background: '#FFF',
      borderRadius: '2px',
      display: 'flex',
      position: 'absolute',
      justifyContent: 'center',
      alignItems: 'center',
    },
    '& .pos-left': {
      left: '0px',
    },
    '& .pos-right': {
      right: '10px',
    },
    '& .pos-left-r': {
      left: '10px',
    },
    '& .pos-right-r': {
      right: '0px',
    },
    '& .bottom': {
      borderTop: `2px solid ${theme.palette.common.black}`,
      padding: '4px 0 0',
      margin: '50px 0 0',
      fontSize: 24,
      textAlign: 'center',
    },
    '& .top': {
      fontSize: 19,
      textAlign: 'center',
      marginBottom: '-2px',
    },
    '& .mt-2': {
      marginTop: '2px',
    },
  },
  powerIconService: {
    position: 'relative',
    width: '150px',
    '& .icon': {
      margin: '0 auto',
    },
    '& .valueTop': {
      width: '66px',
      height: '46px',
      lineHeight: '44px',
      fontSize: '24px',
      background: '#FFF',
      borderRadius: '2px',
      display: 'flex',
      position: 'absolute',
      justifyContent: 'center',
      alignItems: 'center',
    },
    '& .pos-left': {
      left: '0px',
    },
    '& .pos-right': {
      right: '10px',
    },
    '& .pos-left-r': {
      left: '10px',
    },
    '& .pos-right-r': {
      right: '0px',
    },
    '& .bottom': {
      borderTop: `2px solid ${theme.palette.common.black}`,
      padding: '4px 0 0',
      margin: '60px 0 0',
      fontSize: 24,
      textAlign: 'center',
    },
    '& .top': {
      fontSize: 19,
      textAlign: 'center',
    },
  },
  border: {
    border: ({ isLeft }) =>
      `2px solid ${isLeft ? theme.leftStation.contentsBorder : theme.rightStation.contentsBorder}`,
  },
  borderRadius: {
    borderRadius: 4,
  },
  newServiceImgContent: {
    marginTop: '12px',
    '& .icon': {
      margin: '0 auto',
    },
  },
  shiftContainer: {
    height: '180px',
    width: '180px',
  },
  shiftContent: {
    display: 'flex',
    flexDirection: 'row',
    marginTop: 5,
  },
  shiftOperatorLeftContent: {
    width: '100px',
    alignSelf: 'center',
    '& .contentBox': {
      background: '#ffffff',
      width: '100px',
      height: '36px',
      fontSize: 24,
      border: '2px solid #000000',
      borderRadius: 2,
      textAlign: 'center',
      whiteSpace: 'nowrap',
    },
    '& .mb-30': {
      marginBottom: '20px',
    },
  },
  shiftOperatorRightContent: {
    display: 'flex',
    justifyContent: 'center',
    width: '200px',
  },
  arrowIcon: {
    position: 'absolute',
    top: 10,
    right: ({ powerIconLeft }) => (powerIconLeft ? 20 : 10),
  },
  getSupportTable: {
    '&.withArea': {
      width: '140px',
    },
    '&.noArea': {
      width: '150px',
    },
  },
  tableLabel: {
    backgroundColor: '#D2F9FF',
    border: '1px solid #707070',
    textAlign: 'center',
    fontSize: '16px',
    '&.left': {
      padding: '0 4px',
      textAlign: 'left',
    },
  },
  tableValue: {
    backgroundColor: '#ffffff',
    border: '1px solid #707070',
    textAlign: 'center',
    fontSize: '16px',
  },
  getSupport: {
    height: '48px',
    width: '73px',
    fontSize: '16px',
    '&.label': {
      width: '60%',
    },
  },
  resetTimingLabel: {
    height: '32px',
    width: '150px',
    fontSize: '16px',
  },
  resetTimingValue: {
    height: '32px',
    width: '138px',
    fontSize: '16px',
  },
  areaTable: {
    fontSize: '19px',
    marginTop: '5px',
    '& .areaTableHead': {
      height: '28px',
    },
    '& .areaTableBody': {
      height: '38px',
    },
    '& .headArea': {
      width: '38px',
    },
    '& .headLeftRight': {
      width: '54px',
    },
  },
  grabPowerTitle: {
    fontSize: '18px',
    height: '48px',
    padding: '18px 0 0 0',
  },
  bgWhite: {
    background: '#ffffff',
    width: '28px',
    height: '28px',
    border: '1px solid #707070',
    margin: '0 auto',
  },
  bgGreen: {
    background: '#C9F2E2',
    width: '28px',
    height: '28px',
    border: '1px solid #707070',
    margin: '0 auto',
  },
  bgPurple: {
    background: '#EBCFEB',
    width: '28px',
    height: '28px',
    border: '1px solid #707070',
    margin: '0 auto',
  },
  bgYellow: {
    background: '#FFFDB5',
    width: '28px',
    height: '28px',
    border: '1px solid #707070',
    margin: '0 auto',
  },
  bgRed: {
    background: '#FFC8C8',
    width: '28px',
    height: '28px',
    border: '1px solid #707070',
    margin: '0 auto',
  },
  bgBlue: {
    background: '#B4DFFF',
    width: '28px',
    height: '28px',
    border: '1px solid #707070',
    margin: '0 auto',
  },
  movingGrabPower: {
    '& img': {
      width: '30px',
    },
    '& .label': {
      fontSize: '18px',
      marginLeft: '8px',
    },
    '& .grab': {
      marginTop: '8px',
    },
    '& .content': {
      display: 'flex',
      alignItems: 'center',
      '&.bottom': {
        margin: '4px 0px 0px 10px',
        justifyContent: 'space-between',
      },
    },
    '& .armPower': {
      borderRadius: '2px',
      backgroundColor: '#ffffff',
      width: '68px',
      height: '32px',
      fontSize: '24px',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
  },
  timing: {
    borderRadius: 4,
    backgroundColor: '#ffffff',
    width: '130px',
    height: '202px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-evenly',
    padding: '0px 8px 0px 16px',
    '& .timingRow': {
      display: 'flex',
      alignItems: 'center',
    },
    '& img': {
      width: '28px',
      height: '28px',
    },
    '& .label': {
      fontSize: '19px',
      marginLeft: '12px',
    },
  },
}));

// Define type Props
type PowerIconProps = {
  textTop?: string;
  valueLeft: string;
  valueRight: string;
  textBottom?: string;
  isLeft: boolean;
  powerIconLeft: boolean;
  justOne?: boolean;
};

type PowerIconServiceGraphProps = {
  valueLeft: string;
  valueRight: string;
  textTop: string;
  isLeft: boolean;
  powerIconLeft: boolean;
};

export type NewPayoutInfoSupportType = {
  grabLPower: number;
  grabRPower: number;
  grabLPower2: number;
  grabRPower2: number;
  grabLPower3: number;
  grabRPower3: number;
  grabLPower4: number;
  grabRPower4: number;
  grabLPower5: number;
  grabRPower5: number;
  grabLPower6: number;
  grabRPower6: number;
  lPower1: number;
  rPower1: number;
  backTiming: string;
  getSupport: string;
  movingLPower: number;
  movingRPower: number;
  timing1: string;
};

export type NewServiceGraphType = {
  lPower1: number;
  rPower1: number;
  limitLPower: number;
  limitRPower: number;
  resetTiming: string;
};

export type ShiftOperationType = {
  line1: string;
  line2: string;
  line3: string;
  shiftConf: string;
  shiftCm: string;
};

type NewPayoutInfoSupportProps = {
  title: string;
  isLeft: boolean;
  operationConf: string;
  payoutInfoSupport: NewPayoutInfoSupportType;
  newServiceGraph: NewServiceGraphType;
  shiftOperation: ShiftOperationType;
  areaConfHook: TypeShowAreaConf;
};

export type TypeShowAreaConf = {
  showTable: boolean | null;
  showLine: {
    lineFlg1: boolean;
    AreaSpecificSettingsLine2: boolean;
    AreaSpecificSettingsLine3: boolean;
    AreaSpecificSettingsLine4: boolean;
    AreaSpecificSettingsLine5: boolean;
    AreaSpecificSettingsLine6: boolean;
  };
  areaConf?: AreaConfType;
  conf?: ConfType;
};

const PowerIcon: React.FC<PowerIconProps> = (props) => {
  const { valueLeft, valueRight, textBottom, textTop, isLeft, powerIconLeft, justOne } = props;
  const classes = useStyles({ isLeft, powerIconLeft });
  const classPosLeft = powerIconLeft || justOne ? 'pos-left' : 'pos-left-r';
  const classPosRight = powerIconLeft && !justOne ? 'pos-right' : 'pos-right-r';
  const arrowIcon = powerIconLeft ? arrowUpIcon : arrowRigthIcon;

  return (
    <Box className={classes.powerIcon}>
      <Box className={classes.arrowIcon}>
        <img src={arrowIcon} alt="arrowUpIcon" className="icon" />
      </Box>
      <img src={armPowerIcon} alt="armPowerIcon" className="icon" />
      <span className="square">&nbsp;</span>
      {textTop ? <Box className="top">{textTop}</Box> : <div className="mt-2"> </div>}
      <Box>
        <span className={`value ${classes.border} ${classPosLeft}`}>{valueLeft}</span>
        <span className={`value ${classes.border} ${classPosRight}`}>{valueRight}</span>
      </Box>
      {textBottom ? <Box className="bottom">{textBottom}</Box> : <></>}
    </Box>
  );
};

const PowerIconServiceGraph: React.FC<PowerIconServiceGraphProps> = (props) => {
  const { valueLeft, valueRight, textTop, isLeft, powerIconLeft } = props;
  const classes = useStyles({ isLeft });
  const classPosLeft = powerIconLeft ? 'pos-left' : 'pos-left-r';
  const classPosRight = powerIconLeft ? 'pos-right' : 'pos-right-r';
  return (
    <Box className={classes.powerIconService}>
      <Box className="top">{textTop}</Box>
      <span className={`valueTop ${classes.border} ${classPosLeft}`}>{valueLeft}</span>
      <span className={`valueTop ${classes.border} ${classPosRight}`}>{valueRight}</span>
    </Box>
  );
};

const newPaymentInfoSupportContent = (
  isLeft: boolean,
  operationConf: string,
  payoutInfoSupport: NewPayoutInfoSupportType,
  newServiceGraph: NewServiceGraphType,
  shiftOperation: ShiftOperationType,
  areaConfHook: TypeShowAreaConf,
) => {
  const classes = useStyles({ isLeft });
  let contentPaymentInfo: JSX.Element;
  const {
    lineFlg1,
    AreaSpecificSettingsLine2,
    AreaSpecificSettingsLine3,
    AreaSpecificSettingsLine4,
    AreaSpecificSettingsLine5,
    AreaSpecificSettingsLine6,
  } = areaConfHook.showLine;
  const areaConfFlg = areaConfHook.areaConf?.areaConf;
  const machineVersion = areaConfHook.conf?.machineVersion;
  switch (operationConf) {
    case COMMON.OPERATION_CONF.NO_CONF:
      contentPaymentInfo = <></>;
      break;
    case COMMON.OPERATION_CONF.NEW_PAYOUT_SUPPORT:
      contentPaymentInfo = <></>;
      if (machineVersion === null) {
        // プレイヤーサポート設定（Ver.-のステーション設定、またはVer.Dの保存設定）
        contentPaymentInfo = (
          <Box>
            <Box>
              <Box className="inner">
                <PowerIcon
                  isLeft={isLeft}
                  textTop="つかみ時"
                  valueLeft={payoutInfoSupport.grabLPower.toString()}
                  valueRight={payoutInfoSupport.grabRPower.toString()}
                  powerIconLeft
                  textBottom={getClassificationName(CLSFCN.TIMING.CATEGORY_CD, payoutInfoSupport.backTiming)}
                />
                <PowerIcon
                  isLeft={isLeft}
                  textTop="移動時"
                  valueLeft={payoutInfoSupport.movingLPower.toString()}
                  valueRight={payoutInfoSupport.movingRPower.toString()}
                  powerIconLeft={false}
                  textBottom={getClassificationName(CLSFCN.ON_OFF.CATEGORY_CD, payoutInfoSupport.getSupport)}
                />
              </Box>
            </Box>
          </Box>
        );
      } else if (areaConfFlg === COMMON.SWITCH.ON) {
        // プレイヤーサポート設定（Ver.E以上）& エリア設定ON時
        const TABLE1 = [0, 1, 2];
        const TABLE2 = [3, 4, 5];
        const COLOR = [
          classes.bgWhite,
          classes.bgGreen,
          classes.bgPurple,
          classes.bgYellow,
          classes.bgRed,
          classes.bgBlue,
        ];
        const hasArea = [
          lineFlg1,
          AreaSpecificSettingsLine2,
          AreaSpecificSettingsLine3,
          AreaSpecificSettingsLine4,
          AreaSpecificSettingsLine5,
          AreaSpecificSettingsLine6,
        ];
        const grabLPowerList = [
          payoutInfoSupport.grabLPower,
          payoutInfoSupport.grabLPower2,
          payoutInfoSupport.grabLPower3,
          payoutInfoSupport.grabLPower4,
          payoutInfoSupport.grabLPower5,
          payoutInfoSupport.grabLPower6,
        ];
        const grabRPowerList = [
          payoutInfoSupport.grabRPower,
          payoutInfoSupport.grabRPower2,
          payoutInfoSupport.grabRPower3,
          payoutInfoSupport.grabRPower4,
          payoutInfoSupport.grabRPower5,
          payoutInfoSupport.grabRPower6,
        ];
        contentPaymentInfo = (
          <Box>
            <Box>
              <Box className="between">
                <div className={classes.grabPowerTitle}>つかみ時のパワー</div>
                <Table className={`${classes.getSupportTable} withArea`}>
                  <TableRow>
                    <td className={`${classes.tableLabel} ${classes.getSupport} left label`}>
                      獲得
                      <br />
                      サポート
                    </td>
                    <td className={`${classes.tableValue} ${classes.getSupport}`}>
                      {getClassificationName(CLSFCN.ON_OFF.CATEGORY_CD, payoutInfoSupport.getSupport)}
                    </td>
                  </TableRow>
                </Table>
              </Box>
              <Box className="inner areaTable">
                <Table className={classes.areaTable}>
                  <TableRow className="areaTableHead">
                    <td className={`${classes.tableLabel} headArea`}>ｴﾘｱ</td>
                    <td className={`${classes.tableLabel} headLeftRight`}>左</td>
                    <td className={`${classes.tableLabel} headLeftRight`}>右</td>
                  </TableRow>
                  {TABLE1.map((value) => (
                    <TableRow className={`${classes.tableLabel} areaTableBody`}>
                      <td className={classes.tableValue}>
                        <Box className={COLOR[value]}>&nbsp;</Box>
                      </td>
                      <td className={classes.tableValue}>{hasArea[value] ? grabLPowerList[value] : '－'}</td>
                      <td className={classes.tableValue}>{hasArea[value] ? grabRPowerList[value] : '－'}</td>
                    </TableRow>
                  ))}
                </Table>
                <Table className={classes.areaTable}>
                  <TableRow className="areaTableHead">
                    <td className={`${classes.tableLabel} headArea`}>ｴﾘｱ</td>
                    <td className={`${classes.tableLabel} headLeftRight`}>左</td>
                    <td className={`${classes.tableLabel} headLeftRight`}>右</td>
                  </TableRow>
                  {TABLE2.map((value) => (
                    <TableRow className={`${classes.tableLabel} areaTableBody`}>
                      <td className={classes.tableValue}>
                        <Box className={COLOR[value]}>&nbsp;</Box>
                      </td>
                      <td className={classes.tableValue}>{hasArea[value] ? grabLPowerList[value] : '－'}</td>
                      <td className={classes.tableValue}>{hasArea[value] ? grabRPowerList[value] : '－'}</td>
                    </TableRow>
                  ))}
                </Table>
              </Box>
            </Box>
          </Box>
        );
      } else if (areaConfFlg === COMMON.SWITCH.OFF) {
        // プレイヤーサポート設定（Ver.E以上）& エリア設定OFF時
        contentPaymentInfo = (
          <Box className="between">
            <Box className="between column">
              <Table className={`${classes.getSupportTable} noArea`}>
                <TableRow>
                  <td className={`${classes.tableLabel} ${classes.getSupport} left label`}>
                    獲得
                    <br />
                    サポート
                  </td>
                  <td className={`${classes.tableValue} ${classes.getSupport}`}>
                    {getClassificationName(CLSFCN.ON_OFF.CATEGORY_CD, payoutInfoSupport.getSupport)}
                  </td>
                </TableRow>
              </Table>
              <Box className={classes.movingGrabPower}>
                <Box>
                  <Box className="content">
                    <img src={movingCatcherIcon} alt="" />
                    <p className="label">移動時</p>
                  </Box>
                  <Box className="content bottom">
                    <Box className={`${classes.border} armPower`}>{payoutInfoSupport.movingLPower.toString()}</Box>
                    <Box className={`${classes.border} armPower`}>{payoutInfoSupport.movingRPower.toString()}</Box>
                  </Box>
                </Box>
                <Box className="grab">
                  <Box className="content">
                    <img src={grabCatcherIcon} alt="" />
                    <p className="label">つかみ時</p>
                  </Box>
                  <Box className="content bottom">
                    <Box className={`${classes.border} armPower`}>{payoutInfoSupport.grabLPower.toString()}</Box>
                    <Box className={`${classes.border} armPower`}>{payoutInfoSupport.grabRPower.toString()}</Box>
                  </Box>
                </Box>
              </Box>
            </Box>
            <Box className={`${classes.timing} ${classes.border}`}>
              <Box className="timingRow">
                <img
                  src={payoutInfoSupport.timing1[6] === COMMON.SWITCH.ON ? redCatcherIcon : grayCatcherIcon}
                  alt="タイミングMAX"
                />
                <p className="label">MAX</p>
              </Box>
              <Box className="timingRow">
                <img
                  src={payoutInfoSupport.timing1[5] === COMMON.SWITCH.ON ? redCatcherIcon : grayCatcherIcon}
                  alt="タイミング30㎝"
                />
                <p className="label">30㎝</p>
              </Box>
              <Box className="timingRow">
                <img
                  src={payoutInfoSupport.timing1[4] === COMMON.SWITCH.ON ? redCatcherIcon : grayCatcherIcon}
                  alt="タイミング25㎝"
                />
                <p className="label">25㎝</p>
              </Box>
              <Box className="timingRow">
                <img
                  src={payoutInfoSupport.timing1[3] === COMMON.SWITCH.ON ? redCatcherIcon : grayCatcherIcon}
                  alt="タイミング20㎝"
                />
                <p className="label">20㎝</p>
              </Box>
              <Box className="timingRow">
                <img
                  src={payoutInfoSupport.timing1[2] === COMMON.SWITCH.ON ? redCatcherIcon : grayCatcherIcon}
                  alt="タイミング15㎝"
                />
                <p className="label">15㎝</p>
              </Box>
              <Box className="timingRow">
                <img
                  src={payoutInfoSupport.timing1[1] === COMMON.SWITCH.ON ? redCatcherIcon : grayCatcherIcon}
                  alt="タイミング10㎝"
                />
                <p className="label">10㎝</p>
              </Box>
              <Box className="timingRow">
                <img
                  src={payoutInfoSupport.timing1[0] === COMMON.SWITCH.ON ? redCatcherIcon : grayCatcherIcon}
                  alt="タイミング5㎝"
                />
                <p className="label">5㎝</p>
              </Box>
            </Box>
          </Box>
        );
      }
      break;
    case COMMON.OPERATION_CONF.NEW_SERVICE_MODE:
      contentPaymentInfo = (
        <Box>
          <Table>
            <TableRow>
              <td className={`${classes.tableLabel} ${classes.resetTimingLabel}`}>リセットタイミング</td>
              <td className={`${classes.tableValue} ${classes.resetTimingValue}`}>
                {getClassificationName(CLSFCN.RESET_TIMING.CATEGORY_CD, newServiceGraph.resetTiming)}
              </td>
            </TableRow>
          </Table>
          <Box className={classes.newServiceImgContent}>
            <img src={newServiceGraphImg} alt="newServiceGraph" className="icon" />
          </Box>
          <Box className="inner">
            <PowerIconServiceGraph
              valueLeft={newServiceGraph.lPower1.toString()}
              valueRight={newServiceGraph.rPower1.toString()}
              textTop="基本"
              powerIconLeft
              isLeft={isLeft}
            />
            <PowerIconServiceGraph
              valueLeft={newServiceGraph.limitLPower.toString()}
              valueRight={newServiceGraph.limitRPower.toString()}
              textTop="上限"
              powerIconLeft={false}
              isLeft={isLeft}
            />
          </Box>
        </Box>
      );
      break;
    case COMMON.OPERATION_CONF.SHIFT_OPERATION:
      contentPaymentInfo = (
        <Box className={classes.shiftContent}>
          <Box className={classes.shiftOperatorLeftContent}>
            <Box className="contentBox mb-30">
              {getClassificationName(CLSFCN.SHIFT_PATTERN_NAME.CATEGORY_CD, shiftOperation.shiftConf)}
            </Box>
            <Box className="contentBox">
              {getClassificationName(CLSFCN.SHIFT_CM.CATEGORY_CD, shiftOperation.shiftCm)}
            </Box>
          </Box>
          <Box className={classes.shiftOperatorRightContent}>
            <Box className={classes.shiftContainer}>
              <ShiftPanelReview
                shiftConf={shiftOperation.shiftConf}
                line1={shiftOperation.line1}
                line2={shiftOperation.line2}
                line3={shiftOperation.line3}
              />
            </Box>
          </Box>
        </Box>
      );
      break;
    default:
      contentPaymentInfo = <></>;
      break;
  }

  return contentPaymentInfo;
};

const OperationConf: React.FC<NewPayoutInfoSupportProps> = (props) => {
  const { title, isLeft, operationConf, payoutInfoSupport, newServiceGraph, shiftOperation, areaConfHook } = props;
  const classes = useStyles({ isLeft });
  let contentSubtitle = '';
  switch (operationConf) {
    case COMMON.OPERATION_CONF.NO_CONF:
      contentSubtitle = COMMON.OPERATION_TEXT['0'];
      break;
    case COMMON.OPERATION_CONF.NEW_PAYOUT_SUPPORT:
      contentSubtitle = COMMON.OPERATION_TEXT['1'];
      break;
    case COMMON.OPERATION_CONF.NEW_SERVICE_MODE:
      contentSubtitle = COMMON.OPERATION_TEXT['2'];
      break;
    case COMMON.OPERATION_CONF.SHIFT_OPERATION:
      contentSubtitle = COMMON.OPERATION_TEXT['3'];
      break;
    default:
      contentSubtitle = '';
      break;
  }

  return (
    <Box className={`${classes.container} ${classes.border} ${classes.borderRadius}`}>
      <Box className={classes.frame}>
        <Box className={`${classes.title} customTitle`}>
          {title}
          {contentSubtitle && <span>{contentSubtitle}</span>}
        </Box>
        {newPaymentInfoSupportContent(
          isLeft,
          operationConf,
          payoutInfoSupport,
          newServiceGraph,
          shiftOperation,
          areaConfHook,
        )}
      </Box>
    </Box>
  );
};

export default memo(OperationConf);
