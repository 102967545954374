import React, { useCallback, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import Layout from 'components/system/layouts/Layout';
import ScreenContext from 'Contexts/Common/ScreenContext';
import COMMON from 'constants/common';
import { Box, Grid, makeStyles } from '@material-ui/core';
import { useAppDispatch } from 'redux/hooks';
import redirectLinks from 'constants/redirectLinks';
import { NormalSelectBox } from 'components/system/organisms';
import { getClsfcnByCategory } from 'utils/common.helper';
import CLSFCN from 'constants/classification';
import ReturnButton from 'components/system/atoms/buttons/ReturnButton';
import { GroupListType } from 'types/group/GroupType';
import GroupTable from 'components/group/organisms/tables/GroupTable';
import { REST_API } from 'constants/apiUrls';
import HttpConnection from 'utils/httpConnection';
import {
  setNextFromLedOrderSetScr,
  setNextFromOrderSetScr,
  setReturnFromCreateScr,
  setReturnFromLedCreateScr,
  setReturnFromLedSelectScr,
  setReturnFromSelectScr,
} from 'redux/slices/groupSlice';

/* ************ Context ************ */
const ScreenContextValue = {
  title: '登録グループ一覧',
  screenId: COMMON.SCREEN_ID.GROUP_LIST,
  currentMenu: COMMON.MENU.GROUP,
  initializeState: true,
};

/* ********** Constants ********** */
const TABLE_ID = 'dataGroupTable';

/* ************ Style ************ */
const useStyles = makeStyles(() => ({
  contents: {
    padding: '24px 16px 0px 36px',
  },
  topArea: {
    marginBottom: '18px',
    display: 'flex',
    justifyContent: 'flex-start',
    '& .returnButton': {
      width: '200px',
      height: '70px',
    },
  },
  sortArea: {
    right: '0px',
    bottom: '8px',
    position: 'absolute',
  },
  outerGrid: {
    position: 'relative',
  },
}));

const GroupListData: React.FC = () => {
  const history = useHistory();
  const dispatch = useAppDispatch();
  const classes = useStyles();
  const dataSouceSort = getClsfcnByCategory(CLSFCN.GROUP_SORT.CATEGORY_CD);

  /* ************ state/redux ************ */
  const [sort, setSort] = useState<string>(CLSFCN.GROUP_SORT.LASTEST_ORDER);
  const [groupList, setGroupList] = useState<Array<GroupListType>>([]);

  useEffect(() => {
    handleInitialDisplay(sort);
  }, [sort]);

  /* ************ Event ************ */
  // No1. Initial display
  const handleInitialDisplay = async (sortValue: string) => {
    const http = new HttpConnection({ dispatch });
    const data = await http.get<Array<GroupListType>>(REST_API.GROUP.GET_GROUP_LIST, { sort: Number(sortValue) });
    setGroupList(data);
  };

  // No2. Back to the group setting TOP screen
  const handleClickReturn = useCallback(() => {
    history.push(redirectLinks.GROUP_CONF_TOP);
  }, []);

  // No3. Sort
  const handleChangeSort = (ev: React.ChangeEvent<HTMLSelectElement>) => {
    setSort(ev.target.value);
  };

  // No4. Pin/Unpin
  const handlePinning = useCallback(
    async (selectedItem: GroupListType) => {
      const newPinnedStatus =
        Number(selectedItem.pinned) === Number(COMMON.FLAG.OFF) ? COMMON.FLAG.ON : COMMON.FLAG.OFF;
      const http = new HttpConnection({ dispatch });
      const data = await http.post<Array<GroupListType>>(REST_API.GROUP.UPDATE_PIN_STATUS, {
        groupId: selectedItem.groupId,
        pinned: newPinnedStatus,
        sort: Number(sort),
      });

      setGroupList(data);
    },
    [sort],
  );

  // No5. Transition to List
  const handleClickList = useCallback((selectedItem: GroupListType) => {
    if (Number(selectedItem.groupCategory) === COMMON.GROUP_CATEGORY.LED) {
      dispatch(setReturnFromLedSelectScr(COMMON.SCREEN_ID.GROUP_LIST));
      dispatch(setNextFromLedOrderSetScr(COMMON.SCREEN_ID.GROUP_LIST));
      history.push(redirectLinks.GROUP_LED_SELECT_REGISTER_MACHINE, {
        groupId: selectedItem.groupId,
        from: 'groupDataList',
      });
    } else {
      dispatch(setReturnFromSelectScr(COMMON.SCREEN_ID.GROUP_LIST));
      dispatch(setNextFromOrderSetScr(COMMON.SCREEN_ID.GROUP_LIST));
      history.push(redirectLinks.GROUP_GET_GIFT_SELECT_REGISTER_MACHINE, {
        groupId: selectedItem.groupId,
        from: 'groupDataList',
      });
    }
  }, []);

  // No6. Transition to Setting
  const handleClickSetting = useCallback((selectedItem: GroupListType) => {
    if (Number(selectedItem.groupCategory) === COMMON.GROUP_CATEGORY.LED) {
      dispatch(setReturnFromLedCreateScr(COMMON.SCREEN_ID.GROUP_LIST));
      dispatch(setNextFromLedOrderSetScr(COMMON.SCREEN_ID.GROUP_LIST));
      history.push(redirectLinks.GROUP_LED_GROUP_CREATE, {
        fromScrId: COMMON.SCREEN_ID.GROUP_LIST,
        groupId: selectedItem.groupId,
      });
    } else {
      dispatch(setReturnFromCreateScr(COMMON.SCREEN_ID.GROUP_LIST));
      dispatch(setNextFromOrderSetScr(COMMON.SCREEN_ID.GROUP_LIST));
      history.push(redirectLinks.GROUP_GET_GIFT_GROUP_CREATE, {
        fromScrId: COMMON.SCREEN_ID.GROUP_LIST,
        groupId: selectedItem.groupId,
      });
    }
  }, []);

  return (
    <ScreenContext.Provider value={ScreenContextValue}>
      <Layout>
        <Box className={classes.contents}>
          <Box className={classes.topArea}>
            <Box className="returnButton">
              <ReturnButton onClick={handleClickReturn}>
                グループ
                <br />
                設定TOP
              </ReturnButton>
            </Box>
          </Box>

          <Grid container alignItems="flex-end" className={classes.outerGrid}>
            <Grid className={classes.sortArea}>
              <NormalSelectBox
                name="sort"
                dataSource={dataSouceSort}
                value={sort}
                onChange={handleChangeSort}
                label="並べ替え"
              />
            </Grid>
          </Grid>

          <GroupTable
            groupList={groupList}
            tableId={TABLE_ID}
            handlePinning={handlePinning}
            handleClickList={handleClickList}
            handleClickSetting={handleClickSetting}
          />
        </Box>
      </Layout>
    </ScreenContext.Provider>
  );
};

export default GroupListData;
