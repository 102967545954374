import React, { useCallback, useMemo } from 'react';
import { Stage, Layer, Circle, Group, Rect } from 'react-konva';
import { PitConfGrandType } from 'types/machineConf/machineConfType';
import CLSFCN from 'constants/classification';

/* ************ constant ************ */
const ICON_SIZE = 24;
const ICON_SIZE_S = 15;
const AREA_SIZE_M = 330;
const AREA_SIZE_CM = 390;
const AREA_SIZE_S = 210;
const PRIZE_AREA_MAX = 100;

type Props = {
  pitConf: PitConfGrandType;
  className?: string;
  size?: 'M' | 'CM' | 'S';
  strokeColor?: string;
  strokeWidth?: number;
};

// ヘッダ定義
const PitPinpointPanelGrand: React.FC<Props> = (props) => {
  const { pitConf, className, size = 'M', strokeColor = '#000000', strokeWidth = 3 } = props;

  // アイコンとエリアのサイズからkonvaによるアイコンの操作範囲を定義
  let areaSize = AREA_SIZE_M;
  let iconSize = ICON_SIZE;
  switch (size) {
    case 'CM':
      areaSize = AREA_SIZE_CM;
      break;
    case 'S':
      areaSize = AREA_SIZE_S;
      iconSize = ICON_SIZE_S;
      break;
    default:
      break;
  }
  const width = areaSize + iconSize;
  const height = areaSize + iconSize;
  const iconHalfSize = iconSize / 2;
  const isLeft = true;

  // useCallbackで初回のみ関数オブジェクトを作る
  // プライズの座標単位(100x100)から画面の座標単位(360x360)に変換する
  const toScreenPos = useCallback((x: number | null, y: number | null) => {
    if (x === null || y === null) {
      return { x: null, y: null };
    }

    return {
      x: isLeft ? Math.round((x * areaSize) / PRIZE_AREA_MAX) : areaSize - Math.round((x * areaSize) / PRIZE_AREA_MAX),
      y: areaSize - Math.round((y * areaSize) / PRIZE_AREA_MAX),
    };
  }, []);

  const pit1Pos = useMemo(() => toScreenPos(pitConf.pit1X, pitConf.pit1Y), [pitConf.pit1X, pitConf.pit1Y]);
  const pit2Pos = useMemo(() => toScreenPos(pitConf.pit2X, pitConf.pit2Y), [pitConf.pit2X, pitConf.pit2Y]);

  const getPitFillColor = useCallback((status: string | undefined, isPit1 = false) => {
    if (status === CLSFCN.PIT_CONFIG.STATUS.ENABLED) {
      return isPit1 ? '#FF0000' : '#000000';
    }
    return '#E9E8E8';
  }, []);

  const getPitStrokeColor = useCallback((status: string | undefined) => {
    if (status === CLSFCN.PIT_CONFIG.STATUS.ENABLED) {
      return '#FFFFFF';
    }
    return '#707070';
  }, []);

  return (
    <Stage width={width} height={height} className={className}>
      {/* Layer=エリアはアイコンのサイズ分配置する座標をずらす。 */}
      <Layer x={iconHalfSize} y={iconHalfSize} width={areaSize} height={areaSize}>
        {/* 罫線 */}
        <Rect x={0} y={0} width={areaSize} height={areaSize} stroke={strokeColor} strokeWidth={strokeWidth} />
        {/* 落とし穴２ */}
        {pit2Pos.x !== null && pit2Pos.y !== null && (
          <Group x={pit2Pos.x} y={pit2Pos.y}>
            <Circle
              radius={iconHalfSize}
              fill={getPitFillColor(pitConf?.pit2Status)}
              stroke={getPitStrokeColor(pitConf?.pit2Status)}
            />
          </Group>
        )}
        {/* 落とし穴１ */}
        {pit1Pos.x !== null && pit1Pos.y !== null && (
          <Group x={pit1Pos.x} y={pit1Pos.y}>
            <Circle
              radius={iconHalfSize}
              fill={getPitFillColor(pitConf?.pit1Status, true)}
              stroke={getPitStrokeColor(pitConf?.pit1Status)}
            />
          </Group>
        )}
      </Layer>
    </Stage>
  );
};

export default PitPinpointPanelGrand;
