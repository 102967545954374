const COMMON = {
  BASE_URL: process.env.REACT_APP_API_ENDPOINT,
  ENV: process.env.REACT_APP_ENV,
  CONTENT_URL: process.env.REACT_APP_CONTENT_ENDPOINT,
  MENU: {
    HOME: 'HOME',
    DATA: 'DATA',
    MACHINE_CONF: 'MACHINE_CONF',
    GROUP: 'GROUP',
    MAINTENANCE: 'MAINTENANCE',
    OPERATION_EXAMPLE_LIST: 'OPERATION_EXAMPLE_LIST',
    ERROR: 'ERROR',
  },
  ENABLE_STORE_CHANGE: {
    SYSTEM_ADMIN: 'SCR111',
    HEAD_QUARTERS: 'SCR111',
  },
  SHOW_CONTROL: {
    SHOW: 0,
    HIDE: 1,
  },
  LEFT_RIGHT: {
    LEFT: '1',
    RIGHT: '2',
    LEFT_AND_RIGHT: '3',
  },
  MACHINE_TYPE: {
    CLENA3: null,
    JACK: '1',
    GRAND: '2',
  },
  MACHINE_NAME: {
    CLENA3: 'クレナ3',
    JACK: 'クレナ3ジャック',
    GRAND: 'クレナグラン',
  },
  CONF_RECEIVE: {
    // 設定受取中フラグ
    NORMAL: '0', // 通常時
    WAITING: '1', // 待機中
  },
  OPERATION_CONF: {
    NO_CONF: '0',
    NEW_PAYOUT_SUPPORT: '1',
    NEW_SERVICE_MODE: '2',
    SHIFT_OPERATION: '3',
  },
  VALID_FLG: {
    VALID: '0', // 有効
    INVALID: '1', // 無効
  },
  SWITCH: {
    OFF: '0',
    ON: '1',
  },
  FLAG: {
    OFF: '0',
    ON: '1',
  },
  STATUS: {
    NORMAL: '0',
    ABNORMAL: '1',
  },
  TENKEY_MODE: {
    DECIMAL: 0,
    INTEGER: 1,
  },
  MACHINE_CONF: {
    INITIALIZE_CONF_ID: 10,
    INITIALIZE_CONF_ID_JACK: 9,
    FORCE: 1,
    NOT_FORCE: 0,
  },
  SIZE: {
    SMALL: 'S',
    CUSTOM_MEDIUM: 'CM',
    MEDIUM: 'M',
    LARGE: 'L',
  },
  POINTER_TYPE: {
    HOME: 'H',
    CUSTOM_HOME: 'HP',
    END: 'E',
  },
  DATA_VIEW_UNIT: {
    DAILY: '1',
    WEEKLY: '2',
    MONTHLY: '3',
    YEARLY: '4',
  },
  DATA_PREV_SCREEN: {
    DATA_STATION_LIST: '0',
    GROUP_LIST: '1',
    DATA_DATA_SHEET: '4',
  },
  CUSTOM_HOME_POSITION: {
    DEFAULT: '0',
    CUSTOM: '1',
  },
  CHANGE_DATE: {
    CURRENT: '0',
    PREVIOUS: '1',
    NEXT: '2',
  },

  /* =============== MACHINE CONF =============== */
  INITIAL: {
    TIMING: '0001000',
    GRAB_ARM_POWER: 150,
    MOVING_ARM_POWER: 1,
    BACK_HEIGHT: '30',
    BACK_TIMING: '1',
  },
  CONF_DIV: {
    STATION_CONF: '1',
    SAVE_CONF: '2',
  },
  EXEC_DIV: {
    NORMAL: '0', // 初期読込、はじめから設定反映時
    SAVED_CONF: '1', // 保存設定反映時
  },
  AREA: {
    BASE: '0',
    GREEN: '1',
    PURPLE: '2',
    YELLOW: '3',
    RED: '4',
    BLUE: '5',
  },
  SHIFT_ARROW: {
    BACK: '0', // ↑
    BACK_RIGHT: '1', // ↗
    RIGHT: '2', // →
    FRONT_RIGHT: '3', // ↘
    FRONT: '4', // ↓
    FRONT_LEFT: '5', // ↙
    LEFT: '6', // ←
    BACK_LEFT: '7', // ↖
    PIT: '8', // ○
  },
  LEVER_STATUS: {
    FALSE: '0',
    TRUE: '1',
  },
  OPERATION_TEXT: {
    '0': '設定なし',
    '1': 'プレイヤーサポート',
    '2': '新サービスモード',
    '3': 'ずらし運営',
  },
  CATCHER_DIRECTION: {
    NORMAL: '0',
    IN: '1',
    OUT: '2',
  },
  UP_DOWN: {
    DOWN: '0',
    UP: '1',
  },
  /* =============== GROUP =============== */

  DUMMY_GROUP_ID: -1,
  GROUP_CATEGORY: {
    LED: 0,
    GET_GIFT: 1,
  },

  SETTING_TAB: {
    CLENA3: '0',
    JACK: '1',
    GRAND: '2',
  },

  /* =============== MAINTENANCE =============== */
  GIFTS_DISPLAYED: 200, // 登録景品一覧の1ページの表示件数

  /* =============== COMMON =============== */

  // システムエラー画面に表示する情報
  SYSTEM_ERROR_INFO: {
    TEL: '0120-03-8765', // サポートセンター連絡先
  },
  HEADQUARTERS_ID: {
    BNAL: 'bnal',
    BNAM: 'bnam',
  },
  // 画面ID
  SCREEN_ID: {
    TOP: 'SCR111',
    DATA_STATION_LIST: 'SCR211',
    DATA_SALES_DATA_VIEW: 'SCR221',
    DATA_COST_RATE_DATA_VIEW: 'SCR222',
    DATA_DATA_SHEET: 'SCR224',
    DATA_TOTAL_DATA_VIEW: 'SCR225',
    CONF_TOP_JACK: 'SCR312J',
    CONF_TOP_GRAND: 'SCR312G',
    XY_PIT_AREA_TOP_JACK: 'SCR321J',
    XY_PIT_AREA_TOP_GRAND: 'SCR321G',
    CATCHER_SPEED_CONF: 'SCR324',
    CATCHER_SPEED_JACK: 'SCR324J',
    LEVER_OPERATION_JACK: 'SCR327J',
    SUPPORT_PARTITION_GRAND: 'SCR329G',
    GAME_CONF_TOP_JACK: 'SCR331J',
    SHIFT_OPERATION: 'SCR332',
    SHIFT_OPERATION_JACK: 'SCR332J',
    SHIFT_OPERATION_GRAND: 'SCR332G',
    NEW_PAYOUT_SUPPORT_JACK: 'SCR333J',
    NEW_SERVICE_MODE_CONF: 'SCR334',
    NEW_SERVICE_MODE_JACK: 'SCR334J',
    NEW_SERVICE_MODE_GRAND: 'SCR334G',
    SEQUENCE_JACK: 'SCR335J',
    SEQUENCE_GRAND: 'SCR335G',
    COST_SERVICE_JACK: 'SCR341J',
    MESSAGE_CONF_GRAND: 'SCR342G',
    LED_CONF: 'SCR351',
    LED_CONF_JACK: 'SCR351J',
    LED_CONF_GRAND: 'SCR351G',
    OTHER_CONF_JACK: 'SCR361J',
    OTHER_CONF_GRAND: 'SCR361G',
    SAVED_CONF_LIST: 'SCR371',
    USER_LIST: 'SCR531',
    GROUP_SELECT_CATEGORY: 'SCR412',
    GET_GIFT_GROUP_CREATE: 'SCR421',
    GET_GIFT_SELECT_MACHINE: 'SCR422',
    GET_GIFT_ORDER_SET: 'SCR423',
    LED_GROUP_CREATE: 'SCR431',
    LED_SELECT_MACHINE: 'SCR432',
    LED_ORDER_SET: 'SCR433',
    GROUP_LIST: 'SCR441',
    MACHINE_LIST: 'SCR521',
    // MACHINE_DETAIL: 'SCR522',
    MACHINE_DETAIL: 'SCR523',
    GIFT_LIST: 'SCR541',
    GIFT_DETAIL: 'SCR542',
    REGISTER_GIFT_DESTINATION_LIST: 'SCR543',
    STATION_HOME_DETAIL: 'SCR313',
    STATION_HOME_DETAIL_GRAND: 'SCR313G',
    PIT_CONF_JACK: 'SCR322J',
    PIT_CONF_GRAND: 'SCR322G',
    STATION_HOME_DETAIL_JACK: 'SCR313J',
    AREA_CONF_JACK: 'SCR326J',
    AREA_CONF_GRAND: 'SCR326G',
    XY_RANGE_CONF_JACK: 'SCR323J',
    XY_RANGE_CONF_GRAND: 'SCR323G',
    AREA_POWER_CONF_JACK: 'SCR325J',
    NEW_PAYOUT_SUPPORT_GRAND: 'SCR333G',
    OPERATION_EXAMPLE_LIST: 'SCR714',
  },
  EMPTY: '',
  BLANK: '----',
  BLANK1: '-',
  BLANK3: '---',
  // 本部ID
  HQ_ID: {
    BNAL: 'bnal',
  },
  // OPTION BY DATE
  OPTIONS_TAB: {
    DAY: [
      { name: '上限2万円', value: '1' },
      { name: '上限5万円', value: '2' },
      { name: '上限10万円', value: '3' },
    ],
    WEEK: [
      { name: '上限10万円', value: '1' },
      { name: '上限30万円', value: '2' },
      { name: '上限60万円', value: '3' },
    ],
    MONTH: [
      { name: '上限50万円', value: '1' },
      { name: '上限100万円', value: '2' },
      { name: '上限250万円', value: '3' },
    ],
    YEAR: [
      { name: '上限300万円', value: '1' },
      { name: '上限600万円', value: '2' },
      { name: '上限1,200万円', value: '3' },
    ],
  },

  OPTION_SETTING: {
    DAY: [
      { displayLegend: false, max: 20000, stepSize: 1000, mdGridUnit: 10000 },
      { displayLegend: false, max: 50000, stepSize: 5000, mdGridUnit: 10000 },
      { displayLegend: false, max: 100000, stepSize: 10000, mdGridUnit: 20000 },
    ],
    WEEK: [
      { displayLegend: true, max: 100000, stepSize: 10000, mdGridUnit: 20000 },
      { displayLegend: true, max: 300000, stepSize: 10000, mdGridUnit: 50000 },
      { displayLegend: true, max: 600000, stepSize: 10000, mdGridUnit: 50000 },
    ],
    MONTH: [
      { displayLegend: true, max: 500000, stepSize: 10000, mdGridUnit: 50000 },
      { displayLegend: true, max: 1000000, stepSize: 50000, mdGridUnit: 100000 },
      { displayLegend: true, max: 2500000, stepSize: 100000, mdGridUnit: 500000 },
    ],
    YEAR: [
      { displayLegend: true, max: 3000000, stepSize: 100000, mdGridUnit: 500000 },
      { displayLegend: true, max: 6000000, stepSize: 500000, mdGridUnit: 500000 },
      { displayLegend: true, max: 12000000, stepSize: 500000, mdGridUnit: 1000000 },
    ],
  },
  RESET_VALUE_DESCENT_LIMIT: 100,
  RESET_VALUE_DESCENT_LIMIT_JACK: 60,

  // アームサイズ
  ARM_SIZE: {
    NONE: '0',
    S: '1',
    M: '2',
    L: '3',
    SS: '4',
    LL: '5',
  },
};

export default COMMON;
