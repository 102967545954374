import COMMON from 'constants/common';
import React, { useEffect, useState } from 'react';
import Layout from 'components/system/layouts/Layout';
import ScreenContext from 'Contexts/Common/ScreenContext';
import MachineConfContent from 'components/machineConf/organisms/contents/MachineConfContent';
import { Box, makeStyles } from '@material-ui/core';
import { ReturnButton, SelectButton } from 'components/system/atoms';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import { getClassificationName } from 'utils/common.helper';
import CLSFCN from 'constants/classification';
import { useHistory, useLocation } from 'react-router-dom';
import { handleCallSystemError } from 'redux/slices/commonSlice';
import ERROR_CODE from 'constants/errorCode';

import { themeColor } from 'constants/theme';
import LedSelectAreaGrand from 'components/machineConf/organisms/LedConf/LedSelectAreaGrand';
import UrlHelper from 'utils/url.helper';
import redirectLinks from 'constants/redirectLinks';
import { SaveDetailInfoType } from 'types/machineConf/stationSelectType';

/* ************ Context ************ */
const ScreenContextValue = {
  title: '照明演出設定画面',
  screenId: COMMON.SCREEN_ID.LED_CONF_GRAND,
  currentMenu: COMMON.MENU.MACHINE_CONF,
  disableContents: true,
};

/* ************ Style ************ */
const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    minWidth: '1080px',
    minHeight: '563px',
    position: 'relative',
    margin: '18px 24px',
  },
  topContent: {
    display: 'flex',
    '& .returnButton': {
      fontSize: '20px',
      lineHeight: '24px',
      position: 'relative',
      zIndex: 200,
    },
    '& .description': {
      fontSize: '23px',
      marginLeft: '16px',
    },
  },
  middleContent: {
    display: 'flex',
    justifyContent: 'space-between',
    position: 'relative',
    marginTop: '24px',
    '& .middleRightArea': {
      display: 'flex',
      flexDirection: 'column',
    },
    '& .ledSelectArea': {
      position: 'relative',
    },
    '& .settingButton': {
      width: '224px',
      height: '48px',
      fontSize: '19px',
      border: '2px solid #000',
      borderRadius: '8px',
      padding: '0 8px',
      '& div': {
        textAlign: 'left',
      },
    },
    '& .ledDirecting': {
      marginTop: '16px',
    },
    '& .ecoMode': {
      marginTop: '16px',
    },
    '& .doneButton': {
      position: 'absolute',
      right: '0',
      bottom: '0',
    },
  },
  // ステーション別Style
  leftStyle: {
    borderColor: themeColor.leftDarkColor,
    '&:before': {
      borderRightColor: themeColor.leftDarkColor,
    },
    '&:after': {
      borderRightColor: '#FFF',
    },
  },
  rightStyle: {
    borderColor: themeColor.rightDarkColor,
    '&:before': {
      borderRightColor: themeColor.rightDarkColor,
    },
    '&:after': {
      borderRightColor: '#FFF',
    },
  },
  // Common Style
  baseFontSize: {
    fontSize: '19px',
  },
  onOffValue: { fontSize: '21px', lineHeight: '24px' },
  baseArea: {
    backgroundColor: '#FFF',
    borderWidth: '2px',
    borderStyle: 'solid',
    borderRadius: '8px',
  },
  disableInputArea: {
    position: 'absolute',
    height: '100%',
    width: '100%',
    background: 'rgb(128 128 128 / 50%)',
    borderRadius: '8px',
    zIndex: 100,
    top: '0px',
    left: '0px',
  },
  doneImage: {
    width: '56px',
    height: '28px',
    padding: '2px 0px',
    borderRadius: '4px',
    fontSize: '16px',
    textAlign: 'center',
    verticalAlign: 'middle',
    display: 'inline-block',
    background: theme.palette.common.black,
    color: theme.palette.common.white,
  },
}));

/* ******** Main Component ******** */
/**
 *
 * @returns {React.FC} - 照明演出設定
 *
 */
const LedConfGrand: React.FC = () => {
  const classes = useStyles();
  const history = useHistory();
  const location = useLocation();
  const [savedConfProps] = useState(location.state !== undefined ? (location.state as SaveDetailInfoType) : null);
  const dispatch = useAppDispatch();

  /* ************ State ************ */
  // Global
  const ledServiceOtherConf = useAppSelector((state) => state.machineConf.machineGrandConf.ledServiceOtherConf);

  /* ************ Event ************ */
  // 画面表示時
  useEffect(() => {
    // グローバルステートが見つからない場合、システムエラー画面に遷移
    if (!ledServiceOtherConf) {
      dispatch(handleCallSystemError({ errorNo: ERROR_CODE.NOT_FOUND }));
    }
  }, []);

  // 戻るボタン
  const handleClickReturn = () => {
    history.push(
      UrlHelper.convertQueryUrlFrontEnd(redirectLinks.MCONF_GRAND_HOME_DETAIL, {
        selectConfId: 'none',
      }),
    );
  };

  return (
    <ScreenContext.Provider value={ScreenContextValue}>
      <Layout>
        <MachineConfContent savedConfProps={savedConfProps}>
          <Box className={classes.root}>
            <Box className={classes.topContent}>
              <ReturnButton className="returnButton" onClick={handleClickReturn}>
                機器設定 <br />
                詳細
              </ReturnButton>
              <Box className="description">「照明の演出」の設定内容です。</Box>
            </Box>
            <Box className={classes.middleContent}>
              <Box className="ledSelectArea">
                {ledServiceOtherConf && <LedSelectAreaGrand ledSelectAreaConf={ledServiceOtherConf} />}
              </Box>
              <Box>
                <Box className="ledSetting">
                  <Box className={classes.baseFontSize}>設定内容</Box>
                  <SelectButton className="settingButton" disabled arrowSize="0px" isSelected={false}>
                    {ledServiceOtherConf?.ledSetting &&
                      getClassificationName(CLSFCN.LED_SETTING.CATEGORY_CD, ledServiceOtherConf?.ledSetting)}
                  </SelectButton>
                </Box>
                <Box className="ledDirecting">
                  <Box className={classes.baseFontSize}>演出内容</Box>
                  <SelectButton className="settingButton" disabled arrowSize="0px" isSelected={false}>
                    {ledServiceOtherConf?.ledDirecting &&
                      getClassificationName(CLSFCN.LED_DIRECTING.CATEGORY_CD, ledServiceOtherConf?.ledDirecting)}
                  </SelectButton>
                </Box>
                <Box className="ledDirecting">
                  <Box className={classes.baseFontSize}>エコモード</Box>
                  <SelectButton className="settingButton" disabled arrowSize="0px" isSelected={false}>
                    {ledServiceOtherConf?.ecoType &&
                      getClassificationName(CLSFCN.LED_ECO_TYPE.CATEGORY_CD, ledServiceOtherConf?.ecoType)}
                  </SelectButton>
                </Box>
                <Box className="middleRightArea">
                  <Box className="ecoMode">
                    <Box className={classes.baseFontSize}>プレイ時もエコモード継続</Box>
                    <Box className={classes.onOffValue}>
                      {ledServiceOtherConf?.keepEcoType
                        ? getClassificationName(CLSFCN.ON_OFF.CATEGORY_CD, ledServiceOtherConf?.keepEcoType)
                        : 'OFF'}
                    </Box>
                  </Box>
                  <Box className="ecoMode">
                    <Box className={classes.baseFontSize}>景品補充アラート</Box>
                    <Box className={classes.onOffValue}>
                      {ledServiceOtherConf?.giftRefillWaiting
                        ? getClassificationName(CLSFCN.ON_OFF.CATEGORY_CD, ledServiceOtherConf?.giftRefillWaiting)
                        : 'OFF'}
                    </Box>
                  </Box>
                </Box>
              </Box>
            </Box>
          </Box>
        </MachineConfContent>
      </Layout>
    </ScreenContext.Provider>
  );
};

export default LedConfGrand;
