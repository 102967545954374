import React, { useMemo, useContext } from 'react';
import { Stage, Layer, Text, Circle, Group, Rect, Line } from 'react-konva';
import { XyAreaTypeGrand } from 'types/machineConf/areaType';
import MachineConfContext from 'Contexts/MachineConf/MachineConfContext';
import COMMON from 'constants/common';
import { toScreenPos } from 'utils/machineConf.helper';
import { themeColor } from 'constants/theme';

/* ************ constant ************ */
const ICON_SIZE = 52;
const XY_AREA_SIZE = 360;
const PRIZE_AREA_MAX = 100;

/* ************ Component ************ */
const XyAreaGrand: React.FC<XyAreaTypeGrand> = ({
  enableCustomHome,
  // H/Eの位置（画面上に表示される0~100の座標で指定(プライズ機座標系)）
  homePos,
  customHomePos,
  endPos,
}) => {
  // アイコンとエリアのサイズからkonvaによるアイコンの操作範囲を定義
  const width = XY_AREA_SIZE + ICON_SIZE;
  const height = XY_AREA_SIZE + ICON_SIZE;
  const iconHalfSize = ICON_SIZE / 2;
  const { leftRight } = useContext(MachineConfContext); //
  const isLeft = leftRight === COMMON.LEFT_RIGHT.LEFT;
  const INITIAL_POSITION_PARAMS = {
    screenAreaSize: XY_AREA_SIZE,
    panelAreaSize: PRIZE_AREA_MAX,
    isLeft,
  };

  // useMemoでポジション変更時のみ変数を設定する。理由不明だがpropの値は利用できない。
  const areaHomePos = useMemo(
    () =>
      toScreenPos({
        ...homePos,
        ...INITIAL_POSITION_PARAMS,
      }),
    [homePos],
  );
  const areaEndPos = useMemo(
    () =>
      toScreenPos({
        ...endPos,
        ...INITIAL_POSITION_PARAMS,
      }),
    [endPos],
  );
  const areaCustomHomePos = useMemo(
    () =>
      toScreenPos({
        ...customHomePos,
        ...INITIAL_POSITION_PARAMS,
      }),
    [customHomePos],
  );

  /* ************ Event ************ */

  return (
    <Stage width={width} height={height}>
      {/* Layer=エリアはアイコンのサイズ分配置する座標をずらす。 */}
      <Layer x={iconHalfSize} y={iconHalfSize} width={XY_AREA_SIZE} height={XY_AREA_SIZE}>
        {/* Layer内の要素はLayerの左上を(x,y)=(0,0)として座標を指定する */}
        {/* 下に定義した要素が手前に表示されます */}

        {/* マスク（上段、中段左、中段右、下段に分けて描画） */}
        {/* マスク 上段 高さ：上から～ENDアイコン、幅：左から右まで */}
        <Rect x={0} y={0} width={XY_AREA_SIZE} height={areaEndPos.y} fill="black" opacity={0.5} />
        {/* マスク 中段左 高さ：エンドアイコン～ホームアイコン、幅：左側からホームアイコンまたはエリアアイコンまで */}
        <Rect
          x={0}
          y={areaEndPos.y}
          width={isLeft ? areaHomePos.x : areaEndPos.x}
          height={areaHomePos.y - areaEndPos.y}
          fill="black"
          opacity={0.5}
        />
        {/* マスク 中段右 高さ：エンドアイコン～ホームアイコン、幅：エンドアイコンまたはホームアイコンから右端まで */}
        <Rect
          x={isLeft ? areaEndPos.x : areaHomePos.x}
          y={areaEndPos.y}
          width={XY_AREA_SIZE - (isLeft ? areaEndPos.x : areaHomePos.x)}
          height={areaHomePos.y - areaEndPos.y}
          fill="black"
          opacity={0.5}
        />
        {/* マスク 下段 高さ：下から～ホームアイコン、幅：左から右まで */}
        <Rect
          x={0}
          y={areaHomePos.y}
          width={XY_AREA_SIZE}
          height={XY_AREA_SIZE - areaHomePos.y}
          fill="black"
          opacity={0.5}
        />

        {/* ピンクの枠 */}
        <Rect
          x={0}
          y={0}
          width={XY_AREA_SIZE}
          height={XY_AREA_SIZE}
          stroke={themeColor.grandDarkColor}
          strokeWidth={3}
        />

        {/* H，Eに交差する点線 */}
        <Line
          points={[areaEndPos.x, 0, areaEndPos.x, XY_AREA_SIZE]}
          dash={[8, 8]}
          dashOffset={8}
          stroke="#3086FF"
          strokeWidth={4}
        />
        <Line
          points={[0, areaEndPos.y, XY_AREA_SIZE, areaEndPos.y]}
          dash={[8, 8]}
          dashOffset={8}
          stroke="#3086FF"
          strokeWidth={4}
        />
        <Line points={[areaHomePos.x, 0, areaHomePos.x, XY_AREA_SIZE]} dash={[8, 8]} stroke="#FF3E3E" strokeWidth={4} />
        <Line points={[0, areaHomePos.y, XY_AREA_SIZE, areaHomePos.y]} dash={[8, 8]} stroke="#FF3E3E" strokeWidth={4} />
        {/* Homeアイコン */}
        <Group x={areaHomePos.x} y={areaHomePos.y}>
          <Circle radius={iconHalfSize} fill="#FF3E3E" stroke="white" strokeWidth={3} />
          <Text
            width={ICON_SIZE}
            height={ICON_SIZE}
            x={-iconHalfSize}
            y={-iconHalfSize}
            text="H"
            fontSize={22}
            fill="white"
            align="center"
            verticalAlign="middle"
          />
        </Group>

        {/* customHomeアイコン */}
        {enableCustomHome && (
          <Group x={areaCustomHomePos.x} y={areaCustomHomePos.y}>
            <Circle radius={iconHalfSize} fill="#FFB54E" stroke="white" strokeWidth={3} />
            <Text
              width={ICON_SIZE}
              height={ICON_SIZE}
              x={-iconHalfSize}
              y={-iconHalfSize}
              text="HP"
              fontSize={22}
              fill="white"
              align="center"
              verticalAlign="middle"
            />
          </Group>
        )}

        {/* Endアイコン */}
        {/* draggableを指定するとx,yは初期位置としてのみ使用する */}
        <Group x={areaEndPos.x} y={areaEndPos.y}>
          <Circle radius={iconHalfSize} fill="#3086FF" stroke="white" strokeWidth={3} />
          <Text
            width={ICON_SIZE}
            height={ICON_SIZE}
            x={-iconHalfSize}
            y={-iconHalfSize}
            text="E"
            fontSize={22}
            fill="white"
            align="center"
            verticalAlign="middle"
          />
        </Group>
      </Layer>
    </Stage>
  );
};

export default XyAreaGrand;
