import COMMON from 'constants/common';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import Layout from 'components/system/layouts/Layout';
import ScreenContext from 'Contexts/Common/ScreenContext';
import MachineConfContent from 'components/machineConf/organisms/contents/MachineConfContent';
import { Box, makeStyles } from '@material-ui/core';
import { ReturnButton } from 'components/system/atoms';
import redirectLinks from 'constants/redirectLinks';
import { useHistory, useLocation } from 'react-router-dom';
import { themeColor } from 'constants/theme';
import descriptionDownOff from 'assets/images/messages/descriptionDownOff.svg';
import descriptionDownOn from 'assets/images/messages/descriptionDownOn.svg';
import descriptionUpOff from 'assets/images/messages/descriptionUpOff.svg';
import descriptionUpOn from 'assets/images/messages/descriptionUpOn.svg';
import operationDownOff from 'assets/images/messages/operationDownOff.svg';
import operationDownOn from 'assets/images/messages/operationDownOn.svg';
import operationUpOff from 'assets/images/messages/operationUpOff.svg';
import operationUpOn from 'assets/images/messages/operationUpOn.svg';
import UrlHelper from 'utils/url.helper';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import { handleCallSystemError } from 'redux/slices/commonSlice';
import ERROR_CODE from 'constants/errorCode';
import CLSFCN from 'constants/classification';
import { SaveDetailInfoType } from 'types/machineConf/stationSelectType';

/* ************ Context ************ */
const ScreenContextValue = {
  title: 'メッセージ表示設定',
  screenId: COMMON.SCREEN_ID.MESSAGE_CONF_GRAND,
  currentMenu: COMMON.MENU.MACHINE_CONF,
  disableContents: true,
};

/* ************ Style ************ */
const useStyles = makeStyles(() => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    minWidth: '1080px',
    minHeight: '563px',
    margin: '18px 24px',
  },
  topContent: {
    display: 'flex',
    alignItems: 'center',
    '& .description': {
      fontSize: '23px',
      marginLeft: '16px',
    },
  },
  middleContent: {
    display: 'flex',
    position: 'relative',
    marginTop: '20px',
    justifyContent: 'center',
    '& .catcherOperation': {
      width: '880px',
      height: '275px',
    },
    '& .catcherOperationBottom': {
      width: '880px',
      height: '159px',
    },
    '& .title': {
      fontSize: '23px',
      lineHeight: '28px',
      textAlign: 'center',
      marginBottom: '10px',
    },
    '& .content': {
      display: 'flex',
      justifyContent: 'space-between',
    },
    '& .row': {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'center',
    },
    '& .toggleArea': {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      width: '256px',
      height: '88px',
      borderWidth: '2px',
      borderStyle: 'solid',
      borderRadius: '4px',
      padding: '20px 30px 20px 28px',
      '&.movingShift': {
        border: 'none',
      },
    },
    '& .toggleAreaLarge': {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      width: '386px',
      height: '88px',
      borderWidth: '2px',
      borderStyle: 'solid',
      borderRadius: '4px',
      padding: '20px 46px 20px 28px',
      '&.movingShift': {
        border: 'none',
      },
    },
    '& .toggleMargin': {
      marginBottom: '28px',
    },
    '& .toggleMarginRight': {
      marginRight: '16px',
    },
    '& .toggleName': {
      fontSize: '20px',
      marginRight: '8px',
      '&.small': {
        fontSize: '19px',
      },
    },
  },
  grandStyle: {
    borderColor: themeColor.grandDarkColor,
  },
  baseArea: {
    backgroundColor: '#FFF',
    borderWidth: '2px',
    borderStyle: 'solid',
    borderRadius: '8px',
    padding: '16px 40px',
    borderColor: themeColor.grandDarkColor,
  },
  downArrowIcon: {
    margin: '4px auto',
  },
  polyLineIconMedium: {
    position: 'absolute',
    top: '60px',
    left: '301px',
  },
  switchStatus: {
    fontSize: 21,
  },
  boxImage: {
    width: 260,
    height: 217,
    marginTop: '-15px',
    marginRight: 25,
  },
}));

interface PartRefillMsgConf {
  descriptionMsg: boolean;
  motionMsg: boolean;
  giftRefillMsg: boolean;
  giftGetMsg: boolean;
  upDown: boolean;
  staffInfoMsg: boolean;
}

/* ******** Main Component ******** */
/**
 *
 * @returns {React.FC} - メッセージ表示設定
 *
 */
const MessageConfGrand: React.FC = () => {
  const classes = useStyles();
  const history = useHistory();
  const location = useLocation();
  const [savedConfProps] = useState(location.state !== undefined ? (location.state as SaveDetailInfoType) : null);
  const dispatch = useAppDispatch();
  const partRefillMsgConf = useAppSelector((state) => state.machineConf.machineGrandConf.partRefillMsgConf);
  const [tempPartRefillMsgConf, setTempPartRefillMsgConf] = React.useState<PartRefillMsgConf>({
    descriptionMsg: false,
    motionMsg: false,
    giftRefillMsg: false,
    giftGetMsg: false,
    upDown: false,
    staffInfoMsg: false,
  });
  const [partitionImage, setPartitionImage] = React.useState('');
  const interval = useRef<NodeJS.Timeout | null>(null);

  // 戻るボタン
  const handleClickReturn = () => {
    history.push(
      UrlHelper.convertQueryUrlFrontEnd(redirectLinks.MCONF_GRAND_HOME_DETAIL, {
        selectConfId: 'none',
      }),
    );
  };

  useEffect(() => {
    if (!partRefillMsgConf) {
      dispatch(handleCallSystemError({ errorNo: ERROR_CODE.NOT_FOUND }));
    }
    setTempPartRefillMsgConf({
      descriptionMsg: partRefillMsgConf?.descriptionMsg === CLSFCN.ON_OFF.ON,
      motionMsg: partRefillMsgConf?.motionMsg === CLSFCN.ON_OFF.ON,
      giftRefillMsg: partRefillMsgConf?.giftRefillMsg === CLSFCN.ON_OFF.ON,
      giftGetMsg: partRefillMsgConf?.giftGetMsg === CLSFCN.ON_OFF.ON,
      upDown: partRefillMsgConf?.upDown === COMMON.UP_DOWN.UP,
      staffInfoMsg: partRefillMsgConf?.staffInfoMsg === CLSFCN.ON_OFF.ON,
    });
  }, []);

  const handleLoadImage = () => {
    if (tempPartRefillMsgConf.motionMsg && !tempPartRefillMsgConf.descriptionMsg) {
      if (tempPartRefillMsgConf.upDown) {
        setPartitionImage((prevState) => (prevState === operationUpOn ? descriptionUpOff : operationUpOn));
      } else {
        setPartitionImage((prevState) => (prevState === operationDownOn ? descriptionDownOff : operationDownOn));
      }
    }
    if (tempPartRefillMsgConf.motionMsg && tempPartRefillMsgConf.descriptionMsg) {
      if (tempPartRefillMsgConf.upDown) {
        setPartitionImage((prevState) => (prevState === operationUpOn ? descriptionUpOn : operationUpOn));
      } else {
        setPartitionImage((prevState) => (prevState === operationDownOn ? descriptionDownOn : operationDownOn));
      }
    }
    if (!tempPartRefillMsgConf.motionMsg && !tempPartRefillMsgConf.descriptionMsg) {
      if (tempPartRefillMsgConf.upDown) {
        setPartitionImage((prevState) => (prevState === operationUpOff ? descriptionUpOff : operationUpOff));
      } else {
        setPartitionImage((prevState) => (prevState === operationDownOff ? descriptionDownOff : operationDownOff));
      }
    }
    if (!tempPartRefillMsgConf.motionMsg && tempPartRefillMsgConf.descriptionMsg) {
      if (tempPartRefillMsgConf.upDown) {
        setPartitionImage((prevState) => (prevState === operationUpOff ? descriptionUpOn : operationUpOff));
      } else {
        setPartitionImage((prevState) => (prevState === operationDownOff ? descriptionDownOn : operationDownOff));
      }
    }
  };
  useEffect(() => {
    handleLoadImage();
    interval.current = setInterval(() => {
      handleLoadImage();
    }, 3000);

    return () => {
      if (interval.current) {
        clearInterval(interval.current);
      }
    };
  }, [tempPartRefillMsgConf]);

  const renderFlag = useCallback((flag: boolean) => (flag ? 'ON' : 'OFF'), []);

  return (
    <ScreenContext.Provider value={ScreenContextValue}>
      <Layout>
        <MachineConfContent savedConfProps={savedConfProps}>
          <Box className={classes.root}>
            <Box className={classes.topContent}>
              <ReturnButton onClick={handleClickReturn}>
                機器設定
                <br />
                詳細
              </ReturnButton>
              <Box className="description">
                アトラクト画面での「サポートパーテーションのメッセージ」
                <br />
                「景品補充」に関わる設定内容です。
              </Box>
            </Box>
            <Box className={classes.middleContent}>
              <Box className={`${classes.baseArea} catcherOperation`}>
                <Box className="title">サポートパーテーション</Box>
                <Box className="content">
                  <Box className="col">
                    <Box className={`${classes.grandStyle} toggleAreaLarge toggleMargin`}>
                      <Box className="toggleName">
                        サポートパーテーション
                        <br />
                        説明メッセージ
                      </Box>
                      <Box className={classes.switchStatus}>{renderFlag(tempPartRefillMsgConf.descriptionMsg)}</Box>
                    </Box>
                    <Box className={`${classes.grandStyle} toggleAreaLarge`}>
                      <Box className="toggleName">
                        サポートパーテーション
                        <br />
                        動作中メッセージ
                      </Box>
                      <Box className={classes.switchStatus}>{renderFlag(tempPartRefillMsgConf.motionMsg)}</Box>
                    </Box>
                  </Box>
                  <Box className={classes.boxImage}>
                    <img src={partitionImage} alt="partitionImage" className={classes.downArrowIcon} />
                  </Box>
                </Box>
              </Box>
            </Box>
            <Box className={classes.middleContent}>
              <Box className={`${classes.baseArea} catcherOperationBottom`}>
                <Box className="title">景品補充</Box>
                <Box>
                  <Box className="row">
                    <Box className={`${classes.grandStyle} toggleArea toggleMarginRight`}>
                      <Box className="toggleName">
                        景品補充機能
                        <br />
                        メッセージ
                      </Box>
                      <Box className={classes.switchStatus}>{renderFlag(tempPartRefillMsgConf.giftRefillMsg)}</Box>
                    </Box>
                    <Box className={`${classes.grandStyle} toggleArea toggleMarginRight`}>
                      <Box className="toggleName">
                        景品獲得後
                        <br />
                        補充メッセージ
                      </Box>
                      <Box className={classes.switchStatus}>{renderFlag(tempPartRefillMsgConf.giftGetMsg)}</Box>
                    </Box>
                    <Box className={`${classes.grandStyle} toggleArea`}>
                      <Box className="toggleName">
                        スタッフ向け
                        <br />
                        案内メッセージ
                      </Box>
                      <Box className={classes.switchStatus}>{renderFlag(tempPartRefillMsgConf.staffInfoMsg)}</Box>
                    </Box>
                  </Box>
                </Box>
              </Box>
            </Box>
          </Box>
        </MachineConfContent>
      </Layout>
    </ScreenContext.Provider>
  );
};

export default MessageConfGrand;
