import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useAppDispatch } from 'redux/hooks';
import {
  handleClearSystemError,
  setAuth,
  setClassificationList,
  setDeviceInfo,
  setUserInfo,
} from 'redux/slices/commonSlice';
import redirectLinks from 'constants/redirectLinks';
import HttpConnection from 'utils/httpConnection';
import { REST_API } from 'constants/apiUrls';
import { ApplicationInitialType, UserInfoType } from 'types/common/commonType';
import CLSFCN from 'constants/classification';
import COMMON from 'constants/common';
import { getDeviceInfo } from 'utils/common.helper';

// 画面の利用権限チェック
const checkEnableScreen = (userInfo: UserInfoType): boolean => {
  const { enableScreenIdList } = userInfo;

  for (let i = 0; i < enableScreenIdList.length; i += 1) {
    const screenId = enableScreenIdList[i];
    // TOP画面の場合有効
    if (screenId === COMMON.SCREEN_ID.TOP) return true;
  }
  return false;
};

const AuthCheck: React.FC = () => {
  const dispatch = useAppDispatch();
  const history = useHistory();

  const checkAuth = async () => {
    // 認証エラーチェック => セッションタイムアウトエラー ログイン、エラー画面は処理しない。
    // セッション情報の取得を試みる。
    const http = new HttpConnection({ dispatch });
    try {
      const data = await http.get<ApplicationInitialType>(REST_API.COMMON.GET_AUTH);

      if (!data.userInfo || !data.userInfo.role || !checkEnableScreen(data.userInfo)) {
        // ユーザ情報がない場合はセッションタイムアウトエラー
        history.push(redirectLinks.COMMON_SESSION_TIMEOUT_ERROR);
        return;
      }
      // ユーザ情報を復元できた場合はTOP画面へ遷移させる。
      const { userInfo } = data;
      // システム管理者、本部系ユーザ以外は現在店舗に所属店舗を設定
      if (![CLSFCN.ROLE.SYSTEM_ADMIN, CLSFCN.ROLE.HEAD_ADMIN, CLSFCN.ROLE.HEAD_VIEWER].includes(data.userInfo.role)) {
        userInfo.currentStoreId = userInfo.storeId;
        userInfo.currentStoreName = userInfo.storeName;
      }
      // システム管理者以外は現在本部に所属本部を設定
      if (![CLSFCN.ROLE.SYSTEM_ADMIN].includes(data.userInfo.role)) {
        userInfo.currentHeadQId = userInfo.headquartersId;
        userInfo.currentHeadQName = userInfo.headquartersName;
      }
      dispatch(setUserInfo(userInfo));
      dispatch(setDeviceInfo(getDeviceInfo()));
      if (data.classificationList) {
        dispatch(setClassificationList(data.classificationList));
      }
      dispatch(setAuth());
      dispatch(handleClearSystemError());
      history.push(redirectLinks.HOME_TOP);
    } catch (error) {
      // システムエラー画面へ
      history.push(redirectLinks.COMMON_SYSTEM_ERROR);
    }
  };

  // 初回のみ権限チェックを実行する。
  useEffect(() => {
    checkAuth();
  }, []);

  return <></>;
};

export default AuthCheck;
