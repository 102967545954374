import { Box, makeStyles } from '@material-ui/core';
import COMMON from 'constants/common';
import React, { useCallback, useMemo, useState } from 'react';
import DeleteButton from 'components/system/atoms/buttons/DeleteButton';
import prizeMachineIcon from 'assets/images/prizeMachineIcon.png';
import prizeMachineJackIcon from 'assets/images/prizeMachineIconJack.png';
import grandMachine from 'assets/images/grandMachine.svg';
import pointer1 from 'assets/images/pointer1.svg';
import pointer2 from 'assets/images/pointer2.svg';
import pointer3 from 'assets/images/pointer3.svg';
import pointer4 from 'assets/images/pointer4.svg';
import pointer2Jack from 'assets/images/pointer2Jack.svg';
import pointer4Jack from 'assets/images/pointer4Jack.svg';
import { ledSelectAreaType } from 'types/machineConf/ledSelectAreaType';

import { getClassificationName, getClsfcnByCategory, getSortedClsfcnByCategory } from 'utils/common.helper';
import CLSFCN from 'constants/classification';
import { ledSelectButtonType } from 'types/machineConf/ledSelectButtontype';
import SelectList, { INITIAL_SELECT_LIST } from 'components/support/organisms/supports/SelectList';
import { SelectListType } from 'types/support/supportType';
import MESSAGES from 'constants/messages';
import MsgHelper from 'utils/message.helper';
import ColorEffectSelectArea from './ColorEffectSelectArea';

/* ************ Style ************ */
const useStyles = makeStyles(() => ({
  content: {
    display: 'flex',
    padding: '1px',
  },
  settingArea: {
    '& > *': {
      marginBottom: '8px',
    },
    '& > *:last-child': {
      marginBottom: '0px',
    },
    '&.grayOut': {
      position: 'relative',
    },
  },
  imgArea: {
    position: 'relative',
    textAlign: 'center',
    width: '205px',
    '& .prizeMachine': {
      margin: '0 3px',
      display: 'inline',
    },
    '& .prizeGrandMachine': {
      display: 'inline',
    },
    '& .leftPointer': {
      position: 'absolute',
      left: '0',
    },
    '& .rightPointer': {
      position: 'absolute',
      right: '0',
      transform: 'scaleX(-1)',
    },
    '& .pointer1': {
      top: '68px',
    },
    '& .pointer2': {
      top: '188px',
    },
    '& .pointer2Jack': {
      top: '178px',
    },
    '& .pointer3': {
      top: '232px',
    },
    '& .pointer4': {
      top: '274px',
    },
  },
  resetButton: {
    width: '116px',
    height: '48px',
    fontSize: '21px',
    marginBottom: '88px',
  },
  resetGrandButton: {
    width: '116px',
    height: '48px',
    fontSize: '21px',
  },
  disableInputArea: {
    position: 'absolute',
    height: '100%',
    width: '100%',
    background: 'rgb(128 128 128 / 50%)',
    borderRadius: '8px',
    zIndex: 100,
    top: '0px',
    left: '0px',
  },
  selectAreaEmpty: {
    height: 110,
  },
}));

/* ******** Constants ******** */
const INPUT_NAMES = {
  LEFT: {
    COLOR4: 'lLedColor4',
    EFFECT4: 'lLedEffect4',
    COLOR3: 'lLedColor3',
    EFFECT3: 'lLedEffect3',
    COLOR2: 'lLedColor2',
    EFFECT2: 'lLedEffect2',
    COLOR1: 'lLedColor1',
    EFFECT1: 'lLedEffect1',
  },
  RIGHT: {
    COLOR4: 'rLedColor4',
    EFFECT4: 'rLedEffect4',
    COLOR3: 'rLedColor3',
    EFFECT3: 'rLedEffect3',
    COLOR2: 'rLedColor2',
    EFFECT2: 'rLedEffect2',
    COLOR1: 'rLedColor1',
    EFFECT1: 'rLedEffect1',
  },
  LEFT_GRAND: {
    COLOR4: 'lLedGrandColor4',
    EFFECT4: 'lLedGrandEffect4',
    COLOR3: 'lLedGrandColor3',
    EFFECT3: 'lLedGrandEffect3',
    COLOR2: 'lLedGrandColor2',
    EFFECT2: 'lLedGrandEffect2',
    COLOR1: 'lLedGrandColor1',
    EFFECT1: 'lLedGrandEffect1',
  },
  RIGHT_GRAND: {
    COLOR3: 'rLedGrandColor3',
    EFFECT3: 'rLedGrandEffect3',
    COLOR2: 'rLedGrandColor2',
    EFFECT2: 'rLedGrandEffect2',
    COLOR1: 'rLedGrandColor1',
    EFFECT1: 'rLedGrandEffect1',
  },
};

/* ******** Main Component ******** */
/**
 *
 * @Params leftRight, ledSelectAreaConf, onChange
 * @returns {React.FC} - LED 設定エリア
 *
 */
const LedSelectArea: React.FC<ledSelectAreaType> = React.memo((props) => {
  const { leftRight, ledSelectAreaConf, onChange, disabled = false, settingTab = COMMON.SETTING_TAB.CLENA3 } = props;
  const classes = useStyles();
  const isJack = settingTab === COMMON.SETTING_TAB.JACK;
  const isGrand = settingTab === COMMON.SETTING_TAB.GRAND;
  const isClena3 = settingTab === COMMON.SETTING_TAB.CLENA3;
  const areaColor = isJack ? 'grayOut' : '';

  // Local State
  const [selectList, setSelectList] = useState<SelectListType>(INITIAL_SELECT_LIST.selectList);

  // DataSource
  const ledColorDataSource = useMemo(
    () =>
      isGrand
        ? getSortedClsfcnByCategory(CLSFCN.LED_GRAND_COLOR.CATEGORY_CD)
        : getClsfcnByCategory(CLSFCN.LED_COLOR.CATEGORY_CD),
    [isGrand],
  );
  const ledEffectDataSource = useMemo(
    () =>
      isGrand
        ? getClsfcnByCategory(CLSFCN.LED_EFFECT_GRAND.CATEGORY_CD)
        : getClsfcnByCategory(CLSFCN.LED_EFFECT.CATEGORY_CD),
    [isGrand],
  );

  /* ******** Events ******** */
  const changeEffect = (colorValue: string, effectValue: string): string => {
    if (
      // 設定タブの状態・選択した色に応じて、ペアの効果を「無し（点灯）」に変更
      (isGrand && [CLSFCN.LED_GRAND_COLOR.OFF, CLSFCN.LED_GRAND_COLOR.SAMPLE_PATTERN].includes(colorValue)) ||
      colorValue === CLSFCN.LED_COLOR.BASE_COLOR
    ) {
      return CLSFCN.LED_EFFECT.NONE_LIGHTING;
    }

    // 上記条件以外の場合、ペアの効果はそのまま
    return effectValue;
  };

  // リセット
  const handleClickReset = (): void => {
    const newConf = {
      ...ledSelectAreaConf,
      lLedColor4: CLSFCN.LED_COLOR.BASE_COLOR,
      lLedEffect4: CLSFCN.LED_EFFECT.NONE_LIGHTING,
      lLedColor3: CLSFCN.LED_COLOR.BASE_COLOR,
      lLedEffect3: CLSFCN.LED_EFFECT.NONE_LIGHTING,
      lLedColor2: CLSFCN.LED_COLOR.BASE_COLOR,
      lLedEffect2: CLSFCN.LED_EFFECT.NONE_LIGHTING,
      lLedColor1: CLSFCN.LED_COLOR.BASE_COLOR,
      lLedEffect1: CLSFCN.LED_EFFECT.NONE_LIGHTING,
      rLedColor4: CLSFCN.LED_COLOR.BASE_COLOR,
      rLedEffect4: CLSFCN.LED_EFFECT.NONE_LIGHTING,
      rLedColor3: CLSFCN.LED_COLOR.BASE_COLOR,
      rLedEffect3: CLSFCN.LED_EFFECT.NONE_LIGHTING,
      rLedColor2: CLSFCN.LED_COLOR.BASE_COLOR,
      rLedEffect2: CLSFCN.LED_EFFECT.NONE_LIGHTING,
      rLedColor1: CLSFCN.LED_COLOR.BASE_COLOR,
      rLedEffect1: CLSFCN.LED_EFFECT.NONE_LIGHTING,
      // left grand
      lLedGrandColor4: CLSFCN.LED_GRAND_COLOR.SAMPLE_PATTERN,
      lLedGrandEffect4: CLSFCN.LED_EFFECT.NONE_LIGHTING,
      lLedGrandColor3: CLSFCN.LED_GRAND_COLOR.SAMPLE_PATTERN,
      lLedGrandEffect3: CLSFCN.LED_EFFECT.NONE_LIGHTING,
      lLedGrandColor2: CLSFCN.LED_GRAND_COLOR.SAMPLE_PATTERN,
      lLedGrandEffect2: CLSFCN.LED_EFFECT.NONE_LIGHTING,
      lLedGrandColor1: CLSFCN.LED_GRAND_COLOR.SAMPLE_PATTERN,
      lLedGrandEffect1: CLSFCN.LED_EFFECT.NONE_LIGHTING,
      // right grand
      rLedGrandColor3: CLSFCN.LED_GRAND_COLOR.SAMPLE_PATTERN,
      rLedGrandEffect3: CLSFCN.LED_EFFECT.NONE_LIGHTING,
      rLedGrandColor2: CLSFCN.LED_GRAND_COLOR.SAMPLE_PATTERN,
      rLedGrandEffect2: CLSFCN.LED_EFFECT.NONE_LIGHTING,
      rLedGrandColor1: CLSFCN.LED_GRAND_COLOR.SAMPLE_PATTERN,
      rLedGrandEffect1: CLSFCN.LED_EFFECT.NONE_LIGHTING,
    };
    onChange(newConf);
  };

  // on click pulldown items
  const handleClickSelectList = useCallback(
    (ev: React.MouseEvent<HTMLButtonElement>, id: string, oldValue: string) => {
      let dataSource;
      let title;
      let value;
      let placement: 'left' | 'left-start' | 'left-end' | 'right' | 'right-start' | 'right-end';
      let effectInputName: string | null = null;

      if (
        [
          INPUT_NAMES.LEFT.COLOR1,
          INPUT_NAMES.LEFT.COLOR2,
          INPUT_NAMES.LEFT.COLOR3,
          INPUT_NAMES.LEFT.COLOR4,
          INPUT_NAMES.RIGHT.COLOR1,
          INPUT_NAMES.RIGHT.COLOR2,
          INPUT_NAMES.RIGHT.COLOR3,
          INPUT_NAMES.RIGHT.COLOR4,
          INPUT_NAMES.LEFT_GRAND.COLOR1,
          INPUT_NAMES.LEFT_GRAND.COLOR2,
          INPUT_NAMES.LEFT_GRAND.COLOR3,
          INPUT_NAMES.LEFT_GRAND.COLOR4,
          INPUT_NAMES.RIGHT_GRAND.COLOR1,
          INPUT_NAMES.RIGHT_GRAND.COLOR2,
          INPUT_NAMES.RIGHT_GRAND.COLOR3,
        ].includes(id)
      ) {
        dataSource = ledColorDataSource;
        title = MsgHelper.messageFormat(MESSAGES.INF234, '色');
      } else {
        dataSource = ledEffectDataSource;
        title = MsgHelper.messageFormat(MESSAGES.INF234, '効果');
      }
      switch (id) {
        case INPUT_NAMES.LEFT.COLOR1:
        case INPUT_NAMES.LEFT.COLOR2:
        case INPUT_NAMES.LEFT.EFFECT1:
        case INPUT_NAMES.LEFT.EFFECT2:
        case INPUT_NAMES.RIGHT.COLOR1:
        case INPUT_NAMES.RIGHT.COLOR2:
        case INPUT_NAMES.RIGHT.EFFECT1:
        case INPUT_NAMES.RIGHT.EFFECT2:
        case INPUT_NAMES.LEFT_GRAND.COLOR1:
        case INPUT_NAMES.LEFT_GRAND.COLOR2:
        case INPUT_NAMES.LEFT_GRAND.EFFECT1:
        case INPUT_NAMES.LEFT_GRAND.EFFECT2:
        case INPUT_NAMES.RIGHT_GRAND.COLOR1:
        case INPUT_NAMES.RIGHT_GRAND.COLOR2:
        case INPUT_NAMES.RIGHT_GRAND.EFFECT1:
        case INPUT_NAMES.RIGHT_GRAND.EFFECT2:
          placement = 'right-end';
          break;
        default:
          placement = 'right';
      }

      if (id === INPUT_NAMES.LEFT.COLOR1) {
        value = ledSelectAreaConf.lLedColor1;
        effectInputName = INPUT_NAMES.LEFT.EFFECT1;
      } else if (id === INPUT_NAMES.LEFT.COLOR2) {
        value = ledSelectAreaConf.lLedColor2;
        effectInputName = INPUT_NAMES.LEFT.EFFECT2;
      } else if (id === INPUT_NAMES.LEFT.COLOR3) {
        value = ledSelectAreaConf.lLedColor3;
        effectInputName = INPUT_NAMES.LEFT.EFFECT3;
      } else if (id === INPUT_NAMES.LEFT.COLOR4) {
        value = ledSelectAreaConf.lLedColor4;
        effectInputName = INPUT_NAMES.LEFT.EFFECT4;
      } else if (id === INPUT_NAMES.LEFT.EFFECT1) {
        value = ledSelectAreaConf.lLedEffect1;
      } else if (id === INPUT_NAMES.LEFT.EFFECT2) {
        value = ledSelectAreaConf.lLedEffect2;
      } else if (id === INPUT_NAMES.LEFT.EFFECT3) {
        value = ledSelectAreaConf.lLedEffect3;
      } else if (id === INPUT_NAMES.LEFT.EFFECT4) {
        value = ledSelectAreaConf.lLedEffect4;
      } else if (id === INPUT_NAMES.RIGHT.COLOR1) {
        value = ledSelectAreaConf.rLedColor1;
        effectInputName = INPUT_NAMES.RIGHT.EFFECT1;
      } else if (id === INPUT_NAMES.RIGHT.COLOR2) {
        value = ledSelectAreaConf.rLedColor2;
        effectInputName = INPUT_NAMES.RIGHT.EFFECT2;
      } else if (id === INPUT_NAMES.RIGHT.COLOR3) {
        value = ledSelectAreaConf.rLedColor3;
        effectInputName = INPUT_NAMES.RIGHT.EFFECT3;
      } else if (id === INPUT_NAMES.RIGHT.COLOR4) {
        value = ledSelectAreaConf.rLedColor4;
        effectInputName = INPUT_NAMES.RIGHT.EFFECT4;
      } else if (id === INPUT_NAMES.RIGHT.EFFECT1) {
        value = ledSelectAreaConf.rLedEffect1;
      } else if (id === INPUT_NAMES.RIGHT.EFFECT2) {
        value = ledSelectAreaConf.rLedEffect2;
      } else if (id === INPUT_NAMES.RIGHT.EFFECT3) {
        value = ledSelectAreaConf.rLedEffect3;
      } else if (id === INPUT_NAMES.RIGHT.EFFECT4) {
        value = ledSelectAreaConf.rLedEffect4;
      }
      // left grand
      else if (id === INPUT_NAMES.LEFT_GRAND.COLOR1) {
        value = ledSelectAreaConf.lLedGrandColor1;
        effectInputName = INPUT_NAMES.LEFT_GRAND.EFFECT1;
      } else if (id === INPUT_NAMES.LEFT_GRAND.COLOR2) {
        value = ledSelectAreaConf.lLedGrandColor2;
        effectInputName = INPUT_NAMES.LEFT_GRAND.EFFECT2;
      } else if (id === INPUT_NAMES.LEFT_GRAND.COLOR3) {
        value = ledSelectAreaConf.lLedGrandColor3;
        effectInputName = INPUT_NAMES.LEFT_GRAND.EFFECT3;
      } else if (id === INPUT_NAMES.LEFT_GRAND.COLOR4) {
        value = ledSelectAreaConf.lLedGrandColor4;
        effectInputName = INPUT_NAMES.LEFT_GRAND.EFFECT4;
      } else if (id === INPUT_NAMES.LEFT_GRAND.EFFECT1) {
        value = ledSelectAreaConf.lLedGrandEffect1;
      } else if (id === INPUT_NAMES.LEFT_GRAND.EFFECT2) {
        value = ledSelectAreaConf.lLedGrandEffect2;
      } else if (id === INPUT_NAMES.LEFT_GRAND.EFFECT3) {
        value = ledSelectAreaConf.lLedGrandEffect3;
      } else if (id === INPUT_NAMES.LEFT_GRAND.EFFECT4) {
        value = ledSelectAreaConf.lLedGrandEffect4;
      }
      // right grand
      else if (id === INPUT_NAMES.RIGHT_GRAND.COLOR1) {
        value = ledSelectAreaConf.rLedGrandColor1;
        effectInputName = INPUT_NAMES.RIGHT_GRAND.EFFECT1;
      } else if (id === INPUT_NAMES.RIGHT_GRAND.COLOR2) {
        value = ledSelectAreaConf.rLedGrandColor2;
        effectInputName = INPUT_NAMES.RIGHT_GRAND.EFFECT2;
      } else if (id === INPUT_NAMES.RIGHT_GRAND.COLOR3) {
        value = ledSelectAreaConf.rLedGrandColor3;
        effectInputName = INPUT_NAMES.RIGHT_GRAND.EFFECT3;
      } else if (id === INPUT_NAMES.RIGHT_GRAND.EFFECT1) {
        value = ledSelectAreaConf.rLedGrandEffect1;
      } else if (id === INPUT_NAMES.RIGHT_GRAND.EFFECT2) {
        value = ledSelectAreaConf.rLedGrandEffect2;
      } else if (id === INPUT_NAMES.RIGHT_GRAND.EFFECT3) {
        value = ledSelectAreaConf.rLedGrandEffect3;
      } else {
        return;
      }

      setSelectList({
        ...INITIAL_SELECT_LIST.selectList,
        open: true,
        onClick: (data: { value: string }, name: string) => {
          // 選択値が変化ない場合
          if (data.value === oldValue) return;
          let ledName = isGrand ? name.replace('Grand', '') : `${name.slice(0, 4)}Grand${name.slice(4)}`;
          switch (true) {
            // led left
            case name === INPUT_NAMES.LEFT.COLOR4:
              ledName = INPUT_NAMES.LEFT_GRAND.COLOR3;
              break;
            case name === INPUT_NAMES.LEFT.COLOR3:
              ledName = INPUT_NAMES.LEFT_GRAND.COLOR4;
              break;
            case name === INPUT_NAMES.LEFT.EFFECT4:
              ledName = INPUT_NAMES.LEFT_GRAND.EFFECT3;
              break;
            case name === INPUT_NAMES.LEFT.EFFECT3:
              ledName = INPUT_NAMES.LEFT_GRAND.EFFECT4;
              break;

            case name === INPUT_NAMES.LEFT_GRAND.COLOR4:
              ledName = INPUT_NAMES.LEFT.COLOR3;
              break;
            case name === INPUT_NAMES.LEFT_GRAND.COLOR3:
              ledName = INPUT_NAMES.LEFT.COLOR4;
              break;
            case name === INPUT_NAMES.LEFT_GRAND.EFFECT4:
              ledName = INPUT_NAMES.LEFT.EFFECT3;
              break;
            case name === INPUT_NAMES.LEFT_GRAND.EFFECT3:
              ledName = INPUT_NAMES.LEFT.EFFECT4;
              break;

            case name === INPUT_NAMES.RIGHT.COLOR4:
              ledName = INPUT_NAMES.RIGHT_GRAND.COLOR3;
              break;
            case name === INPUT_NAMES.RIGHT_GRAND.COLOR3:
              ledName = INPUT_NAMES.RIGHT.COLOR4;
              break;
            case name === INPUT_NAMES.RIGHT.EFFECT4:
              ledName = INPUT_NAMES.RIGHT_GRAND.EFFECT3;
              break;
            case name === INPUT_NAMES.RIGHT_GRAND.EFFECT3:
              ledName = INPUT_NAMES.RIGHT.EFFECT4;
              break;
            default:
              break;
          }
          const isNotUpdateColorAndEffect =
            // クレナ3・クレナ3ジャックの右側下から3段目を変更した場合は、クレナグランの項目を変更しない
            // （クレナグランでは右側下から4段目に相当するが、項目が存在しないため）
            name === INPUT_NAMES.RIGHT.COLOR3 ||
            name === INPUT_NAMES.RIGHT.EFFECT3 ||
            // クレナグランで「OFF（消灯）」を選択した場合は、クレナ3・クレナ3ジャックの項目は変更しない
            (isGrand && data.value === CLSFCN.LED_GRAND_COLOR.OFF);
          if (effectInputName) {
            let ledEffectName = isGrand
              ? effectInputName.replace('Grand', '')
              : `${effectInputName.slice(0, 4)}Grand${effectInputName.slice(4)}`;
            switch (true) {
              case effectInputName === INPUT_NAMES.LEFT.EFFECT4:
                ledEffectName = INPUT_NAMES.LEFT_GRAND.EFFECT3;
                break;
              case effectInputName === INPUT_NAMES.LEFT.EFFECT3:
                ledEffectName = INPUT_NAMES.LEFT_GRAND.EFFECT4;
                break;
              case effectInputName === INPUT_NAMES.LEFT_GRAND.EFFECT4:
                ledEffectName = INPUT_NAMES.LEFT.EFFECT3;
                break;
              case effectInputName === INPUT_NAMES.LEFT_GRAND.EFFECT3:
                ledEffectName = INPUT_NAMES.LEFT.EFFECT4;
                break;

              case effectInputName === INPUT_NAMES.RIGHT.EFFECT4:
                ledEffectName = INPUT_NAMES.RIGHT_GRAND.EFFECT3;
                break;
              case effectInputName === INPUT_NAMES.RIGHT_GRAND.EFFECT3:
                ledEffectName = INPUT_NAMES.RIGHT.EFFECT4;
                break;
              default:
                break;
            }
            let ledEffect = '0';
            if (effectInputName === INPUT_NAMES.LEFT.EFFECT1) ledEffect = ledSelectAreaConf.lLedEffect1;
            if (effectInputName === INPUT_NAMES.LEFT.EFFECT2) ledEffect = ledSelectAreaConf.lLedEffect2;
            if (effectInputName === INPUT_NAMES.LEFT.EFFECT3) ledEffect = ledSelectAreaConf.lLedEffect3;
            if (effectInputName === INPUT_NAMES.LEFT.EFFECT4) ledEffect = ledSelectAreaConf.lLedEffect4;
            if (effectInputName === INPUT_NAMES.RIGHT.EFFECT1) ledEffect = ledSelectAreaConf.rLedEffect1;
            if (effectInputName === INPUT_NAMES.RIGHT.EFFECT2) ledEffect = ledSelectAreaConf.rLedEffect2;
            if (effectInputName === INPUT_NAMES.RIGHT.EFFECT3) ledEffect = ledSelectAreaConf.rLedEffect3;
            if (effectInputName === INPUT_NAMES.RIGHT.EFFECT4) ledEffect = ledSelectAreaConf.rLedEffect4;
            if (effectInputName === INPUT_NAMES.LEFT_GRAND.EFFECT1) ledEffect = ledSelectAreaConf.lLedGrandEffect1;
            if (effectInputName === INPUT_NAMES.LEFT_GRAND.EFFECT2) ledEffect = ledSelectAreaConf.lLedGrandEffect2;
            if (effectInputName === INPUT_NAMES.LEFT_GRAND.EFFECT3) ledEffect = ledSelectAreaConf.lLedGrandEffect3;
            if (effectInputName === INPUT_NAMES.LEFT_GRAND.EFFECT4) ledEffect = ledSelectAreaConf.lLedGrandEffect4;
            if (effectInputName === INPUT_NAMES.RIGHT_GRAND.EFFECT1) ledEffect = ledSelectAreaConf.rLedGrandEffect1;
            if (effectInputName === INPUT_NAMES.RIGHT_GRAND.EFFECT2) ledEffect = ledSelectAreaConf.rLedGrandEffect2;
            if (effectInputName === INPUT_NAMES.RIGHT_GRAND.EFFECT3) ledEffect = ledSelectAreaConf.rLedGrandEffect3;
            const ledEffectValue = changeEffect(data.value, ledEffect);

            // 色を変更した場合
            onChange({
              ...ledSelectAreaConf,
              [name]: data.value,
              [effectInputName]: ledEffectValue,
              ...(isNotUpdateColorAndEffect
                ? {}
                : {
                    [ledName]: data.value,
                    [ledEffectName]: ledEffectValue,
                  }),
            });
          } else {
            // 効果を変更した場合
            onChange({
              ...ledSelectAreaConf,
              [name]: data.value,
              ...(isNotUpdateColorAndEffect
                ? {}
                : {
                    [ledName]: data.value,
                  }),
            });
          }
        },
        onClose: () => setSelectList(INITIAL_SELECT_LIST.selectList),
        anchorEL: ev.currentTarget,
        dataSource,
        name: id,
        placement,
        title,
        value,
      });
    },
    [ledSelectAreaConf, ledColorDataSource],
  );
  /* ************ Params ************ */
  const getColorParam = (inputName: string, value: string): ledSelectButtonType => ({
    selectButton: {
      isSelected: selectList.name === inputName,
      onClick: (ev) => handleClickSelectList(ev, inputName, value),
    },
    value: getClassificationName(isGrand ? CLSFCN.LED_GRAND_COLOR.CATEGORY_CD : CLSFCN.LED_COLOR.CATEGORY_CD, value),
  });

  const getEffectParam = (inputName: string, value: string): ledSelectButtonType => ({
    selectButton: {
      isSelected: selectList.name === inputName,
      onClick: (ev) => handleClickSelectList(ev, inputName, value),
    },
    value: getClassificationName(isGrand ? CLSFCN.LED_EFFECT_GRAND.CATEGORY_CD : CLSFCN.LED_EFFECT.CATEGORY_CD, value),
  });

  const colorSelect4 = isGrand
    ? getColorParam(INPUT_NAMES.LEFT_GRAND.COLOR4, ledSelectAreaConf.lLedGrandColor4)
    : getColorParam(INPUT_NAMES.LEFT.COLOR4, ledSelectAreaConf.lLedColor4);
  const effectSelect4 = isGrand
    ? getEffectParam(INPUT_NAMES.LEFT_GRAND.EFFECT4, ledSelectAreaConf.lLedGrandEffect4)
    : getEffectParam(INPUT_NAMES.LEFT.EFFECT4, ledSelectAreaConf.lLedEffect4);
  const effectDisabled4 = isGrand
    ? [CLSFCN.LED_GRAND_COLOR.OFF, CLSFCN.LED_GRAND_COLOR.SAMPLE_PATTERN].includes(ledSelectAreaConf.lLedGrandColor4)
    : ledSelectAreaConf.lLedColor4 === CLSFCN.LED_COLOR.BASE_COLOR;

  const colorSelect3 = isGrand
    ? getColorParam(INPUT_NAMES.LEFT_GRAND.COLOR3, ledSelectAreaConf.lLedGrandColor3)
    : getColorParam(INPUT_NAMES.LEFT.COLOR3, ledSelectAreaConf.lLedColor3);
  const effectSelect3 = isGrand
    ? getEffectParam(INPUT_NAMES.LEFT_GRAND.EFFECT3, ledSelectAreaConf.lLedGrandEffect3)
    : getEffectParam(INPUT_NAMES.LEFT.EFFECT3, ledSelectAreaConf.lLedEffect3);
  const effectDisabled3 = isGrand
    ? [CLSFCN.LED_GRAND_COLOR.OFF, CLSFCN.LED_GRAND_COLOR.SAMPLE_PATTERN].includes(ledSelectAreaConf.lLedGrandColor3)
    : ledSelectAreaConf.lLedColor3 === CLSFCN.LED_COLOR.BASE_COLOR;

  const colorSelect2 = isGrand
    ? getColorParam(INPUT_NAMES.LEFT_GRAND.COLOR2, ledSelectAreaConf.lLedGrandColor2)
    : getColorParam(INPUT_NAMES.LEFT.COLOR2, ledSelectAreaConf.lLedColor2);
  const effectSelect2 = isGrand
    ? getEffectParam(INPUT_NAMES.LEFT_GRAND.EFFECT2, ledSelectAreaConf.lLedGrandEffect2)
    : getEffectParam(INPUT_NAMES.LEFT.EFFECT2, ledSelectAreaConf.lLedEffect2);
  const effectDisabled2 = isGrand
    ? [CLSFCN.LED_GRAND_COLOR.OFF, CLSFCN.LED_GRAND_COLOR.SAMPLE_PATTERN].includes(ledSelectAreaConf.lLedGrandColor2)
    : ledSelectAreaConf.lLedColor2 === CLSFCN.LED_COLOR.BASE_COLOR;

  const colorSelect1 = isGrand
    ? getColorParam(INPUT_NAMES.LEFT_GRAND.COLOR1, ledSelectAreaConf.lLedGrandColor1)
    : getColorParam(INPUT_NAMES.LEFT.COLOR1, ledSelectAreaConf.lLedColor1);
  const effectSelect1 = isGrand
    ? getEffectParam(INPUT_NAMES.LEFT_GRAND.EFFECT1, ledSelectAreaConf.lLedGrandEffect1)
    : getEffectParam(INPUT_NAMES.LEFT.EFFECT1, ledSelectAreaConf.lLedEffect1);
  const effectDisabled1 = isGrand
    ? [CLSFCN.LED_GRAND_COLOR.OFF, CLSFCN.LED_GRAND_COLOR.SAMPLE_PATTERN].includes(ledSelectAreaConf.lLedGrandColor1)
    : ledSelectAreaConf.lLedColor1 === CLSFCN.LED_COLOR.BASE_COLOR;

  const colorSelectRight3 = isGrand
    ? getColorParam(INPUT_NAMES.RIGHT_GRAND.COLOR3, ledSelectAreaConf.rLedGrandColor3)
    : getColorParam(INPUT_NAMES.RIGHT.COLOR3, ledSelectAreaConf.rLedColor3);
  const effectSelectRight3 = isGrand
    ? getEffectParam(INPUT_NAMES.RIGHT_GRAND.EFFECT3, ledSelectAreaConf.rLedGrandEffect3)
    : getEffectParam(INPUT_NAMES.RIGHT.EFFECT3, ledSelectAreaConf.rLedEffect3);
  const effectRightDisabled3 = isGrand
    ? [CLSFCN.LED_GRAND_COLOR.OFF, CLSFCN.LED_GRAND_COLOR.SAMPLE_PATTERN].includes(ledSelectAreaConf.rLedGrandColor3)
    : ledSelectAreaConf.rLedColor3 === CLSFCN.LED_COLOR.BASE_COLOR;

  const colorSelectRight2 = isGrand
    ? getColorParam(INPUT_NAMES.RIGHT_GRAND.COLOR2, ledSelectAreaConf.rLedGrandColor2)
    : getColorParam(INPUT_NAMES.RIGHT.COLOR2, ledSelectAreaConf.rLedColor2);
  const effectSelectRight2 = isGrand
    ? getEffectParam(INPUT_NAMES.RIGHT_GRAND.EFFECT2, ledSelectAreaConf.rLedGrandEffect2)
    : getEffectParam(INPUT_NAMES.RIGHT.EFFECT2, ledSelectAreaConf.rLedEffect2);
  const effectRightDisabled2 = isGrand
    ? [CLSFCN.LED_GRAND_COLOR.OFF, CLSFCN.LED_GRAND_COLOR.SAMPLE_PATTERN].includes(ledSelectAreaConf.rLedGrandColor2)
    : ledSelectAreaConf.rLedColor2 === CLSFCN.LED_COLOR.BASE_COLOR;

  const colorSelectRight1 = isGrand
    ? getColorParam(INPUT_NAMES.RIGHT_GRAND.COLOR1, ledSelectAreaConf.rLedGrandColor1)
    : getColorParam(INPUT_NAMES.RIGHT.COLOR1, ledSelectAreaConf.rLedColor1);
  const effectSelectRight1 = isGrand
    ? getEffectParam(INPUT_NAMES.RIGHT_GRAND.EFFECT1, ledSelectAreaConf.rLedGrandEffect1)
    : getEffectParam(INPUT_NAMES.RIGHT.EFFECT1, ledSelectAreaConf.rLedEffect1);
  const effectRightDisabled1 = isGrand
    ? [CLSFCN.LED_GRAND_COLOR.OFF, CLSFCN.LED_GRAND_COLOR.SAMPLE_PATTERN].includes(ledSelectAreaConf.rLedGrandColor1)
    : ledSelectAreaConf.rLedColor1 === CLSFCN.LED_COLOR.BASE_COLOR;

  let leftRightColorEffectSelectAreaRight = leftRight;
  if (isGrand) {
    leftRightColorEffectSelectAreaRight = COMMON.LEFT_RIGHT.LEFT;
  } else {
    leftRightColorEffectSelectAreaRight =
      leftRight === COMMON.LEFT_RIGHT.LEFT_AND_RIGHT ? COMMON.LEFT_RIGHT.RIGHT : leftRight;
  }

  return (
    <Box className={classes.content}>
      <Box className={classes.settingArea}>
        <ColorEffectSelectArea
          leftRight={leftRight === COMMON.LEFT_RIGHT.LEFT_AND_RIGHT ? COMMON.LEFT_RIGHT.LEFT : leftRight}
          selectButtonLR={COMMON.LEFT_RIGHT.LEFT}
          colorSelect={colorSelect4}
          effectSelect={effectSelect4}
          effectDisabled={effectDisabled4}
          disabled={isJack ? true : disabled}
          isAreaGrayOut={isJack}
          isGrand={isGrand}
        />
        <ColorEffectSelectArea
          leftRight={leftRight === COMMON.LEFT_RIGHT.LEFT_AND_RIGHT ? COMMON.LEFT_RIGHT.LEFT : leftRight}
          selectButtonLR={COMMON.LEFT_RIGHT.LEFT}
          colorSelect={colorSelect3}
          effectSelect={effectSelect3}
          effectDisabled={effectDisabled3}
          disabled={disabled}
          isGrand={isGrand}
        />
        <ColorEffectSelectArea
          leftRight={leftRight === COMMON.LEFT_RIGHT.LEFT_AND_RIGHT ? COMMON.LEFT_RIGHT.LEFT : leftRight}
          selectButtonLR={COMMON.LEFT_RIGHT.LEFT}
          colorSelect={colorSelect2}
          effectSelect={effectSelect2}
          effectDisabled={effectDisabled2}
          disabled={isJack ? true : disabled}
          isAreaGrayOut={isJack}
          isGrand={isGrand}
        />
        <ColorEffectSelectArea
          leftRight={leftRight === COMMON.LEFT_RIGHT.LEFT_AND_RIGHT ? COMMON.LEFT_RIGHT.LEFT : leftRight}
          selectButtonLR={COMMON.LEFT_RIGHT.LEFT}
          colorSelect={colorSelect1}
          effectSelect={effectSelect1}
          effectDisabled={effectDisabled1}
          disabled={disabled}
          isGrand={isGrand}
        />
      </Box>
      {isClena3 && (
        <Box className={classes.imgArea}>
          <DeleteButton disabled={disabled} className={classes.resetButton} onClick={handleClickReset}>
            リセット
          </DeleteButton>
          <img src={prizeMachineIcon} alt="prizeMachineIcon" className="prizeMachine" />
          {/* 左側 */}
          <img src={pointer1} alt="pointer1" className="leftPointer pointer1" />
          <img src={pointer2} alt="pointer2" className="leftPointer pointer2" />
          <img src={pointer3} alt="pointer3" className="leftPointer pointer3" />
          <img src={pointer4} alt="pointer4" className="leftPointer pointer4" />
          {/* 右側 */}
          <img src={pointer1} alt="pointer1" className="rightPointer pointer1" />
          <img src={pointer2} alt="pointer2" className="rightPointer pointer2" />
          <img src={pointer3} alt="pointer3" className="rightPointer pointer3" />
          <img src={pointer4} alt="pointer4" className="rightPointer pointer4" />
        </Box>
      )}
      {isJack && (
        <Box className={classes.imgArea}>
          <DeleteButton disabled={disabled} className={classes.resetButton} onClick={handleClickReset}>
            リセット
          </DeleteButton>
          <img src={prizeMachineJackIcon} alt="prizeMachineJackIcon" className="prizeMachine" />
          {/* 左側 */}
          <img src={pointer2Jack} alt="pointer2Jack" className="leftPointer pointer2Jack" />
          <img src={pointer4Jack} alt="pointer4Jack" className="leftPointer pointer4" />
        </Box>
      )}
      {isGrand && (
        <Box className={classes.imgArea}>
          <DeleteButton disabled={disabled} className={classes.resetGrandButton} onClick={handleClickReset}>
            リセット
          </DeleteButton>
          <img src={grandMachine} alt="grandMachine" className="prizeGrandMachine" />
        </Box>
      )}
      <Box className={`${classes.settingArea}  ${areaColor}`}>
        {/* Jackの場合はグレーアウトする */}
        {isJack && <Box className={classes.disableInputArea} />}
        {isGrand ? (
          <Box className={classes.selectAreaEmpty} />
        ) : (
          <ColorEffectSelectArea
            leftRight={leftRight === COMMON.LEFT_RIGHT.LEFT_AND_RIGHT ? COMMON.LEFT_RIGHT.RIGHT : leftRight}
            selectButtonLR={COMMON.LEFT_RIGHT.RIGHT}
            colorSelect={getColorParam(INPUT_NAMES.RIGHT.COLOR4, ledSelectAreaConf.rLedColor4)}
            effectSelect={getEffectParam(INPUT_NAMES.RIGHT.EFFECT4, ledSelectAreaConf.rLedEffect4)}
            effectDisabled={ledSelectAreaConf.rLedColor4 === CLSFCN.LED_COLOR.BASE_COLOR}
            disabled={isJack ? true : disabled}
          />
        )}
        <ColorEffectSelectArea
          leftRight={leftRightColorEffectSelectAreaRight}
          selectButtonLR={COMMON.LEFT_RIGHT.RIGHT}
          colorSelect={colorSelectRight3}
          effectSelect={effectSelectRight3}
          effectDisabled={effectRightDisabled3}
          disabled={isJack ? true : disabled}
          isGrand={isGrand}
        />
        <ColorEffectSelectArea
          leftRight={leftRightColorEffectSelectAreaRight}
          selectButtonLR={COMMON.LEFT_RIGHT.RIGHT}
          colorSelect={colorSelectRight2}
          effectSelect={effectSelectRight2}
          effectDisabled={effectRightDisabled2}
          disabled={isJack ? true : disabled}
          isGrand={isGrand}
        />
        <ColorEffectSelectArea
          leftRight={leftRightColorEffectSelectAreaRight}
          selectButtonLR={COMMON.LEFT_RIGHT.RIGHT}
          colorSelect={colorSelectRight1}
          effectSelect={effectSelectRight1}
          effectDisabled={effectRightDisabled1}
          disabled={isJack ? true : disabled}
          isGrand={isGrand}
        />
      </Box>
      <SelectList selectList={selectList} />
    </Box>
  );
});

export default LedSelectArea;
