import React, { CSSProperties } from 'react';
import { Box, makeStyles, Table, TableBody, TableCell, TableContainer, TableRow } from '@material-ui/core';
import { AreaDetailGrandType, AreaDetailRowGrandType } from 'types/machineConf/areaType';
import COMMON from 'constants/common';
import { AreaConfGrandType } from 'types/machineConf/machineConfType';
import X1PointerMark from 'assets/images/X1PointerMark.svg';
import X2PointerMark from 'assets/images/X2PointerMark.svg';
import Y3PointerMark from 'assets/images/Y3PointerMark.svg';
import { themeColor } from 'constants/theme';
import XyLineGrand from './XyLineGrand';

/* ************* constant ************* */
const tableArrayRender = [0, 1, 2];

type SizeType = {
  width: string;
  height: string;
  border: string;
};
type CellSizeType = {
  [key: string]: SizeType;
};

type IconPointerMarkType = {
  [key: string]: CSSProperties;
};

const sizeDefault = {
  area0: { width: '0px', height: '0px', border: 'none' },
  area1: { width: '0px', height: '0px', border: 'none' },
  area2: { width: '0px', height: '0px', border: 'none' },
  area3: { width: '0px', height: '0px', border: 'none' },
  area4: { width: '0px', height: '0px', border: 'none' },
  area5: { width: '0px', height: '0px', border: 'none' },
};

const pointerMarkTypeDefault: IconPointerMarkType = {
  x1PointerMark: { position: 'absolute', bottom: '-46px', left: '46px' },
  x2PointerMark: { position: 'absolute', bottom: '-46px', left: '46px' },
  y3PointerMark: { position: 'absolute', bottom: '-46px', left: '46px' },
};

const POINTER_WIDTH = 46;
const POINTER_WIDTH_S = 30;
const POINTER_HEIGHT = 52;
const POINTER_HEIGHT_S = 32;
const POSITION_SIZE = 52;
const POSITION_WIDTH_S = 36;
const POSITION_HEIGHT_S = 32;
const POSITION_PADDING = 8;
const PADDING_AREA = 12;
const PADDING_AREA_S = 0;
const BORDER_WIDTH = 2;
const BORDER_WIDTH_S = 0;
const SIZE_AREA = 330;
const SIZE_AREA_S = 210;

/* ************ Style ************ */
const useStyles = makeStyles((theme) => ({
  tableRoot: {
    borderCollapse: 'collapse',
    tableLayout: 'fixed',
    '& .area0': {
      backgroundColor: theme.base.area3,
    },
    '& .area1': {
      backgroundColor: theme.base.area4,
    },
    '& .area2': {
      backgroundColor: theme.base.area5,
    },
    '& .area3': {
      backgroundColor: theme.base.area0,
    },
    '& .area4': {
      backgroundColor: theme.base.area1,
    },
    '& .area5': {
      backgroundColor: theme.base.area2,
    },
    '& td': {
      padding: 0,
      '& img': {
        transform: 'scale(0.8)',
      },
    },
  },
  tableRootBorder: {
    border: '3px solid',
  },
  tableContainer: {
    position: 'relative',
  },
  lineSeparateNone: {
    borderBottomWidth: '0px',
  },
  lineSeparateDot: {
    borderBottom: '2px dashed',
  },
  columnSeparateNone: {
    borderRightWidth: '0px',
  },
  columnSeparateDot: {
    borderRight: '2px dashed',
  },
  smallContainer: {
    top: '-8px',
    left: '-8px',
    width: '226px',
    height: '226px',
    padding: '8px 0px 0px 8px',
  },
  mediumContainer: {
    top: '-10px',
    left: '-10px',
    width: '380px',
    height: '380px',
    padding: '10px',
  },
  largeContainer: {
    top: '-12px',
    left: '-12px',
    width: '354px',
    height: '354px',
    padding: '12px',
  },
  smallStyle: {
    height: '210px',
    width: '210px',
  },
  smallContainerGridDashLineSize: {
    '& .hLine1': {
      height: 2,
      width: 193,
      borderTop: '2px dashed',
      marginTop: 21,
    },
    '& .hLine2': {
      height: 2,
      width: 193,
      borderTop: '2px dashed',
      marginTop: 207,
    },
    '& .vLine1': {
      width: 2,
      height: 189,
      borderRight: '2px dashed',
      marginTop: 21,
      marginLeft: 2,
    },
    '& .vLine2': {
      width: 2,
      height: 189,
      borderRight: '2px dashed',
      marginTop: 21,
      marginLeft: 53,
    },
    '& .vLine3': {
      width: 2,
      height: 189,
      borderRight: '2px dashed',
      marginTop: 21,
      marginLeft: 88,
    },
    '& .vLine4': {
      width: 2,
      height: 189,
      borderRight: '2px dashed',
      marginTop: 21,
      marginLeft: 124,
    },
    '& .vLine5': {
      width: 2,
      height: 189,
      borderRight: '2px dashed',
      marginTop: 21,
      marginLeft: 193,
    },
  },
  mediumStyle: {
    height: '360px',
    width: '360px',
  },
  mediumContainerGridDashLineSize: {},
  largeStyle: {
    height: '330px',
    width: '330px',
  },
  largeContainerGridDashLineSize: {
    '& .hLine1': {
      height: 2,
      width: 302,
      borderTop: '2px dashed',
      marginTop: 34,
    },
    '& .hLine2': {
      height: 2,
      width: 302,
      borderTop: '2px dashed',
      marginTop: 326,
    },
    '& .vLine1': {
      width: 2,
      height: 296,
      borderRight: '2px dashed',
      marginTop: 34,
      marginLeft: 2,
    },
    '& .vLine2': {
      width: 2,
      height: 296,
      borderRight: '2px dashed',
      marginTop: 34,
      marginLeft: 82,
    },
    '& .vLine3': {
      width: 2,
      height: 296,
      borderRight: '2px dashed',
      marginTop: 34,
      marginLeft: 137,
    },
    '& .vLine4': {
      width: 2,
      height: 296,
      borderRight: '2px dashed',
      marginTop: 34,
      marginLeft: 193,
    },
    '& .vLine5': {
      width: 2,
      height: 296,
      borderRight: '2px dashed',
      marginTop: 34,
      marginLeft: 302,
    },
  },
  defaultStyle: {
    borderColor: theme.base.tableBorder,
  },
  leftStyle: {
    borderColor: theme.leftStation.itemBorder,
  },
  rightStyle: {
    borderColor: theme.rightStation.itemBorder,
  },
  xyAreaPower: {
    position: 'absolute',
    top: '40px',
    left: '40px',
    zIndex: 1,
  },
  xyAreaPowerS: {
    position: 'absolute',
    top: '0',
    left: '0',
    zIndex: 1,
  },
  gridDashLine: {
    position: 'absolute',
    left: 0,
    top: 0,
    '& .dashLine': {
      borderColor: theme.base.tableBorder,
      position: 'absolute',
    },
  },
}));

/* ********* Local Function ********** */
const getCellSize = (areaConf: AreaConfGrandType, size: 'S' | 'CM' | 'M' | 'L', borderColor?: string): CellSizeType => {
  const BORDER_AREA = 1;
  let sizeArea = SIZE_AREA;
  switch (size) {
    case 'S':
      sizeArea = SIZE_AREA_S;
      break;
    default:
      break;
  }

  const isDefault = areaConf && areaConf.areaConf === COMMON.SWITCH.OFF;
  if (isDefault) {
    return {
      area0: {
        width: '0px',
        height: '0px',
        border: 'none',
      },
      area1: {
        width: '0px',
        height: '0px',
        border: 'none',
      },
      area2: {
        width: '0px',
        height: '0px',
        border: 'none',
      },
      area3: {
        width: `${sizeArea / 3 - BORDER_AREA}px`,
        height: `${sizeArea / 2 - BORDER_AREA}px`,
        border: 'none',
      },
      area4: {
        width: '0px',
        height: '0px',
        border: 'none',
      },
      area5: {
        width: '0px',
        height: '0px',
        border: 'none',
      },
    };
  }
  const line1 = (areaConf.line1 * sizeArea) / 100;
  const line2 = (areaConf.line2 * sizeArea) / 100;
  const line3 = (areaConf.line3 * sizeArea) / 100;
  let areaWidth0 = line1;
  areaWidth0 = areaWidth0 ? areaWidth0 - BORDER_AREA * 2 : 0;
  let areaHeight0 = sizeArea - line3;
  areaHeight0 = areaHeight0 ? areaHeight0 - BORDER_AREA * 2 : 0;
  const isArea0NoneBorder = [areaWidth0, areaHeight0].includes(0);
  let areaWidth1 = sizeArea - (line1 + (sizeArea - line2));
  areaWidth1 = areaWidth1 ? areaWidth1 - BORDER_AREA * 2 : 0;
  let areaHeight1 = sizeArea - line3;
  areaHeight1 = areaHeight1 ? areaHeight1 - BORDER_AREA * 2 : 0;
  const isArea1NoneBorder = [areaWidth1, areaHeight1].includes(0);
  let areaWidth2 = sizeArea - line2;
  areaWidth2 = areaWidth2 ? areaWidth2 - BORDER_AREA * 2 : 0;
  let areaHeight2 = sizeArea - line3;
  areaHeight2 = areaHeight2 ? areaHeight2 - BORDER_AREA * 2 : 0;
  const isArea2NoneBorder = [areaWidth2, areaHeight2].includes(0);
  let areaWidth3 = line1;
  areaWidth3 = areaWidth3 ? areaWidth3 - BORDER_AREA * 2 : 0;
  let areaHeight3 = line3;
  areaHeight3 = areaHeight3 ? areaHeight3 - BORDER_AREA * 2 : 0;
  const isArea3NoneBorder = [areaWidth3, areaHeight3].includes(0);
  let areaWidth4 = sizeArea - (line1 + (sizeArea - line2));
  areaWidth4 = areaWidth4 ? areaWidth4 - BORDER_AREA * 2 : 0;
  let areaHeight4 = line3;
  areaHeight4 = areaHeight4 ? areaHeight4 - BORDER_AREA * 2 : 0;
  const isArea4NoneBorder = [areaWidth4, areaHeight4].includes(0);
  let areaWidth5 = sizeArea - line2;
  areaWidth5 = areaWidth5 ? areaWidth5 - BORDER_AREA * 2 : 0;
  let areaHeight5 = line3;
  areaHeight5 = areaHeight5 ? areaHeight5 - BORDER_AREA * 2 : 0;
  const isArea5NoneBorder = [areaWidth5, areaHeight5].includes(0);

  let borderArea0 = isArea0NoneBorder ? 'none' : `1px solid ${themeColor.grandDarkColor}`;
  let borderArea1 = isArea1NoneBorder ? 'none' : `1px solid ${themeColor.grandDarkColor}`;
  let borderArea2 = isArea2NoneBorder ? 'none' : `1px solid ${themeColor.grandDarkColor}`;
  let borderArea3 = isArea3NoneBorder ? 'none' : `1px solid ${themeColor.grandDarkColor}`;
  let borderArea4 = isArea4NoneBorder ? 'none' : `1px solid ${themeColor.grandDarkColor}`;
  let borderArea5 = isArea5NoneBorder ? 'none' : `1px solid ${themeColor.grandDarkColor}`;
  if (borderColor) {
    borderArea0 = borderColor;
    borderArea1 = borderColor;
    borderArea2 = borderColor;
    borderArea3 = borderColor;
    borderArea4 = borderColor;
    borderArea5 = borderColor;
  }

  return {
    area0: {
      width: `${areaWidth0}px`,
      height: `${areaHeight0}px`,
      border: borderArea0,
    },
    area1: {
      width: `${areaWidth1}px`,
      height: `${areaHeight1}px`,
      border: borderArea1,
    },
    area2: {
      width: `${areaWidth2}px`,
      height: `${areaHeight2}px`,
      border: borderArea2,
    },
    area3: {
      width: `${areaWidth3}px`,
      height: `${areaHeight3}px`,
      border: borderArea3,
    },
    area4: {
      width: `${areaWidth4}px`,
      height: `${areaHeight4}px`,
      border: borderArea4,
    },
    area5: {
      width: `${areaWidth5}px`,
      height: `${areaHeight5}px`,
      border: borderArea5,
    },
  };
};

const getIconStyle = (areaConf: AreaConfGrandType, size: 'S' | 'CM' | 'M' | 'L'): IconPointerMarkType => {
  let sizeArea = SIZE_AREA;
  let positionWidth = POSITION_SIZE;
  let positionHeight = POSITION_SIZE;
  let pointerWidth = POINTER_WIDTH;
  let pointerHeight = POINTER_HEIGHT;
  let paddingArea = PADDING_AREA;
  let borderWidth = BORDER_WIDTH;
  let pointerPosition = paddingArea + POSITION_PADDING;
  let y3PointerPositionLeft = paddingArea + POSITION_PADDING;
  let fontSize = '28px';
  let lineHeight = '22px';

  switch (size) {
    case 'S':
      sizeArea = SIZE_AREA_S;
      positionWidth = POSITION_WIDTH_S;
      positionHeight = POSITION_HEIGHT_S;
      pointerWidth = POINTER_WIDTH_S;
      pointerHeight = POINTER_HEIGHT_S;
      paddingArea = PADDING_AREA_S;
      borderWidth = BORDER_WIDTH_S;
      pointerPosition = POINTER_HEIGHT_S + POSITION_PADDING / 2;
      y3PointerPositionLeft = POSITION_WIDTH_S + POSITION_PADDING / 2;
      fontSize = '16px';
      lineHeight = '14px';
      break;
    default:
      break;
  }

  let line1 = (areaConf.line1 * sizeArea) / 100 + paddingArea + borderWidth / 2;
  if (size === 'S') {
    line1 -= POINTER_HEIGHT_S / 2;
  }
  let line1Position = line1;
  let line2 = (areaConf.line2 * sizeArea) / 100 + paddingArea + borderWidth / 2;
  if (size === 'S') {
    line2 -= POINTER_HEIGHT_S / 2;
  }
  let line2Position;

  const line3 = (areaConf.line3 * sizeArea) / 100 - pointerHeight / 2 + borderWidth / 2;

  if (size === 'S') {
    line1Position = sizeArea / 4 + positionWidth / 2 - 16;
    line2Position = (sizeArea / 4) * 2 + positionWidth / 2;
  } else {
    line1Position = sizeArea / 3 + positionWidth / 2 - 16;
    line2Position = (sizeArea / 3) * 2 + positionWidth / 2;
  }

  return {
    x1PointerMark: { position: 'absolute', bottom: `-${pointerWidth}px`, left: `${line1}px` },
    x2PointerMark: { position: 'absolute', bottom: `-${pointerWidth}px`, left: `${line2}px` },
    y3PointerMark: { position: 'absolute', bottom: `${line3}px`, right: `-${pointerWidth}px` },
    x1PointerPosition: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      width: `${positionWidth}px`,
      height: `${positionHeight}px`,
      fontSize,
      lineHeight,
      border: '2px solid #ff3e3e',
      position: 'absolute',
      left: `${line1Position}px`,
      top: `-${pointerPosition}px`,
      background: '#ffffff',
      borderRadius: '4px',
    },
    x2PointerPosition: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      width: `${positionWidth}px`,
      height: `${positionHeight}px`,
      fontSize,
      lineHeight,
      border: '2px solid #ffb54e',
      position: 'absolute',
      left: `${line2Position}px`,
      top: `-${pointerPosition}px`,
      background: '#ffffff',
      borderRadius: '4px',
    },
    y3PointerPosition: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      width: `${positionWidth}px`,
      height: `${positionHeight}px`,
      fontSize,
      lineHeight,
      border: '2px solid #3086ff',
      position: 'absolute',
      bottom: `${sizeArea / 2 - POSITION_SIZE / 2}px`,
      left: `-${y3PointerPositionLeft}px`,
      background: '#ffffff',
      borderRadius: '4px',
    },
  };
};

/* ************ Component ************ */
/* ********** Sub Component ********** */
/**
 * AreaDetailRow エリア明細行
 *
 * @author atsushi.teruya
 * @Params {AreaDetailRowGrandType} - {className, pitLine, areaLine}
 * @returns {React.FC} - エリア明細行の表示
 *
 */
const AreaDetailRow: React.FC<AreaDetailRowGrandType> = ({
  className,
  displayCellSeparate,
  value: rowValue = 0,
  size,
  isDefault,
}) => {
  const classes = useStyles();
  // 通常のセルのスタイル
  const cellClass = `${className} ${displayCellSeparate ? classes.columnSeparateDot : classes.columnSeparateNone}`;
  return (
    <TableRow>
      {tableArrayRender.map((value) => {
        const areaStyle = isDefault ? 'area3' : `area${value + (rowValue ? 3 : 0)}`;
        return <TableCell key={value} className={`${cellClass} ${areaStyle}`} style={size[areaStyle]} />;
      })}
    </TableRow>
  );
};

/* ************ Main Component ************ */
const AreaConfPanelGrand: React.FC<AreaDetailGrandType> = ({
  className,
  displayCellSeparate,
  displayTableBorder,
  defaultBorderColor,
  leftRight,
  size,
  areaConf,
  isDefault,
  borderColor,
}) => {
  const classes = useStyles();
  const [sizeArea, setSizeArea] = React.useState<CellSizeType>(sizeDefault);

  // テーブルサイズを定義
  let tableSizeStyle = '';
  let containerSizeStyle = '';
  let pointerWidth = POINTER_WIDTH;
  let pointerHeight = POINTER_HEIGHT;
  let xyAreaPowerClass = classes.xyAreaPower;
  const isLeft = leftRight === COMMON.LEFT_RIGHT.LEFT;
  let containerGridDashLineSize = '';

  switch (size) {
    case COMMON.SIZE.SMALL:
      tableSizeStyle = classes.smallStyle;
      containerSizeStyle = classes.smallContainer;
      pointerWidth = POINTER_WIDTH_S;
      pointerHeight = POINTER_HEIGHT_S;
      xyAreaPowerClass = classes.xyAreaPowerS;
      containerGridDashLineSize = classes.smallContainerGridDashLineSize;
      break;
    case COMMON.SIZE.MEDIUM:
      tableSizeStyle = classes.mediumStyle;
      containerSizeStyle = classes.mediumContainer;
      containerGridDashLineSize = classes.mediumContainerGridDashLineSize;
      break;
    default:
      tableSizeStyle = classes.largeStyle;
      containerSizeStyle = classes.largeContainer;
      containerGridDashLineSize = classes.largeContainerGridDashLineSize;
  }
  // ステーションの向きに応じた配色を定義
  let stationStyle = classes.defaultStyle;
  // デフォルトカラーじゃない場合は左右のスタイルを設定
  if (!defaultBorderColor) {
    stationStyle = isLeft ? classes.leftStyle : classes.rightStyle;
  }
  const contanerStyle = `${classes.tableContainer} ${className} ${containerSizeStyle}`;
  // テーブルのスタイル定義 サイズ、左右の配色など最終的なスタイルを定義
  const tableStyle = `${classes.tableRoot} ${
    displayTableBorder && classes.tableRootBorder
  } ${tableSizeStyle} ${stationStyle}`;
  // 基本は1行ごとに破線のボーダーを表示。XY範囲など一部の画面は非表示。
  const rowClass = `${displayCellSeparate ? classes.lineSeparateDot : classes.lineSeparateNone} ${stationStyle}`;

  const {
    x1PointerMark,
    x2PointerMark,
    y3PointerMark,
    x1PointerPosition,
    x2PointerPosition,
    y3PointerPosition,
  } = areaConf ? getIconStyle(areaConf, size) : pointerMarkTypeDefault;

  React.useEffect(() => {
    const sizeAreaFromAreaConf: CellSizeType = areaConf ? getCellSize(areaConf, size, borderColor) : sizeDefault;
    setSizeArea(sizeAreaFromAreaConf);
  }, [areaConf]);
  const containerGridStyle = `${classes.gridDashLine} ${containerSizeStyle} ${containerGridDashLineSize}`;

  return (
    <>
      <TableContainer className={contanerStyle}>
        <Table className={tableStyle}>
          <TableBody>
            {[0, 1].map((value) => (
              <AreaDetailRow
                key={value}
                className={rowClass}
                displayCellSeparate={displayCellSeparate}
                value={value}
                size={sizeArea}
                isDefault={isDefault}
              />
            ))}
          </TableBody>
        </Table>
        <div className={containerGridStyle}>
          <div className="hLine1 dashLine" />
          <div className="hLine2 dashLine" />
          <div className="vLine1 dashLine" />
          <div className="vLine2 dashLine" />
          <div className="vLine3 dashLine" />
          <div className="vLine4 dashLine" />
          <div className="vLine5 dashLine" />
        </div>
      </TableContainer>
      <Box className={xyAreaPowerClass}>
        <XyLineGrand
          leftRight={leftRight}
          enableCustomHome={false}
          homePos={{ x: areaConf?.line1 ?? 0, y: areaConf?.line2 ?? 0 }}
          customHomePos={{ x: 0, y: 0 }}
          endPos={{ x: areaConf?.line2 ?? 0, y: areaConf?.line3 ?? 0 }}
          size={size}
        />
      </Box>

      <Box style={x1PointerPosition}>{areaConf?.line1}</Box>
      <Box style={x2PointerPosition}>{areaConf?.line2}</Box>
      <Box style={y3PointerPosition}>{areaConf?.line3}</Box>

      <img style={x1PointerMark} src={X1PointerMark} alt="X1PointerMark" width={pointerHeight} height={pointerWidth} />
      <img style={x2PointerMark} src={X2PointerMark} alt="X2PointerMark" width={pointerHeight} height={pointerWidth} />
      <img style={y3PointerMark} src={Y3PointerMark} alt="Y3PointerMark" width={pointerWidth} height={pointerHeight} />
    </>
  );
};

export default AreaConfPanelGrand;
