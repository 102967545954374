import { makeStyles, Table, TableBody, TableCell, TableContainer, TableRow } from '@material-ui/core';
import React, { memo } from 'react';
import { EquipmentSelectDataTableRow } from 'types/group/EquipmentSelectType';
import NormalButton from 'components/system/atoms/buttons/NormalButton';
import ListCheckBox from 'components/system/atoms/checkBoxs/ListCheckBox';
import redirectLinks from 'constants/redirectLinks';
import { useHistory } from 'react-router-dom';
import HttpConnection from 'utils/httpConnection';
import { REST_API } from 'constants/apiUrls';
import { useAppDispatch } from 'redux/hooks';
import InfoDialog from 'components/system/organisms/dialogs/InfoDialog';
import MsgHelper from 'utils/message.helper';
import MSG from 'constants/messages';
import COMMON from 'constants/common';
import TooltipComponent from 'components/system/organisms/dialogs/TooltipComponent';
import { getStationName, renderMachineType } from 'utils/common.helper';
import MachineStationTableHead, { HeaderType } from 'components/data/organisms/tables/common/MachineStationTableHead';
import { MachineSelectStationNameType } from 'types/group/GroupType';

const column1 = 138;
const column2 = 206;
const column3 = 180;

/* ************ Style ************ */
const useStyles = makeStyles(() => ({
  default: {
    height: '439px',
    overflowX: 'hidden',
    marginTop: '12px',
  },
  table: {
    tableLayout: 'fixed',
    '& th,td': {
      border: '1px solid #707070',
      fontSize: '24px',
      lineHeight: '20px',
      minHeight: '74px',
    },
    '& th': {
      textAlign: 'center',
      height: '74px',
      padding: 0,
    },
    '& tbody tr': {
      minHeight: '74px',
    },
    '& td': {
      padding: '0 8px',
      '& .cellStyle': {
        fontSize: '21px',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
      },
    },
  },
  head: {
    backgroundColor: '#CFCFCF',
  },
  tdEdit: {
    textAlign: 'center',
    alignItems: 'center',
    justifyContent: 'center',
    display: 'flex',
    '& .tdEditButton': {
      height: '44px',
      width: '120px',
      backgroundColor: '#A2FFBB',
      fontSize: '24px',
      boxShadow: '5px 5px 3px 0px rgba(0, 0, 0, 0.2)',
    },
  },
  tdStationName: {
    textAlign: 'left',
    alignItems: 'center',
    '& .tdDetailButton': {
      height: '44px',
      width: '128px',
      backgroundColor: '#A2FFBB',
      fontSize: '24px',
      boxShadow: '5px 5px 3px 0px rgba(0, 0, 0, 0.2)',
    },
  },
  tdMachineNo: {
    paddingLeft: '0px !important',
    textAlign: 'left',
    alignItems: 'center',
    '& .tdDetailButton': {
      height: '44px',
      width: '128px',
      backgroundColor: '#A2FFBB',
      fontSize: '24px',
      boxShadow: '5px 5px 3px 0px rgba(0, 0, 0, 0.2)',
    },
  },
  tdEquipmentNo: {
    display: 'flex',
    alignItems: 'center',
  },
  tdMachineName: {
    textAlign: 'left',
  },
  stationRow: {
    display: 'flex',
    lineHeight: '30px',
    alignItems: 'center',
  },
  stationRowName: {
    flex: 1,
    width: '484px',
    paddingRight: 8,
    fontSize: '21px',
  },
}));

/* ************ Type ************ */
type Props = {
  rows: Array<EquipmentSelectDataTableRow>;
  handleSelectedItemChange: (row: EquipmentSelectDataTableRow) => void;
  handleOpenMachineInfo: (row: EquipmentSelectDataTableRow) => void;
};

type MachineExistResponse = {
  count: number;
};

const messageNotExist: string = MsgHelper.messageFormat(MSG.ERR604);

/* ************ Component ************ */
const MachineListTable: React.FC<Props> = ({ rows, handleSelectedItemChange, handleOpenMachineInfo }) => {
  const classes = useStyles();
  const history = useHistory();
  const dispatch = useAppDispatch();
  const [openErrorDialog, setOpenErrorDialog] = React.useState(false);

  const headerColumns: HeaderType[] = [
    { id: 'editColumnId', title: '編集', width: column1 },
    { id: 'machineNoColumnId', title: '登録番号', width: column2 },
    { id: 'machineType', title: '機種名', width: column3 },
    { id: 'stationNameColumnId', title: 'ステーション名', width: '100%' },
  ];

  const getMachineDetail = async (boardSerial: string): Promise<boolean | null> => {
    const http = new HttpConnection({ dispatch }, false);
    const data = await http.get<MachineExistResponse>(REST_API.MAINTENANCE.CHECK_MACHINE_EXIST, { boardSerial });
    const isExist = data.count > 0;
    return Promise.resolve<boolean | null>(isExist);
  };

  const handleGoToUpdateMachineScreen = (row: EquipmentSelectDataTableRow) => {
    getMachineDetail(row.machineNo).then((isExist) => {
      if (isExist) {
        history.push(redirectLinks.MAINT_MACHINE_DETAIL, { boardSerial: row.machineNo });
      } else {
        setOpenErrorDialog(true);
      }
    });
  };

  const handleOpenDialogInfo = (row: EquipmentSelectDataTableRow) => {
    getMachineDetail(row.machineNo).then((isExist) => {
      if (isExist) {
        handleOpenMachineInfo(row);
      } else {
        setOpenErrorDialog(true);
      }
    });
  };

  const getStationNameFunc = React.useCallback(
    ({ machineType, stationName, leftRight, giftName }: MachineSelectStationNameType): string =>
      `${getStationName(machineType, stationName, leftRight)} ${giftName ?? COMMON.BLANK}`,
    [],
  );

  return (
    <>
      <TableContainer id="scrollContainer" className={classes.default}>
        <Table className={classes.table} stickyHeader aria-label="sticky table">
          <MachineStationTableHead columns={headerColumns} />
          <TableBody>
            {rows.map((row) => (
              <TableRow>
                <TableCell className={classes.tdEdit}>
                  <div>
                    <NormalButton className="tdEditButton" onClick={() => handleGoToUpdateMachineScreen(row)}>
                      編集
                    </NormalButton>
                  </div>
                </TableCell>
                <TableCell className={classes.tdMachineNo}>
                  <div className={classes.tdEquipmentNo}>
                    <ListCheckBox
                      checked={row.checked}
                      name="selectStation"
                      onChange={() => handleSelectedItemChange(row)}
                    />
                    <div className="cellStyle">{row.machineNo}</div>
                  </div>
                </TableCell>
                <TableCell className={classes.tdMachineName}>
                  <div className="cellStyle">{renderMachineType(row.machineType)}</div>
                </TableCell>
                <TableCell className={classes.tdStationName}>
                  <div className={classes.stationRow}>
                    <div className={classes.stationRowName}>
                      {row.machineType === COMMON.MACHINE_TYPE.CLENA3 ? (
                        <>
                          <TooltipComponent
                            tooltipText={row.leftStationGiftName}
                            displayWord={`${getStationNameFunc({
                              machineType: row.machineType,
                              stationName: row.leftStationName,
                              leftRight: COMMON.LEFT_RIGHT.LEFT,
                              giftName: row.leftStationGiftName,
                            })}`}
                          />
                          <TooltipComponent
                            tooltipText={row.rightStationGiftName}
                            displayWord={`${getStationNameFunc({
                              machineType: row.machineType,
                              stationName: row.rightStationName,
                              leftRight: COMMON.LEFT_RIGHT.RIGHT,
                              giftName: row.rightStationGiftName,
                            })}`}
                          />
                        </>
                      ) : (
                        <TooltipComponent
                          tooltipText={row.leftStationGiftName}
                          displayWord={`${getStationNameFunc({
                            machineType: row.machineType,
                            stationName: row.leftStationName,
                            leftRight: COMMON.LEFT_RIGHT.LEFT,
                            giftName: row.leftStationGiftName,
                          })}`}
                        />
                      )}
                    </div>
                    <div>
                      <NormalButton className="tdDetailButton" onClick={() => handleOpenDialogInfo(row)}>
                        情報表示
                      </NormalButton>
                    </div>
                  </div>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <InfoDialog open={openErrorDialog} msg={messageNotExist} closeFunc={() => setOpenErrorDialog(false)} />
    </>
  );
};

export default memo(MachineListTable);
