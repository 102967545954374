import React, { useEffect, useState } from 'react';
import Layout from 'components/system/layouts/Layout';
import ScreenContext from 'Contexts/Common/ScreenContext';
import COMMON from 'constants/common';
import MachineConfContent from 'components/machineConf/organisms/contents/MachineConfContent';
import XyAreaGrand from 'components/machineConf/organisms/Area/XyAreaGrand';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import AreaDetailGrand from 'components/machineConf/organisms/Area/AreaDetailGrand';
import { Box, makeStyles, Theme } from '@material-ui/core';
import { ReturnButton } from 'components/system/atoms';
import { useHistory, useLocation } from 'react-router-dom';
import PositionControllerGrand from 'components/machineConf/organisms/Controllers/PositionControllerGrand';
import MachineConfContext from 'Contexts/MachineConf/MachineConfContext';
import CLSFCN from 'constants/classification';
import BoundAreaDetailGrand from 'components/machineConf/organisms/Area/BoundAreaDetailGrand';
import { handleCallSystemError, handleClearApiError } from 'redux/slices/commonSlice';
import { XySpeedLeverConfType } from 'types/machineConf/machineConfType';
import ERROR_CODE from 'constants/errorCode';
import UrlHelper from 'utils/url.helper';
import redirectLinks from 'constants/redirectLinks';
import { SaveDetailInfoType } from 'types/machineConf/stationSelectType';

/* ********** Constants ********** */
const INPUT_NAMES = {
  HOME_X: 'homeX',
  HOME_Y: 'homeY',
  END_X: 'endX',
  END_Y: 'endY',
  CUSTOM_X: 'customX',
  CUSTOM_Y: 'customY',
};

type StyleProps = {
  tempXySpeedLeverConf?: XySpeedLeverConfType;
};

const useStyles = makeStyles<Theme, StyleProps>((theme) => ({
  content: {
    display: 'flex',
    flexDirection: 'column',
    margin: '18px 24px',
    minHeight: '563px',
    minWidth: '1080px',
    position: 'relative',
  },
  baseArea: {
    borderWidth: '2px',
    borderStyle: 'solid',
    borderRadius: '8px',
  },
  baseButton: {
    display: 'flex',
    height: '64px',
    width: '204px',
    fontSize: '20px',
    textAlign: 'left',
    borderRadius: '12px',
    boxShadow: '5px 5px 2px 0px rgba(0, 0, 0, 0.5)',
    padding: '0px 0px 0px 0px',
    '& p': {
      width: '124px',
      textAlign: 'center',
    },
    '& icon': {
      marginRight: '20px',
    },
    '&:hover': {
      boxShadow: '5px 5px 2px 0px rgba(0, 0, 0, 0.5)',
    },
  },
  topArea: {
    display: 'flex',
    height: '70px',
    '& .returnButton': {},
    '& .description': {
      position: 'absolute',
      top: '4px',
      left: '216px',
      fontSize: '20px',
      lineHeight: '20px',
    },
  },
  decisionImage: {
    borderRadius: '4px',
    fontSize: '19px',
    height: '32px',
    padding: '2px 0px',
    width: '60px',
    textAlign: 'center',
    verticalAlign: 'middle',
    display: 'inline-block',
    background: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  mainArea: {
    position: 'relative',
    display: 'flex',
    height: '493px',
    '& .leverArea': {
      position: 'absolute',
      top: '50px',
      height: '372px',
      width: '244px',
      padding: '16px 0px 0px 20px',
      '& .description': {
        height: '178px',
        '& .detailDescription': {
          fontSize: '17px',
        },
      },
      '& .unselectedButton': {
        background: theme.base.gray,
        color: theme.palette.common.black,
      },
      '& .customButton': {
        marginTop: '20px',
      },
      '& .selectedCustom': {
        background: '#FFB54E',
        color: theme.palette.common.white,
        boxShadow: 'none',
      },
      '& .defaultButton': {
        marginTop: '12px',
      },
      '& .selectedDefault': {
        background: '#FF3E3E',
        color: theme.palette.common.white,
        boxShadow: 'none',
      },
    },
    '& .xyInputArea': {
      position: 'absolute',
      top: '-12px',
      left: '294px',
      height: '516px',
      width: '516px',
    },
    '& .doneArea': {
      position: 'absolute',
      top: '393px',
      left: '900px',
    },
  },
  customButtonImage: {
    display: 'flex',
    alignItems: 'center',
    background: '#FFB54E',
    color: theme.palette.common.white,
    height: '44px',
    width: '128px',
    borderRadius: '12px',
    textAlign: 'left',
    padding: '0px 0px 0px 12px',
    margin: '4px 0px',
    fontSize: '16px',
    '& .icon': {
      marginLeft: '8px',
    },
  },
  xyAreaParent: {
    position: 'relative',
    height: '100%',
    width: '100%',
    fontSize: '20px',
    '& .xyArea': {
      position: 'absolute',
      top: '52px',
      left: '52px',
    },
    '& .areaDetail': {
      position: 'absolute',
      top: '16px',
      left: '16px',
    },
    '& .homeXController': {
      position: 'absolute',
      top: '464px',
      left: '142px',
    },
    '& .homeYController': {
      position: 'absolute',
      top: '322px',
      left: '0px',
    },
    '& .endXController': {
      position: 'absolute',
      top: '0px',
      left: '322px',
    },
    '& .endYController': {
      position: 'absolute',
      top: '142px',
      left: '464px',
    },
    '& .customHomeXController': {
      position: 'absolute',
      top: '464px',
      left: '322px',
    },
    '& .customHomeYController': {
      position: 'absolute',
      top: '142px',
      left: '0px',
    },
    '& .minPositionXY': {
      position: 'absolute',
      top: '455px',
      left: '22px',
    },
    '& .maxPositionX': {
      position: 'absolute',
      top: '455px',
      left: '455px',
    },
    '& .maxPositionY': {
      position: 'absolute',
      top: '39px',
      left: '22px',
    },
  },
}));

const ScreenContextValue = {
  title: 'XY範囲設定',
  screenId: COMMON.SCREEN_ID.XY_RANGE_CONF_GRAND,
  currentMenu: COMMON.MENU.MACHINE_CONF,
  disableContents: true,
};

const XyRangeConfGrand: React.FC = () => {
  /* ************ state/redux ************ */
  const leftRight = useAppSelector((state) => state.machineConf.selectedStation.leftRight);
  const areaConf = useAppSelector((state) => state.machineConf.machineGrandConf.areaConf);
  const pitConf = useAppSelector((state) => state.machineConf.machineGrandConf.pitConf);
  const xySpeedLeverConf = useAppSelector((state) => state.machineConf.machineGrandConf.xySpeedLeverConf);

  const isLeft = leftRight === COMMON.LEFT_RIGHT.LEFT;

  const [tempXySpeedLeverConf, setTempXySpeedLeverConf] = useState(xySpeedLeverConf);
  const classes = useStyles({ tempXySpeedLeverConf });
  const history = useHistory();
  const location = useLocation();
  const [savedConfProps] = useState(location.state !== undefined ? (location.state as SaveDetailInfoType) : null);
  const dispatch = useAppDispatch();

  const MachineConfContextValue = {
    leftRight,
  };

  const enableCustomHome = tempXySpeedLeverConf?.customConf === COMMON.FLAG.ON;

  useEffect(() => {
    if (!areaConf || !pitConf || !xySpeedLeverConf) {
      dispatch(handleCallSystemError({ errorNo: ERROR_CODE.NOT_FOUND }));
    }

    const clearError = () => {
      dispatch(handleClearApiError());
    };

    return () => clearError();
  }, []);

  useEffect(() => {
    const customPos = {
      customX: tempXySpeedLeverConf?.customX || 0,
      customY: tempXySpeedLeverConf?.customY || 0,
    };

    if (tempXySpeedLeverConf) {
      // X軸計算
      // 左ステーションの計算
      if (customPos.customX < tempXySpeedLeverConf.homeX) {
        // カスタムがホームより左側
        customPos.customX = tempXySpeedLeverConf.homeX;
      }
      if (tempXySpeedLeverConf.endX < customPos.customX) {
        // カスタムがエンドより右側
        customPos.customX = tempXySpeedLeverConf.homeX;
      }
      // Y軸計算
      if (customPos.customY < tempXySpeedLeverConf.homeY) {
        // カスタムがホームより下
        customPos.customY = tempXySpeedLeverConf.homeY;
      }
      if (tempXySpeedLeverConf.endY < customPos.customY) {
        // カスタムがエンドより上
        customPos.customY = tempXySpeedLeverConf.homeY;
      }
    }

    setTempXySpeedLeverConf((prevState) => {
      if (prevState) {
        return {
          ...prevState,
          ...customPos,
        };
      }
      return prevState;
    });
  }, [tempXySpeedLeverConf?.customConf]);

  /* ************ other component ************ */

  /* ************ Event ************ */
  // 戻るボタン
  const handleClickReturn = () => {
    history.push(
      UrlHelper.convertQueryUrlFrontEnd(redirectLinks.MCONF_GRAND_HOME_DETAIL, {
        selectConfId: 'none',
      }),
    );
  };

  return (
    <ScreenContext.Provider value={ScreenContextValue}>
      <Layout>
        <MachineConfContext.Provider value={MachineConfContextValue}>
          <MachineConfContent savedConfProps={savedConfProps}>
            <Box className={classes.content}>
              <Box className={classes.topArea}>
                <Box className="returnButton">
                  <ReturnButton onClick={handleClickReturn}>
                    機器設定
                    <br />
                    詳細
                  </ReturnButton>
                </Box>
                <Box className="description">「ホームポジション」や「移動範囲」の設定内容です。</Box>
              </Box>
              <Box className={classes.mainArea}>
                <Box className="xyInputArea">
                  <Box className={classes.xyAreaParent}>
                    <Box className="homeXController">
                      <PositionControllerGrand
                        name={INPUT_NAMES.HOME_X}
                        type="H"
                        axis="X"
                        value={String(tempXySpeedLeverConf?.homeX || 0)}
                      />
                    </Box>
                    <Box className="homeYController">
                      <PositionControllerGrand
                        name={INPUT_NAMES.HOME_Y}
                        type="H"
                        axis="Y"
                        value={String(tempXySpeedLeverConf?.homeY || 0)}
                      />
                    </Box>
                    <Box className="endXController">
                      <PositionControllerGrand
                        name={INPUT_NAMES.END_X}
                        type="E"
                        axis="X"
                        value={String(tempXySpeedLeverConf?.endX || 0)}
                      />
                    </Box>
                    <Box className="endYController">
                      <PositionControllerGrand
                        name={INPUT_NAMES.END_Y}
                        type="E"
                        axis="Y"
                        value={String(tempXySpeedLeverConf?.endY || 0)}
                      />
                    </Box>
                    {enableCustomHome && (
                      <>
                        <Box className="customHomeXController">
                          <PositionControllerGrand
                            name={INPUT_NAMES.CUSTOM_X}
                            type="HP"
                            axis="X"
                            value={String(tempXySpeedLeverConf?.customX || 0)}
                          />
                        </Box>
                        <Box className="customHomeYController">
                          <PositionControllerGrand
                            name={INPUT_NAMES.CUSTOM_Y}
                            type="HP"
                            axis="Y"
                            value={String(tempXySpeedLeverConf?.customY || 0)}
                          />
                        </Box>
                      </>
                    )}
                    <Box className="minPositionXY">0</Box>
                    <Box className="maxPositionX">100</Box>
                    <Box className="maxPositionY">100</Box>
                    <Box className="xyArea">
                      <AreaDetailGrand
                        className="areaDetail"
                        displayCellSeparate={pitConf?.settingPattern === CLSFCN.PIT_PATTERN.DETAIL}
                        leftRight={leftRight}
                        size="M"
                        areaConf={areaConf}
                        pitConf={pitConf}
                        isDefault={areaConf && areaConf.areaConf === COMMON.SWITCH.OFF}
                      />
                      {/* 移動不可範囲エリアの表示 */}
                      <BoundAreaDetailGrand isLeft={isLeft} size="M" />
                      <XyAreaGrand
                        leftRight={leftRight}
                        enableCustomHome={enableCustomHome}
                        homePos={{ x: tempXySpeedLeverConf?.homeX || 0, y: tempXySpeedLeverConf?.homeY || 0 }}
                        customHomePos={{ x: tempXySpeedLeverConf?.customX || 0, y: tempXySpeedLeverConf?.customY || 0 }}
                        endPos={{ x: tempXySpeedLeverConf?.endX || 0, y: tempXySpeedLeverConf?.endY || 0 }}
                      />
                    </Box>
                  </Box>
                </Box>
              </Box>
            </Box>
          </MachineConfContent>
        </MachineConfContext.Provider>
      </Layout>
    </ScreenContext.Provider>
  );
};

export default XyRangeConfGrand;
