import * as React from 'react';
import { Box, Dialog, DialogContent, DialogContentText } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import NormalButton from 'components/system/atoms/buttons/NormalButton';
import { EquipmentSelectDataTableRow } from 'types/group/EquipmentSelectType';
import { useAppDispatch } from 'redux/hooks';
import { useHistory } from 'react-router-dom';
import redirectLinks from 'constants/redirectLinks';
import COMMON from 'constants/common';
import HttpConnection from 'utils/httpConnection';
import { REST_API } from 'constants/apiUrls';
import {
  setNextFromLedOrderSetScr,
  setNextFromOrderSetScr,
  setReturnFromCreateScr,
  setReturnFromLedCreateScr,
} from 'redux/slices/groupSlice';
import { renderMachineType } from 'utils/common.helper';
import { themeColor } from 'constants/theme';
import InfoDialog from './InfoDialog';
import MsgHelper from '../../../../utils/message.helper';
import MSG from '../../../../constants/messages';
import TooltipComponent from './TooltipComponent';

/* ************ Style ************ */
const useStyles = makeStyles(() => ({
  paper: {
    backgroundColor: 'transparent',
    width: '1000px',
    boxShadow: 'none',
  },
  content: {
    display: 'flex',
    paddingTop: '0!important',
    paddingBottom: '0!important',
    paddingLeft: '0!important',
    paddingRight: '0!important',
    overflow: 'hidden',
  },
  buttonCloseText: {
    fontSize: 28,
    color: '#ffffff',
  },
  buttonCloseStyle: {
    width: '156px',
    height: '70px',
    borderRadius: '8px',
  },
  dialogContent: {
    background: '#ffffff',
    width: '100%',
    marginLeft: '12px',
  },
  rowData: {
    display: 'flex',
    height: '55px',
    alignItems: 'center',
    fontSize: 21,
    borderBottom: '1px solid #707070',
  },
  rowDataBg: {
    background: themeColor.grandLightColor,
  },
  leftColumn: {
    width: '243px',
    paddingLeft: '12px',
  },
  rightWithButton: {
    flex: 1,
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    display: '-webkit-box',
    '-webkit-line-clamp': 2,
    '-webkit-box-orient': 'vertical',
  },
  rightColumn: {
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    height: '100%',
    paddingLeft: '12px',
    borderLeft: '1px solid #707070',
    '& .tdDetailButton': {
      height: '45px',
      width: '148px',
      backgroundColor: '#A2FFBB',
      fontSize: '19px',
    },
  },
  buttonType: {
    marginRight: '21px',
  },
}));

export type MachineInfoDialogContentGrand = {
  openDialog: boolean;
  machineInfo?: EquipmentSelectDataTableRow | undefined;
};

type MachineInfoDialogProps = {
  machineInfo: MachineInfoDialogContentGrand;
  onCancel: () => void;
};

type GroupExistResponse = {
  count: number;
};

const messageNotExist: string = MsgHelper.messageFormat(MSG.ERR604);

const MachineInfoDialogGrand: React.FC<MachineInfoDialogProps> = (props) => {
  const { machineInfo, onCancel } = props;
  const classes = useStyles();
  const history = useHistory();
  const dispatch = useAppDispatch();
  const [openErrorDialog, setOpenErrorDialog] = React.useState(false);

  const getGroup = async (groupId: number | undefined): Promise<boolean | null> => {
    const http = new HttpConnection({ dispatch });
    const data = await http.get<GroupExistResponse>(REST_API.MAINTENANCE.CHECK_GROUP_EXIST, { groupId });
    const isExist = data.count > 0;
    return Promise.resolve<boolean | null>(isExist);
  };

  const handleGoto = (groupId: number | undefined, url: string) => {
    if (url === redirectLinks.GROUP_LED_GROUP_CREATE) {
      dispatch(setReturnFromLedCreateScr(COMMON.SCREEN_ID.MACHINE_DETAIL));
      dispatch(setNextFromLedOrderSetScr(COMMON.SCREEN_ID.MACHINE_DETAIL));
    } else {
      dispatch(setReturnFromCreateScr(COMMON.SCREEN_ID.MACHINE_DETAIL));
      dispatch(setNextFromOrderSetScr(COMMON.SCREEN_ID.MACHINE_DETAIL));
    }

    getGroup(groupId).then((isExist) => {
      if (isExist) {
        history.push(url, {
          fromScrId: COMMON.SCREEN_ID.MACHINE_DETAIL,
          groupId,
        });
      } else {
        setOpenErrorDialog(true);
      }
    });
  };

  return (
    <Dialog
      classes={{
        paper: classes.paper,
      }}
      open={machineInfo.openDialog}
      onClose={onCancel}
      maxWidth={false}>
      <DialogContent
        classes={{
          root: classes.content,
        }}>
        <Box onClick={onCancel}>
          <DialogContentText>
            <div>
              <NormalButton baseColor="black" className={classes.buttonCloseStyle}>
                <span className={classes.buttonCloseText}>× 閉じる</span>
              </NormalButton>
            </div>
          </DialogContentText>
        </Box>
        <Box className={classes.dialogContent}>
          <div className={classes.rowData}>
            <div className={classes.leftColumn}>
              <span>登録番号</span>
            </div>
            <div className={classes.rightColumn}>
              <span>{machineInfo.machineInfo?.machineNo}</span>
            </div>
          </div>
          <div className={classes.rowData}>
            <div className={classes.leftColumn}>
              <span>機種名</span>
            </div>
            <div className={classes.rightColumn}>
              <span>{renderMachineType(machineInfo.machineInfo?.machineType || null)}</span>
            </div>
          </div>

          <div className={`${classes.rowData} ${classes.rowDataBg} `}>
            <div className={classes.leftColumn}>
              <span>ステーション名</span>
            </div>
            <div className={classes.rightColumn}>
              <div className={classes.rightWithButton}>
                <span>{machineInfo.machineInfo?.leftStationName}</span>
              </div>
            </div>
          </div>
          <div className={`${classes.rowData} ${classes.rowDataBg} `}>
            <div className={classes.leftColumn}>
              <span>景品名称</span>
            </div>
            <div className={classes.rightColumn}>
              <TooltipComponent tooltipText={machineInfo.machineInfo?.leftStationGiftName} lineClamp="2" />
            </div>
          </div>

          <div className={classes.rowData}>
            <div className={classes.leftColumn}>
              <span>獲得演出グループ</span>
            </div>
            <div className={classes.rightColumn}>
              {machineInfo.machineInfo?.earningProductionGroupId !== undefined &&
              machineInfo.machineInfo?.earningProductionGroupId !== null ? (
                <>
                  <div className={classes.rightWithButton}>
                    <span>{machineInfo.machineInfo?.earningProductionGroupName}</span>
                  </div>
                  <div className={classes.buttonType}>
                    <NormalButton
                      className="tdDetailButton"
                      onClick={() =>
                        handleGoto(
                          machineInfo?.machineInfo?.earningProductionGroupId,
                          redirectLinks.GROUP_GET_GIFT_GROUP_CREATE,
                        )
                      }>
                      グループ設定
                    </NormalButton>
                  </div>
                </>
              ) : (
                <span>---</span>
              )}
            </div>
          </div>
          <div className={classes.rowData}>
            <div className={classes.leftColumn}>
              <span>LED演出グループ</span>
            </div>
            <div className={classes.rightColumn}>
              {machineInfo.machineInfo?.ledProductionGroupId !== undefined &&
              machineInfo.machineInfo?.ledProductionGroupId !== null ? (
                <>
                  <div className={classes.rightWithButton}>
                    <span>{machineInfo.machineInfo?.ledProductionGroupName}</span>
                  </div>
                  <div className={classes.buttonType}>
                    <NormalButton
                      className="tdDetailButton"
                      onClick={() =>
                        handleGoto(machineInfo.machineInfo?.ledProductionGroupId, redirectLinks.GROUP_LED_GROUP_CREATE)
                      }>
                      グループ設定
                    </NormalButton>
                  </div>
                </>
              ) : (
                <span>---</span>
              )}
            </div>
          </div>
        </Box>
      </DialogContent>
      <InfoDialog open={openErrorDialog} msg={messageNotExist} closeFunc={() => setOpenErrorDialog(false)} />
    </Dialog>
  );
};

export default MachineInfoDialogGrand;
