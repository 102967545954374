import COMMON from 'constants/common';
import React, { useCallback, useEffect, useState } from 'react';
import Layout from 'components/system/layouts/Layout';
import ScreenContext from 'Contexts/Common/ScreenContext';
import MachineConfContent from 'components/machineConf/organisms/contents/MachineConfContent';
import { Box, makeStyles } from '@material-ui/core';
import { ReturnButton } from 'components/system/atoms';
import redirectLinks from 'constants/redirectLinks';
import { useHistory, useLocation } from 'react-router-dom';
import { themeColor } from 'constants/theme';
import downArrowIcon from 'assets/images/downArrowIcon_3.svg';
import partitionGraphDown from 'assets/images/partitionGraphDown.svg';
import partitionGraphUp from 'assets/images/partitionGraphUp.svg';
import UrlHelper from 'utils/url.helper';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import { handleCallSystemError } from 'redux/slices/commonSlice';
import ERROR_CODE from 'constants/errorCode';
import { getClassificationName } from 'utils/common.helper';
import CLSFCN from 'constants/classification';
import { SaveDetailInfoType } from 'types/machineConf/stationSelectType';

/* ************ Context ************ */
const ScreenContextValue = {
  title: 'サポートパーテーション設定',
  screenId: COMMON.SCREEN_ID.SUPPORT_PARTITION_GRAND,
  currentMenu: COMMON.MENU.MACHINE_CONF,
  disableContents: true,
};

/* ************ Style ************ */
const useStyles = makeStyles(() => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    minWidth: '1080px',
    minHeight: '563px',
    margin: '18px 24px',
  },
  topContent: {
    display: 'flex',
    alignItems: 'center',
    '& .description': {
      fontSize: '23px',
      marginLeft: '16px',
    },
  },
  middleContent: {
    display: 'flex',
    position: 'relative',
    marginTop: '12px',
    justifyContent: 'start',
    alignItems: 'center',
  },
  middleContentMarginTop: { marginTop: 4 },
  boxContent: {
    borderWidth: '2px',
    borderStyle: 'solid',
    borderRadius: '4px',
    backgroundColor: '#FFFFFF',
    borderColor: themeColor.grandDarkColor,
    display: 'flex',
    justifyContent: 'space-between',
    flexDirection: 'row',
    padding: 12,
  },
  boxPlayTo: {
    width: 215,
    height: 80,
    marginRight: 16,
  },
  boxTextContent: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  boxContinuesPlayTo: {
    width: 255,
    height: 80,
    marginRight: 32,
  },
  boxHeightLimit: {
    height: 56,
    marginRight: 13,
  },
  everyTimeContent: {
    height: 56,
  },
  boxContinuesPlay: {
    height: 56,
    marginRight: 7,
  },
  textContent: {
    fontSize: 20,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  textContentOut: {
    fontSize: 23,
  },
  heightLimitWrap: { marginRight: 5 },
  timeSpacing: { paddingLeft: 12 },
  operationWrap: { marginRight: 46, width: 159, textAlign: 'right', justifyContent: 'flex-end' },
  actionButton: {
    backgroundColor: '#32D7A5',
    color: '#FFFFFF',
    display: 'flex',
    fontSize: 21,
    justifyContent: 'center',
    alignItems: 'center',
    width: 96,
    height: 56,
    borderRadius: 8,
  },
  textInputContent: {
    width: 88,
    height: 56,
    borderWidth: '2px',
    borderStyle: 'solid',
    borderRadius: '4px',
    borderColor: themeColor.grandDarkColor,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    fontSize: 28,
    background: '#fff',
  },
  textInputContentDisable: {
    background: '#c3c3c3',
  },
  arrowRight: {
    width: 50,
    height: 56,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginRight: 16,
  },
  middleContentGraph: {
    position: 'relative',
    borderWidth: '2px',
    marginTop: 4,
    display: 'flex',
    justifyContent: 'start',
    alignItems: 'start',
    padding: '0px 132px 11px 0px',
  },
  barChartContent: {
    width: 823,
    height: 283,
  },
  coordinateX: {
    position: 'absolute',
    top: 0,
    width: 88,
    height: 283,
    left: 15,
  },
  coordinateY: {
    position: 'absolute',
    display: 'flex',
    width: 722,
    bottom: 12,
    height: 43,
    left: 90,
  },
  boxCoordinateYLabel: {
    position: 'absolute',
    fontSize: 18,
    right: 5,
    top: 9,
  },
  upperLimitLabel: {
    position: 'absolute',
    fontSize: 18,
    right: 44,
    top: 42,
  },
  downLimitLabel: {
    position: 'absolute',
    fontSize: 18,
    right: 44,
    top: 165,
  },
  downLimitLabelUp: {
    position: 'absolute',
    fontSize: 18,
    right: 44,
    top: 167,
  },
  boxCoordinateContent: {
    width: 32,
    height: 29,
    border: '1px solid #000000',
    borderRadius: 4,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    fontSize: 19,
  },
  boxCoordinateY: {
    position: 'absolute',
    right: 5,
  },
  boxCoordinateX: {
    position: 'absolute',
    top: 3,
  },
  boxCoordinateY1: {
    top: 40,
  },
  boxCoordinateY2: {
    top: 73,
  },
  boxCoordinateY2Up: {
    top: 97,
  },
  boxCoordinateY3: {
    top: 106,
  },
  boxCoordinateY3Up: {
    top: 130,
  },
  boxCoordinateY4: {
    top: 163,
  },
  boxCoordinateY4Up: {
    top: 163,
  },
  boxCoordinateX1: {
    left: 69,
  },
  boxCoordinateX2: {
    left: 141,
  },
  boxCoordinateX3: {
    left: 313,
  },
  boxCoordinateX4: {
    left: 485,
  },
  numberTimeLabel: {
    left: 545,
    fontSize: 18,
  },
  descentLabel: {
    fontSize: 18,
  },
  firstDescent: {
    position: 'absolute',
    top: 30,
    left: 168,
  },
  firstDescentUp: {
    position: 'absolute',
    top: 152,
    left: 108,
  },
  secondDescent: {
    position: 'absolute',
    top: 66,
    left: 242,
  },
  secondDescentUp: {
    position: 'absolute',
    top: 118,
    left: 182,
  },
  thirdDescent: {
    position: 'absolute',
    top: 92,
    left: 414,
  },
  thirdDescentUp: {
    position: 'absolute',
    top: 90,
    left: 300,
  },
  lastDescent: {
    position: 'absolute',
    top: 148,
    left: 582,
  },
  lastDescentUp: {
    position: 'absolute',
    top: 31,
    left: 527,
  },
  arrowIconUp: {
    transform: 'rotate(90deg)',
  },
  arrowIconDown: {
    transform: 'rotate(270deg)',
  },
}));

interface BarGraphImageType {
  upDown: boolean;
  playingReset: boolean;
  upHeightLimit: number;
  downHeightLimit: number;
  firstPlayCount: number;
  firstMovingDistance: number;
  secondPlayCount: number;
  secondMovingDistance: number;
  checkSecondMovingBlank: boolean;
}

/* ******** Function ******** */

const getUpDownText = (isUp: boolean) => (isUp ? '上昇' : '下降');

/* ******** Main Component ******** */
const BarGraphImage: React.FC<BarGraphImageType> = React.memo((partRefillMsgConf) => {
  const classes = useStyles();
  const g1 = partRefillMsgConf.upHeightLimit - partRefillMsgConf.firstMovingDistance;
  const g2 = g1 - partRefillMsgConf.secondMovingDistance;
  // gUp
  const gUp = partRefillMsgConf.downHeightLimit + partRefillMsgConf.firstMovingDistance;
  // y2
  const y2 = partRefillMsgConf.upDown
    ? Math.min(gUp + partRefillMsgConf.secondMovingDistance, partRefillMsgConf.upHeightLimit)
    : Math.max(g1, partRefillMsgConf.downHeightLimit);
  // Y3
  const y3 = partRefillMsgConf.upDown
    ? Math.min(partRefillMsgConf.upHeightLimit, gUp)
    : Math.max(g2, partRefillMsgConf.downHeightLimit);

  const arrY = [partRefillMsgConf.upHeightLimit, y2, y3, partRefillMsgConf.downHeightLimit];
  // X3
  const x3Down =
    g1 <= partRefillMsgConf.downHeightLimit
      ? partRefillMsgConf.firstPlayCount
      : partRefillMsgConf.firstPlayCount + partRefillMsgConf.secondPlayCount;
  const x3Up =
    gUp >= partRefillMsgConf.upHeightLimit
      ? partRefillMsgConf.firstPlayCount
      : partRefillMsgConf.firstPlayCount + partRefillMsgConf.secondPlayCount;

  // const x4Down
  const x4Down =
    g1 <= partRefillMsgConf.downHeightLimit
      ? partRefillMsgConf.firstPlayCount
      : Math.ceil(
          (partRefillMsgConf.upHeightLimit -
            partRefillMsgConf.firstMovingDistance -
            partRefillMsgConf.secondMovingDistance -
            partRefillMsgConf.downHeightLimit) /
            partRefillMsgConf.secondMovingDistance,
        ) *
          partRefillMsgConf.secondPlayCount +
        partRefillMsgConf.firstPlayCount +
        partRefillMsgConf.secondPlayCount;
  // const x4Up
  const x4Up =
    gUp >= partRefillMsgConf.upHeightLimit
      ? partRefillMsgConf.firstPlayCount
      : Math.ceil((partRefillMsgConf.upHeightLimit - gUp) / partRefillMsgConf.secondMovingDistance) *
          partRefillMsgConf.secondPlayCount +
        partRefillMsgConf.firstPlayCount;
  const arrX = [
    partRefillMsgConf.firstPlayCount,
    partRefillMsgConf.firstPlayCount,
    partRefillMsgConf.upDown ? x3Up : x3Down,
    partRefillMsgConf.upDown ? x4Up : x4Down,
  ];

  return (
    <>
      <div className={classes.barChartContent}>
        <img src={partRefillMsgConf.upDown ? partitionGraphUp : partitionGraphDown} alt="partitionGraphDown" />
        <div className={classes.coordinateX}>
          <div className={classes.boxCoordinateYLabel}>高さ</div>
          <div className={classes.upperLimitLabel}>上限</div>
          <div className={`${classes.boxCoordinateContent} ${classes.boxCoordinateY} ${classes.boxCoordinateY1}`}>
            {arrY[0]}
          </div>
          <div
            className={`${classes.boxCoordinateContent} ${classes.boxCoordinateY} ${
              partRefillMsgConf.upDown ? classes.boxCoordinateY2Up : classes.boxCoordinateY2
            }`}>
            {partRefillMsgConf.checkSecondMovingBlank && partRefillMsgConf.upDown ? COMMON.BLANK1 : arrY[1]}
          </div>
          <div
            className={`${classes.boxCoordinateContent} ${classes.boxCoordinateY} ${
              partRefillMsgConf.upDown ? classes.boxCoordinateY3Up : classes.boxCoordinateY3
            }`}>
            {partRefillMsgConf.checkSecondMovingBlank && !partRefillMsgConf.upDown ? COMMON.BLANK1 : arrY[2]}
          </div>
          <div className={classes.downLimitLabelUp}>下限</div>
          <div
            className={`${classes.boxCoordinateContent} ${classes.boxCoordinateY} ${
              partRefillMsgConf.upDown ? classes.boxCoordinateY4Up : classes.boxCoordinateY4
            }`}>
            {arrY[3]}
          </div>
        </div>
        <div className={classes.coordinateY}>
          <div className={`${classes.boxCoordinateContent} ${classes.boxCoordinateX} ${classes.boxCoordinateX1}`}>
            {arrX[0]}
          </div>
          <div className={`${classes.boxCoordinateContent} ${classes.boxCoordinateX} ${classes.boxCoordinateX2}`}>
            {arrX[1]}
          </div>
          <div className={`${classes.boxCoordinateContent} ${classes.boxCoordinateX} ${classes.boxCoordinateX3}`}>
            {partRefillMsgConf.checkSecondMovingBlank ? COMMON.BLANK1 : arrX[2]}
          </div>
          <div className={`${classes.boxCoordinateContent} ${classes.boxCoordinateX} ${classes.boxCoordinateX4}`}>
            {arrX[3]}
          </div>
          <div className={`${classes.boxCoordinateX} ${classes.numberTimeLabel}`}>回数(プレイ)</div>
        </div>
        <div>
          <div
            className={`${classes.descentLabel} ${
              partRefillMsgConf.upDown ? classes.firstDescentUp : classes.firstDescent
            }`}>
            {`${getUpDownText(partRefillMsgConf.upDown)}開始`}
          </div>
          <div
            className={`${classes.descentLabel} ${
              partRefillMsgConf.upDown ? classes.secondDescentUp : classes.secondDescent
            }`}>
            {`1回${getUpDownText(partRefillMsgConf.upDown)}`}
          </div>
          <div
            className={`${classes.descentLabel} ${
              partRefillMsgConf.upDown ? classes.thirdDescentUp : classes.thirdDescent
            }`}>
            {`2回目以降${getUpDownText(partRefillMsgConf.upDown)}`}
          </div>
          <div
            className={`${classes.descentLabel} ${
              partRefillMsgConf.upDown ? classes.lastDescentUp : classes.lastDescent
            }`}>
            {`${getUpDownText(partRefillMsgConf.upDown)}終了`}
          </div>
        </div>
      </div>
    </>
  );
});

/**
 *
 * @returns {React.FC} - サポートパーテーション設定
 *
 */
const SupportPartitionGrand: React.FC = () => {
  const classes = useStyles();
  const history = useHistory();
  const location = useLocation();
  const [savedConfProps] = useState(location.state !== undefined ? (location.state as SaveDetailInfoType) : null);
  const dispatch = useAppDispatch();
  const partRefillMsgConf = useAppSelector((state) => state.machineConf.machineGrandConf.partRefillMsgConf);
  const isUp = partRefillMsgConf?.upDown === COMMON.UP_DOWN.UP;
  const [isCheckRenderBlankSecondMovingDistance, setIsCheckRenderBlankSecondMovingDistance] = React.useState(false);

  useEffect(() => {
    if (!partRefillMsgConf) {
      dispatch(handleCallSystemError({ errorNo: ERROR_CODE.NOT_FOUND }));
    }
  }, []);

  // 戻るボタン
  const handleClickReturn = () => {
    history.push(
      UrlHelper.convertQueryUrlFrontEnd(redirectLinks.MCONF_GRAND_HOME_DETAIL, {
        selectConfId: 'none',
      }),
    );
  };

  /**
   * get second play cont and second moving distance
   */
  const checkRenderBlankSecondMovingDistance = useCallback(
    (isUpCheck: boolean) => {
      let isOk = false;
      if (isUpCheck) {
        if (
          partRefillMsgConf &&
          partRefillMsgConf?.downHeightLimit + Number.parseInt(partRefillMsgConf?.firstMovingDistance ?? '0', 10) >=
            partRefillMsgConf?.upHeightLimit
        ) {
          isOk = true;
        }
      } else if (
        partRefillMsgConf &&
        partRefillMsgConf?.upHeightLimit - Number.parseInt(partRefillMsgConf?.firstMovingDistance ?? '0', 10) <=
          partRefillMsgConf?.downHeightLimit
      ) {
        isOk = true;
      }
      return isOk;
    },
    [partRefillMsgConf],
  );

  useEffect(() => {
    const checkMoving = checkRenderBlankSecondMovingDistance(isUp);
    setIsCheckRenderBlankSecondMovingDistance(checkMoving);
  }, [partRefillMsgConf, isUp]);

  return (
    <ScreenContext.Provider value={ScreenContextValue}>
      <Layout>
        <MachineConfContent savedConfProps={savedConfProps}>
          <Box className={classes.root}>
            <Box className={classes.topContent}>
              <ReturnButton onClick={handleClickReturn}>
                機器設定
                <br />
                詳細
              </ReturnButton>
              <Box className="description">「サポートパーテーション」の設定内容です。</Box>
            </Box>
            <Box className={classes.middleContent}>
              <Box className={`${classes.boxContent} ${classes.boxPlayTo}`}>
                <Box className={classes.textContent}>
                  プレイに
                  <br />
                  連動して
                </Box>
                <Box className={classes.actionButton}>{getUpDownText(isUp)}</Box>
              </Box>
              <Box className={`${classes.boxContent} ${classes.boxContinuesPlayTo}`}>
                <Box className={classes.textContent}>
                  連続プレイ
                  <br />
                  終了時リセット
                </Box>
                <Box className={classes.textContent}>
                  {getClassificationName(CLSFCN.ON_OFF.CATEGORY_CD, partRefillMsgConf?.playingReset ?? '')}
                </Box>
              </Box>
              <Box className={`${classes.boxTextContent} ${classes.boxHeightLimit}`}>
                <Box className={`${classes.textContent} ${classes.textContentOut} ${classes.heightLimitWrap}`}>
                  高さ上限
                </Box>
                <Box className={classes.textInputContent}>{partRefillMsgConf?.upHeightLimit}</Box>
              </Box>
              <Box className={`${classes.arrowRight}`}>
                <img
                  src={downArrowIcon}
                  alt="downArrowIcon"
                  className={isUp ? classes.arrowIconUp : classes.arrowIconDown}
                />
              </Box>
              <Box className={`${classes.boxTextContent} ${classes.boxHeightLimit}`}>
                <Box className={`${classes.textContent} ${classes.textContentOut} ${classes.heightLimitWrap}`}>
                  高さ下限
                </Box>
                <Box className={classes.textInputContent}>{partRefillMsgConf?.downHeightLimit}</Box>
              </Box>
            </Box>
            <Box className={`${classes.middleContent} ${classes.middleContentMarginTop}`}>
              <Box className={`${classes.boxTextContent} ${classes.boxContinuesPlay}`}>
                <Box className={`${classes.textContent} ${classes.textContentOut} ${classes.operationWrap}`}>
                  初回動作
                </Box>
                <Box className={`${classes.textContent} ${classes.textContentOut}`}>連続プレイ</Box>
              </Box>
              <Box className={`${classes.boxTextContent} ${classes.boxHeightLimit}`}>
                <Box className={classes.textInputContent}>{partRefillMsgConf?.firstPlayCount}</Box>
                <Box className={`${classes.textContent} ${classes.textContentOut} ${classes.timeSpacing}`}>回で</Box>
              </Box>
              <Box className={`${classes.boxTextContent} ${classes.everyTimeContent}`}>
                <Box className={classes.textInputContent}>{partRefillMsgConf?.firstMovingDistance}</Box>
                <Box className={`${classes.textContent} ${classes.textContentOut} ${classes.timeSpacing}`}>
                  {isUp ? `上昇` : '下降'}
                </Box>
              </Box>
            </Box>
            <Box className={`${classes.middleContent} ${classes.middleContentMarginTop}`}>
              <Box className={`${classes.boxTextContent} ${classes.boxContinuesPlay}`}>
                <Box className={`${classes.textContent} ${classes.textContentOut} ${classes.operationWrap}`}>
                  2回目以降動作
                </Box>
                <Box className={`${classes.textContent} ${classes.textContentOut}`}>連続プレイ</Box>
              </Box>
              <Box className={`${classes.boxTextContent} ${classes.boxHeightLimit}`}>
                <Box
                  className={`${classes.textInputContent} ${
                    isCheckRenderBlankSecondMovingDistance ? classes.textInputContentDisable : ''
                  }`}>
                  {isCheckRenderBlankSecondMovingDistance ? COMMON.BLANK1 : partRefillMsgConf?.secondPlayCount}
                </Box>
                <Box className={`${classes.textContent} ${classes.textContentOut} ${classes.timeSpacing}`}>回で</Box>
              </Box>
              <Box className={`${classes.boxTextContent} ${classes.everyTimeContent}`}>
                <Box
                  className={`${classes.textInputContent} ${
                    isCheckRenderBlankSecondMovingDistance ? classes.textInputContentDisable : ''
                  }`}>
                  {isCheckRenderBlankSecondMovingDistance ? COMMON.BLANK1 : partRefillMsgConf?.secondMovingDistance}
                </Box>
                <Box className={`${classes.textContent} ${classes.textContentOut} ${classes.timeSpacing}`}>
                  {isUp ? `上昇` : '下降'}
                </Box>
              </Box>
            </Box>
            <Box className={classes.middleContentGraph}>
              <BarGraphImage
                upDown={isUp}
                playingReset={partRefillMsgConf?.playingReset === CLSFCN.ON_OFF.ON}
                upHeightLimit={partRefillMsgConf?.upHeightLimit ?? 0}
                downHeightLimit={partRefillMsgConf?.downHeightLimit ?? 0}
                firstPlayCount={parseInt(partRefillMsgConf?.firstPlayCount ?? '0', 10)}
                firstMovingDistance={parseInt(partRefillMsgConf?.firstMovingDistance ?? '0', 10)}
                secondPlayCount={parseInt(partRefillMsgConf?.secondPlayCount ?? '0', 10)}
                secondMovingDistance={parseInt(partRefillMsgConf?.secondMovingDistance ?? '0', 10)}
                checkSecondMovingBlank={isCheckRenderBlankSecondMovingDistance}
              />
            </Box>
          </Box>
        </MachineConfContent>
      </Layout>
    </ScreenContext.Provider>
  );
};

export default SupportPartitionGrand;
