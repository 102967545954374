import { makeStyles, Table, TableBody, TableCell, TableContainer, TableRow } from '@material-ui/core';
import React from 'react';
import COMMON from 'constants/common';
import { TotalDataTableType, TotalDataType } from 'types/data/dataType';
import { getStationName, renderMachineType } from 'utils/common.helper';
import MachineStationTableHead, { HeaderType } from '../../../common/organisms/headers/MachineStationTableHead';
import ListCheckBox from '../../../system/atoms/checkBoxs/ListCheckBox';
import TooltipComponent from '../../../system/organisms/dialogs/TooltipComponent';

/* ************ Style ************ */

interface TableProps {
  height?: number;
}

/* ************ Style ************ */
const useStyles = makeStyles(() => ({
  container: (props: TableProps) => ({
    height: props.height || '407px',
    width: '1100px',
  }),
  table: {
    tableLayout: 'fixed',
    '& th,td': {
      border: '1px solid #707070',
      fontSize: '23px',
      lineHeight: '28px',
      minHeight: '74px',
    },
    '& th': {
      textAlign: 'center',
      height: '58px',
      padding: 0,
    },
    '& tbody tr': {
      minHeight: '74px',
    },
    '& td': {
      padding: '0 8px',
      '& .cellStyle': {
        fontSize: '16px',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
      },
    },
  },
  tdStation: {
    textAlign: 'left',
    '&.top': {
      verticalAlign: 'top',
    },
  },
}));
const useCellStyles = makeStyles(() => ({
  tdStation: {
    textAlign: 'left',
  },
  tdTargetDatetime: {
    textAlign: 'center',
    fontSize: '16px',
    whiteSpace: 'pre-line',
    overflow: 'hidden',
    height: 58,
  },
  tdOthers: {
    padding: '0 8px',
    textAlign: 'left',
    fontSize: '16px',
    overflow: 'hidden',
    height: 58,
  },
  tdNoPadding: {
    padding: '0 !important',
  },
  tdContentFirst: {
    borderBottom: '1px solid #000',
  },
  tdContent: {
    display: 'flex',
    flexDirection: 'row',
    padding: '0 8px',
    height: 58,
  },
  stationRowName: {
    flex: 1,
    width: '484px',
    paddingRight: 8,
    fontSize: '16px',
    paddingTop: 5,
    paddingBottom: 5,
    lineHeight: '25px',
  },
  formatLongTextStationName: {
    width: 200,
    textOverflow: 'ellipsis',
    '& div': {
      display: 'block',
    },
  },
}));

const TotalDataTableCell: React.FC<{
  row: Array<TotalDataType>;
  handleClickCheckBox: (machineNo: string, stationId: number | null) => void;
}> = React.memo(({ row, handleClickCheckBox }) => {
  const classes = useCellStyles();
  if (row[0].machineType === COMMON.MACHINE_TYPE.CLENA3) {
    return (
      <>
        <TableCell className={`${classes.tdStation} ${classes.tdNoPadding}`}>
          <div className={`${classes.tdTargetDatetime} ${classes.tdContentFirst}`}>
            {row[0].targetDatetime ?? COMMON.BLANK1}
          </div>
          {row[1] ? (
            <div className={`${classes.tdTargetDatetime}`}>{row[1].targetDatetime ?? COMMON.BLANK1}</div>
          ) : (
            <></>
          )}
        </TableCell>
        <TableCell className={`${classes.tdStation} ${classes.tdNoPadding}`}>
          <div className={`${classes.tdContent} ${classes.tdContentFirst}`}>
            <ListCheckBox
              checked={row[0].selected ?? false}
              name="selectStation"
              onChange={() => handleClickCheckBox(row[0].boardSerial, row[0].stationId)}
            />
            <div className={classes.stationRowName}>
              <div className={`cellStyle ${classes.formatLongTextStationName}`}>
                <TooltipComponent
                  tooltipText={getStationName(row[0].machineType, row[0].stationName, COMMON.LEFT_RIGHT.LEFT)}
                  displayWord={getStationName(row[0].machineType, row[0].stationName, COMMON.LEFT_RIGHT.LEFT)}
                  isBlank
                />
              </div>
              <div className={`cellStyle ${classes.formatLongTextStationName}`}>
                <TooltipComponent
                  tooltipText={row[0].giftName === '' ? COMMON.BLANK : row[0].giftName}
                  displayWord={row[0].giftName === '' ? COMMON.BLANK : row[0].giftName}
                />
              </div>
            </div>
          </div>
          {row[1] ? (
            <div className={classes.tdContent}>
              <ListCheckBox
                checked={row[1].selected === undefined ? false : row[1].selected}
                name="selectStation"
                onChange={() => handleClickCheckBox(row[1].boardSerial, row[1].stationId)}
              />
              <div className={classes.stationRowName}>
                <div className={`cellStyle ${classes.formatLongTextStationName}`}>
                  <TooltipComponent
                    tooltipText={getStationName(row[1].machineType, row[1].stationName, COMMON.LEFT_RIGHT.RIGHT)}
                    displayWord={getStationName(row[1].machineType, row[1].stationName, COMMON.LEFT_RIGHT.RIGHT)}
                  />
                </div>
                <div className={`cellStyle ${classes.formatLongTextStationName}`}>
                  <TooltipComponent
                    tooltipText={row[1].giftName === '' ? COMMON.BLANK : row[1].giftName}
                    displayWord={row[1].giftName === '' ? COMMON.BLANK : row[1].giftName}
                  />
                </div>
              </div>
            </div>
          ) : (
            <></>
          )}
        </TableCell>
        <TableCell className={`${classes.tdStation} ${classes.tdNoPadding}`}>
          <div className={`${classes.tdOthers} ${classes.tdContentFirst}`}>
            {row[0].coinSalesTotal ?? COMMON.BLANK1}
            <br />
            {row[0].playTotal ?? COMMON.BLANK1}
          </div>
          {row[1] ? (
            <div className={`${classes.tdOthers}`}>
              {row[1].coinSalesTotal ?? COMMON.BLANK1}
              <br />
              {row[1].playTotal ?? COMMON.BLANK1}
            </div>
          ) : (
            <></>
          )}
        </TableCell>
        <TableCell className={`${classes.tdStation} ${classes.tdNoPadding}`}>
          <div className={`${classes.tdOthers} ${classes.tdContentFirst}`}>
            {row[0].coinTotal ?? COMMON.BLANK1}
            <br />
            {row[0].payoutTotal ?? COMMON.BLANK1}
          </div>
          {row[1] ? (
            <div className={`${classes.tdOthers}`}>
              {row[1].coinTotal ?? COMMON.BLANK1}
              <br />
              {row[1].payoutTotal ?? COMMON.BLANK1}
            </div>
          ) : (
            <></>
          )}
        </TableCell>
        <TableCell className={`${classes.tdStation} ${classes.tdNoPadding}`}>
          <div className={`${classes.tdOthers} ${classes.tdContentFirst}`}>
            {row[0].serviceTotal ?? COMMON.BLANK1}
            <br />
            {row[0].creditClearTotal ?? COMMON.BLANK1}
          </div>
          {row[1] ? (
            <div className={`${classes.tdOthers}`}>
              {row[1].serviceTotal ?? COMMON.BLANK1}
              <br />
              {row[1].creditClearTotal ?? COMMON.BLANK1}
            </div>
          ) : (
            <></>
          )}
        </TableCell>
      </>
    );
  }
  return (
    <>
      <TableCell className={`${classes.tdStation} ${classes.tdNoPadding}`}>
        <div className={`${classes.tdTargetDatetime}`}>{row[0].targetDatetime ?? COMMON.BLANK1}</div>
      </TableCell>
      <TableCell className={`${classes.tdStation} ${classes.tdNoPadding}`}>
        <div className={classes.tdContent}>
          <ListCheckBox
            checked={row[0].selected === undefined ? false : row[0].selected}
            name="selectStation"
            onChange={() => handleClickCheckBox(row[0].boardSerial, row[0].stationId)}
          />
          <div className={classes.stationRowName}>
            <div className={`cellStyle ${classes.formatLongTextStationName}`}>
              <TooltipComponent
                tooltipText={getStationName(row[0].machineType, row[0].stationName, COMMON.LEFT_RIGHT.LEFT)}
                displayWord={getStationName(row[0].machineType, row[0].stationName, COMMON.LEFT_RIGHT.LEFT)}
                isBlank
              />
            </div>
            <div className={`cellStyle ${classes.formatLongTextStationName}`}>
              <TooltipComponent
                tooltipText={row[0].giftName === '' ? COMMON.BLANK : row[0].giftName}
                displayWord={row[0].giftName === '' ? COMMON.BLANK : row[0].giftName}
              />
            </div>
          </div>
        </div>
      </TableCell>
      <TableCell className={`${classes.tdStation} ${classes.tdNoPadding}`}>
        <div className={`${classes.tdOthers}`}>
          {row[0].coinSalesTotal ?? COMMON.BLANK1}
          <br />
          {row[0].playTotal ?? COMMON.BLANK1}
        </div>
      </TableCell>
      <TableCell className={`${classes.tdStation} ${classes.tdNoPadding}`}>
        <div className={`${classes.tdOthers}`}>
          {row[0].coinTotal ?? COMMON.BLANK1}
          <br />
          {row[0].payoutTotal ?? COMMON.BLANK1}
        </div>
      </TableCell>
      <TableCell className={`${classes.tdStation} ${classes.tdNoPadding}`}>
        <div className={`${classes.tdOthers}`}>
          {row[0].serviceTotal ?? COMMON.BLANK1}
          <br />
          {row[0].creditClearTotal ?? COMMON.BLANK1}
        </div>
      </TableCell>
    </>
  );
});

const TotalDataTable: React.FC<TotalDataTableType> = ({ tableId, stationList, handleSelectedItemChange, height }) => {
  const classes = useStyles({ height });
  const headerColumns: Array<HeaderType> = [
    { id: 'machineNoMachineName', title: '登録番号\n機種名', width: 140 },
    { id: 'targetDatetime', title: '最終\n取得日時', width: 120 },
    { id: 'stationGift', title: 'ステーション名\n景品名', width: 220 },
    { id: 'salesPlay', title: '売上\nプレイ回数', width: 120 },
    { id: 'coinPayout', title: 'コイン枚数\n景品払い出し数', width: 140 },
    { id: 'serviceCreditClear', title: 'サービス回数\nクレジットクリア数', width: 180 },
  ];

  return (
    <TableContainer id={tableId} className={classes.container}>
      <Table className={classes.table} stickyHeader aria-label="sticky table">
        <MachineStationTableHead columns={headerColumns} />
        <TableBody>
          {stationList &&
            stationList.map((row) => (
              <TableRow key={`table-row-key-${row[0].boardSerial}`}>
                <TableCell className={`${classes.tdStation} top`}>
                  <div className="cellStyle">{row[0].boardSerial}</div>
                  <div className="cellStyle">{renderMachineType(row[0].machineType)}</div>
                </TableCell>
                <TotalDataTableCell row={row} handleClickCheckBox={handleSelectedItemChange} />
              </TableRow>
            ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default TotalDataTable;
