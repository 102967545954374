import { Box, makeStyles, Table, TableRow } from '@material-ui/core';
import CLSFCN from 'constants/classification';
import React from 'react';
import COMMON from 'constants/common';
import { useAppSelector } from 'redux/hooks';
import { getClassificationName } from 'utils/common.helper';

/* ************ Style ************ */
const useStyles = makeStyles((theme) => ({
  title: {
    fontSize: '24px',
    lineHeight: '28px',
  },
  detailBlock: {
    borderCollapse: 'collapse',
    fontSize: '14px',
  },
  detailArmBlock: {
    borderCollapse: 'collapse',
    fontSize: '14px',
    marginTop: 7,
    width: '171px',
  },
  areaTop: {
    backgroundColor: '#D2F9FF',
    height: '27px',
    border: '1px solid #707070',
    textAlign: 'center',
    fontSize: '14px',
    width: '84px',
  },
  areaTopNonBorderBottom: {
    backgroundColor: '#D2F9FF',
    height: '27px',
    border: '1px solid #707070',
    textAlign: 'center',
    fontSize: '14px',
    width: '84px',
    borderBottomColor: 'transparent',
  },
  armNonBorderBottom: {
    backgroundColor: '#D2F9FF',
    height: '27px',
    border: '1px solid #707070',
    textAlign: 'center',
    fontSize: '14px',
    width: '106px',
    borderBottomColor: 'transparent',
  },
  areaTopValue: {
    backgroundColor: '#ffffff',
    border: '1px solid #707070',
    height: '27px',
    textAlign: 'center',
    fontSize: '14px',
    width: '66px',
  },
  areaTopValueNonBorderBottom: {
    backgroundColor: '#ffffff',
    border: '1px solid #707070',
    height: '27px',
    textAlign: 'center',
    fontSize: '14px',
    width: '66px',
    borderBottomColor: 'transparent',
  },
  armSizeValue: {
    backgroundColor: '#ffffff',
    border: '1px solid #707070',
    height: '27px',
    textAlign: 'center',
    fontSize: '14px',
    borderBottomColor: 'transparent',
  },
  armSizeItem: {
    width: '72px',
  },
  armSizeWrap: {
    width: '210px',
    display: 'flex',
    alignItems: 'center',
  },
  areaBottom: {
    backgroundColor: '#D2F9FF',
    border: '1px solid #707070',
    height: '60px',
    width: '60px',
    lineHeight: '18px',
    verticalAlign: 'middle',
    textAlign: 'center',
    fontSize: '14px',
  },
  areaSmallBottom: {
    backgroundColor: '#D2F9FF',
    border: '1px solid #707070',
    height: '60px',
    width: '40px',
    lineHeight: '18px',
    verticalAlign: 'middle',
    textAlign: 'center',
    fontSize: '14px',
  },
  areaBottomValue: {
    backgroundColor: '#ffffff',
    border: '1px solid #707070',
    height: '60px',
    width: '60px',
    verticalAlign: 'middle',
    textAlign: 'center',
    fontSize: '14px',
  },
  bgWhite: {
    background: '#ffffff',
    width: '44px',
    height: '44px',
    border: '1px solid #707070',
    margin: '0 auto',
  },
  bgGreen: {
    background: '#C9F2E2',
    width: '44px',
    height: '44px',
    border: '1px solid #707070',
    margin: '0 auto',
  },
  bgPurple: {
    background: '#EBCFEB',
    width: '44px',
    height: '44px',
    border: '1px solid #707070',
    margin: '0 auto',
  },
  bgYellow: {
    background: '#FFFDB5',
    width: '44px',
    height: '44px',
    border: '1px solid #707070',
    margin: '0 auto',
  },
  bgRed: {
    background: '#FFC8C8',
    width: '44px',
    height: '44px',
    border: '1px solid #707070',
    margin: '0 auto',
  },
  bgBlue: {
    background: '#B4DFFF',
    width: '44px',
    height: '44px',
    border: '1px solid #707070',
    margin: '0 auto',
  },
  table: {
    border: (props: { isLeft: boolean }) =>
      props.isLeft ? `2px solid ${theme.grandStation.contentsBorder}` : '2px solid rgb(255, 67, 134)',
    borderRadius: 4,
    padding: '5px 10px',
    height: '580px',
    width: '320px',
  },
  emptyRow: {
    height: 27,
  },
}));

const TableStationHomeDetailGrand: React.FC<{ isLeft: boolean; isStationConf: boolean }> = ({
  isLeft,
  isStationConf,
}) => {
  const {
    machineGrandConf: { areaConf, conf },
    showAreaColor,
  } = useAppSelector((state) => state.machineConf);
  const classes = useStyles({ isLeft });

  let leftArmSizeFlag = false;
  let rightArmSizeFlag = false;
  let centerArmSizeFlag = false;
  if (
    conf?.leftArmSize === COMMON.ARM_SIZE.NONE ||
    conf?.leftArmSize === COMMON.ARM_SIZE.S ||
    conf?.leftArmSize === COMMON.ARM_SIZE.M ||
    conf?.leftArmSize === COMMON.ARM_SIZE.L ||
    conf?.leftArmSize === COMMON.ARM_SIZE.SS ||
    conf?.leftArmSize === COMMON.ARM_SIZE.LL
  ) {
    leftArmSizeFlag = true;
  }
  if (
    conf?.rightArmSize === COMMON.ARM_SIZE.NONE ||
    conf?.rightArmSize === COMMON.ARM_SIZE.S ||
    conf?.rightArmSize === COMMON.ARM_SIZE.M ||
    conf?.rightArmSize === COMMON.ARM_SIZE.L ||
    conf?.rightArmSize === COMMON.ARM_SIZE.SS ||
    conf?.rightArmSize === COMMON.ARM_SIZE.LL
  ) {
    rightArmSizeFlag = true;
  }
  if (
    conf?.centerArmSize === COMMON.ARM_SIZE.NONE ||
    conf?.centerArmSize === COMMON.ARM_SIZE.S ||
    conf?.centerArmSize === COMMON.ARM_SIZE.M ||
    conf?.centerArmSize === COMMON.ARM_SIZE.L ||
    conf?.centerArmSize === COMMON.ARM_SIZE.SS ||
    conf?.centerArmSize === COMMON.ARM_SIZE.LL
  ) {
    centerArmSizeFlag = true;
  }

  const renderArmSize = () => (
    <Table className={classes.detailBlock}>
      <TableRow>
        <td className={classes.areaTopNonBorderBottom}>ｱｰﾑｻｲｽﾞ</td>
        <td className={classes.armSizeValue}>
          <Box className={classes.armSizeWrap}>
            <Box className={classes.armSizeItem}>
              {leftArmSizeFlag
                ? getClassificationName(CLSFCN.ARM_SIZE.CATEGORY_CD, String(conf?.leftArmSize))
                : COMMON.BLANK1}
            </Box>
            /
            <Box className={classes.armSizeItem}>
              {centerArmSizeFlag
                ? getClassificationName(CLSFCN.ARM_SIZE.CATEGORY_CD, String(conf?.centerArmSize))
                : COMMON.BLANK1}
            </Box>
            /
            <Box className={classes.armSizeItem}>
              {rightArmSizeFlag
                ? getClassificationName(CLSFCN.ARM_SIZE.CATEGORY_CD, String(conf?.rightArmSize))
                : COMMON.BLANK1}
            </Box>
          </Box>
        </td>
      </TableRow>
    </Table>
  );

  const renderArmSizeEmpty = () => <Box className={classes.emptyRow} />;

  return (
    <Box className={classes.table}>
      <Box className={classes.title}>
        <b>アーム詳細</b>
      </Box>
      {isStationConf ? renderArmSize() : renderArmSizeEmpty()}
      <Table>
        <TableRow>
          <td className={classes.areaTop}>初期ｱｰﾑ状態</td>
          <td className={classes.areaTopValue}>
            {getClassificationName(CLSFCN.ARM_CONDITION.CATEGORY_CD, String(areaConf?.armCondition))}
          </td>
          <td className={classes.areaTop}>初期高さ</td>
          <td className={classes.areaTopValue}>{areaConf?.initialHeight}</td>
        </TableRow>

        <TableRow>
          <td className={classes.areaTop}>ｱｰﾑ開度</td>
          <td className={classes.areaTopValue}>
            {getClassificationName(CLSFCN.ARM_OPEN.CATEGORY_CD, String(areaConf?.armOpen))}
          </td>
          {isStationConf ? (
            <>
              <td className={classes.areaTop}>角度</td>
              <td className={classes.areaTopValue}>
                {getClassificationName(CLSFCN.CATCHER_ANGEL.CATEGORY_CD, String(areaConf?.catcherAngle))}
              </td>
            </>
          ) : (
            <></>
          )}
        </TableRow>
      </Table>

      <Table className={classes.detailArmBlock}>
        <TableRow>
          <td className={classes.armNonBorderBottom}>エリア設定</td>
          <td className={classes.areaTopValueNonBorderBottom}>
            {getClassificationName(CLSFCN.ON_OFF.CATEGORY_CD, areaConf?.areaConf ?? COMMON.SWITCH.OFF)}
          </td>
        </TableRow>
      </Table>

      <Table>
        <TableRow>
          <td className={classes.areaBottom}>ｴﾘｱ</td>
          <td className={classes.areaSmallBottom}>左</td>
          <td className={classes.areaSmallBottom}>中</td>
          <td className={classes.areaSmallBottom}>右</td>
          <td className={classes.areaBottom}>
            下降
            <br /> 限界
          </td>
          <td className={classes.areaBottom}>
            押込 <br />力
          </td>
        </TableRow>
        <TableRow>
          <td className={classes.areaBottomValue}>
            <Box className={classes.bgWhite}>&nbsp;</Box>
          </td>
          <td className={classes.areaBottomValue}>
            {((showAreaColor.area0 || areaConf?.areaConf === COMMON.SWITCH.OFF) && areaConf?.lPower1) || COMMON.BLANK1}
          </td>
          <td className={classes.areaBottomValue}>
            {((showAreaColor.area0 || areaConf?.areaConf === COMMON.SWITCH.OFF) && areaConf?.cPower1) || COMMON.BLANK1}
          </td>
          <td className={classes.areaBottomValue}>
            {((showAreaColor.area0 || areaConf?.areaConf === COMMON.SWITCH.OFF) && areaConf?.rPower1) || COMMON.BLANK1}
          </td>
          <td className={classes.areaBottomValue}>
            {((showAreaColor.area0 || areaConf?.areaConf === COMMON.SWITCH.OFF) && areaConf?.descentLimit1) ||
              COMMON.BLANK1}
          </td>
          <td className={classes.areaBottomValue}>
            {((showAreaColor.area0 || areaConf?.areaConf === COMMON.SWITCH.OFF) &&
              getClassificationName(CLSFCN.AREA_CONFIG.PUSHING_CATEGORY_CD, String(areaConf?.pushing1))) ||
              COMMON.BLANK1}
          </td>
        </TableRow>
        {areaConf?.areaConf !== COMMON.SWITCH.OFF && (
          <>
            <TableRow>
              <td className={classes.areaBottomValue}>
                <Box className={classes.bgGreen}>&nbsp;</Box>
              </td>
              <td className={classes.areaBottomValue}>{(showAreaColor.area1 && areaConf?.lPower2) || COMMON.BLANK1}</td>
              <td className={classes.areaBottomValue}>{(showAreaColor.area1 && areaConf?.cPower2) || COMMON.BLANK1}</td>
              <td className={classes.areaBottomValue}>{(showAreaColor.area1 && areaConf?.rPower2) || COMMON.BLANK1}</td>
              <td className={classes.areaBottomValue}>
                {(showAreaColor.area1 && areaConf?.descentLimit2) || COMMON.BLANK1}
              </td>
              <td className={classes.areaBottomValue}>
                {(showAreaColor.area1 &&
                  getClassificationName(CLSFCN.AREA_CONFIG.PUSHING_CATEGORY_CD, String(areaConf?.pushing2))) ||
                  COMMON.BLANK1}
              </td>
            </TableRow>
            <TableRow>
              <td className={classes.areaBottomValue}>
                <Box className={classes.bgPurple}>&nbsp;</Box>
              </td>
              <td className={classes.areaBottomValue}>{(showAreaColor.area2 && areaConf?.lPower3) || COMMON.BLANK1}</td>
              <td className={classes.areaBottomValue}>{(showAreaColor.area2 && areaConf?.cPower3) || COMMON.BLANK1}</td>
              <td className={classes.areaBottomValue}>{(showAreaColor.area2 && areaConf?.rPower3) || COMMON.BLANK1}</td>
              <td className={classes.areaBottomValue}>
                {(showAreaColor.area2 && areaConf?.descentLimit3) || COMMON.BLANK1}
              </td>
              <td className={classes.areaBottomValue}>
                {(showAreaColor.area2 &&
                  getClassificationName(CLSFCN.AREA_CONFIG.PUSHING_CATEGORY_CD, String(areaConf?.pushing3))) ??
                  COMMON.BLANK1}
              </td>
            </TableRow>
            <TableRow>
              <td className={classes.areaBottomValue}>
                <Box className={classes.bgYellow}>&nbsp;</Box>
              </td>
              <td className={classes.areaBottomValue}>{(showAreaColor.area3 && areaConf?.lPower4) || COMMON.BLANK1}</td>
              <td className={classes.areaBottomValue}>{(showAreaColor.area3 && areaConf?.cPower4) || COMMON.BLANK1}</td>
              <td className={classes.areaBottomValue}>{(showAreaColor.area3 && areaConf?.rPower4) || COMMON.BLANK1}</td>
              <td className={classes.areaBottomValue}>
                {(showAreaColor.area3 && areaConf?.descentLimit4) || COMMON.BLANK1}
              </td>
              <td className={classes.areaBottomValue}>
                {(showAreaColor.area3 &&
                  getClassificationName(CLSFCN.AREA_CONFIG.PUSHING_CATEGORY_CD, String(areaConf?.pushing4))) ||
                  COMMON.BLANK1}
              </td>
            </TableRow>
            <TableRow>
              <td className={classes.areaBottomValue}>
                <Box className={classes.bgRed}>&nbsp;</Box>
              </td>
              <td className={classes.areaBottomValue}>{(showAreaColor.area4 && areaConf?.lPower5) || COMMON.BLANK1}</td>
              <td className={classes.areaBottomValue}>{(showAreaColor.area4 && areaConf?.cPower5) || COMMON.BLANK1}</td>
              <td className={classes.areaBottomValue}>{(showAreaColor.area4 && areaConf?.rPower5) || COMMON.BLANK1}</td>
              <td className={classes.areaBottomValue}>
                {(showAreaColor.area4 && areaConf?.descentLimit5) || COMMON.BLANK1}
              </td>
              <td className={classes.areaBottomValue}>
                {(showAreaColor.area4 &&
                  getClassificationName(CLSFCN.AREA_CONFIG.PUSHING_CATEGORY_CD, String(areaConf?.pushing5))) ||
                  COMMON.BLANK1}
              </td>
            </TableRow>
            <TableRow>
              <td className={classes.areaBottomValue}>
                <Box className={classes.bgBlue}>&nbsp;</Box>
              </td>
              <td className={classes.areaBottomValue}>{(showAreaColor.area5 && areaConf?.lPower6) || COMMON.BLANK1}</td>
              <td className={classes.areaBottomValue}>{(showAreaColor.area5 && areaConf?.cPower6) || COMMON.BLANK1}</td>
              <td className={classes.areaBottomValue}>{(showAreaColor.area5 && areaConf?.rPower6) || COMMON.BLANK1}</td>
              <td className={classes.areaBottomValue}>
                {(showAreaColor.area5 && areaConf?.descentLimit6) || COMMON.BLANK1}
              </td>
              <td className={classes.areaBottomValue}>
                {(showAreaColor.area5 &&
                  getClassificationName(CLSFCN.AREA_CONFIG.PUSHING_CATEGORY_CD, String(areaConf?.pushing6))) ||
                  COMMON.BLANK1}
              </td>
            </TableRow>
          </>
        )}
      </Table>
    </Box>
  );
};

export default TableStationHomeDetailGrand;
