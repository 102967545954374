import { makeStyles, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@material-ui/core';
import COMMON from 'constants/common';
import React, { memo } from 'react';
import { MachineSelectListType, MachineSelectStationNameType } from 'types/group/GroupType';
import TooltipComponent from 'components/system/organisms/dialogs/TooltipComponent';
import { getStationName, renderMachineType } from 'utils/common.helper';
import ListCheckBox from '../../../system/atoms/checkBoxs/ListCheckBox';

const column1 = 220;
const column2 = 198;
const column3 = 689;
/* ************ Style ************ */
const useStyles = makeStyles(() => ({
  default: {
    height: '352px',
    overflowX: 'hidden',
  },
  table: {
    tableLayout: 'fixed',
    width: 1108,
    borderCollapse: 'separate', // collapseにするとスクロール時にヘッダーのborderが消えるので注意
    borderLeft: '1px solid #707070',
    '& th,td': {
      lineHeight: '30px',
    },
    '& th': {
      fontSize: '23px',
      textAlign: 'center',
      height: '48px',
      padding: 0,
      backgroundColor: '#CFCFCF',
      backgroundClip: 'padding-box',
      borderTop: '1px solid #707070',
      borderBottom: '1px solid #707070',
      borderRight: '1px solid #707070',
    },
    '& tbody tr': {
      height: '76px',
    },
    '& td': {
      borderBottom: '1px solid #707070',
      borderRight: '1px solid #707070',
      '& .cellStyle': {
        whiteSpace: 'nowrap',
        overflow: 'hidden',
      },
    },
    '& .boardSerial': {
      textAlign: 'center',
    },
  },
  tdMachineName: {
    textAlign: 'left',
    alignItems: 'center',
    padding: '0px 0px',
  },
  tdEquipmentNo: {
    fontSize: '23px',
    display: 'flex',
    alignItems: 'center',
  },
  tdStationName: {
    fontSize: '21px',
    textAlign: 'left',
    alignItems: 'center',
    padding: '0 16px',
  },
}));

/* ************ Type ************ */
type Props = {
  rows: Array<MachineSelectListType>;
  handleCheck: (ev: React.ChangeEvent<HTMLInputElement>, checked: boolean, row: MachineSelectListType) => void;
};

/* ************ Component ************ */
const MachineSelectTable: React.FC<Props> = ({ rows, handleCheck }) => {
  const classes = useStyles();

  const headerColumns = [
    {
      id: 'boardSerial',
      label: '登録番号',
      width: column1,
      hasCheckBox: true,
      align: 'left',
      className: 'boardSerial',
    },
    { id: 'machineType', label: '機種名', width: column2, hasCheckBox: false, className: 'boardSerial' },
    { id: 'stationName', label: 'ステーション名 景品名', width: column3, hasCheckBox: false, className: 'boardSerial' },
  ];

  const getStationNameFunc = React.useCallback(
    ({ machineType, stationName, leftRight, giftName }: MachineSelectStationNameType): string =>
      `${getStationName(machineType, stationName, leftRight)} ${giftName ?? COMMON.BLANK}`,
    [],
  );

  return (
    <TableContainer id="scrollContainer" className={classes.default}>
      <Table className={classes.table} stickyHeader aria-label="sticky table">
        <TableHead>
          <TableRow>
            {headerColumns.map((column) => (
              <TableCell key={column.id} style={{ width: column.width }} className={column.className}>
                {column.label}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row) => {
            const key = `equipment_select_table_key_${row.boardSerial}`;
            return (
              <TableRow key={key}>
                <TableCell className={classes.tdMachineName} style={{ width: headerColumns[0].width }}>
                  <div className={classes.tdEquipmentNo}>
                    <ListCheckBox
                      checked={row.checked}
                      name="selectStation"
                      onChange={(ev: React.ChangeEvent<HTMLInputElement>, checked: boolean) =>
                        handleCheck(ev, checked, row)
                      }
                    />
                    <div className="cellStyle">{row.boardSerial}</div>
                  </div>
                </TableCell>
                <TableCell className={classes.tdStationName} style={{ width: headerColumns[1].width }}>
                  <div className="cellStyle">{renderMachineType(row.machineType)}</div>
                </TableCell>
                <TableCell className={classes.tdStationName} style={{ width: headerColumns[2].width }}>
                  {row.machineType === COMMON.MACHINE_TYPE.CLENA3 ? (
                    <>
                      <TooltipComponent
                        tooltipText={row.leftGiftName}
                        displayWord={`${getStationNameFunc({
                          machineType: row.machineType,
                          stationName: row.leftStationName,
                          leftRight: COMMON.LEFT_RIGHT.LEFT,
                          giftName: row.leftGiftName,
                        })}`}
                      />
                      <TooltipComponent
                        tooltipText={row.rightGiftName}
                        displayWord={`${getStationNameFunc({
                          machineType: row.machineType,
                          stationName: row.rightStationName,
                          leftRight: COMMON.LEFT_RIGHT.RIGHT,
                          giftName: row.rightGiftName,
                        })}`}
                      />
                    </>
                  ) : (
                    <TooltipComponent
                      tooltipText={row.leftGiftName}
                      displayWord={`${getStationNameFunc({
                        machineType: row.machineType,
                        stationName: row.leftStationName,
                        leftRight: COMMON.LEFT_RIGHT.LEFT,
                        giftName: row.leftGiftName,
                      })}`}
                    />
                  )}
                </TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default memo(MachineSelectTable);
