import React, { useEffect } from 'react';
import ScreenContext from 'Contexts/Common/ScreenContext';
import COMMON from 'constants/common';
import { Box, makeStyles } from '@material-ui/core';
import { getUserAuthThunk } from '../../redux/slices/commonSlice';
import { useAppDispatch } from '../../redux/hooks';
import Manual from './components/Manual';
import Information from './components/Information';
import Layout from '../../components/system/layouts/Layout';
import HttpConnection from '../../utils/httpConnection';
import { REST_API } from '../../constants/apiUrls';
import { setInformationList, setIsShowNewNotify, setManualsList } from '../../redux/slices/manualInformationSlice';
import { IInformationType, IManualType } from '../../types/manualInformation/manualInformationType';

/* ************ Context ************ */
const ScreenContextValue = {
  title: 'TOP',
  screenId: COMMON.SCREEN_ID.TOP,
  currentMenu: COMMON.MENU.HOME,
  disableContents: true,
  initializeState: true,
};

// CSS定義
const useStyles = makeStyles((theme) => ({
  contents: {
    marginLeft: '104px',
    backgroundColor: theme.palette.common.black,
    padding: '0px 16px 12px 16px',
    '& .detail': {
      height: 668,
      width: '1144px',
      padding: '4px 5px',
      borderRadius: '0 0 4px 4px',
      backgroundColor: theme.palette.common.white,
    },
  },
  contentsDetail: {
    height: '668px',
    width: '1144px',
  },
}));

/* ************ Component ************ */
const Top: React.FC = () => {
  const dispatch = useAppDispatch();
  const classes = useStyles();

  // Get information api
  const getInformationApi = async () => {
    const http = new HttpConnection({ dispatch });
    const data = await http.get<IInformationType[]>(REST_API.MANUAL_NOTICE.GET_INFORMATION_PRIVATE);
    // Update redux
    dispatch(setInformationList(data));
  };
  // get manual api
  const getManualApi = async () => {
    const http = new HttpConnection({ dispatch });
    const data = await http.get<IManualType[]>(REST_API.MANUAL_NOTICE.GET_MANUAL);
    // Update redux
    dispatch(setManualsList(data));
  };

  const updateBrowsingHistoryApi = async () => {
    const http = new HttpConnection({ dispatch });
    await http.put<number>(REST_API.MANUAL_NOTICE.UPDATE_BROWSING_HISTORY_DATE);
    // Remove notify icon when we mount manual screen
    dispatch(setIsShowNewNotify(false));
  };

  useEffect(() => {
    dispatch(getUserAuthThunk());
    // Get information public
    getInformationApi().then();
    // Get manual
    getManualApi().then();
    // Call api get browsing history of user
    updateBrowsingHistoryApi().then();
  }, []);

  return (
    <ScreenContext.Provider value={ScreenContextValue}>
      <Layout>
        <Box className={classes.contents}>
          <Box className={classes.contentsDetail}>
            <Box className="detail">
              <Information />
              <Manual />
            </Box>
          </Box>
        </Box>
      </Layout>
    </ScreenContext.Provider>
  );
};

export default Top;
