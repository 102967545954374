/* eslint-disable no-param-reassign */
import CLSFCN from 'constants/classification';
import COMMON from 'constants/common';
import { produce } from 'immer';
import { AreaConfType, LedServiceOtherConfType, MachineConfType, PitConfType } from 'types/machineConf/machineConfType';

const INITIAL_LINE = '000000';
const LINE_INDEX = [...Array(6)];
const LINE_INDEX_JACK = [...Array(5)];

/**
 * 左右ステーション対応
 * @author takuya.tomisato
 *
 */
export const handleLeftRightInversion = (tmpConf: MachineConfType): MachineConfType => {
  const returnConf = tmpConf;
  if (tmpConf.pitConf) {
    const pitConf = {
      ...tmpConf.pitConf,
    };
    pitConf.line1 = tmpConf.pitConf.line6;
    pitConf.line2 = tmpConf.pitConf.line5;
    pitConf.line3 = tmpConf.pitConf.line4;
    pitConf.line4 = tmpConf.pitConf.line3;
    pitConf.line5 = tmpConf.pitConf.line2;
    pitConf.line6 = tmpConf.pitConf.line1;
    returnConf.pitConf = pitConf;
  }
  if (tmpConf.areaConf) {
    const areaConf = {
      ...tmpConf.areaConf,
    };
    areaConf.line1 = tmpConf.areaConf.line6;
    areaConf.line2 = tmpConf.areaConf.line5;
    areaConf.line3 = tmpConf.areaConf.line4;
    areaConf.line4 = tmpConf.areaConf.line3;
    areaConf.line5 = tmpConf.areaConf.line2;
    areaConf.line6 = tmpConf.areaConf.line1;
    areaConf.lPower1 = tmpConf.areaConf.rPower1;
    areaConf.rPower1 = tmpConf.areaConf.lPower1;
    areaConf.lPower2 = tmpConf.areaConf.rPower2;
    areaConf.rPower2 = tmpConf.areaConf.lPower2;
    areaConf.lPower3 = tmpConf.areaConf.rPower3;
    areaConf.rPower3 = tmpConf.areaConf.lPower3;
    areaConf.lPower4 = tmpConf.areaConf.rPower4;
    areaConf.rPower4 = tmpConf.areaConf.lPower4;
    areaConf.lPower5 = tmpConf.areaConf.rPower5;
    areaConf.rPower5 = tmpConf.areaConf.lPower5;
    areaConf.lPower6 = tmpConf.areaConf.rPower6;
    areaConf.rPower6 = tmpConf.areaConf.lPower6;
    returnConf.areaConf = areaConf;
  }
  if (tmpConf.operationConf) {
    const operationConf = {
      ...tmpConf.operationConf,
    };
    operationConf.grabLPower = tmpConf.operationConf.grabRPower;
    operationConf.grabRPower = tmpConf.operationConf.grabLPower;
    operationConf.grabLPower2 = tmpConf.operationConf.grabRPower2;
    operationConf.grabRPower2 = tmpConf.operationConf.grabLPower2;
    operationConf.grabLPower3 = tmpConf.operationConf.grabRPower3;
    operationConf.grabRPower3 = tmpConf.operationConf.grabLPower3;
    operationConf.grabLPower4 = tmpConf.operationConf.grabRPower4;
    operationConf.grabRPower4 = tmpConf.operationConf.grabLPower4;
    operationConf.grabLPower5 = tmpConf.operationConf.grabRPower5;
    operationConf.grabRPower5 = tmpConf.operationConf.grabLPower5;
    operationConf.grabLPower6 = tmpConf.operationConf.grabRPower6;
    operationConf.grabRPower6 = tmpConf.operationConf.grabLPower6;
    operationConf.movingLPower = tmpConf.operationConf.movingRPower;
    operationConf.movingRPower = tmpConf.operationConf.movingLPower;
    operationConf.movingLPower2 = tmpConf.operationConf.movingRPower2;
    operationConf.movingRPower2 = tmpConf.operationConf.movingLPower2;
    operationConf.movingLPower3 = tmpConf.operationConf.movingRPower3;
    operationConf.movingRPower3 = tmpConf.operationConf.movingLPower3;
    operationConf.movingLPower4 = tmpConf.operationConf.movingRPower4;
    operationConf.movingRPower4 = tmpConf.operationConf.movingLPower4;
    operationConf.movingLPower5 = tmpConf.operationConf.movingRPower5;
    operationConf.movingRPower5 = tmpConf.operationConf.movingLPower5;
    operationConf.movingLPower6 = tmpConf.operationConf.movingRPower6;
    operationConf.movingRPower6 = tmpConf.operationConf.movingLPower6;
    operationConf.limitLPower = tmpConf.operationConf.limitRPower;
    operationConf.limitRPower = tmpConf.operationConf.limitLPower;
    returnConf.operationConf = operationConf;
  }
  return returnConf;
};

/**
 * 行データ変換
 * pitやareaのlineX(実態は列)の情報を行の情報に変換する。
 * ex) line1-6 = "123450", Param.lineNumuber=0
 *     result = "111111"
 * @author atsushi.teruya
 * @param {Array<string>} lineList -
 * @param {number} lineNumber -
 * @returns {string}
 */
export const toLineData = (lineList: Array<string>, lineNumber: number): string => {
  const result = `${lineList[0][lineNumber]}${lineList[1][lineNumber]}${lineList[2][lineNumber]}${lineList[3][lineNumber]}${lineList[4][lineNumber]}${lineList[5][lineNumber]}`;
  return result;
};

/**
 * 行データ変換 かんたん用
 * pitのlineX(実態は列)の情報を行の情報に変換する。
 * ex) line1-3 = "110000", Param.lineNumuber=0
 *     result = "111"
 * @author atsushi.teruya
 * @param {Array<string>} lineList -
 * @param {number} lineNumber -
 * @returns {string}
 */
export const toSimplePitLineData = (lineList: Array<string>, lineNumber: number): string => {
  const result = `${lineList[0][lineNumber]}${lineList[1][lineNumber]}${lineList[2][lineNumber]}`;
  return result;
};

/**
 * 配列変換 落とし穴用
 * pitのlineX(実態は列)を処理しやすいようにlineの配列に変換する。
 * @author atsushi.teruya
 * @param {PitConfType} pitConf -
 * @returns {Array<string>}
 */
export const toArrayPitLine = (pitConf?: PitConfType): Array<string> => [
  pitConf?.line1 || INITIAL_LINE,
  pitConf?.line2 || INITIAL_LINE,
  pitConf?.line3 || INITIAL_LINE,
  pitConf?.line4 || INITIAL_LINE,
  pitConf?.line5 || INITIAL_LINE,
  pitConf?.line6 || INITIAL_LINE,
];

/**
 * 配列変換 落とし穴 簡単用
 * pitのlineX(実態は列)を処理しやすいようにlineの配列に変換する。
 * @author atsushi.teruya
 * @param {Array<string} pitLineArray -
 * @returns {Array<string>}
 */
export const toArraySimplePitLine = (pitLineArray: Array<string>): Array<string> => {
  const simplePitLineArray: Array<string> = [];
  for (let i = 0; i < 3; i += 1) {
    let simpleLine = '';
    const iS = i * 2;
    for (let j = 0; j < 3; j += 1) {
      const jS = j * 2;
      if (
        pitLineArray[iS][jS] === COMMON.FLAG.ON &&
        pitLineArray[iS][jS + 1] === COMMON.FLAG.ON &&
        pitLineArray[iS + 1][jS] === COMMON.FLAG.ON &&
        pitLineArray[iS + 1][jS + 1] === COMMON.FLAG.ON
      ) {
        simpleLine += COMMON.FLAG.ON;
      } else {
        simpleLine += COMMON.FLAG.OFF;
      }
    }
    simplePitLineArray.push(simpleLine);
  }
  return simplePitLineArray;
};

export const toArraySimplePitLineJack = (pitLineArray: Array<string>): Array<string> => {
  const simplePitLineArray: Array<string> = [];
  for (let i = 0; i < 3; i += 1) {
    let simpleLine = '';
    const iS = i * 2;

    if (pitLineArray[iS][0 + 1] === COMMON.FLAG.ON && pitLineArray[iS + 1][0 + 1] === COMMON.FLAG.ON) {
      simpleLine += COMMON.FLAG.ON;
    } else {
      simpleLine += COMMON.FLAG.OFF;
    }

    for (let j = 1; j < 3; j += 1) {
      const jS = j * 2;
      if (
        pitLineArray[iS][jS] === COMMON.FLAG.ON &&
        pitLineArray[iS][jS + 1] === COMMON.FLAG.ON &&
        pitLineArray[iS + 1][jS] === COMMON.FLAG.ON &&
        pitLineArray[iS + 1][jS + 1] === COMMON.FLAG.ON
      ) {
        simpleLine += COMMON.FLAG.ON;
      } else {
        simpleLine += COMMON.FLAG.OFF;
      }
    }
    simplePitLineArray.push(simpleLine);
  }
  return simplePitLineArray;
};

/**
 * 配列変換 落とし穴用
 * pitのlineX(実態は列)を処理しやすいように[列][行]の二次元配列に変換する。
 * @author masaki.kitamura
 * @param {PitConfType} pitConf -
 * @returns {string[][]}
 */
export const to2DArrayPit = (pitConf?: PitConfType): string[][] => {
  const array = toArrayPitLine(pitConf);

  // かんたん(9ブロック) →3x3を返す
  if (pitConf?.settingPattern === CLSFCN.PIT_CONFIG.PATTERN.SIMPLE) {
    return [...Array(3)].map((_, i) =>
      [...Array(3)].map((__, j) =>
        array[j * 2].charAt(i * 2) === '1' &&
        array[j * 2].charAt(i * 2 + 1) === '1' &&
        array[j * 2 + 1].charAt(i * 2) === '1' &&
        array[j * 2 + 1].charAt(i * 2 + 1) === '1'
          ? '1'
          : '0',
      ),
    );
  }

  // 詳細(36ブロック) →6x6を返す
  if (pitConf?.settingPattern === CLSFCN.PIT_CONFIG.PATTERN.DETAIL) {
    return [...Array(6)].map((_, i) => [...Array(6)].map((__, j) => array[j].charAt(i)));
  }

  return [...Array(6)].map(() => [...Array(6)].map(() => '0'));
};

export const to2DArrayPitJack = (pitConf?: PitConfType): string[][] => {
  const array = toArrayPitLine(pitConf);

  // かんたん(9ブロック) →3x3を返す
  if (pitConf?.settingPattern === CLSFCN.PIT_CONFIG.PATTERN.SIMPLE) {
    const pitLineArray = toArrayPitLine(pitConf);
    const simplePit = toArraySimplePitLineJack(pitLineArray);

    const mappingPitLine = [];

    for (let i = 0; i < 3; i += 1) {
      mappingPitLine.push([simplePit[0][i], simplePit[1][i], simplePit[2][i]]);
    }

    return mappingPitLine;
  }

  // 詳細(36ブロック) →6x6を返す
  if (pitConf?.settingPattern === CLSFCN.PIT_CONFIG.PATTERN.DETAIL) {
    return [...Array(6)].map((_, i) => [...Array(6)].map((__, j) => array[j].charAt(i)));
  }

  return [...Array(6)].map(() => [...Array(6)].map(() => '0'));
};

/**
 * to2DArrayPit の逆変換。新しいオブジェクトで返す
 * @author masaki.kitamura
 * @param {PitConfType} pitConf
 * @param {string[][]} pit2DArray
 * @returns {PitConfType}
 */
export const toPitConfFrom2DArray = (pitConf: PitConfType, pit2DArray: string[][]): PitConfType => {
  let temp: PitConfType;
  if (pitConf.settingPattern === CLSFCN.PIT_CONFIG.PATTERN.SIMPLE) {
    // かんたんの場合は変更があった部分のみ更新する。
    const previousLineList = toArrayPitLine(pitConf);
    const newLieList: Array<string> = ['', '', '', '', '', ''];
    const previousPits = to2DArrayPit(pitConf);
    for (let i = 0; i < 3; i += 1) {
      for (let j = 0; j < 3; j += 1) {
        const iN = i * 2;
        const jN = j * 2;
        if (previousPits[i][j] === pit2DArray[i][j]) {
          // 同じ場合は元のpitconfを設定
          newLieList[jN] += previousLineList[jN][iN] + previousLineList[jN][iN + 1];
          newLieList[jN + 1] += previousLineList[jN + 1][iN] + previousLineList[jN + 1][iN + 1];
        } else {
          // 異なる場合は新しいpitconfを設定
          newLieList[jN] += pit2DArray[i][j] + pit2DArray[i][j];
          newLieList[jN + 1] += pit2DArray[i][j] + pit2DArray[i][j];
        }
      }
    }
    temp = {
      ...pitConf,
      line1: newLieList[0],
      line2: newLieList[1],
      line3: newLieList[2],
      line4: newLieList[3],
      line5: newLieList[4],
      line6: newLieList[5],
    };
  } else {
    temp = produce(pitConf, (draft) => {
      draft.line1 = pit2DArray.reduce((prev, row) => prev + row[0], '');
      draft.line2 = pit2DArray.reduce((prev, row) => prev + row[1], '');
      draft.line3 = pit2DArray.reduce((prev, row) => prev + row[2], '');
      draft.line4 = pit2DArray.reduce((prev, row) => prev + row[3], '');
      draft.line5 = pit2DArray.reduce((prev, row) => prev + row[4], '');
      draft.line6 = pit2DArray.reduce((prev, row) => prev + row[5], '');
    });
  }
  return temp;
};

/**
 * 配列変換 エリア用
 * areaのlineX(実態は列)を処理しやすいようにlineの配列に変換する。
 * @author atsushi.teruya
 * @param {PitConfType} pitConf -
 * @returns {Array<string>}
 */
export const toArrayAreaLine = (areaConf?: AreaConfType): Array<string> => [
  areaConf?.line1 || INITIAL_LINE,
  areaConf?.line2 || INITIAL_LINE,
  areaConf?.line3 || INITIAL_LINE,
  areaConf?.line4 || INITIAL_LINE,
  areaConf?.line5 || INITIAL_LINE,
  areaConf?.line6 || INITIAL_LINE,
];

/**
 * 配列変換 エリア用
 * pitのlineX(実態は列)を処理しやすいように[列][行]の二次元配列に変換する。
 * @author atsushi.teruya
 * @param {AreaConfType} areaConf -
 * @returns {string[][]}
 */
export const to2DArrayArea = (areaConf?: AreaConfType): string[][] => {
  const areaLineList = toArrayAreaLine(areaConf);
  return LINE_INDEX.map((_, i) => LINE_INDEX.map((__, j) => areaLineList[j].charAt(i)));
};

/**
 * 配列変換 エリア用
 * pitのlineX(実態は列)を処理しやすいように[列][行]の二次元配列に変換する。
 * @author atsushi.teruya
 * @param {AreaConfType} areaConf -
 * @returns {string[][]}
 */
export const to2DArrayAreaJack = (areaConf?: AreaConfType): string[][] => {
  const areaLineList = toArrayAreaLine(areaConf);
  return LINE_INDEX_JACK.map((_, i) =>
    LINE_INDEX.map((__, j) => {
      let index = i;
      if (areaLineList[j].length >= 6) {
        index = i + 1;
      }
      return areaLineList[j].charAt(index);
    }),
  );
};

/**
 * to2DArrayArea の逆変換。新しいオブジェクトで返す
 * @author atsushi.teruya
 * @param {AreaConfType} areaConf
 * @param {string[][]} area2DArray
 * @returns {AreaConfType}
 */
export const toAreaConfFrom2DArray = (areaConf: AreaConfType, area2DArray: string[][]): AreaConfType => {
  // 行列を変換
  const lineList = area2DArray.map(
    (value, i) =>
      area2DArray[0][i] +
      area2DArray[1][i] +
      area2DArray[2][i] +
      area2DArray[3][i] +
      area2DArray[4][i] +
      area2DArray[5][i],
  );
  const result: AreaConfType = {
    ...areaConf,
    line1: lineList[0],
    line2: lineList[1],
    line3: lineList[2],
    line4: lineList[3],
    line5: lineList[4],
    line6: lineList[5],
  };

  return result;
};

/**
 * to2DArrayArea の逆変換。新しいオブジェクトで返す
 * @author atsushi.teruya
 * @param {AreaConfType} areaConf
 * @param {string[][]} area2DArray
 * @returns {AreaConfType}
 */
export const toAreaConfFrom2DArrayJack = (areaConf: AreaConfType, area2DArray: string[][]): AreaConfType => {
  // 行列を変換
  const lineList = [];
  for (let i = 0; i < 6; i += 1) {
    lineList.push(
      `${area2DArray[0][i] !== '' ? area2DArray[0][i] : '0'}${area2DArray[1][i] !== '' ? area2DArray[1][i] : '0'}${
        area2DArray[2][i] !== '' ? area2DArray[2][i] : '0'
      }${area2DArray[3][i] !== '' ? area2DArray[3][i] : '0'}${area2DArray[4][i] !== '' ? area2DArray[4][i] : '0'}`,
    );
  }
  const result: AreaConfType = {
    ...areaConf,
    line1: lineList[0],
    line2: lineList[1],
    line3: lineList[2],
    line4: lineList[3],
    line5: lineList[4],
    line6: lineList[5],
  };

  return result;
};

/**
 * エリア入力有無取得
 * エリアごとのパネル入力有無を算出、返却する。
 * @author atsushi.teruya
 * @param {PitConfType} pitConf -
 * @returns {Array<string>}
 */
export const hasAreas = (areaConf?: AreaConfType): Array<boolean> => {
  const areaList = [false, false, false, false, false, false];
  const lineList = toArrayAreaLine(areaConf);
  for (let i = 0; i < 6; i += 1) {
    const line = lineList[i];
    const len = line.length;
    for (let j = 0; j < len; j += 1) {
      areaList[Number(line[j])] = true;
    }
  }
  return areaList;
};
/**
 * エリア入力有無取得
 * エリアごとのパネル入力有無を算出、返却する。
 * @author atsushi.teruya
 * @param {PitConfType} pitConf -
 * @returns {Array<string>}
 */
export const hasAreasJack = (areaConf?: AreaConfType): Array<boolean> => {
  const areaList = [false, false, false, false, false, false];
  const lineList = toArrayAreaLine(areaConf);
  for (let i = 0; i < 6; i += 1) {
    const line = lineList[i];
    const len = line.length;
    for (let j = 1; j < len; j += 1) {
      areaList[Number(line[j])] = true;
    }
  }
  return areaList;
};

/**
 * 設定済みのエリア数を計算
 * @param {AreaConfType} areaConf
 * @returns {number}
 */
export const countAreaTotal = (areaConf: AreaConfType | undefined): number => {
  if (!areaConf) return 0;
  if (areaConf?.areaConf === COMMON.SWITCH.OFF) {
    return 1;
  }
  let count = 0;
  const inputAreas = hasAreas(areaConf);
  for (let i = 0; i < inputAreas.length && i < 6; i += 1) {
    if (inputAreas[i]) {
      count += 1;
    }
  }
  return count;
};

/**
 * y座標変換(画面→パネル)
 * 画面の座標をプライズ機のパネルの座標に変換する
 * @author atsushi.teruya
 * @param {number} y -画面の座標
 * @param {number} screenAreaSize - 画面のエリアサイズ
 * @param {number} panelAreaSize - パネルのエリアサイズ
 * @returns {number}
 */
const toPanelPosY = (y: number, screenAreaSize: number, panelAreaSize: number): number =>
  panelAreaSize - Math.round((y / screenAreaSize) * panelAreaSize);

const toPanelPosYJack = (y: number, screenAreaSizeHeight: number, panelAreaSize: number): number =>
  panelAreaSize - Math.round((y / screenAreaSizeHeight) * panelAreaSize);

/**
 * x座標変換(画面→パネル) 左パネル用
 * 画面の座標をプライズ機のパネルの座標に変換する
 * @author atsushi.teruya
 * @param {number} x -画面の座標
 * @param {number} screenAreaSize - 画面のエリアサイズ
 * @param {number} panelAreaSize - パネルのエリアサイズ
 * @returns {number}
 */
const toPanelPosXLeft = (x: number, screenAreaSize: number, panelAreaSize: number): number =>
  Math.round((x / screenAreaSize) * panelAreaSize);

/**
 * x座標変換(画面→パネル) 右パネル用
 * 画面の座標をプライズ機のパネルの座標に変換する
 * @author atsushi.teruya
 * @param {number} x -画面の座標
 * @param {number} screenAreaSize - 画面のエリアサイズ
 * @param {number} panelAreaSize - パネルのエリアサイズ
 * @returns {number}
 */
const toPanelPosXRight = (x: number, screenAreaSize: number, panelAreaSize: number): number =>
  panelAreaSize - Math.round((x / screenAreaSize) * panelAreaSize);

/**
 * 座標変換(画面→パネル)
 * 画面の座標をプライズ機のパネルの座標に変換する
 * @author atsushi.teruya
 * @param {number} x -画面の座標
 * @param {number} y -画面の座標
 * @param {number} screenAreaSize - 画面のエリアサイズ
 * @param {number} panelAreaSize - パネルのエリアサイズ
 * @param {boolean} isLeft - true => 左パネル, false => 右パネル
 * @returns {number}
 */
export const toPanelPos = (params: {
  x: number;
  y: number;
  screenAreaSize: number;
  panelAreaSize: number;
  isLeft: boolean;
}): { x: number; y: number } => ({
  x: params.isLeft
    ? toPanelPosXLeft(params.x, params.screenAreaSize, params.panelAreaSize)
    : toPanelPosXRight(params.x, params.screenAreaSize, params.panelAreaSize),
  y: toPanelPosY(params.y, params.screenAreaSize, params.panelAreaSize),
});

export const toPanelPosJack = (params: {
  x: number;
  y: number;
  screenAreaSize: number;
  screenAreaSizeHeight: number;
  panelAreaSize: number;
  isLeft: boolean;
}): { x: number; y: number } => ({
  x: params.isLeft
    ? toPanelPosXLeft(params.x, params.screenAreaSize, params.panelAreaSize)
    : toPanelPosXRight(params.x, params.screenAreaSize, params.panelAreaSize),
  y: toPanelPosYJack(params.y, params.screenAreaSizeHeight, params.panelAreaSize),
});

/**
 * y座標変換(パネル→画面)
 * プライズ機のパネルの座標を画面の座標に変換する
 * @author atsushi.teruya
 * @param {number} y - プライズ機のパネルの座標
 * @param {number} screenAreaSize - 画面のエリアサイズ
 * @param {number} panelAreaSize - パネルのエリアサイズ
 * @returns {number}
 */
const toScreenPosY = (y: number, screenAreaSize: number, panelAreaSize: number): number =>
  screenAreaSize - Math.round((y * screenAreaSize) / panelAreaSize);

const toScreenPosYJack = (y: number, screenAreaSizeHeight: number, panelAreaSize: number): number =>
  screenAreaSizeHeight - Math.round((y * screenAreaSizeHeight) / panelAreaSize);
export const toScreenPosJack = (params: {
  x: number;
  y: number;
  screenAreaSize: number;
  screenAreaSizeHeight: number;
  panelAreaSize: number;
  isLeft: boolean;
}): { x: number; y: number } => ({
  x: params.isLeft
    ? toScreenPosXLeft(params.x, params.screenAreaSize, params.panelAreaSize)
    : toScreenPosXRight(params.x, params.screenAreaSize, params.panelAreaSize),
  y: toScreenPosYJack(params.y, params.screenAreaSizeHeight, params.panelAreaSize),
});

/**
 * x座標変換(パネル→画面) 左パネル用
 * プライズ機のパネルの座標を画面の座標に変換する
 * @author atsushi.teruya
 * @param {number} x - プライズ機のパネルの座標
 * @param {number} screenAreaSize - 画面のエリアサイズ
 * @param {number} panelAreaSize - パネルのエリアサイズ
 * @returns {number}
 */
const toScreenPosXLeft = (x: number, screenAreaSize: number, panelAreaSize: number): number =>
  Math.round((x * screenAreaSize) / panelAreaSize);

/**
 * x座標変換(パネル→画面) 右パネル用
 * プライズ機のパネルの座標を画面の座標に変換する
 * @author atsushi.teruya
 * @param {number} x - プライズ機のパネルの座標
 * @param {number} screenAreaSize - 画面のエリアサイズ
 * @param {number} panelAreaSize - パネルのエリアサイズ
 * @returns {number}
 */
const toScreenPosXRight = (x: number, screenAreaSize: number, panelAreaSize: number): number =>
  screenAreaSize - Math.round((x * screenAreaSize) / panelAreaSize);

/**
 * 座標変換(パネル→画面)
 * プライズ機のパネルの座標を画面の座標に変換する
 * @author atsushi.teruya
 * @param {number} x -プライズ機のパネルの座標
 * @param {number} y -プライズ機のパネルの座標
 * @param {number} screenAreaSize - 画面のエリアサイズ
 * @param {number} panelAreaSize - パネルのエリアサイズ
 * @param {boolean} isLeft - true => 左パネル, false => 右パネル
 * @returns {number}
 */
export const toScreenPos = (params: {
  x: number;
  y: number;
  screenAreaSize: number;
  panelAreaSize: number;
  isLeft: boolean;
}): { x: number; y: number } => ({
  x: params.isLeft
    ? toScreenPosXLeft(params.x, params.screenAreaSize, params.panelAreaSize)
    : toScreenPosXRight(params.x, params.screenAreaSize, params.panelAreaSize),
  y: toScreenPosY(params.y, params.screenAreaSize, params.panelAreaSize),
});

/**
 * ずらし運営設定のパネル制御
 * パターンがカスタム以外の場合、shiftConfの値に応じた矢印パネルになるよう、lineの値を変換して返す
 * パターンがカスタムの場合、各lineの値をそのまま返す
 * @param {string} shiftConf - ずらしパターン設定
 * @param {string} line1 - 矢印パネル左列の値
 * @param {string} line2 - 矢印パネル中央列の値
 * @param {string} line3 - 矢印パネル右列の値
 * @returns {Array<string>}
 */
export const convertLineValue = (shiftConf: string, line1: string, line2: string, line3: string): Array<string> => {
  let newLine1 = '';
  let newLine2 = '';
  let newLine3 = '';

  switch (shiftConf) {
    case CLSFCN.SHIFT_PATTERN.FRONT_SHIFT:
      newLine1 = `${COMMON.SHIFT_ARROW.FRONT}${COMMON.SHIFT_ARROW.FRONT}${COMMON.SHIFT_ARROW.PIT}`;
      newLine2 = `${COMMON.SHIFT_ARROW.FRONT}${COMMON.SHIFT_ARROW.FRONT}${COMMON.SHIFT_ARROW.PIT}`;
      newLine3 = `${COMMON.SHIFT_ARROW.FRONT}${COMMON.SHIFT_ARROW.FRONT}${COMMON.SHIFT_ARROW.PIT}`;
      break;
    case CLSFCN.SHIFT_PATTERN.RIGHT_SHIFT:
      newLine1 = `${COMMON.SHIFT_ARROW.RIGHT}${COMMON.SHIFT_ARROW.RIGHT}${COMMON.SHIFT_ARROW.RIGHT}`;
      newLine2 = `${COMMON.SHIFT_ARROW.RIGHT}${COMMON.SHIFT_ARROW.RIGHT}${COMMON.SHIFT_ARROW.RIGHT}`;
      newLine3 = `${COMMON.SHIFT_ARROW.PIT}${COMMON.SHIFT_ARROW.PIT}${COMMON.SHIFT_ARROW.PIT}`;
      break;
    case CLSFCN.SHIFT_PATTERN.LEFT_SHIFT:
      newLine1 = `${COMMON.SHIFT_ARROW.PIT}${COMMON.SHIFT_ARROW.PIT}${COMMON.SHIFT_ARROW.PIT}`;
      newLine2 = `${COMMON.SHIFT_ARROW.LEFT}${COMMON.SHIFT_ARROW.LEFT}${COMMON.SHIFT_ARROW.LEFT}`;
      newLine3 = `${COMMON.SHIFT_ARROW.LEFT}${COMMON.SHIFT_ARROW.LEFT}${COMMON.SHIFT_ARROW.LEFT}`;
      break;
    case CLSFCN.SHIFT_PATTERN.CENTER_HORIZONTAL_SHIFT:
      newLine1 = `${COMMON.SHIFT_ARROW.RIGHT}${COMMON.SHIFT_ARROW.RIGHT}${COMMON.SHIFT_ARROW.RIGHT}`;
      newLine2 = `${COMMON.SHIFT_ARROW.PIT}${COMMON.SHIFT_ARROW.PIT}${COMMON.SHIFT_ARROW.PIT}`;
      newLine3 = `${COMMON.SHIFT_ARROW.LEFT}${COMMON.SHIFT_ARROW.LEFT}${COMMON.SHIFT_ARROW.LEFT}`;
      break;
    case CLSFCN.SHIFT_PATTERN.CENTER_VERTICAL_SHIFT:
      newLine1 = `${COMMON.SHIFT_ARROW.FRONT}${COMMON.SHIFT_ARROW.PIT}${COMMON.SHIFT_ARROW.BACK}`;
      newLine2 = `${COMMON.SHIFT_ARROW.FRONT}${COMMON.SHIFT_ARROW.PIT}${COMMON.SHIFT_ARROW.BACK}`;
      newLine3 = `${COMMON.SHIFT_ARROW.FRONT}${COMMON.SHIFT_ARROW.PIT}${COMMON.SHIFT_ARROW.BACK}`;
      break;
    case CLSFCN.SHIFT_PATTERN.CENTER_SHIFT:
      newLine1 = `${COMMON.SHIFT_ARROW.FRONT_RIGHT}${COMMON.SHIFT_ARROW.RIGHT}${COMMON.SHIFT_ARROW.BACK_RIGHT}`;
      newLine2 = `${COMMON.SHIFT_ARROW.FRONT}${COMMON.SHIFT_ARROW.PIT}${COMMON.SHIFT_ARROW.BACK}`;
      newLine3 = `${COMMON.SHIFT_ARROW.FRONT_LEFT}${COMMON.SHIFT_ARROW.LEFT}${COMMON.SHIFT_ARROW.BACK_LEFT}`;
      break;
    case CLSFCN.SHIFT_PATTERN.BACK_SHIFT:
      newLine1 = `${COMMON.SHIFT_ARROW.PIT}${COMMON.SHIFT_ARROW.BACK}${COMMON.SHIFT_ARROW.BACK}`;
      newLine2 = `${COMMON.SHIFT_ARROW.PIT}${COMMON.SHIFT_ARROW.BACK}${COMMON.SHIFT_ARROW.BACK}`;
      newLine3 = `${COMMON.SHIFT_ARROW.PIT}${COMMON.SHIFT_ARROW.BACK}${COMMON.SHIFT_ARROW.BACK}`;
      break;
    case CLSFCN.SHIFT_PATTERN.CUSTOM_SHIFT:
      newLine1 = line1;
      newLine2 = line2;
      newLine3 = line3;
      break;
    default:
      break;
  }

  return [newLine1, newLine2, newLine3];
};

/**
 * ecoTypeに値が無い場合、ecoModeを元に値を設定する
 *
 * @param {LedServiceOtherConfType} ledServiceOtherConf LED演出・料金サービス・その他設定情報
 * @returns {string} ecoTypeの値
 */
export const initializeEcoType = (ledServiceOtherConf: LedServiceOtherConfType): string => {
  const { ecoMode, ecoType } = ledServiceOtherConf;
  // ecoTypeに値がある場合、そのまま返す
  if (ecoType !== null) return ecoType;
  // ecoModeがOFFの場合、ecoTypeにOFFを設定
  if (ecoMode === CLSFCN.LED_ECO_MODE.OFF) return CLSFCN.LED_ECO_TYPE.OFF;
  // ecoModeがOFF以外の場合、ecoTypeにエコタイプ1を設定
  return CLSFCN.LED_ECO_TYPE.ECO1;
};

/**
 * リセットタイミングの表示用
 *
 * @param {string} resetTiming
 * @returns {string} 表示用文字列
 */
export const getResetTimingText = (resetTiming: string): string => {
  // 区分値の文字列と画面表示時の文字列が異なる仕様のため、画面表示用の関数を用意
  const text = '';
  if (resetTiming === CLSFCN.RESET_TIMING.GET_GIFT) return '獲得時';
  if (resetTiming === CLSFCN.RESET_TIMING.END_CONTINUOUS_PLAY) return '連続終了時';
  return text;
};
