import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import Layout from 'components/system/layouts/Layout';
import ScreenContext from 'Contexts/Common/ScreenContext';
import COMMON from 'constants/common';
import { Box, makeStyles } from '@material-ui/core';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import UrlHelper from 'utils/url.helper';
import redirectLinks from 'constants/redirectLinks';
import HttpConnection from 'utils/httpConnection';
import { REST_API } from 'constants/apiUrls';
import { ReturnButton } from 'components/system/atoms';
import NormalButton from 'components/system/atoms/buttons/NormalButton';
import OutlineTextBox from 'components/system/atoms/textBoxs/OutlineTextBox';
import DeleteButton from 'components/system/atoms/buttons/DeleteButton';
import { initializeGroupSlice, setCreateLedGroup, setLedGroup } from 'redux/slices/groupSlice';
import {
  GroupLocationType,
  LedGroupType,
  LedLeftGrandType,
  LedRightGrandType,
  LedSettingType,
} from 'types/group/GroupType';
import { getClsfcnByCategory, getSortedClsfcnByCategory } from 'utils/common.helper';
import LedSelectArea from 'components/machineConf/organisms/LedConf/LedSelectArea';
import SelectList, { INITIAL_SELECT_LIST } from 'components/support/organisms/supports/SelectList';
import { Alignment, SelectListType } from 'types/support/supportType';
import CLSFCN from 'constants/classification';
import { ledSelectAreaConfType } from 'types/machineConf/ledSelectAreaConfType';
import RadioButtons, { RadioType } from 'components/system/atoms/radioButtons/RadioButton';
import LedGroupBasicSettings from 'components/group/layouts/LedGroupBasicSettings';
import MESSAGES from 'constants/messages';
import MsgHelper from 'utils/message.helper';
import MenuTab from 'components/data/organisms/tabs/MenuTab';

/* ************ Context ************ */
const ScreenContextValue = {
  title: 'LED演出設定グループ作成',
  screenId: COMMON.SCREEN_ID.LED_GROUP_CREATE,
  currentMenu: COMMON.MENU.GROUP,
};

/* ********** Constants ********** */
const leftRight = COMMON.LEFT_RIGHT.LEFT_AND_RIGHT;
export const GROUP_LED_INPUT_NAMES = {
  SETTING: 'setting',
  DIRECTING: 'directing',
  ECO_MODE: 'ecoMode',
  KEEP_ECO_TYPE: 'keepEcoType',
  COMMUNICATION_CONF: 'communicationConf',
  BGM: 'bgm',
  SE: 'se',
  VOICE: 'voice',
};
// 上部タブメニュー
const MENU_SETTING = [
  { label: 'クレナ3の設定', settingTab: COMMON.SETTING_TAB.CLENA3 },
  { label: 'クレナ3ジャックの設定', settingTab: COMMON.SETTING_TAB.JACK },
  { label: 'クレナグランの設定', settingTab: COMMON.SETTING_TAB.GRAND },
];

// グループ設定の選択肢
const statusRadios = (): Array<RadioType> => [
  {
    value: COMMON.VALID_FLG.VALID,
    label: '有効',
  },
  {
    value: COMMON.VALID_FLG.INVALID,
    label: '無効',
  },
];

const convertLedSettingToFlat = (ledGroup: {
  ledLeft: LedSettingType;
  ledRight: LedSettingType;
  ledLeftGrand: LedLeftGrandType;
  ledRightGrand: LedRightGrandType;
}): ledSelectAreaConfType => ({
  lLedEffect1: ledGroup.ledLeft.ledEffect1,
  lLedEffect2: ledGroup.ledLeft.ledEffect2,
  lLedEffect3: ledGroup.ledLeft.ledEffect3,
  lLedEffect4: ledGroup.ledLeft.ledEffect4,
  lLedColor1: ledGroup.ledLeft.ledColor1,
  lLedColor2: ledGroup.ledLeft.ledColor2,
  lLedColor3: ledGroup.ledLeft.ledColor3,
  lLedColor4: ledGroup.ledLeft.ledColor4,
  rLedEffect1: ledGroup.ledRight.ledEffect1,
  rLedEffect2: ledGroup.ledRight.ledEffect2,
  rLedEffect3: ledGroup.ledRight.ledEffect3,
  rLedEffect4: ledGroup.ledRight.ledEffect4,
  rLedColor1: ledGroup.ledRight.ledColor1,
  rLedColor2: ledGroup.ledRight.ledColor2,
  rLedColor3: ledGroup.ledRight.ledColor3,
  rLedColor4: ledGroup.ledRight.ledColor4,
  // left grand
  lLedGrandEffect1: ledGroup.ledLeftGrand.ledEffect1 ?? ledGroup.ledLeft.ledEffect1,
  lLedGrandEffect2: ledGroup.ledLeftGrand.ledEffect2 ?? ledGroup.ledLeft.ledEffect2,
  lLedGrandEffect3: ledGroup.ledLeftGrand.ledEffect3 ?? ledGroup.ledLeft.ledEffect4,
  lLedGrandEffect4: ledGroup.ledLeftGrand.ledEffect4 ?? ledGroup.ledLeft.ledEffect3,
  lLedGrandColor1: ledGroup.ledLeftGrand.ledColor1 ?? ledGroup.ledLeft.ledColor1,
  lLedGrandColor2: ledGroup.ledLeftGrand.ledColor2 ?? ledGroup.ledLeft.ledColor2,
  lLedGrandColor3: ledGroup.ledLeftGrand.ledColor3 ?? ledGroup.ledLeft.ledColor4,
  lLedGrandColor4: ledGroup.ledLeftGrand.ledColor4 ?? ledGroup.ledLeft.ledColor3,
  // right grand
  rLedGrandEffect1: ledGroup.ledRightGrand.ledEffect1 ?? ledGroup.ledRight.ledEffect1,
  rLedGrandEffect2: ledGroup.ledRightGrand.ledEffect2 ?? ledGroup.ledRight.ledEffect2,
  rLedGrandEffect3: ledGroup.ledRightGrand.ledEffect3 ?? ledGroup.ledRight.ledEffect4,
  rLedGrandColor1: ledGroup.ledRightGrand.ledColor1 ?? ledGroup.ledRight.ledColor1,
  rLedGrandColor2: ledGroup.ledRightGrand.ledColor2 ?? ledGroup.ledRight.ledColor2,
  rLedGrandColor3: ledGroup.ledRightGrand.ledColor3 ?? ledGroup.ledRight.ledColor4,
});

/* ************ Style ************ */
const useStyles = makeStyles(() => ({
  contens: {
    padding: '8px 16px',
  },
  topArea: {
    display: 'flex',
    margin: '0px auto 10px',
  },
  groupName: {
    padding: '0 20px 0 75px',
    width: '720px',
    height: '71px',
    borderRadius: '4px',
    '& .outlinedLabel': {
      width: '120px',
      margin: 'auto 72px auto 0',
      whiteSpace: 'nowrap',
    },
    '& .outlinedInput': {
      margin: 'auto 0',
      height: '60px',
    },
  },
  status: {
    '& legend': {
      fontSize: 21,
    },
    '& div': {
      '& label': {
        fontSize: 21,
      },
    },
    margin: '12px 0 0 auto',
  },
  setting: {
    display: 'flex',
    gap: '16px 40px',
    height: '280px',
  },
  rightTopBtnArea: {
    textAlign: 'center',
  },
  rightSettingArea: {
    margin: '0, auto',
  },
  rightBottomBtnArea: {
    display: 'flex',
    marginLeft: 'auto',
  },
  nextBtn: {
    width: '200px',
    height: '70px',
    background: '#EBEBEB',
    margin: '4px 0 0 auto',
    fontSize: '21px',
    boxShadow: '5px 5px 3px rgba(0, 0, 0, 0.2)',
  },
  middleContent: {
    display: 'flex',
    justifyContent: 'space-between',
    '& .ledSelectArea': {
      position: 'relative',
      height: '100%',
    },
  },
  initBtn: {
    fontSize: 21,
    lineHeight: '24px',
    height: 52,
    width: 144,
    margin: '3px 0 0',
  },
  disableInputArea: {
    position: 'absolute',
    height: '89%',
    width: '100%',
    background: 'rgb(128 128 128 / 50%)',
    borderRadius: '8px 8px 8px 8px',
    zIndex: 100,
    top: '57px',
    left: '0px',
  },
  flexArea: {
    display: 'flex',
  },
  blockArea: {
    display: 'block',
  },
  tab: {
    border: '1px solid #8C93B0',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: '0px 0px',
    height: '54px',
    whiteSpace: 'nowrap',
    width: '100%',
    textAlign: 'center',
    fontSize: '19px',
    margin: '0px auto 4px',
    '& span': {
      width: '17px',
    },
  },
}));

/* ******** Components ******** */
const LedGroupCreate: React.FC = () => {
  const dispatch = useAppDispatch();
  const history = useHistory();
  const classes = useStyles();
  const location = useLocation<GroupLocationType>();
  const fromScrId: string = location.state?.fromScrId ?? '';
  const groupId: number = location.state?.groupId ?? COMMON.DUMMY_GROUP_ID;
  // DataSource
  const settingDataSource = useMemo(() => getClsfcnByCategory(CLSFCN.LED_SETTING.CATEGORY_CD), []);
  const directingDataSource = useMemo(() => getClsfcnByCategory(CLSFCN.LED_DIRECTING.CATEGORY_CD), []);
  const ecoModeDataSource = useMemo(() => getSortedClsfcnByCategory(CLSFCN.LED_ECO_MODE.CATEGORY_CD), []);
  const ecoTypeDataSource = useMemo(() => getClsfcnByCategory(CLSFCN.LED_ECO_TYPE.CATEGORY_CD), []);
  const communicationConfDataSource = useMemo(
    () => getClsfcnByCategory(CLSFCN.GROUP_COMMUNICATION_CONF.CATEGORY_CD),
    [],
  );
  const bgmDataSource = useMemo(() => getClsfcnByCategory(CLSFCN.GROUP_BGM.CATEGORY_CD), []);
  const seDataSource = useMemo(() => getClsfcnByCategory(CLSFCN.GROUP_SE.CATEGORY_CD), []);
  const voiceDataSource = useMemo(() => getClsfcnByCategory(CLSFCN.GROUP_VOICE.CATEGORY_CD), []);

  /* ************ state/redux ************ */
  const { ledGroup } = useAppSelector((state) => state.group);
  const [inputValue, setInputValue] = useState<{
    groupName: string;
    status: string;
    setting: string;
    directing: string;
    ecoMode: string;
    keepEcoType: string;
    communicationConf: string;
    bgm: string;
    se: string;
    voice: string;
  }>({
    groupName: ledGroup.groupName,
    status: ledGroup.status,
    setting: ledGroup.setting,
    directing: ledGroup.directing,
    ecoMode: ledGroup.ecoMode,
    keepEcoType: ledGroup.keepEcoType,
    communicationConf: ledGroup.communicationConf,
    bgm: ledGroup.bgm,
    se: ledGroup.se,
    voice: ledGroup.voice,
  });
  const [ledSettings, setLedSettings] = useState<ledSelectAreaConfType>(
    convertLedSettingToFlat({
      ledLeft: ledGroup.ledLeft,
      ledRight: ledGroup.ledRight,
      ledLeftGrand: ledGroup.ledLeftGrand,
      ledRightGrand: ledGroup.ledRightGrand,
    }),
  );
  const [selectList, setSelectList] = useState<SelectListType>(INITIAL_SELECT_LIST.selectList);
  const isSimpleSetting = inputValue.setting === CLSFCN.LED_SETTING.SIMPLE;
  const [settingTab, setSettingTab] = useState<string>(COMMON.SETTING_TAB.CLENA3);
  const [ecoMode, setEcoMode] = useState<string>(ledGroup.ecoMode);
  const [jackEcoMode, setJackEcoMode] = useState<string>(ledGroup.ecoType);
  const [lastJackEcoMode, setLastJackEcoMode] = useState<string>(
    ledGroup.ecoType === CLSFCN.LED_ECO_TYPE.OFF ? CLSFCN.LED_ECO_TYPE.ECO1 : ledGroup.ecoType,
  ); // jackエコモードONの場合のみ直近の選択値を保持

  // 戻るボタンの情報取得(URL、ラベル)
  const prevScrInfo = ((): { name: string; url: string } => {
    if (ledGroup.returnFromCreateScr === COMMON.SCREEN_ID.GROUP_LIST) {
      return {
        name: '登録グループ一覧',
        url: redirectLinks.GROUP_LIST_DATA,
      };
    }
    if (ledGroup.returnFromCreateScr === COMMON.SCREEN_ID.MACHINE_DETAIL) {
      return {
        name: '機器一覧',
        url: redirectLinks.MAINT_MACHINE_LIST,
      };
    }
    return {
      name: 'ｸﾞﾙｰﾌﾟｶﾃｺﾞﾘ\n選択',
      url: redirectLinks.GROUP_SELECT_CONF,
    };
  })();

  /* ************ UseEffect ************ */
  useEffect(() => {
    // 遷移元画面によって分岐
    if (fromScrId === COMMON.SCREEN_ID.GROUP_SELECT_CATEGORY) {
      dispatch(initializeGroupSlice());
    } else if (fromScrId === COMMON.SCREEN_ID.GROUP_LIST) {
      getGroupApi();
    } else if (fromScrId === COMMON.SCREEN_ID.MACHINE_DETAIL) {
      getGroupApi();
    }
  }, []);
  useEffect(() => {
    setStateEcoMode();
  }, [inputValue.ecoMode]);
  useEffect(() => {
    // inputValue.ecoModeの値設定
    let afterInputEcoMode;
    if (settingTab === COMMON.SETTING_TAB.CLENA3) {
      afterInputEcoMode = ecoMode;
    } else {
      afterInputEcoMode = jackEcoMode;
    }
    setInputValue({
      ...inputValue,
      ecoMode: afterInputEcoMode,
    });
  }, [settingTab]);

  /* ************ APIs ************ */
  const getGroupApi = async () => {
    // グループ情報取得
    const http = new HttpConnection({ dispatch });
    await http
      .get<LedGroupType>(
        UrlHelper.convertGetUrl(REST_API.GROUP.LED_GROUP, {
          group_id: groupId.toString(),
        }),
      )
      .then((d: LedGroupType) => {
        dispatch(setLedGroup(d));
        setInputValue({
          ...inputValue,
          groupName: d.groupName,
          status: d.status,
          setting: d.setting,
          directing: d.directing,
          ecoMode: d.ecoMode,
          keepEcoType: d.keepEcoType ?? CLSFCN.LED_ECO_MODE.OFF,
          communicationConf: d.communicationConf,
          bgm: d.bgm,
          se: d.se,
          voice: d.voice,
        });
        setLedSettings(
          convertLedSettingToFlat({
            ledLeft: d.ledLeft,
            ledRight: d.ledRight,
            ledLeftGrand: d.ledLeftGrand,
            ledRightGrand: d.ledRightGrand,
          }),
        );
        setEcoMode(d.ecoMode);
        setJackEcoMode(d.ecoType);
        setLastJackEcoMode(d.ecoType === CLSFCN.LED_ECO_TYPE.OFF ? CLSFCN.LED_ECO_TYPE.ECO1 : d.ecoType);
      });
  };

  // validation
  const ledGroupValidateApi = async () => {
    const http = new HttpConnection({ dispatch });
    return http.post(REST_API.GROUP.LED_GROUP_SETTING, {
      groupName: inputValue.groupName,
      status: inputValue.status,
      setting: inputValue.setting,
      directing: inputValue.directing,
      ecoMode,
      ecoType: jackEcoMode,
      communicationConf: inputValue.communicationConf,
      bgm: inputValue.bgm,
      se: inputValue.se,
      voice: inputValue.voice,
      keepEcoType: inputValue.keepEcoType,
      ledLeft: {
        lLedColor1: ledSettings.lLedColor1,
        lLedColor2: ledSettings.lLedColor2,
        lLedColor3: ledSettings.lLedColor3,
        lLedColor4: ledSettings.lLedColor4,
        lLedEffect1: ledSettings.lLedEffect1,
        lLedEffect2: ledSettings.lLedEffect2,
        lLedEffect3: ledSettings.lLedEffect3,
        lLedEffect4: ledSettings.lLedEffect4,
      },
      ledRight: {
        rLedColor1: ledSettings.rLedColor1,
        rLedColor2: ledSettings.rLedColor2,
        rLedColor3: ledSettings.rLedColor3,
        rLedColor4: ledSettings.rLedColor4,
        rLedEffect1: ledSettings.rLedEffect1,
        rLedEffect2: ledSettings.rLedEffect2,
        rLedEffect3: ledSettings.rLedEffect3,
        rLedEffect4: ledSettings.rLedEffect4,
      },
      ledLeftGrand: {
        lLedColor1: ledSettings.lLedGrandColor1,
        lLedColor2: ledSettings.lLedGrandColor2,
        lLedColor3: ledSettings.lLedGrandColor3,
        lLedColor4: ledSettings.lLedGrandColor4,
        lLedEffect1: ledSettings.lLedGrandEffect1,
        lLedEffect2: ledSettings.lLedGrandEffect2,
        lLedEffect3: ledSettings.lLedGrandEffect3,
        lLedEffect4: ledSettings.lLedGrandEffect4,
      },
      ledRightGrand: {
        rLedColor1: ledSettings.rLedGrandColor1,
        rLedColor2: ledSettings.rLedGrandColor2,
        rLedColor3: ledSettings.rLedGrandColor3,
        rLedEffect1: ledSettings.rLedGrandEffect1,
        rLedEffect2: ledSettings.rLedGrandEffect2,
        rLedEffect3: ledSettings.rLedGrandEffect3,
      },
    });
  };
  /* ************ Event ************ */
  // return button
  const handleClickReturn = () => {
    dispatch(initializeGroupSlice());
    history.push(prevScrInfo.url);
  };

  // change input items
  const handleChangeInput = (ev: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>): void => {
    setInputValue({
      ...inputValue,
      [ev.target.name]: ev.target.value,
    });
  };

  // LED照明演出設定
  const handleChangeLedSelectArea = useCallback((ledSelectAreaConf: ledSelectAreaConfType) => {
    setLedSettings(ledSelectAreaConf);
  }, []);

  // on click pulldown items
  const handleClickSelectList = useCallback(
    (ev: React.MouseEvent<HTMLButtonElement>, id: string, tab?: string) => {
      let dataSource;
      let title;
      let value;
      let placementDefault: Alignment = 'left';

      if (id === GROUP_LED_INPUT_NAMES.SETTING) {
        dataSource = settingDataSource;
        title = MsgHelper.messageFormat(MESSAGES.INF234, '設定内容');
        value = inputValue.setting;
      } else if (id === GROUP_LED_INPUT_NAMES.DIRECTING) {
        dataSource = directingDataSource;
        title = MsgHelper.messageFormat(MESSAGES.INF234, '演出内容');
        value = inputValue.directing;
      } else if (id === GROUP_LED_INPUT_NAMES.ECO_MODE && tab === COMMON.SETTING_TAB.CLENA3) {
        dataSource = ecoModeDataSource;
        title = MsgHelper.messageFormat(MESSAGES.INF234, 'エコモード');
        value = inputValue.ecoMode;
      } else if (id === GROUP_LED_INPUT_NAMES.KEEP_ECO_TYPE && tab === COMMON.SETTING_TAB.GRAND) {
        dataSource = ecoModeDataSource;
        title = MsgHelper.messageFormat(MESSAGES.INF234, 'プレイ時もエコモード継続');
        value = inputValue.keepEcoType;
      } else if (
        id === GROUP_LED_INPUT_NAMES.ECO_MODE &&
        [COMMON.SETTING_TAB.JACK, COMMON.SETTING_TAB.GRAND].includes(`${tab}`)
      ) {
        dataSource = ecoTypeDataSource;
        title = MsgHelper.messageFormat(MESSAGES.INF234, 'エコモード');
        value = inputValue.ecoMode;
      } else if (id === GROUP_LED_INPUT_NAMES.COMMUNICATION_CONF) {
        dataSource = communicationConfDataSource;
        title = MsgHelper.messageFormat(MESSAGES.INF234, '通信設定');
        value = inputValue.communicationConf;
      } else if (id === GROUP_LED_INPUT_NAMES.BGM) {
        dataSource = bgmDataSource;
        title = MsgHelper.messageFormat(MESSAGES.INF234, 'BGM');
        value = inputValue.bgm;
        placementDefault = 'left-end';
      } else if (id === GROUP_LED_INPUT_NAMES.SE) {
        dataSource = seDataSource;
        title = MsgHelper.messageFormat(MESSAGES.INF234, 'SE');
        value = inputValue.se;
      } else if (id === GROUP_LED_INPUT_NAMES.VOICE) {
        dataSource = voiceDataSource;
        title = MsgHelper.messageFormat(MESSAGES.INF234, 'ボイス');
        value = inputValue.voice;
      } else {
        return;
      }

      setSelectList({
        ...INITIAL_SELECT_LIST.selectList,
        open: true,
        onClick: (data: { value: string }, name: string) => {
          setInputValue({
            ...inputValue,
            [name]: data.value,
          });
        },
        onClose: () => setSelectList(INITIAL_SELECT_LIST.selectList),
        anchorEL: ev.currentTarget,
        dataSource,
        name: id,
        placement: placementDefault,
        title,
        value,
      });
    },
    [inputValue],
  );

  // reset setting button
  const handleClickReset = () => {
    const newConf = {
      ...ledSettings,
      lLedColor4: CLSFCN.LED_COLOR.BASE_COLOR,
      lLedEffect4: CLSFCN.LED_EFFECT.NONE_LIGHTING,
      lLedColor3: CLSFCN.LED_COLOR.BASE_COLOR,
      lLedEffect3: CLSFCN.LED_EFFECT.NONE_LIGHTING,
      lLedColor2: CLSFCN.LED_COLOR.BASE_COLOR,
      lLedEffect2: CLSFCN.LED_EFFECT.NONE_LIGHTING,
      lLedColor1: CLSFCN.LED_COLOR.BASE_COLOR,
      lLedEffect1: CLSFCN.LED_EFFECT.NONE_LIGHTING,
      rLedColor4: CLSFCN.LED_COLOR.BASE_COLOR,
      rLedEffect4: CLSFCN.LED_EFFECT.NONE_LIGHTING,
      rLedColor3: CLSFCN.LED_COLOR.BASE_COLOR,
      rLedEffect3: CLSFCN.LED_EFFECT.NONE_LIGHTING,
      rLedColor2: CLSFCN.LED_COLOR.BASE_COLOR,
      rLedEffect2: CLSFCN.LED_EFFECT.NONE_LIGHTING,
      rLedColor1: CLSFCN.LED_COLOR.BASE_COLOR,
      rLedEffect1: CLSFCN.LED_EFFECT.NONE_LIGHTING,
      // left grand
      lLedGrandColor4: CLSFCN.LED_GRAND_COLOR.SAMPLE_PATTERN,
      lLedGrandEffect4: CLSFCN.LED_EFFECT.NONE_LIGHTING,
      lLedGrandColor3: CLSFCN.LED_GRAND_COLOR.SAMPLE_PATTERN,
      lLedGrandEffect3: CLSFCN.LED_EFFECT.NONE_LIGHTING,
      lLedGrandColor2: CLSFCN.LED_GRAND_COLOR.SAMPLE_PATTERN,
      lLedGrandEffect2: CLSFCN.LED_EFFECT.NONE_LIGHTING,
      lLedGrandColor1: CLSFCN.LED_GRAND_COLOR.SAMPLE_PATTERN,
      lLedGrandEffect1: CLSFCN.LED_EFFECT.NONE_LIGHTING,
      // right grand
      rLedGrandColor3: CLSFCN.LED_GRAND_COLOR.SAMPLE_PATTERN,
      rLedGrandEffect3: CLSFCN.LED_EFFECT.NONE_LIGHTING,
      rLedGrandColor2: CLSFCN.LED_GRAND_COLOR.SAMPLE_PATTERN,
      rLedGrandEffect2: CLSFCN.LED_EFFECT.NONE_LIGHTING,
      rLedGrandColor1: CLSFCN.LED_GRAND_COLOR.SAMPLE_PATTERN,
      rLedGrandEffect1: CLSFCN.LED_EFFECT.NONE_LIGHTING,
    };
    setLedSettings(newConf);

    // 初期化
    setInputValue({
      ...inputValue,
      status: COMMON.VALID_FLG.VALID,
      setting: CLSFCN.LED_SETTING.SIMPLE,
      directing: CLSFCN.LED_DIRECTING.MIX1,
      ecoMode: settingTab === COMMON.SETTING_TAB.CLENA3 ? CLSFCN.LED_ECO_MODE.OFF : CLSFCN.LED_ECO_TYPE.OFF,
      keepEcoType: CLSFCN.LED_ECO_MODE.OFF,
      communicationConf: CLSFCN.GROUP_COMMUNICATION_CONF.SYNC,
      bgm: CLSFCN.GROUP_BGM.BGM1,
      se: CLSFCN.GROUP_SE.SET1,
      voice: CLSFCN.GROUP_VOICE.SET1,
    });
  };

  // next button
  const handleClicknext = () => {
    ledGroupValidateApi().then(() => {
      dispatch(
        setCreateLedGroup({
          groupName: inputValue.groupName,
          status: inputValue.status,
          setting: inputValue.setting,
          directing: inputValue.directing,
          ecoMode,
          ecoType: jackEcoMode,
          communicationConf: inputValue.communicationConf,
          bgm: inputValue.bgm,
          se: inputValue.se,
          voice: inputValue.voice,
          keepEcoType: inputValue.keepEcoType,
          ledLeft: {
            ledColor1: ledSettings.lLedColor1,
            ledColor2: ledSettings.lLedColor2,
            ledColor3: ledSettings.lLedColor3,
            ledColor4: ledSettings.lLedColor4,
            ledEffect1: ledSettings.lLedEffect1,
            ledEffect2: ledSettings.lLedEffect2,
            ledEffect3: ledSettings.lLedEffect3,
            ledEffect4: ledSettings.lLedEffect4,
          },
          ledRight: {
            ledColor1: ledSettings.rLedColor1,
            ledColor2: ledSettings.rLedColor2,
            ledColor3: ledSettings.rLedColor3,
            ledColor4: ledSettings.rLedColor4,
            ledEffect1: ledSettings.rLedEffect1,
            ledEffect2: ledSettings.rLedEffect2,
            ledEffect3: ledSettings.rLedEffect3,
            ledEffect4: ledSettings.rLedEffect4,
          },
          ledLeftGrand: {
            ledColor1: ledSettings.lLedGrandColor1,
            ledColor2: ledSettings.lLedGrandColor2,
            ledColor3: ledSettings.lLedGrandColor3,
            ledColor4: ledSettings.lLedGrandColor4,
            ledEffect1: ledSettings.lLedGrandEffect1,
            ledEffect2: ledSettings.lLedGrandEffect2,
            ledEffect3: ledSettings.lLedGrandEffect3,
            ledEffect4: ledSettings.lLedGrandEffect4,
          },
          ledRightGrand: {
            ledColor1: ledSettings.rLedGrandColor1,
            ledColor2: ledSettings.rLedGrandColor2,
            ledColor3: ledSettings.rLedGrandColor3,
            ledEffect1: ledSettings.rLedGrandEffect1,
            ledEffect2: ledSettings.rLedGrandEffect2,
            ledEffect3: ledSettings.rLedGrandEffect3,
          },
        }),
      );
      // 登録機器選択画面へ遷移
      history.push(redirectLinks.GROUP_LED_SELECT_REGISTER_MACHINE);
    });
  };
  // タブクリック（切り替え）
  const handleMainMenuTab = (select: string) => {
    setSettingTab(select);
  };

  // (state)ecoMode,jackEcoModeに値設定
  const setStateEcoMode = () => {
    // stateに設定
    if (settingTab === COMMON.SETTING_TAB.CLENA3) {
      // 現在がクレナ3を表示時
      setEcoMode(inputValue.ecoMode);
      if (inputValue.ecoMode === CLSFCN.LED_ECO_MODE.OFF) {
        // [①OFF]選択時
        setJackEcoMode(CLSFCN.LED_ECO_TYPE.OFF);
      } else {
        // [②ON]選択時
        // クレナ3エコモードがONの時、直近Jackエコモードを設定
        setJackEcoMode(lastJackEcoMode);
      }
    } else {
      // 現在がクレナ3Jackを表示時
      setJackEcoMode(inputValue.ecoMode);
      // jackエコモードがONの時、
      if (inputValue.ecoMode === CLSFCN.LED_ECO_TYPE.OFF) {
        // ①OFF選択時
        setEcoMode(CLSFCN.LED_ECO_MODE.OFF);
        setLastJackEcoMode(CLSFCN.LED_ECO_TYPE.ECO1); // 初期化
      } else {
        // [②エコモード1],[③エコモード2],[④エコモード3]選択時
        setEcoMode(CLSFCN.LED_ECO_MODE.ON);
        setLastJackEcoMode(inputValue.ecoMode); // 直近選択したJackエコモードを保持
      }
    }
  };

  return (
    <ScreenContext.Provider value={ScreenContextValue}>
      <Layout>
        <Box className={classes.contens}>
          <Box className={classes.topArea}>
            <ReturnButton onClick={handleClickReturn}>{prevScrInfo.name}</ReturnButton>
            <OutlineTextBox
              id="groupName"
              name="groupName"
              label="グループ名"
              labelPlacement="top"
              value={inputValue.groupName}
              onChange={handleChangeInput}
              className={classes.groupName}
              fullWidth
            />
            <RadioButtons
              row
              name="status"
              label="グループ設定"
              radioList={statusRadios()}
              value={inputValue.status}
              onChange={handleChangeInput}
              className={classes.status}
            />
          </Box>
          <Box className={classes.middleContent}>
            <Box className="ledSelectArea">
              <Box className={classes.blockArea}>
                <Box className={classes.flexArea}>
                  {/* コンテンツ上部(メニュータブ) */}
                  {MENU_SETTING.map((menu) => (
                    <MenuTab
                      className={classes.tab}
                      disabledNext
                      disabledPrevious
                      isSelected={settingTab === menu.settingTab}
                      onClick={() => handleMainMenuTab(menu.settingTab)}>
                      {menu.label}
                    </MenuTab>
                  ))}
                </Box>
                <LedSelectArea
                  leftRight={leftRight}
                  ledSelectAreaConf={ledSettings}
                  onChange={handleChangeLedSelectArea}
                  disabled={isSimpleSetting}
                  settingTab={settingTab}
                />
              </Box>
              {isSimpleSetting && <Box className={classes.disableInputArea} />}
            </Box>
            <Box className={classes.blockArea}>
              <Box className={classes.rightTopBtnArea}>
                <DeleteButton onClick={handleClickReset} className={classes.initBtn}>
                  設定初期化
                </DeleteButton>
              </Box>
              <Box className={classes.rightSettingArea}>
                <LedGroupBasicSettings
                  selectList={selectList}
                  inputValue={inputValue}
                  handleClickSelectList={handleClickSelectList}
                  settingTab={settingTab}
                />
              </Box>
              <Box className={classes.rightBottomBtnArea}>
                <NormalButton className={classes.nextBtn} onClick={handleClicknext}>
                  登録機器選択へ
                </NormalButton>
              </Box>
            </Box>
          </Box>
        </Box>
        <SelectList selectList={selectList} />
      </Layout>
    </ScreenContext.Provider>
  );
};

export default LedGroupCreate;
