import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Box, makeStyles } from '@material-ui/core';
import LoginLayout from 'components/system/layouts/LoginLayout';
import ScreenContext from 'Contexts/Common/ScreenContext';
import { useAppDispatch } from 'redux/hooks';
import OutlineTextBox from 'components/system/atoms/textBoxs/OutlineTextBox';
import DoneButton from 'components/system/atoms/buttons/DoneButton';
import NomalCheckBox from 'components/system/atoms/checkBoxs/NomalCheckBox';
import HttpConnection from 'utils/httpConnection';
import { REST_API } from 'constants/apiUrls';
import redirectLinks from 'constants/redirectLinks';
import LoginLogo from 'components/login/layouts/loginLogo';
import { PasswordConfInputValueType } from 'types/login/PasswordConfType';
import prizeMachineImage from 'assets/images/machine.png';

/* ************ Style ************ */
const useStyles = makeStyles(() => ({
  contens: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: '111px 48px 23px 48px',
    '& .leftArea': {
      width: '436px',
      '& .systemLogo': {
        height: '50px',
        marginTop: 23,
      },
      '& .systemImage': {
        position: 'absolute',
        width: 652,
        top: 198,
        left: -80,
        zIndex: 0,
      },
    },
    '& .rightArea': {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      width: '60%',
      padding: '20px 48px 80px 48px',
      background: '#E5E5E5',
      borderRadius: '8px',
      zIndex: 1,
      '& .title': {
        fontSize: '40px',
      },
      '& .description': {
        margin: '16px 0 32px 0',
        fontSize: '28px',
      },
    },
  },
  inputArea: {
    width: '560px',
  },
  textBox: {
    marginTop: '24px',
    '& .outlinedInput': {
      height: '60px',
    },
  },
  checkBox: {},
  doneBtn: {
    height: '60px',
    marginTop: '48px',
    fontSize: '28px',
  },
}));
/* ************ Context ************ */
const ScreenContextValue = {
  title: 'ログイン設定',
  screenId: 'SCR012',
  currentMenu: '',
};

/* ************ Component ************ */
const Login: React.FC = () => {
  const classes = useStyles();
  const history = useHistory();
  const dispatch = useAppDispatch();
  /* ************ state/redux ************ */
  // 画面項目用
  const [inputValue, setInputValue] = useState<PasswordConfInputValueType>({
    password: '',
    passwordConfirm: '',
  });
  const [isDisplayPw, setIsDisplayPw] = useState<boolean>();

  /* ************ API ************ */
  const updatePasswordApi = () => {
    const http = new HttpConnection({ dispatch });
    http.post(REST_API.LOGIN.UPDATE_PASSWORD, inputValue).then(() => {
      history.push(redirectLinks.HOME_TOP);
    });
  };

  /* ************ Event ************ */
  // 画面項目のチェンジイベント
  const handleChangeInput = (ev: React.ChangeEvent<HTMLInputElement>): void => {
    setInputValue({
      ...inputValue,
      [ev.target.name]: ev.target.value,
    });
  };
  const handleChangeIsDisplayPw = (event: React.ChangeEvent<HTMLInputElement>) => {
    setIsDisplayPw(event.target.checked);
  };
  const handleClickDone = () => {
    updatePasswordApi();
  };

  return (
    <ScreenContext.Provider value={ScreenContextValue}>
      <LoginLayout>
        <Box className={classes.contens}>
          <Box className="leftArea">
            <Box className="systemLogo">
              <LoginLogo />
            </Box>
            <Box className="systemImage">
              <img src={prizeMachineImage} alt="プライズ機イメージ" />
            </Box>
          </Box>
          <Box className="rightArea">
            <div className="title">パスワード設定</div>
            <div className="description">パスワードを設定してください。</div>
            <Box className={classes.inputArea}>
              <OutlineTextBox
                id="password"
                name="password"
                value={inputValue.password}
                placeholder="パスワード"
                type={isDisplayPw ? 'text' : 'password'}
                onChange={handleChangeInput}
                className={classes.textBox}
                fullWidth
              />
              <OutlineTextBox
                id="passwordConfirm"
                name="passwordConfirm"
                value={inputValue.passwordConfirm}
                placeholder="パスワード再入力"
                type={isDisplayPw ? 'text' : 'password'}
                onChange={handleChangeInput}
                className={classes.textBox}
                fullWidth
              />
              <NomalCheckBox
                label="パスワードを表示する"
                checked={isDisplayPw}
                onChange={handleChangeIsDisplayPw}
                exClassName={classes.checkBox}
              />
              <DoneButton className={classes.doneBtn} onClick={handleClickDone} fullWidth>
                設定
              </DoneButton>
            </Box>
          </Box>
        </Box>
      </LoginLayout>
    </ScreenContext.Provider>
  );
};

export default Login;
