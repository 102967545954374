import COMMON from 'constants/common';
import React, { useEffect, useState } from 'react';
import Layout from 'components/system/layouts/Layout';
import ScreenContext from 'Contexts/Common/ScreenContext';
import MachineConfContent from 'components/machineConf/organisms/contents/MachineConfContent';
import { Box, makeStyles } from '@material-ui/core';
import { ReturnButton } from 'components/system/atoms';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import { getClassificationName } from 'utils/common.helper';
import CLSFCN from 'constants/classification';
import redirectLinks from 'constants/redirectLinks';
import { useHistory, useLocation } from 'react-router-dom';
import { themeColor } from 'constants/theme';
import graph from 'assets/images/newServiceConfGraph.svg';
import armPowerTripleIcon from 'assets/images/armPowerTripleIcon.svg';
import UrlHelper from 'utils/url.helper';
import { handleCallSystemError } from 'redux/slices/commonSlice';
import ERROR_CODE from 'constants/errorCode';
import { getResetTimingText } from 'utils/machineConf.helper';
import { SaveDetailInfoType } from 'types/machineConf/stationSelectType';

/* ************ Context ************ */
const ScreenContextValue = {
  title: '新サービスモード設定',
  screenId: COMMON.SCREEN_ID.NEW_SERVICE_MODE_GRAND,
  currentMenu: COMMON.MENU.MACHINE_CONF,
  disableContents: true,
};

/* ************ Constants ************ */
const COLORS = {
  BASIC_ARM: '#00B050',
  PLAY_COUNT: '#3086FF',
  LIMIT_ARM: '#FF3E3E',
  LIMIT_PLAY_COUNT: '#AB47BC',
};

/* ************ Style ************ */
const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    minWidth: '1080px',
    minHeight: '563px',
    position: 'relative',
    margin: '18px 24px',
  },
  topContent: {
    display: 'flex',
    '& .returnButton': {
      fontSize: '20px',
      lineHeight: '24px',
      position: 'relative',
      zIndex: 200,
    },
    '& .modeArea': {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      width: '282px',
      height: '72px',
      fontSize: '20px',
      position: 'relative',
      zIndex: 200,
      padding: '0 16px',
      marginLeft: '32px',
    },
    '& .description': {
      fontSize: '23px',
      marginLeft: '16px',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
  },
  middleContent: {
    display: 'flex',
    justifyContent: 'space-between',
    marginTop: '16px',
    '& .limitPCArea': {
      width: '188px',
      height: '240px',
      padding: '8px 4px 8px 8px',
      '& .limitPCRow': {
        display: 'flex',
        alignItems: 'center',
        marginTop: '53px',
      },
      '& .limitPCLabelTop': {
        marginLeft: '8px',
      },
      '& .limitPCLabelBottom': {
        marginTop: '8px',
      },
    },
  },
  bottomContent: {
    display: 'flex',
    justifyContent: 'space-between',
    marginTop: '6px',
    '& .bottomLeft': {
      display: 'flex',
    },
    '& .graphArea': {
      width: '322px',
      height: '230px',
      position: 'relative',
      '& .graphImage': {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%,-50%)',
      },
      '& .graphBasicAPIcon': {
        width: '36px',
        position: 'absolute',
        top: '6px',
        left: '56px',
      },
      '& .graphLimitAPIcon': {
        width: '36px',
        position: 'absolute',
        top: '48px',
        left: '226px',
      },
      '& .graphBoxLLarge, & .graphBoxLarge, & .graphBoxMedium, & .graphBoxSmall': {
        position: 'absolute',
        fontSize: '19px',
        textAlign: 'center',
        backgroundColor: '#FFF',
        border: '1px solid #000',
        borderRadius: '4px',
      },
      '& .graphBoxLarge': {
        width: '38px',
        height: '29px',
      },
      '& .graphBoxLLarge': {
        width: '52px',
        height: '29px',
      },
      '& .graphBoxMedium': {
        width: '38px',
        height: '29px',
      },
      '& .graphBoxSmall': {
        width: '29px',
        height: '29px',
      },
      '& .graphBasicLeft': {
        top: '48px',
        left: '8px',
      },
      '& .graphBasicMiddle': {
        top: '48px',
        left: '53px',
      },
      '& .graphBasicRight': {
        top: '48px',
        left: '97px',
      },
      '& .graphBasicAO': {
        top: '84px',
        left: '53px',
      },
      '& .graphLimitLeft': {
        top: '84px',
        left: '182px',
      },
      '& .graphLimitMiddle': {
        top: '84px',
        left: '224px',
      },
      '& .graphLimitRight': {
        top: '84px',
        left: '266px',
      },
      '& .graphLimitAO': {
        top: '120px',
        left: '224px',
      },
      '& .graphPC': {
        top: '188px',
        left: '122px',
      },
      '& .graphLimitPC': {
        top: '188px',
        left: '168px',
      },
    },
    '& .resetTimingArea': {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      width: '430px',
      height: '124px',
      padding: '8px',
      marginLeft: '10px',
    },
    '& .bottomRight': {
      marginTop: 'auto',
      marginLeft: 'auto',
    },
  },
  arrowAreaLarge: {
    width: '268px',
    height: '240px',
    backgroundColor: '#FFF',
    position: 'relative',
    borderWidth: '2px',
    borderStyle: 'solid',
    borderRadius: '8px 3px 3px 8px',
    padding: '8px',
    '&:before, &:after': {
      content: '""',
      position: 'absolute',
      top: '50%',
      transform: 'translateY(-50%)',
      borderStyle: 'solid',
      borderRadius: '3px',
      borderTopColor: 'transparent',
      borderRightColor: 'transparent',
      borderBottomColor: 'transparent',
    },
    '&:before': {
      left: '265px',
      borderWidth: '120px',
      borderLeftWidth: '55px',
    },
    '&:after': {
      left: '264px',
      borderWidth: '118px',
      borderLeftWidth: '54px',
    },
    '& .powerRow': {
      display: 'flex',
      width: '276px',
      justifyContent: 'space-between',
      '& .powerSetting': {
        marginTop: '2px',
      },
    },
    '& .powerRowIcon': {
      width: '276px',
      display: 'flex',
      justifyContent: 'center',
    },
    '& .armOpenRow': {
      position: 'relative',
      width: '276px',
      textAlign: 'center',
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      marginTop: 8,
      '& .limitArmOpenBox': {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        borderWidth: '2px',
        borderStyle: 'solid',
        borderRadius: '4px',
        margin: '0 auto',
      },
      '& .armOpenLabel': {
        textAlign: 'left',
        right: '8px',
        bottom: '0',
        marginLeft: 5,
      },
    },
  },
  arrowAreaSmall: {
    width: '166px',
    height: '240px',
    backgroundColor: '#FFF',
    position: 'relative',
    borderWidth: '2px',
    borderStyle: 'solid',
    borderRadius: '8px 3px 3px 8px',
    padding: '8px',
    '&:before, &:after': {
      content: '""',
      position: 'absolute',
      top: '50%',
      transform: 'translateY(-50%)',
      borderStyle: 'solid',
      borderRadius: '3px',
      borderTopColor: 'transparent',
      borderRightColor: 'transparent',
      borderBottomColor: 'transparent',
    },
    '&:before': {
      left: '163px',
      borderWidth: '120px',
      borderLeftWidth: '54px',
    },
    '&:after': {
      left: '161px',
      borderWidth: '120px',
      borderLeftWidth: '54px',
    },
    '& .playCountRow': {
      display: 'flex',
      alignItems: 'center',
      width: '180px',
      marginTop: '53px',
      '& .playCountLabelTop': {
        marginLeft: '8px',
        zIndex: 1,
      },
    },
    '& .playCountLabelBottom': {
      width: '180px',
      position: 'relative',
      zIndex: 1,
      marginTop: '8px',
    },
  },
  // ステーション別Style
  grandStyle: {
    borderColor: themeColor.grandDarkColor,
    '&:before': {
      borderLeftColor: themeColor.grandDarkColor,
    },
    '&:after': {
      borderLeftColor: '#FFF',
    },
  },
  // Color
  basicArmColor: {
    color: COLORS.BASIC_ARM,
  },
  playCountColor: {
    color: COLORS.PLAY_COUNT,
  },
  limitArmColor: {
    color: COLORS.LIMIT_ARM,
  },
  limitPlayCountColor: {
    color: COLORS.LIMIT_PLAY_COUNT,
  },
  // Font
  fontLarge: {
    fontSize: '24px',
    lineHeight: '24px',
  },
  fontSmall: {
    fontSize: '19px',
  },
  // Common Style
  zIndex1: {
    zIndex: 1,
  },
  disableInputArea: {
    position: 'absolute',
    height: '100%',
    width: '100%',
    background: 'rgb(128 128 128 / 50%)',
    borderRadius: '8px',
    zIndex: 100,
    top: '0px',
    left: '0px',
  },
  baseArea: {
    backgroundColor: '#FFF',
    borderWidth: '2px',
    borderStyle: 'solid',
    borderRadius: '8px',
  },
  armPowerTripleIcon: {
    width: '72px',
    height: '72px',
    margin: '0 8px',
  },
  doneImage: {
    width: '56px',
    height: '28px',
    padding: '2px 0px',
    borderRadius: '4px',
    fontSize: '16px',
    textAlign: 'center',
    verticalAlign: 'middle',
    display: 'inline-block',
    background: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  inputBox: {
    width: '90px',
    height: '56px',
    fontSize: '28px',
    '&.fontMedium': {
      fontSize: '24px',
    },
    '&.resetTiming': {
      width: '182px',
      height: '65px',
    },
  },
  boxValue: {
    marginTop: 2,
    width: '90px',
    height: '56px',
    fontSize: '28px',
    border: `2px solid ${theme.grandStation.itemBorder}`,
    borderRadius: 4,
    justifyContent: 'center',
    display: 'flex',
    alignItems: 'center',
    color: COLORS.BASIC_ARM,
    zIndex: 99,
  },
  boxSerial: {
    width: 182,
    height: 65,
    color: '#000000',
    fontSize: 28,
  },
  boxTextRed: {
    color: COLORS.LIMIT_ARM,
  },
  boxTextBlue: {
    color: COLORS.PLAY_COUNT,
  },
  boxTextPurple: {
    color: COLORS.LIMIT_PLAY_COUNT,
  },
}));

/* ******** Main Component ******** */
/**
 *
 * @returns {React.FC} - 新サービスモード設定
 *
 */
const NewServiceModeConfGrand: React.FC = () => {
  const classes = useStyles();
  const history = useHistory();
  const dispatch = useAppDispatch();
  const location = useLocation();
  const [savedConfProps] = useState(location.state !== undefined ? (location.state as SaveDetailInfoType) : null);

  /* ************ State ************ */
  // Global
  const operationConf = useAppSelector((state) => state.machineConf.machineGrandConf.operationConf);
  const areaConf = useAppSelector((state) => state.machineConf.machineGrandConf.areaConf);

  /* ************ Event ************ */
  useEffect(() => {
    if (!operationConf || !areaConf) {
      dispatch(handleCallSystemError({ errorNo: ERROR_CODE.NOT_FOUND }));
    }
  }, []);

  const handleClickReturn = () => {
    history.push(
      UrlHelper.convertQueryUrlFrontEnd(redirectLinks.MCONF_GRAND_HOME_DETAIL, {
        selectConfId: 'none',
      }),
    );
  };

  return (
    <ScreenContext.Provider value={ScreenContextValue}>
      <Layout>
        <MachineConfContent savedConfProps={savedConfProps}>
          <Box className={classes.root}>
            <Box className={classes.topContent}>
              <ReturnButton className="returnButton" onClick={handleClickReturn}>
                機器設定
                <br />
                詳細
              </ReturnButton>
              <Box className="description">新サービスモード設定の詳細です。</Box>
            </Box>
            <Box className={classes.middleContent}>
              <Box className={`${classes.arrowAreaLarge} ${classes.grandStyle}`}>
                <Box className={`${classes.fontLarge} ${classes.basicArmColor}`}>基本のアームパワー</Box>
                <Box className="powerRowIcon">
                  <img src={armPowerTripleIcon} alt="armPowerTripleIcon" className={classes.armPowerTripleIcon} />
                </Box>
                <Box className="powerRow">
                  <Box className={`${classes.boxValue}`}>{String(areaConf?.lPower1)}</Box>
                  <Box className={`${classes.boxValue}`}>{String(areaConf?.cPower1)}</Box>
                  <Box className={`${classes.boxValue}`}>{String(areaConf?.rPower1)}</Box>
                </Box>
                <Box className="armOpenRow">
                  <Box className={classes.fontSmall}>
                    アーム
                    <br />
                    開度
                  </Box>
                  <Box className={`${classes.boxValue}`}>{areaConf?.armOpen}%</Box>
                  <Box className={`${classes.fontSmall} armOpenLabel`}>から開始し、</Box>
                </Box>
              </Box>
              <Box className={`${classes.arrowAreaSmall} ${classes.grandStyle}`}>
                <Box className={`${classes.fontLarge} ${classes.playCountColor}`}>プレイ回数</Box>
                <Box className="playCountRow">
                  <Box className={`${classes.boxValue} ${classes.boxTextBlue}`}>
                    {operationConf?.playCount &&
                      getClassificationName(CLSFCN.PLAY_COUNT.CATEGORY_CD, operationConf.playCount)}
                  </Box>
                  <Box className={`${classes.fontSmall} playCountLabelTop`}>回目以降</Box>
                </Box>
                <Box className={`${classes.fontSmall} playCountLabelBottom`}>パワーを上昇させ、</Box>
              </Box>
              <Box className={`${classes.arrowAreaLarge} ${classes.grandStyle}`}>
                <Box className={`${classes.fontLarge} ${classes.limitArmColor}`}>上限のアームパワー</Box>
                <Box className="powerRowIcon">
                  <img src={armPowerTripleIcon} alt="armPowerTripleIcon" className={classes.armPowerTripleIcon} />
                </Box>
                <Box className="powerRow">
                  <Box className={`${classes.boxValue} ${classes.boxTextRed}`}>{operationConf?.limitLPower}</Box>
                  <Box className={`${classes.boxValue} ${classes.boxTextRed}`}>{operationConf?.limitCPower}</Box>
                  <Box className={`${classes.boxValue} ${classes.boxTextRed}`}>{operationConf?.limitRPower}</Box>
                </Box>
                <Box className="armOpenRow">
                  <Box className={classes.fontSmall}>
                    アーム
                    <br />
                    開度
                  </Box>
                  <Box className={`${classes.boxValue} ${classes.boxTextRed}`}>{`${areaConf?.armOpen}`}%</Box>
                  <Box className={`${classes.fontSmall} armOpenLabel`}>を目標に、</Box>
                </Box>
              </Box>
              <Box className={`${classes.baseArea} limitPCArea ${classes.grandStyle}`}>
                <Box className={`${classes.fontLarge} ${classes.limitPlayCountColor}`}>上限プレイ回数</Box>
                <Box className="limitPCRow">
                  <Box className={`${classes.boxValue} ${classes.boxTextPurple}`}>
                    {operationConf?.limitPlayCount &&
                      getClassificationName(CLSFCN.LIMIT_PLAY_COUNT.CATEGORY_CD, operationConf.limitPlayCount)}
                  </Box>
                  <Box className={`${classes.fontSmall} limitPCLabelTop`}>回目</Box>
                </Box>
                <Box className={`${classes.fontSmall} limitPCLabelBottom`}>まで、上昇を続ける</Box>
              </Box>
            </Box>
            <Box className={classes.bottomContent}>
              <Box className="bottomLeft">
                <Box className={`${classes.baseArea} graphArea ${classes.grandStyle}`}>
                  {/* グラフのラベルと画像 */}
                  <img src={graph} alt="graph" className="graphImage" />
                  <img src={armPowerTripleIcon} alt="armPowerTripleIcon" className="graphBasicAPIcon" />
                  <Box className={`${classes.basicArmColor} graphBoxLarge graphBasicLeft`}>{areaConf?.lPower1}</Box>
                  <Box className={`${classes.basicArmColor} graphBoxLarge graphBasicMiddle`}>{areaConf?.cPower1}</Box>
                  <Box className={`${classes.basicArmColor} graphBoxLarge graphBasicRight`}>{areaConf?.rPower1}</Box>
                  <Box className={`${classes.basicArmColor} graphBoxLLarge graphBasicAO`}>{areaConf?.armOpen}%</Box>
                  <img src={armPowerTripleIcon} alt="armPowerTripleIcon" className="graphLimitAPIcon" />
                  <Box className={`${classes.limitArmColor} graphBoxLarge graphLimitLeft`}>
                    {operationConf?.limitLPower}
                  </Box>
                  <Box className={`${classes.limitArmColor} graphBoxLarge graphLimitMiddle`}>
                    {operationConf?.limitCPower}
                  </Box>
                  <Box className={`${classes.limitArmColor} graphBoxLarge graphLimitRight`}>
                    {operationConf?.limitRPower}
                  </Box>
                  <Box className={`${classes.limitArmColor} graphBoxLLarge graphLimitAO`}>{areaConf?.armOpen}%</Box>
                  <Box className={`${classes.playCountColor} graphBoxSmall graphPC`}>{operationConf?.playCount}</Box>
                  <Box className={`${classes.limitPlayCountColor} graphBoxMedium graphLimitPC`}>
                    {operationConf?.limitPlayCount}
                  </Box>
                </Box>
                <Box className={`${classes.baseArea} resetTimingArea ${classes.grandStyle}`}>
                  <Box>
                    <Box className={classes.fontLarge}>リセットタイミング</Box>
                    <Box className={classes.fontSmall}>
                      上昇したアームパワーを
                      <br />
                      リセットするタイミング
                      <br />
                      を設定します。
                    </Box>
                  </Box>
                  <Box className={`${classes.boxValue} ${classes.boxSerial}`}>
                    {getResetTimingText(operationConf?.resetTiming ?? '')}
                  </Box>
                </Box>
              </Box>
            </Box>
          </Box>
        </MachineConfContent>
      </Layout>
    </ScreenContext.Provider>
  );
};

export default NewServiceModeConfGrand;
