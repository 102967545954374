import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import COMMON from 'constants/common';
import {
  GetGiftGroupType,
  LedGroupType,
  GroupSliceType,
  MachineListType,
  LedSettingType,
  LedRightGrandType,
} from 'types/group/GroupType';
import CLSFCN from 'constants/classification';

/* ************ State ************ */
const initialState: GroupSliceType = {
  getGiftGroup: {
    returnFromCreateScr: COMMON.SCREEN_ID.GROUP_SELECT_CATEGORY,
    returnFromSelectScr: COMMON.SCREEN_ID.GET_GIFT_GROUP_CREATE,
    nextFromOrderSetScr: COMMON.SCREEN_ID.GROUP_SELECT_CATEGORY,
    groupId: COMMON.DUMMY_GROUP_ID,
    versionNo: -1,
    groupName: '',
    se: COMMON.SWITCH.ON,
    voice: COMMON.SWITCH.ON,
    selectMachineSort: CLSFCN.MACHINE_SORT.BOARD_SERIAL_ASK,
    machineList: [],
  },
  ledGroup: {
    returnFromCreateScr: COMMON.SCREEN_ID.GROUP_SELECT_CATEGORY,
    returnFromSelectScr: COMMON.SCREEN_ID.LED_GROUP_CREATE,
    nextFromOrderSetScr: COMMON.SCREEN_ID.GROUP_SELECT_CATEGORY,
    groupId: COMMON.DUMMY_GROUP_ID,
    versionNo: -1,
    groupName: '',
    status: COMMON.VALID_FLG.VALID,
    setting: CLSFCN.LED_SETTING.SIMPLE,
    directing: CLSFCN.LED_DIRECTING.MIX1,
    ecoMode: CLSFCN.LED_ECO_MODE.OFF,
    ecoType: CLSFCN.LED_ECO_TYPE.OFF,
    keepEcoType: CLSFCN.LED_ECO_MODE.OFF,
    communicationConf: CLSFCN.GROUP_COMMUNICATION_CONF.SYNC,
    bgm: CLSFCN.GROUP_BGM.BGM1,
    se: CLSFCN.GROUP_SE.SET1,
    voice: CLSFCN.GROUP_VOICE.SET1,
    ledLeft: {
      ledColor1: CLSFCN.LED_COLOR.BASE_COLOR,
      ledColor2: CLSFCN.LED_COLOR.BASE_COLOR,
      ledColor3: CLSFCN.LED_COLOR.BASE_COLOR,
      ledColor4: CLSFCN.LED_COLOR.BASE_COLOR,
      ledEffect1: CLSFCN.LED_EFFECT.NONE_LIGHTING,
      ledEffect2: CLSFCN.LED_EFFECT.NONE_LIGHTING,
      ledEffect3: CLSFCN.LED_EFFECT.NONE_LIGHTING,
      ledEffect4: CLSFCN.LED_EFFECT.NONE_LIGHTING,
    },
    ledRight: {
      ledColor1: CLSFCN.LED_COLOR.BASE_COLOR,
      ledColor2: CLSFCN.LED_COLOR.BASE_COLOR,
      ledColor3: CLSFCN.LED_COLOR.BASE_COLOR,
      ledColor4: CLSFCN.LED_COLOR.BASE_COLOR,
      ledEffect1: CLSFCN.LED_EFFECT.NONE_LIGHTING,
      ledEffect2: CLSFCN.LED_EFFECT.NONE_LIGHTING,
      ledEffect3: CLSFCN.LED_EFFECT.NONE_LIGHTING,
      ledEffect4: CLSFCN.LED_EFFECT.NONE_LIGHTING,
    },
    ledLeftGrand: {
      ledColor1: CLSFCN.LED_GRAND_COLOR.SAMPLE_PATTERN,
      ledColor2: CLSFCN.LED_GRAND_COLOR.SAMPLE_PATTERN,
      ledColor3: CLSFCN.LED_GRAND_COLOR.SAMPLE_PATTERN,
      ledColor4: CLSFCN.LED_GRAND_COLOR.SAMPLE_PATTERN,
      ledEffect1: CLSFCN.LED_EFFECT.NONE_LIGHTING,
      ledEffect2: CLSFCN.LED_EFFECT.NONE_LIGHTING,
      ledEffect3: CLSFCN.LED_EFFECT.NONE_LIGHTING,
      ledEffect4: CLSFCN.LED_EFFECT.NONE_LIGHTING,
    },
    ledRightGrand: {
      ledColor1: CLSFCN.LED_GRAND_COLOR.SAMPLE_PATTERN,
      ledColor2: CLSFCN.LED_GRAND_COLOR.SAMPLE_PATTERN,
      ledColor3: CLSFCN.LED_GRAND_COLOR.SAMPLE_PATTERN,
      ledEffect1: CLSFCN.LED_EFFECT.NONE_LIGHTING,
      ledEffect2: CLSFCN.LED_EFFECT.NONE_LIGHTING,
      ledEffect3: CLSFCN.LED_EFFECT.NONE_LIGHTING,
    },
    selectMachineSort: CLSFCN.MACHINE_SORT.BOARD_SERIAL_ASK,
    machineList: [],
  },
};

/* ******* Event ****** */
const slice = createSlice({
  name: 'group',
  initialState,
  reducers: {
    /* ========== COMMON ========== */
    initializeGroupSlice: () => initialState,
    /* ========== GET GIFT ========== */
    setReturnFromCreateScr(state, action: PayloadAction<string>) {
      state.getGiftGroup = {
        ...state.getGiftGroup,
        returnFromCreateScr: action.payload,
      };
    },
    setReturnFromSelectScr(state, action: PayloadAction<string>) {
      state.getGiftGroup = {
        ...state.getGiftGroup,
        returnFromSelectScr: action.payload,
      };
    },
    setNextFromOrderSetScr(state, action: PayloadAction<string>) {
      state.getGiftGroup = {
        ...state.getGiftGroup,
        nextFromOrderSetScr: action.payload,
      };
    },
    setCreateGroup(
      state,
      action: PayloadAction<{
        groupName: string;
        se: string;
        voice: string;
      }>,
    ) {
      state.getGiftGroup = {
        ...state.getGiftGroup,
        groupName: action.payload.groupName,
        se: action.payload.se,
        voice: action.payload.voice,
      };
    },
    setGetGiftGroup(state, action: PayloadAction<GetGiftGroupType>) {
      state.getGiftGroup = {
        ...state.getGiftGroup,
        groupId: action.payload.groupId,
        versionNo: action.payload.versionNo,
        groupName: action.payload.groupName,
        se: action.payload.se,
        voice: action.payload.voice,
        machineList: action.payload.machineList,
      };
    },
    setSelectMachineSort(state, action: PayloadAction<string>) {
      state.getGiftGroup = {
        ...state.getGiftGroup,
        selectMachineSort: action.payload,
      };
    },
    setMachineList(state, action: PayloadAction<MachineListType[]>) {
      state.getGiftGroup.machineList = action.payload;
    },
    /* ========== LED ========== */
    setReturnFromLedCreateScr(state, action: PayloadAction<string>) {
      state.ledGroup = {
        ...state.ledGroup,
        returnFromCreateScr: action.payload,
      };
    },
    setReturnFromLedSelectScr(state, action: PayloadAction<string>) {
      state.ledGroup = {
        ...state.ledGroup,
        returnFromSelectScr: action.payload,
      };
    },
    setNextFromLedOrderSetScr(state, action: PayloadAction<string>) {
      state.ledGroup = {
        ...state.ledGroup,
        nextFromOrderSetScr: action.payload,
      };
    },
    setCreateLedGroup(
      state,
      action: PayloadAction<{
        groupName: string;
        status: string;
        setting: string;
        directing: string;
        ecoMode: string;
        ecoType: string;
        keepEcoType: string;
        communicationConf: string;
        bgm: string;
        se: string;
        voice: string;
        ledLeft: LedSettingType;
        ledRight: LedSettingType;
        ledLeftGrand: LedSettingType;
        ledRightGrand: LedRightGrandType;
      }>,
    ) {
      state.ledGroup = {
        ...state.ledGroup,
        groupName: action.payload.groupName,
        status: action.payload.status,
        setting: action.payload.setting,
        directing: action.payload.directing,
        ecoMode: action.payload.ecoMode,
        ecoType: action.payload.ecoType,
        keepEcoType: action.payload.keepEcoType ?? CLSFCN.LED_ECO_MODE.OFF,
        communicationConf: action.payload.communicationConf,
        bgm: action.payload.bgm,
        se: action.payload.se,
        voice: action.payload.voice,
        ledLeft: {
          ledColor1: action.payload.ledLeft.ledColor1,
          ledColor2: action.payload.ledLeft.ledColor2,
          ledColor3: action.payload.ledLeft.ledColor3,
          ledColor4: action.payload.ledLeft.ledColor4,
          ledEffect1: action.payload.ledLeft.ledEffect1,
          ledEffect2: action.payload.ledLeft.ledEffect2,
          ledEffect3: action.payload.ledLeft.ledEffect3,
          ledEffect4: action.payload.ledLeft.ledEffect4,
        },
        ledRight: {
          ledColor1: action.payload.ledRight.ledColor1,
          ledColor2: action.payload.ledRight.ledColor2,
          ledColor3: action.payload.ledRight.ledColor3,
          ledColor4: action.payload.ledRight.ledColor4,
          ledEffect1: action.payload.ledRight.ledEffect1,
          ledEffect2: action.payload.ledRight.ledEffect2,
          ledEffect3: action.payload.ledRight.ledEffect3,
          ledEffect4: action.payload.ledRight.ledEffect4,
        },
        ledLeftGrand: {
          ledColor1: action.payload.ledLeftGrand.ledColor1 ?? action.payload.ledLeft.ledColor1,
          ledColor2: action.payload.ledLeftGrand.ledColor2 ?? action.payload.ledLeft.ledColor2,
          ledColor3: action.payload.ledLeftGrand.ledColor3 ?? action.payload.ledLeft.ledColor4,
          ledColor4: action.payload.ledLeftGrand.ledColor4 ?? action.payload.ledLeft.ledColor3,
          ledEffect1: action.payload.ledLeftGrand.ledEffect1 ?? action.payload.ledLeft.ledEffect1,
          ledEffect2: action.payload.ledLeftGrand.ledEffect2 ?? action.payload.ledLeft.ledEffect2,
          ledEffect3: action.payload.ledLeftGrand.ledEffect3 ?? action.payload.ledLeft.ledEffect4,
          ledEffect4: action.payload.ledLeftGrand.ledEffect4 ?? action.payload.ledLeft.ledEffect3,
        },
        ledRightGrand: {
          ledColor1: action.payload.ledRightGrand.ledColor1 ?? action.payload.ledRight.ledColor1,
          ledColor2: action.payload.ledRightGrand.ledColor2 ?? action.payload.ledRight.ledColor2,
          ledColor3: action.payload.ledRightGrand.ledColor3 ?? action.payload.ledRight.ledColor4,
          ledEffect1: action.payload.ledRightGrand.ledEffect1 ?? action.payload.ledRight.ledEffect1,
          ledEffect2: action.payload.ledRightGrand.ledEffect2 ?? action.payload.ledRight.ledEffect2,
          ledEffect3: action.payload.ledRightGrand.ledEffect3 ?? action.payload.ledRight.ledEffect4,
        },
      };
    },
    setLedGroup(state, action: PayloadAction<LedGroupType>) {
      state.ledGroup = {
        ...state.ledGroup,
        groupId: action.payload.groupId,
        versionNo: action.payload.versionNo,
        groupName: action.payload.groupName,
        status: action.payload.status,
        setting: action.payload.setting,
        directing: action.payload.directing,
        ecoMode: action.payload.ecoMode,
        ecoType: action.payload.ecoType,
        keepEcoType: action.payload.keepEcoType ?? CLSFCN.LED_ECO_MODE.OFF,
        communicationConf: action.payload.communicationConf,
        bgm: action.payload.bgm,
        se: action.payload.se,
        voice: action.payload.voice,
        ledLeft: action.payload.ledLeft,
        ledRight: action.payload.ledRight,
        machineList: action.payload.machineList,
        ledLeftGrand: {
          ledColor1: action.payload.ledLeftGrand.ledColor1 ?? action.payload.ledLeft.ledColor1,
          ledColor2: action.payload.ledLeftGrand.ledColor2 ?? action.payload.ledLeft.ledColor2,
          ledColor3: action.payload.ledLeftGrand.ledColor3 ?? action.payload.ledLeft.ledColor4,
          ledColor4: action.payload.ledLeftGrand.ledColor4 ?? action.payload.ledLeft.ledColor3,
          ledEffect1: action.payload.ledLeftGrand.ledEffect1 ?? action.payload.ledLeft.ledEffect1,
          ledEffect2: action.payload.ledLeftGrand.ledEffect2 ?? action.payload.ledLeft.ledEffect2,
          ledEffect3: action.payload.ledLeftGrand.ledEffect3 ?? action.payload.ledLeft.ledEffect4,
          ledEffect4: action.payload.ledLeftGrand.ledEffect4 ?? action.payload.ledLeft.ledEffect3,
        },
        ledRightGrand: {
          ledColor1: action.payload.ledRightGrand.ledColor1 ?? action.payload.ledRight.ledColor1,
          ledColor2: action.payload.ledRightGrand.ledColor2 ?? action.payload.ledRight.ledColor2,
          ledColor3: action.payload.ledRightGrand.ledColor3 ?? action.payload.ledRight.ledColor4,
          ledEffect1: action.payload.ledRightGrand.ledEffect1 ?? action.payload.ledRight.ledEffect1,
          ledEffect2: action.payload.ledRightGrand.ledEffect2 ?? action.payload.ledRight.ledEffect2,
          ledEffect3: action.payload.ledRightGrand.ledEffect3 ?? action.payload.ledRight.ledEffect4,
        },
      };
    },
    setSelectLedMachineSort(state, action: PayloadAction<string>) {
      state.ledGroup = {
        ...state.ledGroup,
        selectMachineSort: action.payload,
      };
    },
    setLedMachineList(state, action: PayloadAction<MachineListType[]>) {
      state.ledGroup.machineList = action.payload;
    },
  },
});

export const {
  initializeGroupSlice,
  setReturnFromCreateScr,
  setReturnFromSelectScr,
  setNextFromOrderSetScr,
  setCreateGroup,
  setGetGiftGroup,
  setSelectMachineSort,
  setMachineList,
  setReturnFromLedCreateScr,
  setReturnFromLedSelectScr,
  setNextFromLedOrderSetScr,
  setLedGroup,
  setCreateLedGroup,
  setSelectLedMachineSort,
  setLedMachineList,
} = slice.actions;
export default slice.reducer;
