import COMMON from 'constants/common';
import React, { useEffect, useState } from 'react';
import Layout from 'components/system/layouts/Layout';
import ScreenContext from 'Contexts/Common/ScreenContext';
import MachineConfContent from 'components/machineConf/organisms/contents/MachineConfContent';
import { Box, makeStyles } from '@material-ui/core';
import { ReturnButton } from 'components/system/atoms';
import redirectLinks from 'constants/redirectLinks';
import { useHistory, useLocation } from 'react-router-dom';
import { themeColor } from 'constants/theme';
import downArrowIcon from 'assets/images/downArrowIcon_3.svg';
import polyLineIconMedium from 'assets/images/polyLineIconMedium.svg';
import polyLineIconLarge from 'assets/images/polyLineIconLarge.svg';
import ERROR_CODE from 'constants/errorCode';
import { handleCallSystemError } from 'redux/slices/commonSlice';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import UrlHelper from 'utils/url.helper';
import { SaveDetailInfoType } from 'types/machineConf/stationSelectType';

/* ************ Context ************ */
const ScreenContextValue = {
  title: 'シーケンス設定',
  screenId: COMMON.SCREEN_ID.SEQUENCE_GRAND,
  currentMenu: COMMON.MENU.MACHINE_CONF,
  disableContents: true,
};

/* ************ Style ************ */
const useStyles = makeStyles(() => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    minWidth: '1080px',
    minHeight: '563px',
    margin: '18px 24px',
  },
  topContent: {
    display: 'flex',
    alignItems: 'center',
    '& .description': {
      fontSize: '23px',
      marginLeft: '16px',
    },
  },
  middleContent: {
    display: 'flex',
    position: 'relative',
    marginTop: '20px',
    '& .catcherOperation': {
      width: '670px',
      height: '361px',
    },
    '& .catcherReturn': {
      width: '327px',
      height: '361px',
      marginLeft: '36px',
    },
    '& .title': {
      fontSize: '21px',
      textAlign: 'center',
    },
    '& .content': {
      display: 'flex',
      justifyContent: 'space-between',
    },
    '& .toggleArea': {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      width: '276px',
      height: '72px',
      borderWidth: '2px',
      borderStyle: 'solid',
      borderRadius: '4px',
      padding: '4px 16px',
      '&.movingShift': {
        border: 'none',
      },
    },
    '& .toggleName': {
      fontSize: '21px',
      '&.small': {
        fontSize: '19px',
      },
    },
  },
  grandStyle: {
    borderColor: themeColor.grandDarkColor,
  },
  baseArea: {
    backgroundColor: '#FFF',
    borderWidth: '2px',
    borderStyle: 'solid',
    borderRadius: '8px',
    padding: '6px 24px',
    borderColor: themeColor.grandDarkColor,
  },
  downArrowIcon: {
    margin: '4px auto',
  },
  polyLineIconMedium: {
    position: 'absolute',
    top: '60px',
    left: '301px',
  },
  polyLineIconLarge: {
    position: 'absolute',
    top: '60px',
    left: '644px',
  },
  switchStatus: {
    fontSize: 21,
  },
}));

/* ******** Constants ******** */
const onOffString = (value: string | undefined) => {
  if (value === undefined) return '';
  return value === COMMON.VALID_FLG.VALID ? 'ON' : 'OFF';
};

/* ******** Main Component ******** */
/**
 *
 * @returns {React.FC} - シーケンス設定
 *
 */
const SequenceConfGrand: React.FC = () => {
  const classes = useStyles();
  const history = useHistory();
  const location = useLocation();
  const [savedConfProps] = useState(location.state !== undefined ? (location.state as SaveDetailInfoType) : null);
  const dispatch = useAppDispatch();
  const operationConf = useAppSelector((state) => state.machineConf.machineGrandConf.operationConf);

  /* ************ Event ************ */

  useEffect(() => {
    if (!operationConf) {
      dispatch(handleCallSystemError({ errorNo: ERROR_CODE.NOT_FOUND }));
    }
  }, []);

  // 戻るボタン
  const handleClickReturn = () => {
    history.push(
      UrlHelper.convertQueryUrlFrontEnd(redirectLinks.MCONF_GRAND_HOME_DETAIL, {
        selectConfId: 'none',
      }),
    );
  };

  return (
    <ScreenContext.Provider value={ScreenContextValue}>
      <Layout>
        <MachineConfContent savedConfProps={savedConfProps}>
          <Box className={classes.root}>
            <Box className={classes.topContent}>
              <ReturnButton onClick={handleClickReturn}>
                機器設定
                <br />
                詳細
              </ReturnButton>
              <Box className="description">「各シーケンス」の設定です。</Box>
            </Box>
            <Box className={classes.middleContent}>
              <Box className={`${classes.baseArea} catcherOperation`}>
                <Box className="title">キャッチャー操作時</Box>
                <Box className="content">
                  <Box className="col">
                    <Box className={`${classes.grandStyle} toggleArea`}>
                      <Box className="toggleName">X 移動</Box>
                      <Box className={classes.switchStatus}>{onOffString(operationConf?.movingX)}</Box>
                    </Box>
                    <img src={downArrowIcon} alt="downArrowIcon" className={classes.downArrowIcon} />
                    <Box className={`${classes.grandStyle} toggleArea`}>
                      <Box className="toggleName">Y 移動</Box>
                      <Box className={classes.switchStatus}>{onOffString(operationConf?.movingY)}</Box>
                    </Box>
                    <img src={downArrowIcon} alt="downArrowIcon" className={classes.downArrowIcon} />
                    <Box className={`${classes.grandStyle} toggleArea`}>
                      <Box className="toggleName">アームを開く</Box>
                      <Box className={classes.switchStatus}>{onOffString(operationConf?.openingArm)}</Box>
                    </Box>
                  </Box>
                  <Box className="col">
                    <Box className={`${classes.grandStyle} toggleArea`}>
                      <Box className="toggleName small">
                        下降
                        <br />
                        (戻り時、必ず上昇)
                      </Box>
                      <Box className={classes.switchStatus}>{onOffString(operationConf?.descending)}</Box>
                    </Box>
                    <img src={downArrowIcon} alt="downArrowIcon" className={classes.downArrowIcon} />
                    {operationConf?.operationConf === COMMON.OPERATION_CONF.SHIFT_OPERATION ? (
                      <Box className={`${classes.grandStyle} toggleArea`}>
                        <Box className="toggleName">ずらし移動</Box>
                      </Box>
                    ) : (
                      <Box className="toggleArea movingShift">
                        <img src={downArrowIcon} alt="downArrowIcon" className={classes.downArrowIcon} />
                      </Box>
                    )}
                    <img src={downArrowIcon} alt="downArrowIcon" className={classes.downArrowIcon} />
                    <Box className={`${classes.grandStyle} toggleArea`}>
                      <Box className="toggleName">アームを閉じる</Box>
                      <Box className={classes.switchStatus}>{onOffString(operationConf?.closingArm)}</Box>
                    </Box>
                  </Box>
                </Box>
              </Box>
              <Box className={`${classes.baseArea} ${classes.grandStyle} catcherReturn`}>
                <Box className="title">キャッチャー戻り時</Box>
                <Box className="col">
                  <Box className={`${classes.grandStyle} toggleArea`}>
                    <Box className="toggleName">落とし口へ移動</Box>
                    <Box className={classes.switchStatus}>{onOffString(operationConf?.movingPit)}</Box>
                  </Box>
                  <img src={downArrowIcon} alt="downArrowIcon" className={classes.downArrowIcon} />
                  <Box className={`${classes.grandStyle} toggleArea`}>
                    <Box className="toggleName">アームを開閉</Box>
                    <Box className={classes.switchStatus}>{onOffString(operationConf?.finishingArm)}</Box>
                  </Box>
                  <img src={downArrowIcon} alt="downArrowIcon" className={classes.downArrowIcon} />
                  <Box className={`${classes.grandStyle} toggleArea`}>
                    <Box className="toggleName">HPに戻る</Box>
                    <Box className={classes.switchStatus}>{onOffString(operationConf?.movingHome)}</Box>
                  </Box>
                </Box>
              </Box>
              <img src={polyLineIconMedium} alt="polyLineIconMedium" className={classes.polyLineIconMedium} />
              <img src={polyLineIconLarge} alt="polyLineIconLarge" className={classes.polyLineIconLarge} />
            </Box>
          </Box>
        </MachineConfContent>
      </Layout>
    </ScreenContext.Provider>
  );
};

export default SequenceConfGrand;
