import COMMON from 'constants/common';
import React, { useEffect, useState } from 'react';
import Layout from 'components/system/layouts/Layout';
import ScreenContext from 'Contexts/Common/ScreenContext';
import MachineConfContent from 'components/machineConf/organisms/contents/MachineConfContent';
import { Box, makeStyles } from '@material-ui/core';
import { ReturnButton } from 'components/system/atoms';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import redirectLinks from 'constants/redirectLinks';
import { useHistory, useLocation } from 'react-router-dom';
import { themeColor } from 'constants/theme';
import ShiftPanelGrand from 'components/machineConf/organisms/ShiftOperation/ShiftPanelGrand';
import { getClassificationName } from 'utils/common.helper';
import { handleCallSystemError } from 'redux/slices/commonSlice';
import ERROR_CODE from 'constants/errorCode';
import CLSFCN from 'constants/classification';
import UrlHelper from 'utils/url.helper';
import { SaveDetailInfoType } from 'types/machineConf/stationSelectType';

/* ************ Context ************ */
const ScreenContextValue = {
  title: 'ずらし運営設定',
  screenId: COMMON.SCREEN_ID.SHIFT_OPERATION_GRAND,
  currentMenu: COMMON.MENU.MACHINE_CONF,
  disableContents: true,
};

/* ************ Style ************ */
const useStyles = makeStyles(() => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    minWidth: '1080px',
    minHeight: '563px',
    position: 'relative',
    margin: '18px 24px',
  },
  topContent: {
    display: 'flex',
    '& .returnButton': {
      fontSize: '20px',
      lineHeight: '24px',
      position: 'relative',
      zIndex: 200,
    },
    '& .description': {
      fontSize: '23px',
      marginLeft: '16px',
      justifyContent: 'center',
      alignItems: 'center',
      display: 'flex',
    },
  },
  middleContent: {
    display: 'flex',
    position: 'relative',
    marginTop: '28px',
    '& .shiftCmArea': {
      width: '386px',
      fontSize: '19px',
      padding: '22px',
      marginTop: '40px',
      '& .scaMiddle': {
        display: 'flex',
        alignItems: 'end',
        marginTop: '20px',
        gap: '5px',
      },
      '& .scaBottom': {
        display: 'flex',
        alignItems: 'start',
        marginTop: '20px',
        '&:before': {
          content: '"※"',
          display: 'block',
        },
      },
    },
    '& .panelArea': {
      width: '390px',
      height: '390px',
      marginLeft: '44px',
    },
  },
  grandStyle: {
    borderColor: themeColor.grandDarkColor,
    '&:before': {
      borderRightColor: themeColor.grandDarkColor,
    },
    '&:after': {
      borderRightColor: '#FFF',
    },
  },
  // Common Style
  baseArea: {
    backgroundColor: '#FFF',
    borderWidth: '2px',
    borderStyle: 'solid',
    borderRadius: '8px',
  },
  textBox: {
    width: 100,
    height: 56,
    borderColor: themeColor.grandDarkColor,
    fontSize: 28,
    textAlign: 'center',
    border: '2px solid',
    borderRadius: 8,
    justifyContent: 'center',
    alignItems: 'center',
    display: 'flex',
  },
}));

/* ******** Main Component ******** */
/**
 *
 * @returns {React.FC} - ずらし設定
 *
 */
const ShiftOperationGrand: React.FC = () => {
  const classes = useStyles();
  const history = useHistory();
  const dispatch = useAppDispatch();
  const location = useLocation();
  const [savedConfProps] = useState(location.state !== undefined ? (location.state as SaveDetailInfoType) : null);

  /* ************ State ************ */
  // Global
  const operationConf = useAppSelector((state) => state.machineConf.machineGrandConf.operationConf);

  /* ************ Event ************ */
  useEffect(() => {
    if (!operationConf) {
      dispatch(handleCallSystemError({ errorNo: ERROR_CODE.NOT_FOUND }));
    }
  }, []);

  // 戻るボタン
  const handleClickReturn = () => {
    history.push(
      UrlHelper.convertQueryUrlFrontEnd(redirectLinks.MCONF_GRAND_HOME_DETAIL, {
        selectConfId: 'none',
      }),
    );
  };

  return (
    <ScreenContext.Provider value={ScreenContextValue}>
      <Layout>
        <MachineConfContent savedConfProps={savedConfProps}>
          <Box className={classes.root}>
            <Box className={classes.topContent}>
              <ReturnButton className="returnButton" onClick={handleClickReturn}>
                機器設定
                <br />
                詳細
              </ReturnButton>
              <Box className="description">ずらし運営設定の詳細です。</Box>
            </Box>
            <Box className={classes.middleContent}>
              <Box>
                <Box className={`${classes.baseArea} ${classes.grandStyle} shiftCmArea`}>
                  <Box>キャッチャー下降後、</Box>
                  <Box className="scaMiddle">
                    <Box id="shiftConf" className={classes.textBox}>
                      {operationConf?.shiftCm &&
                        getClassificationName(CLSFCN.SHIFT_CM.CATEGORY_CD, operationConf.shiftCm)}
                    </Box>
                    <Box>矢印の方向にずらす。</Box>
                  </Box>
                  <Box className="scaBottom">
                    移動範囲を超える場合、
                    <br />
                    それ以上には動きません。
                  </Box>
                </Box>
              </Box>
              <Box className="panelArea">
                <ShiftPanelGrand shiftConf={operationConf?.shiftDirection ?? ''} />
              </Box>
            </Box>
          </Box>
        </MachineConfContent>
      </Layout>
    </ScreenContext.Provider>
  );
};

export default ShiftOperationGrand;
