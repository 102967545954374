import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import Layout from 'components/system/layouts/Layout';
import { Box, Checkbox, FormControlLabel, Grid, IconButton, makeStyles, withStyles } from '@material-ui/core';
import MuiPagination from '@material-ui/lab/Pagination';
import ScreenContext from 'Contexts/Common/ScreenContext';
import COMMON from 'constants/common';
import redirectLinks from 'constants/redirectLinks';
import ReturnButton from 'components/system/atoms/buttons/ReturnButton';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import { NormalSelectBox } from 'components/system/organisms';
import NormalButton from 'components/system/atoms/buttons/NormalButton';
import CLSFCN from 'constants/classification';
import { getClsfcnByCategory } from 'utils/common.helper';
import HttpConnection from 'utils/httpConnection';
import { REST_API } from 'constants/apiUrls';
import ConfirmDialog from 'components/system/organisms/dialogs/ConfirmDialog';
import MsgHelper from 'utils/message.helper';
import MSG from 'constants/messages';
import InfoDialog from 'components/system/organisms/dialogs/InfoDialog';
import { GetGiftListType, GiftNameTypeTableRow, UploadGiftCsvResponseType } from 'types/maintenance/GiftType';
import GiftListTable from 'components/maintenance/organisms/tables/GiftListTable';
import CsvUploadDialog from 'components/system/organisms/dialogs/CsvUploadDialog';
import { setGiftNoStation, setGiftSearchKey, setSortGiftList } from 'redux/slices/maintenanceSlice';
import OutlineTextBox from 'components/system/atoms/textBoxs/OutlineTextBox';
import ListCheckBox from '../../components/system/atoms/checkBoxs/ListCheckBox';

/* ************ Context ************ */
const ScreenContextValue = {
  title: '登録景品一覧',
  screenId: COMMON.SCREEN_ID.GIFT_LIST,
  currentMenu: COMMON.MENU.MAINTENANCE,
};

type DeleteResponseType = {
  result: boolean;
};

/* ************ Style ************ */
const useStyles = makeStyles(() => ({
  content: {
    height: '598px',
    width: '1144px',
    padding: '0px 12px 0px 16px',
  },
  topArea: {
    display: 'flex',
    justifyContent: 'flex-start',
    '& .returnButton': {
      flex: 1,
      padding: '10px 0px 0px 0px',
      width: '224px',
    },
  },
  mainArea: {
    display: 'flex',
    height: '428px',
    flexDirection: 'row',
  },
  mainButtonArea: {
    width: '200px',
    padding: '0 0 0 18px',
    marginTop: '14px',
  },
  decision: {
    marginTop: '-39px',
    '& .btnDeleteRow': {
      height: '68px',
      width: '166px',
      backgroundColor: '#E8FF4A',
    },
    display: 'flex',
  },
  buttonSize: {
    fontSize: '22px',
    lineHeight: '24px',
  },
  boxTextRight: {
    margin: '10px 0px 0px 15px',
  },
  boxTextRightContent: {
    display: 'inline-flex',
    alignItems: 'flex-end',
  },
  addNewButton: {
    width: '180px',
    height: '60px',
    fontSize: '24px',
    background: '#EBEBEB',
    boxShadow: '5px 5px 3px rgba(0, 0, 0, 0.2)',
    borderRadius: 8,
    marginBottom: '20px',
  },
  importCSVButton: {
    width: '180px',
    height: '60px',
    fontSize: '24px',
    background: '#EBEBEB',
    borderRadius: 8,
    boxShadow: '5px 5px 3px rgba(0, 0, 0, 0.2)',
  },
  contentBox: { overflow: 'hidden' },
  contentText: { fontSize: '24px' },
  boxKeyword: {
    display: 'inline-flex',
    padding: '20px 0px 0px 0px',
    width: '266px',
  },
  keyword: {
    '& .outlinedInput': {
      width: '266px',
      '& input': {
        fontSize: '20px',
      },
    },
  },
  searchBtn: {
    margin: '1px 0px 0px -42px',
  },
  boxCheckBox: {
    display: 'inline-flex',
    fontSize: '20px',
    margin: '20px 10px 0px 10px',
  },
  boxCheckBoxAll: {
    display: 'inline-flex',
    fontSize: '20px',
    margin: '0px 5px -10px 5px',
  },
  checkBox: {
    display: 'inline',
    fontSize: '18px',
  },
  totalCnt: {
    fontSize: '24px',
    margin: '41px 200px 17px auto',
  },
}));
// ページネーションCSS変更
const Pagination = withStyles({
  root: {
    display: 'inline-block',
    '& .MuiPaginationItem-sizeLarge': {
      fontSize: '1.2rem',
    },
  },
})(MuiPagination);

// messages
const messageDeleteEquipmentSuccess: string = MsgHelper.messageFormat(MSG.INF304, 'チェックした景品');
const messageUploadCsvSuccess: string = MsgHelper.messageFormat(MSG.INF306, '読込登録');
const messageInfoDeleteEquipment: string = MsgHelper.messageFormat(MSG.INF112, '景品');

const GiftList: React.FC = () => {
  const history = useHistory();
  const dispatch = useAppDispatch();
  const classes = useStyles();
  const loginUserInfo = useAppSelector((state) => state.common.userInfo);
  const { sortGiftList, giftSearchKey, giftNoStation } = useAppSelector((state) => state.maintenance);
  const { isPc } = useAppSelector((state) => state.common.deviceInfo);
  const [giftDatasource, setGiftDatasource] = React.useState<Array<GiftNameTypeTableRow>>([]);
  const sortDataSource = getClsfcnByCategory(CLSFCN.DATA_GIFT_LIST_SORT.CATEGORY_CD);
  const [openDeleteConfirmDlg, setOpenDeleteConfirmDlg] = React.useState<boolean>(false);
  const [openImportErrorDlg, setOpenImportErrorDlg] = React.useState<boolean>(false);
  const [openDeleteSuccessDlg, setOpenDeleteSuccessDlg] = React.useState<boolean>(false);
  const [openUploadCsvSuccess, setOpenUploadCsvSuccess] = React.useState<boolean>(false);
  const [openCsvDialog, setOpenCsvDialog] = React.useState<boolean>(false);
  const [messageConfirmDeleteEquipment, setMessageConfirmDeleteEquipment] = React.useState<string>('');
  const [messageImportError, setMessageImportError] = React.useState<string>('');
  const [openDeleteInfoDlg, setOpenDeleteInfoDlg] = React.useState<boolean>(false);
  const [inputWord, setInputWord] = React.useState<string>(giftSearchKey);
  const [firstDisplay, setFirstDisplay] = React.useState<boolean>(true);
  const [countQueried, setCountQueried] = React.useState<number>(0);
  const [countTotal, setCountTotal] = React.useState<number>(0);
  const [scrollTop, setScrollTop] = React.useState<boolean>(false);

  /* ************ API ************ */
  const getGiftNameList = async (sortType: string) => {
    const http = new HttpConnection({ dispatch });
    const data = await http.get<GetGiftListType>(REST_API.MAINTENANCE.GIFT.GET_MACHINE_GIFT_NAME_LIST, {
      sort: sortType,
      search: giftSearchKey,
      noStation: giftNoStation,
    });
    let machineGiftNameListFormat: Array<GiftNameTypeTableRow> = [];
    if (data.list.length > 0) {
      machineGiftNameListFormat = data.list.map((giftName, index) => ({
        key: giftName.giftId,
        order: index + 1,
        checked: false,
        giftName: giftName.giftName,
        price: giftName.giftCost,
        updatedAt: giftName.updatedAt.replaceAll('-', '/'),
      }));
    }
    setCountQueried(machineGiftNameListFormat.length);
    setCountTotal(data.count);
    setGiftDatasource(machineGiftNameListFormat);
    setCheckedAll(false); // 全件チェッククリア
    setPage(1); // ページ初期化
    setScrollTop(true); // テーブルのスクロールトップ
  };

  const uploadGiftCsv = async (file: File | undefined) => {
    if (!(loginUserInfo?.currentHeadQId && loginUserInfo?.currentStoreId)) return;
    const http = new HttpConnection({ dispatch });
    const data = await http.postFormData<UploadGiftCsvResponseType>(REST_API.MAINTENANCE.GET_UPLOAD_GIFT_CSV, {
      giftCsv: file,
    });
    setOpenCsvDialog(false);
    if (data.success) {
      if (data.error === '') {
        setOpenUploadCsvSuccess(true);
      } else {
        setMessageImportError(MsgHelper.messageFormat(MSG.INF307, data.error ?? ''));
        setOpenImportErrorDlg(true);
      }
      getGiftNameList(sortGiftList);
    } else {
      setMessageImportError(data.error ?? '');
      setOpenImportErrorDlg(true);
    }
  };

  useEffect(() => {
    getGiftNameList(sortGiftList);
  }, []);

  useEffect(() => {
    if (firstDisplay) {
      // 初回表示時
      setFirstDisplay(false);
    } else {
      getGiftNameList(sortGiftList);
    }
  }, [giftSearchKey, giftNoStation]);

  const handleClickGroupConfTop = () => {
    dispatch(setSortGiftList(CLSFCN.DATA_GIFT_LIST_SORT.GIFT_LIST_SORT_DEFAULT));
    dispatch(setGiftSearchKey(''));
    dispatch(setGiftNoStation(COMMON.FLAG.OFF));
    history.push(redirectLinks.MAINT_TOP);
  };

  /* ************ Event ************ */
  // sort change
  const handleChangeSort = (ev: React.ChangeEvent<HTMLSelectElement>) => {
    const sortType = ev.target.value;
    dispatch(setSortGiftList(sortType));
    getGiftNameList(sortType);
  };

  const handleSelectedItemChange = (itemChecked: GiftNameTypeTableRow) => {
    const newRows = giftDatasource.map((item) => {
      if (itemChecked !== null && item.key === itemChecked.key) {
        return { ...item, checked: !item.checked };
      }
      return item;
    });
    setGiftDatasource([...newRows]);
  };

  const handleAllCheckboxChange = (ev: React.ChangeEvent<HTMLInputElement>) => {
    setCheckedAll(ev.target.checked);
    const newRows = giftDatasource.map((item) => ({ ...item, checked: ev.target.checked }));
    setGiftDatasource([...newRows]);
  };

  const handleShowCsvImportDialog = () => {
    setOpenCsvDialog(true);
  };

  const handleShowDeleteDialog = () => {
    const equipmentDeleteData = giftDatasource.filter((equipment) => equipment.checked);
    if (equipmentDeleteData.length > 0) {
      setOpenDeleteConfirmDlg(true);
      let listEquipmentString = '';
      equipmentDeleteData.forEach((item) => {
        listEquipmentString += `・${item.giftName}\n`;
      });
      setMessageConfirmDeleteEquipment(MsgHelper.messageFormat(MSG.INF228, '景品', listEquipmentString));
    } else {
      setOpenDeleteInfoDlg(true);
    }
  };

  const deleteGift = async (giftIdList: string[]) => {
    if (!(loginUserInfo?.currentHeadQId && loginUserInfo?.currentStoreId)) return;
    const http = new HttpConnection({ dispatch });
    const data = await http.post<DeleteResponseType>(REST_API.MAINTENANCE.DELETE_GIFT, { giftIdList });
    if (data.result) {
      setOpenDeleteSuccessDlg(true);
      await getGiftNameList(sortGiftList);
    }
  };

  const handleOkDeleteConfirmDlg = () => {
    setOpenDeleteConfirmDlg(false);

    const giftIdList = giftDatasource.reduce((arrGiftIds: string[], item: GiftNameTypeTableRow) => {
      if (item.checked) {
        arrGiftIds.push(item.key);
      }
      return arrGiftIds;
    }, []);

    deleteGift(giftIdList);
  };

  const handleGotoScreen = (routeName: string) => {
    history.push(routeName);
  };

  const handleUploadCsvSuccess = (file: File | undefined) => {
    uploadGiftCsv(file);
  };

  const handleChangeKeyword = (ev: React.ChangeEvent<HTMLInputElement>): void => {
    setInputWord(ev.target.value);
  };

  const handleClickSearch = () => {
    dispatch(setGiftSearchKey(inputWord));
  };

  const handleChangeCheck = (ev: React.ChangeEvent<HTMLInputElement>) => {
    dispatch(setGiftNoStation(ev.target.checked ? COMMON.FLAG.ON : COMMON.FLAG.OFF));
  };

  const [checkedAll, setCheckedAll] = useState<boolean>(false); // 全件チェック

  const [page, setPage] = React.useState(1);
  const handleChange = (event: React.ChangeEvent<unknown>, value: number) => {
    setPage(value);
    setScrollTop(true); // テーブルのスクロールトップ
  };

  return (
    <ScreenContext.Provider value={ScreenContextValue}>
      <Layout>
        <Box className={classes.content}>
          <Box className={classes.topArea}>
            <Box className="returnButton">
              <ReturnButton onClick={handleClickGroupConfTop}>
                管理登録
                <br />
                TOP
              </ReturnButton>
            </Box>
            <Box className={classes.boxTextRight}>
              <Box className={classes.boxKeyword}>
                <OutlineTextBox
                  id="keyWord"
                  name="keyWord"
                  placeholder="キーワード検索"
                  labelPlacement="top"
                  value={inputWord}
                  onChange={handleChangeKeyword}
                  className={classes.keyword}
                  fullWidth
                />
                <IconButton onClick={handleClickSearch} className={classes.searchBtn}>
                  🔍
                </IconButton>
              </Box>
              <Box className={classes.boxCheckBox}>
                <FormControlLabel
                  control={
                    <Checkbox
                      onChange={handleChangeCheck}
                      checked={giftNoStation === COMMON.FLAG.ON}
                      defaultChecked={false}
                      color="default"
                    />
                  }
                  label={<span className={classes.checkBox}>機器に未登録の景品のみ表示</span>}
                  labelPlacement="end"
                  className={classes.checkBox}
                />
              </Box>
              <Box className={classes.boxTextRightContent}>
                <NormalSelectBox
                  name="sort"
                  dataSource={sortDataSource}
                  value={sortGiftList}
                  onChange={handleChangeSort}
                  label="並べ替え"
                />
              </Box>
            </Box>
          </Box>
          <Box className={classes.boxCheckBoxAll}>
            <FormControlLabel
              control={
                <ListCheckBox
                  checked={checkedAll}
                  name="selectStation"
                  onChange={(e) => {
                    handleAllCheckboxChange(e);
                  }}
                />
              }
              label={<span className={classes.checkBox}>景品全選択</span>}
              labelPlacement="end"
              className={classes.checkBox}
            />
          </Box>
          <Box className={classes.mainArea}>
            <GiftListTable
              rows={giftDatasource}
              page={page}
              handleSelectedItemChange={handleSelectedItemChange}
              checkedAll={checkedAll}
              scrollTop={scrollTop}
              setScrollTop={setScrollTop}
            />
            <Box className={classes.mainButtonArea}>
              <NormalButton
                className={classes.addNewButton}
                onClick={() => handleGotoScreen(redirectLinks.GIFT_DETAIL)}>
                ＋ 新規登録
              </NormalButton>
              {isPc && (
                <NormalButton className={classes.importCSVButton} onClick={handleShowCsvImportDialog}>
                  CSV読込登録
                </NormalButton>
              )}
            </Box>
          </Box>
          <div style={{ textAlign: 'right', margin: '0 200px 0 0' }}>
            <Pagination
              count={Math.ceil(countQueried / COMMON.GIFTS_DISPLAYED)}
              page={page}
              variant="outlined"
              shape="rounded"
              size="large"
              siblingCount={2}
              boundaryCount={1}
              onChange={handleChange}
            />
          </div>
          <Grid className={classes.decision}>
            <NormalButton className="btnDeleteRow" onClick={handleShowDeleteDialog}>
              <span className={classes.buttonSize}>チェックした景品を削除</span>
            </NormalButton>
            <Box className={classes.totalCnt}>
              {`登録景品総数 ${countTotal.toLocaleString()}件中 / ${countQueried.toLocaleString()}件該当`}
            </Box>
          </Grid>
        </Box>
        <ConfirmDialog
          open={openDeleteConfirmDlg}
          msg={messageConfirmDeleteEquipment}
          onOk={handleOkDeleteConfirmDlg}
          onCancel={() => setOpenDeleteConfirmDlg(false)}
          addClass={{
            contentBox: classes.contentBox,
            contentText: classes.contentText,
          }}
        />
        <InfoDialog
          open={openDeleteSuccessDlg}
          msg={messageDeleteEquipmentSuccess}
          closeFunc={() => setOpenDeleteSuccessDlg(false)}
        />
        <InfoDialog
          open={openUploadCsvSuccess}
          msg={messageUploadCsvSuccess}
          closeFunc={() => setOpenUploadCsvSuccess(false)}
        />
        <InfoDialog open={openImportErrorDlg} msg={messageImportError} closeFunc={() => setOpenImportErrorDlg(false)} />
        <CsvUploadDialog
          open={openCsvDialog}
          onOk={(file) => handleUploadCsvSuccess(file)}
          onCancel={() => setOpenCsvDialog(false)}
        />
        <InfoDialog
          open={openDeleteInfoDlg}
          msg={messageInfoDeleteEquipment}
          closeFunc={() => setOpenDeleteInfoDlg(false)}
        />
      </Layout>
    </ScreenContext.Provider>
  );
};

export default GiftList;
