import COMMON from 'constants/common';
import store from 'redux/store';
import { ClassificationType, DeviceInfoType } from 'types/common/commonType';
import CLSFCN from '../constants/classification';

/**
 * 権限チェック
 * @author atsushi.teruya
 *
 * @param category  category of classification
 * @param value  value of classification
 * @returns boolean true => collct , false => not collect
 */
export const checkScreenAuth = (screenId: string): boolean => {
  const { userInfo } = store.getState().common;
  // 存在チェック用にsetを作成
  const enableScreenIdSet = new Set(userInfo?.enableScreenIdList);

  return enableScreenIdSet.has(screenId);
};

/**
 * カテゴリ別区分名取得
 * @author atsushi.teruya
 *
 * @param category  category of classification
 * @param value  value of classification
 * @returns string
 */
export const getClassificationName = (category: string, value: string): string => {
  let name = '';
  const { classificationList } = store.getState().common;
  if (classificationList != null && classificationList[category] !== null) {
    name = classificationList[category][value]?.name ?? '';
  }
  return name;
};

/**
 * カテゴリ別区分情報リスト取得
 * @author atsushi.teruya
 *
 * @param category  category of classification
 * @returns Array<{category: string | null; key1: string | null; key2: string | null; value: string; name: string}>
 */
export const getClsfcnByCategory = (category: string): Array<ClassificationType> => {
  let classification: Array<ClassificationType> = [];
  const { classificationList } = store.getState().common;

  if (classificationList && classificationList[category]) {
    const classificationData = classificationList[category];
    classification = Object.keys(classificationData).reduce<Array<ClassificationType>>((_currentArray, key) => {
      _currentArray.push({
        category,
        key1: null,
        key2: null,
        value: key,
        name: classificationData[key].name,
      });

      return _currentArray;
    }, []);
  }

  return classification;
};

/**
 * カテゴリ別区分情報リスト取得（区分マスタ.並び順でソート）
 * @author atsushi.teruya
 *
 * @param category  category of classification
 * @returns Array<{category: string | null; key1: string | null; key2: string | null; value: string; name: string sort: number}>
 */
export const getSortedClsfcnByCategory = (category: string): Array<ClassificationType> => {
  let classification: Array<ClassificationType> = [];
  const { classificationList } = store.getState().common;

  if (classificationList && classificationList[category]) {
    const classificationData = classificationList[category];
    classification = Object.keys(classificationData).reduce<Array<ClassificationType>>((_currentArray, key) => {
      _currentArray.push({
        category,
        key1: null,
        key2: null,
        value: key,
        name: classificationData[key].name,
        sort: classificationData[key].sort,
      });

      return _currentArray;
    }, []);
  }

  // sort順にソート
  classification.sort((a, b) => {
    const sortA = a.sort ? a.sort : 0;
    const sortB = b.sort ? b.sort : 0;
    if (sortA > sortB) {
      return 1;
    }
    return -1;
  });

  return classification;
};

export const handleScrollToId = (id: string, offsetTop = 56): void => {
  const element = document.getElementById(id);
  if (element) {
    window.scroll(0, element.offsetTop - offsetTop);
  }
};

export const handleScrollToTop = (): void => {
  let position = window.innerHeight;
  const moveValue = 10;
  const interval = 5;
  const scroll = () => {
    position = position - moveValue < 0 ? 0 : position - moveValue;
    window.scrollTo(0, position);
    const id = setTimeout(scroll, interval);
    if (position === 0) clearTimeout(id);
  };
  scroll();
};

/**
 * 0/1フラグをtrue/falseに変換(主にトグル用)
 * @param string flg('0'または'1')
 */
export const convertToBool4Flg = (flg: string): boolean => {
  if (flg === COMMON.SWITCH.ON) return true;
  if (flg === COMMON.SWITCH.OFF) return false;
  return false;
};

/**
 * true/falseフラグを0/1に変換(主にトグル用)
 * @param boolean flg(true/false)
 */
export const convertToString4Flg = (flg: boolean): string => {
  if (flg) return COMMON.SWITCH.ON;
  if (!flg) return COMMON.SWITCH.OFF;
  return COMMON.SWITCH.OFF;
};

/**
 * 0/1フラグをtrue/falseに変換
 * @param string flg('0'または'1')
 */
export const convertToBool4ValidFlg = (flg: string): boolean => {
  if (flg === COMMON.VALID_FLG.VALID) return true;
  if (flg === COMMON.VALID_FLG.INVALID) return false;
  return false;
};

/**
 * true/falseフラグを0/1に変換
 * @param boolean flg(true/false)
 */
export const convertToString4ValidFlg = (flg: boolean): string => {
  if (flg) return COMMON.VALID_FLG.VALID;
  if (!flg) return COMMON.VALID_FLG.INVALID;
  return COMMON.VALID_FLG.INVALID;
};

/**
 * userAgentから当システムで使用する情報を取得
 * ※ ログイン画面でのみ利用すること
 * @param boolean flg(true/false)
 */
export const getDeviceInfo = (): DeviceInfoType => {
  // userAgentから取得する項目を追加する場合は、DeviceInfoTypeに項目を追加して当Functionに実装する
  // androidがtabとspを精緻に判別するのが難しいので、pcまたはmobile(tab/sp)で判別
  const deviceInfo: DeviceInfoType = { isMobile: false, isPc: true, deviceType: 'pc' };

  if (
    /Android|webOS|iPhone|iPad|iPod|BlackBerry|Mobile|IEMobile|Opera Mini|Windows Phone|Mobi/i.test(navigator.userAgent)
  ) {
    deviceInfo.isMobile = true;
    deviceInfo.isPc = false;
    deviceInfo.deviceType = 'mobile';
  } else {
    deviceInfo.isMobile = false;
    deviceInfo.isPc = true;
    deviceInfo.deviceType = 'pc';
  }
  return deviceInfo;
};

/**
 * function use for get file name from path
 * @param path
 */
export const basename = (path: string): string | undefined => {
  if (undefined === path) return 'default.csv';
  return path.split('/').pop();
};

/**
 * 機器バージョンを取得
 * @param {string|null} machineVersion 機器バージョン
 * @param {boolean} convertNull nullの変換有無
 */
export const getMachineVersion = (machineVersion: string | null, convertNull = false): string => {
  if (machineVersion !== null) return machineVersion;
  // ブランク表示しない場合は、「Ver.D」として扱う
  if (convertNull) return 'D';
  // ブランク表示する場合は、「Ver.-」として扱う
  return '-';
};

/**
 * 機器バージョンをアルファベット順の数値に変換
 * @param {string|null} machineVersion 機器バージョン
 * @returns {number|null}
 */
export const convertMachineVersionToNumber = (machineVersion: string | null): number | null => {
  // 1文字以外または、A-Z以外の場合はnullを返す（machineVersionがnullの場合は許容）
  if (machineVersion !== null && (machineVersion.length !== 1 || !/^[A-Z]/.test(machineVersion))) {
    return null;
  }

  let targetChar = '';
  if (machineVersion === null) {
    // machineVersionがnullの場合は、Ver.Dとして扱う
    targetChar = 'D';
  } else {
    targetChar = machineVersion;
  }
  // The ASCII code for 'A' is 65, but we want to convert 'A' to 1, so we subtract 1.
  const charCodeBase = 'A'.charCodeAt(0) - 1;
  return targetChar.charCodeAt(0) - charCodeBase;
};

export const renderMachineType = (machineType: string | null): string => {
  let machineTypeName = COMMON.MACHINE_NAME.CLENA3;
  switch (machineType) {
    case COMMON.MACHINE_TYPE.GRAND:
      machineTypeName = COMMON.MACHINE_NAME.GRAND;
      break;
    case COMMON.MACHINE_TYPE.JACK:
      machineTypeName = COMMON.MACHINE_NAME.JACK;
      break;
    default:
      break;
  }
  return machineTypeName;
};

/**
 * 機器の位置を含めた機種名を出力
 * @param {string|null} machineType 機種種別
 * @param {string} leftRight 左右
 * @returns {string}
 */
export const renderMachineTypeWithSuffix = (machineType: string | null, leftRight: string): string => {
  let machineTypeName = '';
  switch (machineType) {
    case COMMON.MACHINE_TYPE.CLENA3:
      machineTypeName = `${COMMON.MACHINE_NAME.CLENA3}${leftRight === COMMON.LEFT_RIGHT.LEFT ? 'L' : 'R'}`;
      break;
    case COMMON.MACHINE_TYPE.JACK:
      machineTypeName = COMMON.MACHINE_NAME.JACK;
      break;
    case COMMON.MACHINE_TYPE.GRAND:
      machineTypeName = COMMON.MACHINE_NAME.GRAND;
      break;
    default:
      break;
  }
  return machineTypeName;
};

/**
 * Get station name
 * @param machineType
 * @param stationName
 * @param leftRight
 */
export const getStationName = (machineType: string | null, stationName: string, leftRight: string): string => {
  if (machineType === COMMON.MACHINE_TYPE.CLENA3) {
    return leftRight === COMMON.LEFT_RIGHT.LEFT
      ? `L : ${stationName === null ? '' : stationName}`
      : `R : ${stationName === null ? '' : stationName}`;
  }
  return stationName === null ? '' : stationName;
};

/**
 *
 * @param getSupport
 * @param supportConf
 */
export const getSupportConf = (getSupport: string | null, supportConf: string | null): string => {
  if (getSupport === CLSFCN.ON_OFF.OFF && supportConf === CLSFCN.GET_SUPPORT_CONF.OFF_1)
    return CLSFCN.GET_SUPPORT_CONF.OFF_1;
  if (getSupport === CLSFCN.ON_OFF.OFF && supportConf === CLSFCN.GET_SUPPORT_CONF.OFF_2)
    return CLSFCN.GET_SUPPORT_CONF.OFF_2;
  if (getSupport === CLSFCN.ON_OFF.ON && supportConf === CLSFCN.GET_SUPPORT_CONF.OFF_1)
    return CLSFCN.GET_SUPPORT_CONF.ON_1;
  if (getSupport === CLSFCN.ON_OFF.ON && supportConf === CLSFCN.GET_SUPPORT_CONF.OFF_2)
    return CLSFCN.GET_SUPPORT_CONF.ON_2;
  return '';
};
