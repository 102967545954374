import React from 'react';
import { Box, makeStyles, Theme } from '@material-ui/core';
import SelectedStationInfo from 'components/machineConf/organisms/labels/SelectedStationInfo';
import { SaveDetailInfoType } from 'types/machineConf/stationSelectType';
import COMMON from 'constants/common';
import SaveDetailInfo from '../labels/SaveDetailInfo';

type StyleProps = {
  isLeft: boolean;
  machineType: string | null;
};

type MachineConfContentSettingDetailProps = {
  isLeft: boolean;
  isStationListScreen: boolean;
  isSaveSetting?: boolean;
  stationName: string;
  giftName: string;
  machineType: string | null;
  machineVersion: string | null;
  savedConfProps: SaveDetailInfoType;
};

const useStyles = makeStyles<Theme, StyleProps>((theme) => ({
  contents: {
    marginLeft: '104px',
    backgroundColor: theme.palette.common.black,
    padding: '0px 12px 12px 12px',
    height: '680px',
  },
  detail: {
    height: '668px',
    borderWidth: '8px',
    borderRadius: '8px',
    borderStyle: 'solid',
    width: '1144px',
    padding: '16px 0px 0px 0px',
  },
  contentStyle: {
    backgroundColor: ({ isLeft, machineType }) => {
      if (machineType === COMMON.MACHINE_TYPE.GRAND) {
        return theme.grandStation.contents;
      }
      return isLeft ? theme.leftStation.contents : theme.rightStation.contents;
    },
    borderColor: ({ isLeft, machineType }) => {
      if (machineType === COMMON.MACHINE_TYPE.GRAND) {
        return theme.grandStation.contentsBorder;
      }
      return isLeft ? theme.leftStation.contentsBorder : theme.rightStation.contentsBorder;
    },
  },
  selectStation: {
    height: '40px',
    margin: '0px 24px 0px 0px',
  },
}));

const MachineConfContentSettingDetail: React.FC<MachineConfContentSettingDetailProps> = (props) => {
  const { isLeft, stationName, giftName, children, isSaveSetting, machineType, machineVersion, savedConfProps } = props;
  const classes = useStyles({ isLeft, machineType });
  const headerTitle = isSaveSetting ? (
    <SaveDetailInfo
      saveName={savedConfProps.saveName}
      saveMachineVersion={savedConfProps.saveMachineVersion}
      saveMachineType={savedConfProps.saveMachineType}
    />
  ) : (
    <SelectedStationInfo
      stationName={stationName}
      giftName={giftName}
      leftRight={isLeft ? COMMON.LEFT_RIGHT.LEFT : COMMON.LEFT_RIGHT.RIGHT}
      boardStation=""
      machineType={machineType}
      machineVersion={machineVersion}
    />
  );
  return (
    <>
      <Box className={classes.contents}>
        <Box className={`${classes.detail} ${classes.contentStyle}`}>
          <Box className={classes.selectStation}>{headerTitle}</Box>
          {children}
        </Box>
      </Box>
    </>
  );
};

export default MachineConfContentSettingDetail;
