import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import { REST_API } from 'constants/apiUrls';
import CLSFCN from 'constants/classification';
import COMMON from 'constants/common';
import {
  MachineConfState,
  MachineConfType,
  ConfListType,
  OperationConfType,
  AreaConfType,
  XySpeedLeverConfType,
  PitConfType,
  LedServiceOtherConfType,
  MachineConfGrandType,
  ShowAreaColorType,
} from 'types/machineConf/machineConfType';
import {
  SelectedStationType,
  StationDetailType,
  StationListType,
  StationSerialType,
} from 'types/machineConf/stationSelectType';
import HttpConnection from 'utils/httpConnection';
import { handleLeftRightInversion, initializeEcoType } from 'utils/machineConf.helper';

/* ************ 機器設定用Redux定義 ************ */
/* ************ State ************ */
// Reduxで管理するグローバルステートの設定
const initialState: MachineConfState = {
  stationSelect: {
    stationList: [], // SCR311/ステーション選択画面でDBから取得したステーション
    selectedBaseConfRowIdx: -1,
  },
  selectedStation: {
    boardStation: '',
    machineType: null,
    machineVersion: null,
  },
  machineConf: {},
  machineGrandConf: {},
  selectedStationList: [],
  savedConfList: [],
  machineTypeFilter: '',
  stationListSort: CLSFCN.DATA_STATION_SORT.STATION_NAME_ASC,
  showAreaColor: {
    area0: true,
    area1: true,
    area2: true,
    area3: true,
    area4: true,
    area5: true,
  },
};

/* ******* Event ****** */
// ステーション一覧取得
export const getStationListThunk = createAsyncThunk(
  'machineConf/getStationsList',
  async (
    payload: { headquartersId?: string | null; storeId?: string | null; machineType: string; sort: string },
    thunk,
  ) => {
    const http = new HttpConnection(thunk);
    return http.get<Array<StationListType>>(REST_API.MACHINE_CONF.GET_STATION_LIST, payload);
  },
);

// 設定キャンセル
export const cancelMachineConfThunk = createAsyncThunk(
  'machineConf/confReceiveCancel',
  async (payload: { stationId: number; headquartersId?: string | null; storeId?: string | null }, thunk) => {
    const http = new HttpConnection(thunk);
    await http.get(REST_API.MACHINE_CONF.CONF_RECEIVE_CANCEL, payload);
  },
);

// 選択ステーション設定情報取得
export const getSelectStationConfDataThunk = createAsyncThunk(
  'machineConf/getSelectStationConfData',
  async (payload: { selectConfId: string; execDiv: string; leftRight: string; boardStation?: string }, thunk) => {
    const http = new HttpConnection(thunk);
    const data = await http.get<MachineConfType>(REST_API.MACHINE_CONF.GET_CONF_DATA, payload);
    return {
      data,
      execDiv: payload.execDiv,
    };
  },
);

// 保存設定一覧取得
export const getConfListThunk = createAsyncThunk(
  'machineConf/getConfList',
  async (payload: { machineType: string | null }, thunk) => {
    const http = new HttpConnection(thunk);
    const data = await http.get<Array<ConfListType>>(REST_API.MACHINE_CONF.GET_CONF_LIST, payload);
    return data;
  },
);

// 設定保存
export const saveConfThunk = createAsyncThunk(
  'machineConf/postConfSave',
  async (
    payload: {
      saveConfName: string;
      machineConf: MachineConfType;
      leftRight: string | undefined;
      boardStation: string;
      machineType: string | null;
      machineVersion: string | null;
    },
    thunk,
  ) => {
    const http = new HttpConnection(thunk);
    const result = await http.postSecond(REST_API.MACHINE_CONF.SAVE_CONF, payload);
    return result;
  },
);

// 設定送信（パブリッシュ）
export const sendConfThunk = createAsyncThunk(
  'machineConf/postConf',
  async (
    payload: {
      force: number;
      machineConf: MachineConfType;
      serialList: StationSerialType;
      leftRight: string | undefined;
      headStoreId: string;
    },
    thunk,
  ) => {
    const http = new HttpConnection(thunk);
    await http.post(REST_API.MACHINE_CONF.SEND_CONF, payload);
  },
);

function replaceAt(str: string, index: number, ch: string) {
  if (!str) return str;
  return str.replace(/./g, (c, i) => (i === index ? ch : c));
}

function transform(payload: MachineConfType) {
  const { pitConf, areaConf, ledServiceOtherConf, operationConf } = payload;
  if (!pitConf || !areaConf || !ledServiceOtherConf || !operationConf) return payload;
  const replaceIndex = 0;
  const replacePitConfValue = '1';
  const replaceAreaConfValue = '0';
  const defaultLedServiceOtherConf = '0';

  return {
    ...payload,
    pitConf: {
      ...pitConf,
      line1: replaceAt(pitConf.line1, replaceIndex, replacePitConfValue),
      line2: replaceAt(pitConf.line2, replaceIndex, replacePitConfValue),
      line3: replaceAt(pitConf.line3, replaceIndex, replacePitConfValue),
      line4: replaceAt(pitConf.line4, replaceIndex, replacePitConfValue),
      line5: replaceAt(pitConf.line5, replaceIndex, replacePitConfValue),
      line6: replaceAt(pitConf.line6, replaceIndex, replacePitConfValue),
    },
    areaConf: {
      ...areaConf,
      line1: replaceAt(areaConf.line1, replaceIndex, replaceAreaConfValue),
      line2: replaceAt(areaConf.line2, replaceIndex, replaceAreaConfValue),
      line3: replaceAt(areaConf.line3, replaceIndex, replaceAreaConfValue),
      line4: replaceAt(areaConf.line4, replaceIndex, replaceAreaConfValue),
      line5: replaceAt(areaConf.line5, replaceIndex, replaceAreaConfValue),
      line6: replaceAt(areaConf.line6, replaceIndex, replaceAreaConfValue),
    },
    ledServiceOtherConf: {
      ...ledServiceOtherConf,
      lLedColor4: defaultLedServiceOtherConf,
      lLedEffect4: defaultLedServiceOtherConf,
      lLedColor2: defaultLedServiceOtherConf,
      lLedEffect2: defaultLedServiceOtherConf,
      rLedColor4: defaultLedServiceOtherConf,
      rLedEffect4: defaultLedServiceOtherConf,
      rLedColor3: defaultLedServiceOtherConf,
      rLedEffect3: defaultLedServiceOtherConf,
      rLedColor2: defaultLedServiceOtherConf,
      rLedEffect2: defaultLedServiceOtherConf,
      rLedColor1: defaultLedServiceOtherConf,
      rLedEffect1: defaultLedServiceOtherConf,
    },
  };
}

const formatStationList = (stationListData: StationListType[]): StationDetailType[] => {
  const stationList: StationDetailType[] = [];
  stationListData.forEach((station) => {
    const leftStation = {
      stationId: station.leftStationId,
      stationName: station.leftStationName,
      leftRight: COMMON.LEFT_RIGHT.LEFT,
      boardStation: station.leftBoardStation,
      boardSerial: station.boardSerial,
      machineType: station.machineType,
      machineVersion: station.leftMachineVersion,
      giftName: station.leftGiftName,
      confId: station.leftConfId,
      confReceive: station.leftConfReceive,
      versionNo: station.leftVersionNo,
      giftId: null,
      selectStation: undefined,
    };
    stationList.push(leftStation);
    if (station.machineType === COMMON.MACHINE_TYPE.CLENA3) {
      const rightStation = {
        stationId: station.rightStationId,
        stationName: station.rightStationName,
        leftRight: COMMON.LEFT_RIGHT.RIGHT,
        boardStation: station.rightBoardStation,
        boardSerial: station.boardSerial,
        machineType: station.machineType,
        machineVersion: station.rightMachineVersion,
        giftName: station.rightGiftName,
        confId: station.rightConfId,
        confReceive: station.rightConfReceive,
        versionNo: station.rightVersionNo,
        giftId: null,
        selectStation: undefined,
      };
      stationList.push(rightStation);
    }
  });
  return stationList;
};

const slice = createSlice({
  name: 'machineConf',
  initialState,
  reducers: {
    setMachineStatusBaseConf(state, action: PayloadAction<{ index: number; value: number }>) {
      const { stationSelect } = state;
      // 選択中のラジオボタンを解除
      stationSelect.stationList[stationSelect.selectedBaseConfRowIdx].baseConfSelect = 0;
      // 選択したラジオボタンを選択中に変更
      stationSelect.stationList[action.payload.index].baseConfSelect = action.payload.value;
      // 選択中のラジオボタンを指定
      stationSelect.selectedBaseConfRowIdx = action.payload.index;
    },
    setMachineStatusSelectStation(state, action: PayloadAction<{ index: number; value: number }>) {
      const { stationSelect } = state;
      const { index } = action.payload;
      // 選択状態を反転
      const rowData = stationSelect.stationList[index];
      // undefinedを考慮して判定する。
      if (rowData.selectStation) {
        rowData.selectStation = false;
      } else {
        rowData.selectStation = true;
      }
      // 基準設定の指定がない場合(steteの基準設定のindexが初期値と同じ)は今回選択したステーションを基準設定に指定する。
      if (stationSelect.selectedBaseConfRowIdx === initialState.stationSelect.selectedBaseConfRowIdx) {
        // 選択したステーションの基準設定を選択中に変更。(基準設定に自身のステーションIDを設定)
        rowData.baseConfSelect = rowData.stationId;
        stationSelect.selectedBaseConfRowIdx = index;
      } else if (stationSelect.selectedBaseConfRowIdx === index) {
        // 今回選択したステーションが基準設定に指定されていた場合(選択解除)
        // 表示中の何れかのステーションを選択中にする。

        // 選択中のラジオボタンを解除
        rowData.baseConfSelect = -1;
        stationSelect.selectedBaseConfRowIdx = initialState.stationSelect.selectedBaseConfRowIdx;

        // trueを返すまで配列を処理する。
        stationSelect.stationList.some((value, roopIndex) => {
          const station = value;
          if (station.selectStation) {
            // 表示中のラジオボタンを選択中に変更
            station.baseConfSelect = station.stationId;
            stationSelect.selectedBaseConfRowIdx = roopIndex;
            return true;
          }
          return false;
        });
      }
    },
    setSelectedStationInfo(state, action: PayloadAction<SelectedStationType>) {
      // 基準設定に選択したステーション名と景品名をステートへ保存
      state.selectedStation = action.payload;
    },
    setLeverOperation(state, action: PayloadAction<{ leverTime: string; enterButton: string }>) {
      if (state.machineConf.xySpeedLeverConf) {
        const { xySpeedLeverConf } = state.machineConf;
        xySpeedLeverConf.leverTime = action.payload.leverTime;
        xySpeedLeverConf.enterButton = action.payload.enterButton;
      }
    },
    setXySpeedLeverConf(state, action: PayloadAction<XySpeedLeverConfType | undefined>) {
      state.machineConf.xySpeedLeverConf = action.payload;
    },
    setOperationConf(state, action: PayloadAction<OperationConfType | undefined>) {
      state.machineConf.operationConf = action.payload;
    },
    setMachineTypeFilter(state, action: PayloadAction<string>) {
      state.machineTypeFilter = action.payload;
    },
    setStationListSort(state, action: PayloadAction<string>) {
      state.stationListSort = action.payload;
    },
    setOperationConfModeOff(state, action: PayloadAction<string>) {
      // ステートへ保存
      if (state.machineConf.operationConf) {
        const { operationConf } = state.machineConf;
        operationConf.operationConf = action.payload;
      }
    },
    setMachineConf(state, action: PayloadAction<MachineConfType>) {
      state.machineConf = action.payload;
    },
    setAreaConf(state, action: PayloadAction<AreaConfType | undefined>) {
      state.machineConf.areaConf = action.payload;
    },
    setPitConf(state, action: PayloadAction<PitConfType | undefined>) {
      state.machineConf.pitConf = action.payload;
    },
    setLedServiceOtherConf(state, action: PayloadAction<LedServiceOtherConfType | undefined>) {
      state.machineConf.ledServiceOtherConf = action.payload;
    },
    setPitConfleftRightInversion(
      state,
      action: PayloadAction<{
        line1: string;
        line2: string;
        line3: string;
        line4: string;
        line5: string;
        line6: string;
      }>,
    ) {
      // ステートへ保存
      if (state.machineConf.pitConf) {
        const { pitConf } = state.machineConf;
        pitConf.line1 = action.payload.line1;
        pitConf.line2 = action.payload.line2;
        pitConf.line3 = action.payload.line3;
        pitConf.line4 = action.payload.line4;
        pitConf.line5 = action.payload.line5;
        pitConf.line6 = action.payload.line6;
      }
    },
    setAreaConfleftRightInversion(
      state,
      action: PayloadAction<{
        line1: string;
        line2: string;
        line3: string;
        line4: string;
        line5: string;
        line6: string;
        lPower1: number;
        rPower1: number;
        lPower2: number;
        rPower2: number;
        lPower3: number;
        rPower3: number;
        lPower4: number;
        rPower4: number;
        lPower5: number;
        rPower5: number;
        lPower6: number;
        rPower6: number;
      }>,
    ) {
      // ステートへ保存
      if (state.machineConf.areaConf) {
        const { areaConf } = state.machineConf;
        areaConf.line1 = action.payload.line1;
        areaConf.line2 = action.payload.line2;
        areaConf.line3 = action.payload.line3;
        areaConf.line4 = action.payload.line4;
        areaConf.line5 = action.payload.line5;
        areaConf.line6 = action.payload.line6;
      }
    },
    setOperationConfleftRightInversion(
      state,
      action: PayloadAction<{
        grabLPower: number;
        grabRPower: number;
        movingLPower: number;
        movingRPower: number;
      }>,
    ) {
      // ステートへ保存
      if (state.machineConf.operationConf) {
        const { operationConf } = state.machineConf;
        operationConf.grabLPower = action.payload.grabLPower;
        operationConf.grabRPower = action.payload.grabLPower;
        operationConf.movingLPower = action.payload.movingLPower;
        operationConf.movingRPower = action.payload.movingRPower;
      }
    },
    setSelectedStationList(state, action: PayloadAction<Array<StationDetailType>>) {
      state.selectedStationList = action.payload;
    },
    setMachineGrandConf(state, action: PayloadAction<MachineConfGrandType>) {
      state.machineGrandConf = action.payload;
    },
    setAutoSelectedStationList(state) {
      const { stationSelect } = state;
      const selectedList = stationSelect.stationList.filter((value) => value.selectStation);
      state.selectedStationList = selectedList;
    },
    setShowAreaColor(state, action: PayloadAction<ShowAreaColorType>) {
      state.showAreaColor = action.payload;
    },
    setDataFromGetStationListThunk(state, action) {
      const dummyStId = -1;
      const newStationList = formatStationList(action.payload);
      const { selectedStationList } = state;
      const selectedStationIds = selectedStationList.map((st) => st.stationId); // 選択中のステーション
      let baseConfStationId =
        selectedStationList.find((st) => st.stationId === st.baseConfSelect)?.stationId ?? dummyStId;
      let newSelectedBaseConfRowIdx = initialState.stationSelect.selectedBaseConfRowIdx;

      // 受取待機中のステーションを除く選択済みのステーション
      const selectedStation = selectedStationList.filter(
        (st) =>
          newStationList.find((newSt) => newSt.stationId === st.stationId)?.confReceive !== COMMON.CONF_RECEIVE.WAITING,
      );

      // 基準の設定が受取待機中である場合、selectedStationの中のステーションを再設定
      if (
        newStationList.find((newSt) => newSt.stationId === baseConfStationId)?.confReceive ===
        COMMON.CONF_RECEIVE.WAITING
      ) {
        baseConfStationId = selectedStation[0]?.stationId ?? dummyStId;
      }

      // ステーション一覧を加工
      const stationList = newStationList.map((st, i) => {
        const selected = selectedStationIds.includes(st.stationId);
        if (!selected) return st; // 未選択のステーション
        const isBaseConfSt = st.stationId === baseConfStationId;

        const newSt = { ...st, selectStation: true };

        // 受取待機中の場合
        if (st.confReceive === COMMON.CONF_RECEIVE.WAITING) {
          if (isBaseConfSt) {
            // 基準の設定は、新規で再設定
            newSelectedBaseConfRowIdx =
              newStationList.findIndex((s) => s.stationId === baseConfStationId) ??
              initialState.stationSelect.selectedBaseConfRowIdx;
          }
          delete newSt.baseConfSelect;
          newSt.selectStation = false;
          return newSt;
        }

        // 受取待機中以外

        if (isBaseConfSt) {
          // 基準の設定は受取待機中以外は、既存の状態で再設定
          newSelectedBaseConfRowIdx = i;
          newSt.baseConfSelect = baseConfStationId;
          return newSt;
        }
        return newSt;
      });

      // ステーション一覧を更新
      state.stationSelect.stationList = stationList;
      // 基準の設定のindex
      state.stationSelect.selectedBaseConfRowIdx = newSelectedBaseConfRowIdx;
      // 選択中のステーションを更新
      state.selectedStationList = selectedStation;
      // 基準の設定に指定したステーション情報を初期化
      state.selectedStation = initialState.selectedStation;
    },
    initializeMachineConfSlice: () => initialState,
    initializeMachineConf: (state) => {
      state.machineConf = initialState.machineConf;
    },
    initializeStationSelect: (state) => {
      state.stationSelect = initialState.stationSelect;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getStationListThunk.fulfilled, (state, action) => {
      slice.caseReducers.setDataFromGetStationListThunk(state, action);
    });
    builder.addCase(getSelectStationConfDataThunk.fulfilled, (state, action) => {
      const prevStationId = state.machineConf.conf?.stationId;
      let newPayload = action.payload.data;
      // 保存設定反映時
      if (prevStationId && action.payload.execDiv === COMMON.EXEC_DIV.SAVED_CONF) {
        // 既にグローバルステートの中にstationIdがある場合は、stationIdを上書きしない
        if (newPayload.conf) {
          newPayload.conf.stationId = prevStationId;
        }
        // 設定中ステーションの左右と反映する設定の左右が異なる場合、一部項目の左右反転を行う
        if (state.selectedStation.leftRight !== newPayload.conf?.leftRight) {
          newPayload = handleLeftRightInversion(newPayload);
        }
        // 設定中ステーションの機種種別がクレナ3ジャック
        // または反映する設定がクレナ3ジャックの設定の場合
        if (
          state.selectedStation.machineType === COMMON.MACHINE_TYPE.JACK ||
          newPayload.conf?.machineType === COMMON.MACHINE_TYPE.JACK
        ) {
          // ecoTypeがnullの場合、ecoModeの値に応じて値を設定する
          if (newPayload.ledServiceOtherConf) {
            newPayload.ledServiceOtherConf.ecoType = initializeEcoType(newPayload.ledServiceOtherConf);
          }
          // クレナ3ジャックで無視する部分の設定値を補完する
          // ※エリア最奥行の設定、照明設定で設定しない箇所
          state.machineConf = transform(newPayload);
        } else {
          state.machineConf = newPayload;
        }
      } else {
        state.machineConf = newPayload;
      }
    });
    builder.addCase(getConfListThunk.fulfilled, (state, action) => {
      state.savedConfList = action.payload;
    });
  },
});

export const {
  setMachineStatusBaseConf,
  setMachineStatusSelectStation,
  setSelectedStationInfo,
  setLeverOperation,
  setOperationConf,
  setOperationConfModeOff,
  setXySpeedLeverConf,
  setAreaConf,
  setPitConf,
  setLedServiceOtherConf,
  setPitConfleftRightInversion,
  setAreaConfleftRightInversion,
  setOperationConfleftRightInversion,
  setMachineConf,
  setSelectedStationList,
  setAutoSelectedStationList,
  setMachineTypeFilter,
  setStationListSort,
  setDataFromGetStationListThunk,
  setMachineGrandConf,
  setShowAreaColor,
  initializeMachineConfSlice,
  initializeMachineConf,
  initializeStationSelect,
} = slice.actions;
export default slice.reducer;
