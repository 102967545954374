import React, { memo } from 'react';
import { Box, Grid, Theme } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useHistory } from 'react-router-dom';

import PointerIcon from 'components/machineConf/atoms/icons/PointerIcon';
import COMMON from 'constants/common';
import CLSFCN from 'constants/classification';
import NormalButton from 'components/system/atoms/buttons/NormalButton';
import redirectLinks from 'constants/redirectLinks';

import { AreaConfGrandType, PitConfGrandType, XySpeedLeverConfType } from 'types/machineConf/machineConfType';
import { SaveDetailInfoType } from 'types/machineConf/stationSelectType';

import AreaDetailGrand from '../Area/AreaDetailGrand';

const MAX_CONTAINER = 210;
const INNER_CONTAINER = 204;
const PERCENT_RATIO_INNER = 2.04;
const PERCENT_RATIO_CONTAINER = 2.03;
const MAX_WITH_COORDINATE = 30;
const WIDTH_BOX = 30;
const MAX_WIDTH_ICON = 28;
const DASH_LINE_WIDTH = 4;
const MARGIN_VALUE = 8;
const MIN_WIDTH_LINE = 25;
const X_Y_LIMIT_H = 5;
const WIDTH_BORDER_BOX = 2;
const WIDTH_BORDER_WRAP = 2;
const MIN_LIMIT_X = 22;
const MAX_SIZE = 100;
const MAX_Y = 87;

type XyConfInfoProps = {
  title: string;
  isLeft: boolean;
  xySpeedLeverConf: XySpeedLeverConfType | undefined;
  areaConf?: AreaConfGrandType;
  pitConf?: PitConfGrandType;
  showLine?: boolean;
  savedConfProps?: SaveDetailInfoType | null;
};

type XyConfInfoStyleProps = {
  isLeft: boolean;
};

const useStyles = makeStyles<Theme, XyConfInfoStyleProps>((theme) => ({
  xyContainer: {
    padding: '4px',
    position: 'relative',
    borderRadius: '4px',
    height: '282px',
    width: '446px',
    textAlign: 'center',
    border: ({ isLeft }) => `2px solid ${isLeft ? theme.grandStation.itemBorder : theme.rightStation.itemBorder}`,
    '& .customTitle': {
      width: '140px',
    },
    '& .customPanel': {
      transform: 'translateX(-50%)',
    },
    '& .customSpecifyArea': {},
  },
  xyStyle: {
    width: '100%',
    height: '100%',
  },
  title: {
    fontSize: 24,
    lineHeight: '28px',
    textAlign: 'left',
  },
  subTitle: {
    fontSize: 21,
    whiteSpace: 'pre-wrap',
  },
  frame: {
    flexDirection: 'row',
    alignItems: 'center',
    width: '100%',
    height: '100%',
    display: 'flex',
  },
  child: {
    margin: '0 0px 0px 4px',
  },
  border: {
    border: '3px solid',
    borderColor: ({ isLeft }) => (isLeft ? theme.grandStation.itemBorder : theme.rightStation.itemBorder),
  },
  specifyArea: {
    display: 'flex',
    width: `${MAX_CONTAINER}px`,
    height: `${MAX_CONTAINER}px`,
    flexDirection: 'column',
    overflow: 'hidden',
    position: 'relative',
    textAlign: 'center',
    margin: '0 0 0 36px',
    '& .inner': {
      position: 'relative',
    },
    '& .table': {
      position: 'relative',
      width: `${INNER_CONTAINER}px`,
      height: `${INNER_CONTAINER}px`,
    },
    '& .tableCols': {
      display: 'flex',
      position: 'absolute',
      width: '100%',
      height: '100%',
      top: 0,
      left: 0,
      right: 0,
      zIndex: 9,
    },
    '& .tableCol': {
      width: '100%',
      height: '100%',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-around',
      background: '#ffffff',
    },
    '& .tableRows': {
      width: '100%',
      height: '100%',
      position: 'relative',
      zIndex: 11,
    },
    '& .tableRow': {
      width: '100%',
      height: '33px',
      borderTop: 'none',
    },
    '& .tableColLeft-1, & .tableColLeft-3': {
      borderRight: '1px solid',
      borderRightColor: theme.leftStation.itemBorder,
    },
    '& .tableRowLeft-2, & .tableRowLeft-4': {
      borderTop: '1px solid',
      borderColor: theme.leftStation.itemBorder,
    },
    '& .tableColRight-1, & .tableColRight-3': {
      borderRight: '1px solid',
      borderRightColor: theme.rightStation.itemBorder,
    },
    '& .tableRowRight-2, & .tableRowRight-4': {
      borderTop: '1px solid',
      borderColor: theme.rightStation.itemBorder,
    },
    '& .line': {
      width: '33px',
      height: '33px',
    },
  },
  panel: {
    width: `${MAX_CONTAINER}px`,
    height: `${MAX_CONTAINER}px`,
    flexDirection: 'column',
    position: 'absolute',
    textAlign: 'center',
    top: '32px',
    left: '149px',
    zIndex: 20,
    border: '3px solid',
    borderColor: theme.grandStation.itemBorder,
  },
  abs: {
    position: 'absolute',
  },
  homeHr: {
    position: 'absolute',
    borderTop: `dashed ${DASH_LINE_WIDTH}px #FF3E3E`,
    width: `${INNER_CONTAINER}px`,
  },
  homeVertical: {
    position: 'absolute',
    borderLeft: `${DASH_LINE_WIDTH}px dashed #FF3E3E`,
    width: '4px',
    height: `${INNER_CONTAINER}px`,
  },
  endHr: {
    position: 'absolute',
    borderTop: 'dashed 4px #3086FF',
    width: `${INNER_CONTAINER}px`,
  },
  endVertical: {
    position: 'absolute',
    borderLeft: `dashed ${DASH_LINE_WIDTH}px #3086FF`,
    width: '2px',
    height: `${INNER_CONTAINER}px`,
  },
  homeBg: {
    background: '#FFFFFF',
    border: '1px solid #FF3E3E',
    borderRadius: '2px',
    width: `${MAX_WITH_COORDINATE}px`,
    height: `${MAX_WITH_COORDINATE}px`,
    fontSize: '19px',
  },
  endBg: {
    background: '#FFFFFF',
    border: '1px solid #3086FF',
    borderRadius: '2px',
    width: `${MAX_WITH_COORDINATE}px`,
    height: `${MAX_WITH_COORDINATE}px`,
    fontSize: '19px',
  },
  p: {
    letterSpacing: '-2px',
  },
  button: {
    width: '157px',
    height: '70px',
    background: '#EBEBEB',
    margin: '0 0 0 auto',
    fontSize: '20px',
    boxShadow: '5px 5px 3px rgba(0, 0, 0, 0.2)',
    textAlign: 'left',
    lineHeight: '24px',
    justifyContent: 'flex-start',
    padding: '6px 8px',
  },
  buttonWrap: {
    marginLeft: '12px',
  },
}));

const XyConfInfoGrand: React.FC<XyConfInfoProps> = (props) => {
  const { title, xySpeedLeverConf, isLeft, areaConf, pitConf, showLine = true, savedConfProps } = props;
  const classes = useStyles({ isLeft });
  const history = useHistory();

  const hIcon = () => {
    const item: Array<JSX.Element> = [];
    if (isLeft) {
      const iconPos = {
        transform: `translate(${
          Number(xySpeedLeverConf?.homeX) * PERCENT_RATIO_INNER - MAX_WIDTH_ICON / 2 - WIDTH_BORDER_WRAP
        }px,${INNER_CONTAINER - Number(xySpeedLeverConf?.homeY) * PERCENT_RATIO_INNER - MAX_WIDTH_ICON / 2}px`,
        left: '1px',
      };
      item.push(
        <Box className={classes.abs} style={iconPos}>
          <PointerIcon size="S" type="H" />
        </Box>,
      );
    } else {
      const iconPos = {
        transform: `translate(${
          INNER_CONTAINER -
          MAX_WIDTH_ICON / 2 +
          DASH_LINE_WIDTH / 2 -
          Number(xySpeedLeverConf?.homeX) * PERCENT_RATIO_INNER
        }px,${
          INNER_CONTAINER -
          MAX_WIDTH_ICON / 2 +
          DASH_LINE_WIDTH / 2 -
          Number(xySpeedLeverConf?.homeY) * PERCENT_RATIO_INNER
        }px`,
      };
      item.push(
        <Box className={classes.abs} style={iconPos}>
          <PointerIcon size="S" type="H" />
        </Box>,
      );
    }
    return item;
  };

  const hValue = () => {
    const item: Array<JSX.Element> = [];
    let marginLeft = -WIDTH_BOX / 2;
    let marginTop = -WIDTH_BOX / 2;
    let marginLimitX = 0;

    if (isLeft) {
      if (xySpeedLeverConf) {
        const valueOverloadX = Math.abs(xySpeedLeverConf?.homeX - xySpeedLeverConf?.endX);
        const valueOverloadY = Math.abs(xySpeedLeverConf?.homeY - xySpeedLeverConf?.endY);
        if (xySpeedLeverConf?.homeX < X_Y_LIMIT_H && xySpeedLeverConf.homeY < X_Y_LIMIT_H) {
          marginLimitX = (X_Y_LIMIT_H - xySpeedLeverConf?.homeX) * 1.8 + WIDTH_BORDER_BOX;
        }
        if (valueOverloadX <= MIN_WIDTH_LINE && marginLimitX === 0) {
          const pixelBetween = (valueOverloadX * INNER_CONTAINER) / 100 / 2;
          if (pixelBetween < (MAX_WITH_COORDINATE + 1) / 2) {
            marginLeft -= (MAX_WITH_COORDINATE + 1) / 2 - pixelBetween;
          }
        }
        if (valueOverloadY <= MIN_WIDTH_LINE) {
          const pixelBetween = (valueOverloadY * INNER_CONTAINER) / 100 / 2;
          if (pixelBetween < MAX_WITH_COORDINATE / 2) {
            marginTop += MAX_WITH_COORDINATE / 2 - pixelBetween;
          }
        }
      }
      const xValPos = {
        transform: `translate(${Number(xySpeedLeverConf?.homeX) * PERCENT_RATIO_CONTAINER}px, ${
          MAX_CONTAINER - DASH_LINE_WIDTH
        }px)`,
        left: `${marginLeft + marginLimitX}px`,
        top: '4px',
      };
      const hYMarginTop =
        Number(xySpeedLeverConf?.homeY) > MAX_Y
          ? 27
          : (MAX_SIZE - Number(xySpeedLeverConf?.homeY)) * PERCENT_RATIO_CONTAINER;
      const yValPos = {
        transform: `translate(${-MAX_WITH_COORDINATE - MARGIN_VALUE}px, ${hYMarginTop}px)`,
        top: `${marginTop}px`,
      };
      item.push(
        <Box>
          <Box className={classes.abs} style={xValPos}>
            <Box className={classes.homeBg}>
              <p className={classes.p}>{xySpeedLeverConf?.homeX}</p>
            </Box>
          </Box>
          <Box className={classes.abs} style={yValPos}>
            <Box className={classes.homeBg}>
              <p className={classes.p}>{xySpeedLeverConf?.homeY}</p>
            </Box>
          </Box>
        </Box>,
      );
    } else {
      if (xySpeedLeverConf) {
        const valueOverloadX = Math.abs(xySpeedLeverConf?.homeX - xySpeedLeverConf?.endX);
        const valueOverloadY = Math.abs(xySpeedLeverConf?.homeY - xySpeedLeverConf?.endY);
        if (xySpeedLeverConf?.homeX < X_Y_LIMIT_H && xySpeedLeverConf.homeY < X_Y_LIMIT_H) {
          marginLimitX = (X_Y_LIMIT_H - xySpeedLeverConf?.homeX) * PERCENT_RATIO_CONTAINER + WIDTH_BORDER_BOX;
        }
        if (valueOverloadX <= MIN_WIDTH_LINE && marginLimitX === 0) {
          const pixelBetween = (valueOverloadX * INNER_CONTAINER) / 100 / 2;
          if (pixelBetween < (MAX_WITH_COORDINATE + 1) / 2) {
            marginLeft += (MAX_WITH_COORDINATE + 1) / 2 - pixelBetween;
          }
        }
        if (valueOverloadY <= MIN_WIDTH_LINE) {
          const pixelBetween = (valueOverloadY * INNER_CONTAINER) / 100 / 2;
          if (pixelBetween < MAX_WITH_COORDINATE / 2) {
            marginTop += MAX_WITH_COORDINATE / 2 - pixelBetween;
          }
        }
      }
      const xValPos = {
        transform: `translate(${MAX_CONTAINER - Number(xySpeedLeverConf?.homeX) * PERCENT_RATIO_CONTAINER}px, ${
          MAX_CONTAINER - DASH_LINE_WIDTH
        }px`,
        left: `${marginLeft - marginLimitX}px`,
      };
      const yValPos = {
        transform: `translate(${MAX_CONTAINER + MARGIN_VALUE}px, ${
          MAX_CONTAINER - Number(xySpeedLeverConf?.homeY) * PERCENT_RATIO_CONTAINER
        }px`,
        top: `${marginTop}px`,
      };
      item.push(
        <Box>
          <Box className={classes.abs} style={xValPos}>
            <Box className={classes.homeBg}>
              <p className={classes.p}>{xySpeedLeverConf?.homeX}</p>
            </Box>
          </Box>
          <Box className={classes.abs} style={yValPos}>
            <Box className={classes.homeBg}>
              <p className={classes.p}>{xySpeedLeverConf?.homeY}</p>
            </Box>
          </Box>
        </Box>,
      );
    }
    return item;
  };

  const hVerLine = () => {
    const item: Array<JSX.Element> = [];
    if (isLeft) {
      const VarPos = {
        transform: `translate(${
          Number(xySpeedLeverConf?.homeX) * PERCENT_RATIO_INNER - WIDTH_BORDER_WRAP - WIDTH_BORDER_BOX / 2
        }px, 0px)`,
      };
      item.push(
        <Box className={classes.homeVertical} style={VarPos}>
          &nbsp;
        </Box>,
      );
    } else {
      const VarPos = {
        transform: `translate(${INNER_CONTAINER - Number(xySpeedLeverConf?.homeX) * PERCENT_RATIO_INNER}px,0px`,
      };
      item.push(
        <Box className={classes.homeVertical} style={VarPos}>
          &nbsp;
        </Box>,
      );
    }
    return item;
  };

  const hHrLine = () => {
    const item: Array<JSX.Element> = [];
    const hrPos = {
      transform: `translate(0px, ${
        INNER_CONTAINER - Number(xySpeedLeverConf?.homeY) * PERCENT_RATIO_INNER - WIDTH_BORDER_WRAP
      }px)`,
    };
    item.push(<div className={classes.homeHr} style={hrPos} />);
    return item;
  };

  const eIcon = () => {
    const item: Array<JSX.Element> = [];
    if (isLeft) {
      const iconPos = {
        transform: `translate(${
          Number(xySpeedLeverConf?.endX) * PERCENT_RATIO_INNER - MAX_WIDTH_ICON / 2 - WIDTH_BORDER_WRAP
        }px,${INNER_CONTAINER - Number(xySpeedLeverConf?.endY) * PERCENT_RATIO_INNER - MAX_WIDTH_ICON / 2}px`,
        left: '1px',
      };
      item.push(
        <Box className={classes.abs} style={iconPos}>
          <PointerIcon size="S" type="E" />
        </Box>,
      );
    } else {
      const iconPos = {
        transform: `translate(${
          INNER_CONTAINER -
          MAX_WIDTH_ICON / 2 +
          DASH_LINE_WIDTH / 2 -
          Number(xySpeedLeverConf?.endX) * PERCENT_RATIO_INNER
        }px,${
          INNER_CONTAINER -
          MAX_WIDTH_ICON / 2 +
          DASH_LINE_WIDTH / 2 -
          Number(xySpeedLeverConf?.endY) * PERCENT_RATIO_INNER
        }px`,
      };
      item.push(
        <Box className={classes.abs} style={iconPos}>
          <PointerIcon size="S" type="E" />
        </Box>,
      );
    }
    return item;
  };

  const eValue = () => {
    const item: Array<JSX.Element> = [];
    let marginLeft = -WIDTH_BOX / 2;
    let marginTop = -WIDTH_BOX / 2;
    let marginLimitX = 0;

    if (isLeft) {
      if (xySpeedLeverConf) {
        // Check if endY is over MAX_Y
        if (xySpeedLeverConf?.endY > MAX_Y) {
          marginTop = -4;
          const minusY = MAX_SIZE - xySpeedLeverConf?.endY;
          marginTop += minusY * -2;
        }
        const valueOverloadX = Math.abs(xySpeedLeverConf?.endX - xySpeedLeverConf?.homeX);
        const valueOverloadY = Math.abs(xySpeedLeverConf?.endY - xySpeedLeverConf?.homeY);
        if (
          xySpeedLeverConf?.homeX < X_Y_LIMIT_H &&
          xySpeedLeverConf.homeY < X_Y_LIMIT_H &&
          valueOverloadX < MIN_LIMIT_X
        ) {
          marginLimitX = (MIN_LIMIT_X - xySpeedLeverConf?.endX) * PERCENT_RATIO_CONTAINER + WIDTH_BORDER_BOX;
        }
        if (valueOverloadX <= MIN_WIDTH_LINE && marginLimitX === 0) {
          const pixelBetween = (valueOverloadX * INNER_CONTAINER) / 100 / 2;
          if (pixelBetween < MAX_WITH_COORDINATE / 2) {
            marginLeft += MAX_WITH_COORDINATE / 2 - pixelBetween;
          }
        }
        if (valueOverloadY <= MIN_WIDTH_LINE && xySpeedLeverConf?.endY <= MAX_Y) {
          const pixelBetween = (valueOverloadY * INNER_CONTAINER) / 100 / 2;
          if (pixelBetween < MAX_WITH_COORDINATE / 2) {
            marginTop -= MAX_WITH_COORDINATE / 2 - pixelBetween;
          }
        }
      }

      const xValPos = {
        transform: `translate(${Number(xySpeedLeverConf?.endX) * PERCENT_RATIO_INNER}px, ${
          MAX_CONTAINER - DASH_LINE_WIDTH
        }px)`,
        left: `${marginLeft + marginLimitX}px`,
        top: '4px',
      };
      const yValPos = {
        transform: `translate(${-MAX_WITH_COORDINATE - MARGIN_VALUE}px,  ${
          (MAX_SIZE - Number(xySpeedLeverConf?.endY)) * PERCENT_RATIO_INNER
        }px)`,
        top: `${marginTop}px`,
      };
      item.push(
        <Box>
          <Box className={classes.abs} style={xValPos}>
            <Box className={classes.endBg}>
              <p className={classes.p}>{xySpeedLeverConf?.endX}</p>
            </Box>
          </Box>
          <Box className={classes.abs} style={yValPos}>
            <Box className={classes.endBg}>
              <p className={classes.p}>{xySpeedLeverConf?.endY}</p>
            </Box>
          </Box>
        </Box>,
      );
    } else {
      if (xySpeedLeverConf) {
        const valueOverloadX = Math.abs(xySpeedLeverConf?.endX - xySpeedLeverConf?.homeX);
        const valueOverloadY = Math.abs(xySpeedLeverConf?.endY - xySpeedLeverConf?.homeY);
        if (
          xySpeedLeverConf?.homeX < X_Y_LIMIT_H &&
          xySpeedLeverConf.homeY < X_Y_LIMIT_H &&
          valueOverloadX < MIN_LIMIT_X
        ) {
          marginLimitX = (MIN_LIMIT_X - xySpeedLeverConf?.endX) * PERCENT_RATIO_CONTAINER - WIDTH_BORDER_BOX * 2;
        }
        if (valueOverloadX <= MIN_WIDTH_LINE && marginLimitX === 0) {
          const pixelBetween = (valueOverloadX * INNER_CONTAINER) / 100 / 2;
          if (pixelBetween < MAX_WITH_COORDINATE / 2) {
            marginLeft -= MAX_WITH_COORDINATE / 2 - pixelBetween;
          }
        }
        if (valueOverloadY <= MIN_WIDTH_LINE) {
          const pixelBetween = (valueOverloadY * INNER_CONTAINER) / 100 / 2;
          if (pixelBetween < MAX_WITH_COORDINATE / 2) {
            marginTop -= MAX_WITH_COORDINATE / 2 - pixelBetween;
          }
        }
      }
      const xValPos = {
        transform: `translate(${MAX_CONTAINER - Number(xySpeedLeverConf?.endX) * PERCENT_RATIO_CONTAINER}px, ${
          MAX_CONTAINER - DASH_LINE_WIDTH
        }px`,
        left: `${marginLeft - marginLimitX}px`,
      };
      const yValPos = {
        transform: `translate(${MAX_CONTAINER + MARGIN_VALUE}px,${
          MAX_CONTAINER - Number(xySpeedLeverConf?.endY) * PERCENT_RATIO_CONTAINER
        }px`,
        top: `${marginTop}px`,
      };
      item.push(
        <Box>
          <Box className={classes.abs} style={xValPos}>
            <Box className={classes.endBg}>
              <p className={classes.p}>{xySpeedLeverConf?.endX}</p>
            </Box>
          </Box>
          <Box className={classes.abs} style={yValPos}>
            <Box className={classes.endBg}>
              <p className={classes.p}>{xySpeedLeverConf?.endY}</p>
            </Box>
          </Box>
        </Box>,
      );
    }
    return item;
  };

  const eVerLine = () => {
    const item: Array<JSX.Element> = [];
    if (isLeft) {
      const isSolidBorder = xySpeedLeverConf?.endX === xySpeedLeverConf?.homeX;
      const VarPos = {
        transform: `translate(${Number(xySpeedLeverConf?.endX) * PERCENT_RATIO_INNER - WIDTH_BORDER_WRAP}px,0px)`,
        ...(isSolidBorder
          ? {
              borderLeft: 'solid 4px #3086FF',
            }
          : {}),
      };
      item.push(
        <Box className={classes.endVertical} style={VarPos}>
          &nbsp;
        </Box>,
      );
    } else {
      const VarPos = {
        transform: `translate(${INNER_CONTAINER - Number(xySpeedLeverConf?.endX) * PERCENT_RATIO_INNER}px,0px`,
      };
      item.push(
        <Box className={classes.endVertical} style={VarPos}>
          &nbsp;
        </Box>,
      );
    }
    return item;
  };

  const eHrLine = () => {
    const item: Array<JSX.Element> = [];
    const isSolidBorder = xySpeedLeverConf?.endY === xySpeedLeverConf?.homeY;
    const hrPos = {
      transform: `translate(0px, ${
        INNER_CONTAINER - Number(xySpeedLeverConf?.endY) * PERCENT_RATIO_INNER - WIDTH_BORDER_WRAP
      }px)`,
      ...(isSolidBorder
        ? {
            borderTop: 'solid 4px #3086FF',
          }
        : {}),
    };

    item.push(<div className={classes.endHr} style={hrPos} />);
    return item;
  };

  const customHpIcon = () => {
    const item: Array<JSX.Element> = [];
    if (xySpeedLeverConf?.customConf === COMMON.CUSTOM_HOME_POSITION.CUSTOM) {
      if (isLeft) {
        const iconPos = {
          transform: `translate(${
            Number(xySpeedLeverConf?.customX) * PERCENT_RATIO_INNER -
            MAX_WIDTH_ICON / 2 +
            DASH_LINE_WIDTH / 2 -
            WIDTH_BORDER_WRAP
          }px,${
            INNER_CONTAINER -
            Number(xySpeedLeverConf?.customY) * PERCENT_RATIO_INNER -
            MAX_WIDTH_ICON / 2 +
            DASH_LINE_WIDTH / 2 -
            WIDTH_BORDER_WRAP
          }px`,
        };
        item.push(
          <Box className={classes.abs} style={iconPos}>
            <PointerIcon size="S" type="HP" />
          </Box>,
        );
      } else {
        const iconPos = {
          transform: `translate(${
            INNER_CONTAINER -
            MAX_WIDTH_ICON / 2 +
            DASH_LINE_WIDTH / 2 -
            Number(xySpeedLeverConf?.customX) * PERCENT_RATIO_INNER
          }px,${
            INNER_CONTAINER -
            MAX_WIDTH_ICON / 2 +
            DASH_LINE_WIDTH / 2 -
            Number(xySpeedLeverConf?.customY) * PERCENT_RATIO_INNER
          }px`,
        };
        item.push(
          <Box className={classes.abs} style={iconPos}>
            <PointerIcon size="S" type="HP" />
          </Box>,
        );
      }
    }
    return item;
  };

  const handleTransitConfigPitScreen = () => {
    history.push({ pathname: redirectLinks.MCONF_GRAND_CONF_PIT_CONF, state: savedConfProps });
  };

  const handleTransitXyRangeConfScreen = () => {
    history.push({ pathname: redirectLinks.MCONF_GRAND_CONF_XY_RANGE_CONF, state: savedConfProps });
  };

  const handleTransitConfigAreaScreen = () => {
    history.push({ pathname: redirectLinks.MCONF_GRAND_CONF_AREA_CONF, state: savedConfProps });
  };

  return (
    <Box className={classes.xyContainer}>
      <Box className={`${classes.title}`}>
        <b>{title}</b>
      </Box>
      <Grid container spacing={1}>
        <Grid item>
          <Box className={classes.xyStyle}>
            <Box>
              <Box className={`${classes.child} customTable`}>
                <Box className={`${classes.specifyArea} customSpecifyArea`}>
                  <AreaDetailGrand
                    className="areaDetail"
                    displayCellSeparate={pitConf?.settingPattern === CLSFCN.PIT_PATTERN.DETAIL}
                    leftRight={COMMON.LEFT_RIGHT.LEFT}
                    size="CM"
                    areaConf={areaConf}
                    pitConf={pitConf}
                    isDefault={areaConf && areaConf.areaConf === COMMON.SWITCH.OFF}
                  />
                </Box>
              </Box>
              <Box className={`${classes.panel} customPanel`}>
                {showLine && eHrLine()}
                {showLine && hHrLine()}
                {showLine && eVerLine()}
                {showLine && hVerLine()}
                {eValue()}
                {hValue()}
                {hIcon()}
                {customHpIcon()}
                {eIcon()}
              </Box>
            </Box>
          </Box>
        </Grid>
        <Grid item className={classes.buttonWrap}>
          <Grid container direction="column" spacing={1}>
            <Grid item>
              <NormalButton className={classes.button} onClick={handleTransitConfigPitScreen}>
                落とし穴設定
              </NormalButton>
            </Grid>
            <Grid item>
              <NormalButton className={classes.button} onClick={handleTransitXyRangeConfScreen}>
                XY移動範囲
              </NormalButton>
            </Grid>
            <Grid item>
              <NormalButton className={classes.button} onClick={handleTransitConfigAreaScreen}>
                エリア分割設定
              </NormalButton>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
};

export default memo(XyConfInfoGrand);
