import React, { memo } from 'react';
import { makeStyles, TableCell } from '@material-ui/core';
import COMMON from 'constants/common';
import { getStationName } from 'utils/common.helper';
import { StationResponseType } from 'types/maintenance/StationType';
import ListCheckBox from '../../../../system/atoms/checkBoxs/ListCheckBox';
import TooltipComponent from '../../../../system/organisms/dialogs/TooltipComponent';

interface StationGiftTableCellType {
  row: Array<StationResponseType>;
  handleClickCheckBox: (machineNo: string, stationId: number | null) => void;
}

/* ************ Style ************ */
const useStyles = makeStyles(() => ({
  tdStation: {
    textAlign: 'left',
  },
  tdNoPadding: {
    padding: '0 !important',
  },
  tdStationContentFirst: {
    borderBottom: '1px solid #000',
  },
  tdStationContent: {
    display: 'flex',
    flexDirection: 'row',
    padding: '0 8px',
    height: 58,
  },
  stationRowName: {
    flex: 1,
    width: '484px',
    paddingRight: 8,
    fontSize: '16px',
    paddingTop: 5,
    paddingBottom: 5,
    lineHeight: '25px',
  },
}));

const StationGiftTableCell: React.FC<StationGiftTableCellType> = React.memo(({ row, handleClickCheckBox }) => {
  const classes = useStyles();
  return (
    <TableCell className={`${classes.tdStation} ${classes.tdNoPadding}`}>
      {row[0].machineType === COMMON.MACHINE_TYPE.CLENA3 ? (
        <>
          <div className={`${classes.tdStationContent} ${classes.tdStationContentFirst}`}>
            <ListCheckBox
              checked={row[0].selected === undefined ? false : row[0].selected}
              name="selectStation"
              onChange={() => handleClickCheckBox(row[0].boardSerial, row[0].stationId)}
            />
            <div className={classes.stationRowName}>
              <TooltipComponent
                tooltipText={row[0].giftName}
                displayWord={getStationName(row[0].machineType, row[0].stationName, COMMON.LEFT_RIGHT.LEFT)}
              />
              <TooltipComponent tooltipText={row[0].giftName} displayWord={`${row[0].giftName ?? COMMON.BLANK}`} />
            </div>
          </div>
          {row[1] ? (
            <div className={classes.tdStationContent}>
              <ListCheckBox
                checked={row[1].selected === undefined ? false : row[1].selected}
                name="selectStation"
                onChange={() => handleClickCheckBox(row[1].boardSerial, row[1].stationId)}
              />
              <div className={classes.stationRowName}>
                <TooltipComponent
                  tooltipText={row[1].giftName}
                  displayWord={getStationName(row[1].machineType, row[1].stationName, COMMON.LEFT_RIGHT.RIGHT)}
                />
                <TooltipComponent tooltipText={row[1].giftName} displayWord={`${row[1].giftName ?? COMMON.BLANK}`} />
              </div>
            </div>
          ) : (
            <></>
          )}
        </>
      ) : (
        <div className={classes.tdStationContent}>
          <ListCheckBox
            checked={row[0].selected === undefined ? false : row[0].selected}
            name="selectStation"
            onChange={() => handleClickCheckBox(row[0].boardSerial, row[0].stationId)}
          />
          <div className={classes.stationRowName}>
            <TooltipComponent tooltipText={row[0].giftName} displayWord={`${row[0].stationName ?? COMMON.BLANK}`} />
            <TooltipComponent tooltipText={row[0].giftName} displayWord={`${row[0].giftName ?? COMMON.BLANK}`} />
          </div>
        </div>
      )}
    </TableCell>
  );
});

export default memo(StationGiftTableCell);
