import { makeStyles, Table, TableBody, TableCell, TableContainer, TableRow } from '@material-ui/core';
import React, { memo } from 'react';
import { useAppDispatch } from 'redux/hooks';
import { setMachineList, setLedMachineList } from 'redux/slices/groupSlice';
import { MachineListType, MachineSelectStationNameType } from 'types/group/GroupType';
import COMMON from 'constants/common';
import TooltipComponent from 'components/system/organisms/dialogs/TooltipComponent';
import { getStationName, renderMachineType } from 'utils/common.helper';
import MachineStationTableHead from 'components/data/organisms/tables/common/MachineStationTableHead';

const column1 = 229;
const column2 = 198;
/* ************ Style ************ */
const useStyles = makeStyles(() => ({
  default: {
    height: '332px',
    width: 1100,
    overflowX: 'hidden',
  },
  table: {
    tableLayout: 'fixed',
    borderCollapse: 'separate', // collapseにするとスクロール時にヘッダーのborderが消えるので注意
    borderLeft: '1px solid #707070',
    '& th,td': {
      fontSize: '23px',
      lineHeight: '24px',
      padding: '0 16px',
    },
    '& th': {
      textAlign: 'center',
      height: '48px',
      padding: 0,
      backgroundColor: '#CFCFCF',
      borderTop: '1px solid #707070',
      borderBottom: '1px solid #707070',
      borderRight: '1px solid #707070',
    },
    '& tbody tr': {
      backgroundColor: '#FFFFFF',
      '&:hover': {
        cursor: 'pointer',
      },
    },
    '& td': {
      minHeight: '76px',
      borderBottom: '1px solid #707070',
      borderRight: '1px solid #707070',
      '&.cellStyle': {
        whiteSpace: 'nowrap',
        overflow: 'hidden',
      },
    },
  },
  trSelected: {
    position: 'relative',
    zIndex: 1350,
  },
  tdMachineName: {
    display: 'flex',
    gap: 12,
    alignItems: 'center',
  },
  orderNumber: {
    height: '40px',
    width: '40px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: '4px',
    backgroundColor: '#535B80',
    color: '#FFFFFF',
    flexShrink: 0,
  },
  tdStationName: {
    textAlign: 'left',
  },
  rightStationNameWrap: {
    paddingTop: 4,
  },
}));
/* ************ Type ************ */
type Props = {
  selectedOrderNo?: number;
  groupCategory: number;
  rows: MachineListType[];
  handleClickRow: (
    ev: React.MouseEvent<HTMLTableRowElement>,
    orderNo: number,
    rows: MachineListType[],
    handleClickNewOrderNo: (newOrderNo: number, oldOrderNo: number, machineList: MachineListType[]) => void,
  ) => void;
  className?: string;
};
/* ************ Component ************ */
const OrderSettingTable: React.FC<Props> = ({ className, groupCategory, rows, handleClickRow, selectedOrderNo }) => {
  const classes = useStyles();
  const dispatch = useAppDispatch();

  /* ************ Events ************ */

  // click new order no
  const handleClickNewOrderNo = (newOrderNo: number, oldOrderNo: number, machineList: MachineListType[]) => {
    if (newOrderNo === oldOrderNo) return; // 連動順に変更がない場合

    let newMList = [];
    if (newOrderNo > oldOrderNo) {
      // 連動順が上位になるケース
      newMList = machineList.map((m) => {
        // 選択した行は新連動順を設定
        if (oldOrderNo === m.orderNo) return { ...m, orderNo: newOrderNo };

        // 選択した行より上位の機器の連動順は維持
        if (oldOrderNo > m.orderNo) return m;

        // 選択した行の旧連動順から新連動順までの間に存在する機器の連動順は繰り上げ
        if (oldOrderNo < m.orderNo && m.orderNo <= newOrderNo) return { ...m, orderNo: m.orderNo - 1 };

        // 新連動順より下位の機器の連動順は維持
        return m;
      });
    } else {
      // 連動順が下位になるケース
      newMList = machineList.map((m) => {
        // 選択した行は新連動順を設定
        if (oldOrderNo === m.orderNo) return { ...m, orderNo: newOrderNo };

        // 新連動順より上位の機器の連動順は維持
        if (newOrderNo > m.orderNo) return m;

        // 新連動順より下位の機器から選択した行の旧連動順までの間に存在する機器は繰り下げ
        if (newOrderNo <= m.orderNo && m.orderNo < oldOrderNo) return { ...m, orderNo: m.orderNo + 1 };

        // 選択した行より下位の機器の連動順は維持
        return m;
      });
    }

    // 再並び替え
    newMList.sort((a, b) => {
      if (a.orderNo > b.orderNo) {
        return 1;
      }
      return -1;
    });

    if (groupCategory === COMMON.GROUP_CATEGORY.LED) {
      dispatch(setLedMachineList(newMList));
    } else {
      dispatch(setMachineList(newMList));
    }
  };

  // テーブル列定義
  const headerColumns = [
    { id: 'boardSerial', title: '登録番号', width: column1 },
    { id: 'machineType', title: '機種名', width: column2 },
    { id: 'stationNameAndGiftName', title: 'ステーション名 景品名', width: '100%' },
  ];

  const getStationNameFunc = React.useCallback(
    ({ machineType, stationName, leftRight, giftName }: MachineSelectStationNameType): string =>
      `${getStationName(machineType, stationName, leftRight)} ${giftName ?? COMMON.BLANK}`,
    [],
  );

  return (
    <TableContainer id="scrollContainer" className={className || classes.default}>
      <Table className={classes.table} stickyHeader aria-label="sticky table">
        <MachineStationTableHead columns={headerColumns} />
        <TableBody>
          {rows.map((row) => {
            const isSelected = row.orderNo === selectedOrderNo;
            return (
              <TableRow
                className={isSelected ? classes.trSelected : ''}
                key={row.boardSerial}
                onClick={(event) => handleClickRow(event, row.orderNo, rows, handleClickNewOrderNo)}>
                <TableCell className={`cellStyle ${classes.tdMachineName}`}>
                  <span className={classes.orderNumber}>{row.orderNo}</span>
                  <div>{row.boardSerial}</div>
                </TableCell>
                <TableCell className={`cellStyle ${classes.tdStationName}`}>
                  {renderMachineType(row.machineType)}
                </TableCell>
                <TableCell className={classes.tdStationName}>
                  {row.machineType === COMMON.MACHINE_TYPE.CLENA3 ? (
                    <>
                      <TooltipComponent
                        tooltipText={row.giftNameL}
                        displayWord={`${getStationNameFunc({
                          machineType: row.machineType,
                          stationName: row.stationNameL,
                          leftRight: COMMON.LEFT_RIGHT.LEFT,
                          giftName: row.giftNameL,
                        })}`}
                      />
                      <div className={classes.rightStationNameWrap}>
                        <TooltipComponent
                          tooltipText={row.giftNameR}
                          displayWord={`${getStationNameFunc({
                            machineType: row.machineType,
                            stationName: row.stationNameR,
                            leftRight: COMMON.LEFT_RIGHT.RIGHT,
                            giftName: row.giftNameR,
                          })}`}
                        />
                      </div>
                    </>
                  ) : (
                    <TooltipComponent
                      tooltipText={row.giftNameL}
                      displayWord={`${getStationNameFunc({
                        machineType: row.machineType,
                        stationName: row.stationNameL,
                        leftRight: COMMON.LEFT_RIGHT.LEFT,
                        giftName: row.giftNameL,
                      })}`}
                    />
                  )}
                </TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </TableContainer>
  );
};
export default memo(OrderSettingTable);
