import React, { memo } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Box, Theme } from '@material-ui/core';

// makeStylesでstyleを定義
const useStyles = makeStyles<Theme, StyleProps>((theme) => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    backgroundColor: theme.palette.common.white,
    borderRadius: '8px',
    paddingLeft: '8px',
    paddingRight: '8px',
    borderWidth: '2px',
    borderStyle: 'solid',
    textTransform: 'none',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
  },
  defaultStyle: {
    width: '200px',
    height: '48px',
    fontSize: '19px',
    borderColor: ({ borderColor }) => borderColor ?? theme.palette.common.black,
  },
}));

/* ************ Type ************ */
type StyleProps = {
  children?: React.ReactNode;
  className?: string;
  borderColor?: string;
};

/* ********** Main Component ********* */
const BorderBox: React.FC<StyleProps> = (props) => {
  const { children, className, borderColor } = props;
  const classes = useStyles({ borderColor });
  const styleClass = `${classes.root} ${className || classes.defaultStyle}`;

  return <Box className={styleClass}>{children}</Box>;
};

// レンダーが前回と変わらない場合前回のレンダーを再利用
export default memo(BorderBox);
